interface ICardWithTitleProps {
  title: string;
  classNames?: string;
}

export const CardWithTitle: React.FC<ICardWithTitleProps> = ({ title, children, classNames }) => {
  return (
    <div className={`bg-white ml-8 rounded-md shadow-md ${classNames}`}>
      <h4 className='px-12 py-4 bg-blue-600 text-white rounded-t-md font-semibold text-2xl'>{title}</h4>
      <div className='p-12'>{children}</div>
    </div>
  );
};
