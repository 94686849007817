import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ISecurity } from '@alphaswap/types';
import { useStoreActions } from 'store/hooks';
import { Layout } from 'components';
import { SymbolComponent } from 'components/stock';
import { IdeaObject } from 'interfaces';

type Params = {
  symbol?: string;
};

export const Symbol: React.FC = () => {
  const [symbolState, setSymbolState] = useState<ISecurity | null>(null);
  const [ideas, setIdeas] = useState<IdeaObject[] | null>(null);
  const { symbol } = useParams<Params>();
  const { loadSymbolData, loadIdeasWithSymbol } = useStoreActions(actions => actions.stockStore);

  useEffect(() => {
    if (symbol) {
      loadSymbolData(symbol)
        .then(response => setSymbolState(response.data))
        .catch(err => console.error(new Error(err)));

      loadIdeasWithSymbol(symbol)
        .then(response => setIdeas(response.data))
        .catch(err => console.error(new Error(err)));
    }
  }, []);

  return (
    <Layout>
      <SymbolComponent symbol={symbolState} symbolIdeas={ideas} />
    </Layout>
  );
};
