import { ThesisObject } from 'interfaces';

export enum TabNames {
  Thesis = 'thesis',
  Company = 'company',
  Financials = 'financials',
  Valuation = 'valuation',
  Esg = 'esg',
}

export interface IdeaThesisBodyProps {
  selectedTabName: string;
  thesis: ThesisObject;
}
