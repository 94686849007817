import { useFormContext } from 'react-hook-form';
import { stoplossList, timeframeList } from 'utils/data';
import { TradeType } from 'utils/enums';
import { useStoreState } from 'store/hooks';
import { LabelPicker, CountInput, SelectInput } from 'components/fields';

export const TradeStrategy = () => {
  const { errors, watch } = useFormContext();
  const {
    generateData: { entryPrice, targetPrice, tradeType, timeframe, stopLoss },
    security,
  } = useStoreState(state => state.generationStore);

  const entryPriceValue = watch('entryPrice', entryPrice);
  const tradeTypeValue = watch('tradeType', tradeType);

  return (
    <div className='grid max-w-xs gap-4'>
      <div>
        <p className='my-2 text-sm text-blue-800'>Trade type</p>
        <div>
          <div className='grid gap-4 grid-cols-2-min'>
            <LabelPicker
              defaultValue={tradeType}
              activeClass={'bg-indigo-50'}
              inputClass={'hidden'}
              labelClass={'rounded-sm cursor-pointer border border-blue-200 text-sm text-color-blue px-4 py-2'}
              name={'tradeType'}
              options={[
                {
                  label: 'LONG',
                  value: 'LONG',
                },
                {
                  label: 'SHORT',
                  value: 'SHORT',
                },
              ]}
              rules={{
                required: {
                  value: true,
                  message: 'You need to select one',
                },
              }}
            />
          </div>
          {errors.tradeType && <p className={'text-xs text-red-600'}>{errors.tradeType.message}</p>}
        </div>
      </div>
      <div>
        <p className={'my-2 text-sm text-blue-800'}>Entry price</p>
        <div className={'flex'}>
          <CountInput
            defaultValue={entryPrice || security.openPrice}
            name={'entryPrice'}
            rules={{
              required: {
                value: true,
                message: 'Fields is required',
              },
              min: {
                value: 0.00001,
                message: 'Should be more than 0',
              },
            }}
          />
        </div>
      </div>

      <div>
        <p className='my-2 text-sm text-blue-800'>Target price</p>
        <div className='flex'>
          <CountInput
            defaultValue={targetPrice}
            name={'targetPrice'}
            rules={{
              required: {
                value: true,
                message: 'Fields is required',
              },
              min: {
                value: 0.00001,
                message: 'Should be more than 0',
              },
              validate: {
                targetRange: (value: number) => {
                  if (tradeTypeValue === TradeType.SHORT)
                    return (
                      value < entryPriceValue || 'Target price need less than entry price when short type is SHORT'
                    );

                  return value > entryPriceValue || 'Target price need bigger than entry price when short type is LONG';
                },
              },
            }}
          />
        </div>
      </div>
      <div>
        <p className='my-2 text-sm text-blue-800'>Timeframe</p>
        <SelectInput
          defaultValue={timeframe ?? ''}
          options={timeframeList}
          name='timeframe'
          placeholder='Select your timeframe'
          rules={{
            required: {
              value: true,
              message: 'Fields is required',
            },
          }}
        />
      </div>
      <div className=''>
        <p className='my-2 text-sm text-blue-800'>Stop-Loss</p>
        <SelectInput
          defaultValue={stopLoss ?? ''}
          options={stoplossList}
          name='stopLoss'
          placeholder='Select your stop-loss'
          rules={{
            required: {
              value: true,
              message: 'Fields is required',
            },
          }}
        />
      </div>
    </div>
  );
};
