import { useEffect } from 'react';
import mixpanel from 'utils/mixpanelIntegration';
import { CardWithTitle } from 'components/common';
import { TextArea } from 'components/fields';
import { Banner } from 'components/common';

interface ExecutiveSummaryProps {
  flowType?: string;
}

const ExecutiveSummary = ({ flowType }: ExecutiveSummaryProps) => {
  useEffect(() => {
    mixpanel.dispatchEvent(`${flowType} idea: Executive summary`);
  }, []);

  return (
    <div id='executiveSummary' className={'pt-8'}>
      <CardWithTitle title={'Executive Summary'}>
        <Banner
          className='w-full p-2 text-xs text-gray-800 bg-blue-100 border border-blue-600 rounded lg:text-sm'
          textContent={
            'The executive summary should be a concise and convincing summary of your investment idea that catches a potential co-investor’s eye. Because it is the first thing  others see of your investment idea, it is a very important part of the overall thesis.'
          }
        />
        <p className='mx-1 my-3 text-sm text-gray-700'>Please write your Executive Summary here</p>
        <TextArea
          name='executiveSummary'
          rules={{
            required: true,
            maxLength: 400,
          }}
        />
      </CardWithTitle>
    </div>
  );
};

export default ExecutiveSummary;
