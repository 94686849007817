import { AxiosResponse } from 'axios';
import { IVote } from '@alphaswap/types';
import { client } from 'store/api';
import { ResponseStatusCode } from 'utils/enums';

// FIXME: move to separate file
interface IPaginationResult<T> {
  rows: Array<T>;
  count: number;
}

export const voteApi = {
  getVotes: async (query: string): Promise<IPaginationResult<IVote>> => {
    const response = await client.get<any, AxiosResponse<IPaginationResult<IVote>>>(`/vote?${query}`);
    if (response?.status === ResponseStatusCode.OK) {
      return response.data;
    }

    throw new Error("Can't fetch votes(s). Something went wrong");
  },
};
