import { getCountryNameByCode } from 'utils/countryHelpers';
import { toFixedCustom } from 'utils/numberHelpers';
import { Pill } from 'components/common/Pill';
import { IUserStatsCardProps } from './types';

export const UserStatsCard: React.FC<IUserStatsCardProps> = ({
  sector,
  region,
  unrealizedPerformance,
  realizedPerformance,
}) => (
  <div className={'user-stats-card bg-white p-8 rounded-md shadow-md'}>
    <div className={'grid grid-cols-auto-first gap-x-8'}>
      <div className={'sectors mb-4'}>
        <h4 className={'title uppercase text-gray-400 text-xs'}>Sector</h4>
        <div className='mt-2'>{sector ? <Pill textContent={sector} size={'small'} color={'blue'} /> : '-'}</div>
      </div>
      <div className={'region mb-4'}>
        <h4 className={'title uppercase text-gray-400 text-xs'}>Geography</h4>
        <div className={'mt-2'}>
          {region ? (
            <Pill textContent={getCountryNameByCode(region)?.label || ''} size={'small'} color={'indigo'} />
          ) : (
            '-'
          )}
        </div>
      </div>
    </div>
    <div className={'unrealizedPerformance mb-4'}>
      <h4 className={'title uppercase text-gray-400 text-xs'}>Avarage unrealized performance</h4>
      <p className='text-sm mt-2'>{toFixedCustom(unrealizedPerformance, 2, 0)}%</p>
    </div>
    <div className='realizedPerformance'>
      <h4 className={'title uppercase text-gray-400 text-xs'}>Avarage realized performance</h4>
      <p className='text-sm mt-2'>{toFixedCustom(realizedPerformance, 2, 0)}%</p>
    </div>
  </div>
);
