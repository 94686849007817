import { Link, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

import { useStoreActions, useStoreState } from 'store/hooks';
import { ArrowLeftIcon } from 'components/icons/contribution';
import { IdeaDetailsHeader, IdeaStatsBanner } from 'components/idea';
import { IdeaThesis } from 'components/idea/IdeaThesis';
import { ButtonComponent } from 'components/common';
import { Comments } from 'components/comments';
import { showErrorModal } from 'utils';
import { IdeaStatus, ResponseStatusCode } from 'utils/enums';
import { stringNewlinesToJsxWithBr } from 'utils/stringHelpers';
import { UserIdeaPageProps } from './types';

const UserIdeaPage = ({ idea }: UserIdeaPageProps): JSX.Element => {
  const { closeIdeaThunk } = useStoreActions(actions => actions.ideaStore);
  const { user } = useStoreState(state => state.userStore);
  const history = useHistory();

  const showSuccessfullyClosedIdeaModal = () => {
    Swal.fire({ title: 'Idea is closed.' })
      .then(() => history.push('/my-alphaswap/idea-management'))
      .catch(() => 'Operation is canceled.');
  };

  const handleOnIdeaClose = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: `You are about to close ${idea.security.symbol}`,
      showCancelButton: true,
      confirmButtonText: "I'm sure",
    })
      .then(async response => {
        if (response.isConfirmed) {
          const res = await closeIdeaThunk();

          if (res?.status !== ResponseStatusCode.CREATED) return showErrorModal(res);

          return showSuccessfullyClosedIdeaModal();
        }
      })
      .catch(() => 'Operation is canceled.');
  };

  const renderIdeaActions = () => {
    if (idea.status === IdeaStatus.CLOSED) return null;

    if (idea.author.id === user.id) {
      return (
        <div className={'flex justify-end my-10'}>
          <button
            type={'button'}
            className={'px-4 py-2 text-sm font-medium text-white uppercase bg-red-400 rounded-md shadow cursor-pointer'}
            onClick={handleOnIdeaClose}
          >
            Close idea
          </button>
          <Link className='ml-3' to={`/idea/${idea.id}/edit`}>
            <button
              type={'button'}
              className={'px-4 py-2 text-sm font-light text-center text-white uppercase bg-indigo-500 rounded-md'}
            >
              Edit my investment thesis
            </button>
          </Link>
        </div>
      );
    }

    const hasVoted = idea.votes?.find((vote: any) => vote.user.id === user.id);

    if (hasVoted)
      return (
        <div className='flex justify-end my-10'>
          <button
            type={'button'}
            className={'px-4 py-2 text-sm font-light text-center text-white bg-indigo-500 rounded-md'}
          >
            You already voted for this idea
          </button>
        </div>
      );

    return (
      <div className='flex justify-end my-10'>
        <Link to={`/idea/${idea.id}/vote`}>
          <button
            type={'button'}
            className={'px-4 py-2 text-sm font-light text-center text-white bg-indigo-500 rounded-md'}
          >
            Co-Invest in this idea
          </button>
        </Link>
      </div>
    );
  };

  return (
    <>
      {/* BACK BUTTON */}
      <Link to={'/'}>
        <ButtonComponent
          textContent={'BACK TO ALL IDEAS'}
          className={
            'inline-flex px-8 py-3 mt-6 font-medium text-gray-800 rounded-md shadow-sm bg-button-back-gray lg:mt-12'
          }
        >
          <ArrowLeftIcon className={'mr-2 text-gray-800 fill-current'} />
        </ButtonComponent>
      </Link>

      <IdeaDetailsHeader />

      <IdeaStatsBanner
        entryPrice={idea.entryPrice}
        currency={idea.security.currency}
        timeframe={idea.timeframe}
        classes={'px-3 py-4 mt-8 text-gray-800 bg-white border border-indigo-200 rounded-md'}
      />

      {/* EXECUTIVE SUMMARY */}
      <div className={'p-10 my-10 rounded-md shadow-md bg-indigo-50'}>
        <h4 className={'text-xl text-indigo-700'}>Executive Summary</h4>
        <p className={'mt-4 text-base text-gray-900'}>{stringNewlinesToJsxWithBr(idea.executiveSummary)}</p>
      </div>

      {renderIdeaActions()}

      {idea.thesis && <IdeaThesis />}

      <div className={'mt-8'}>
        <p className={'my-4 text-gray-800 sm:my-0'}>Community’s comments</p>

        <div className={'px-3 py-8 my-12 mt-5 bg-white rounded-md sm:px-10'}>
          <Comments isCommentInputVisible />
        </div>
      </div>
    </>
  );
};

export default UserIdeaPage;
