import { useEffect, useState } from 'react';
import { getCountryNameByCode } from 'utils/countryHelpers';
import { useStoreActions } from 'store/hooks';
import { Layout } from 'components';
import { PodcastTitle, ContributionsTable } from 'components/insights';
import { geoHeader, sectorHeader } from './data';

export const InsightsPage: React.FC = () => {
  const { loadSectorThunk, loadRegionThunk } = useStoreActions(actions => actions.insightStore);
  const [sectorBody, setSectorbody] = useState<any[]>([]);
  const [geoBody, setGeoBody] = useState<any[]>([]);

  useEffect(() => {
    loadSectorThunk()
      .then((res: any) => setSectorbody(res))
      .catch(err => console.error(new Error(err)));

    loadRegionThunk()
      .then((res: any) => setGeoBody(res))
      .catch(err => console.error(new Error(err)));
  }, []);

  const prepareSectorData = () => sectorBody?.map((item: any) => ({ data: [item.sector, item.contrib] }));
  const prepareGeoData = () =>
    geoBody?.map((item: any) => ({
      data: [item.region !== 'null' ? getCountryNameByCode(item.region as string)?.label : null, item.contrib],
    }));

  return (
    <Layout>
      <div className={'flex items-center justify-center flex-1 p-4'}>
        <div className={'container w-full mx-auto md:py-8'}>
          <h2 className={'my-5 text-indigo-800 lg:text-xl'}>Community’s Podcast</h2>
          <PodcastTitle />
          <div className={'my-5 lg:flex'}>
            <div className={'lg:px-1 lg:w-1/2'}>
              <h2 className={'my-5 text-indigo-800 lg:text-xl'}>Sectors contributions</h2>

              <div className={'p-4 mt-10 bg-white border border-indigo-100 rounded-md'}>
                <ContributionsTable header={sectorHeader} body={prepareSectorData()} />
              </div>
            </div>

            <div className={'lg:px-1 lg:w-1/2'}>
              <h2 className={'my-5 text-indigo-800 lg:text-xl'}>Region contributions</h2>

              <div className={'p-4 mt-10 bg-white border border-indigo-100 rounded-md'}>
                <ContributionsTable header={geoHeader} body={prepareGeoData()} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
