import { countryList } from './data';
import { RegionType } from '@alphaswap/types';

interface ICountryObject {
  label: string;
  value: string | null;
}

export const getCountryNameByCode = (code: string): ICountryObject | undefined =>
  countryList.find(({ value }) => value === code);

export const getRegionNameByCode = (
  region: RegionType,
  fallbackValue: ICountryObject = { label: 'Other', value: null }
): ICountryObject => countryList.find(({ value }) => value === region) || fallbackValue;
