import React from 'react';
import { format } from 'date-fns';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { IdeaObject } from '../../../interfaces';
import { StatusDot } from '../../StatusDot';

interface StockPublishedTableProps {
  symbolIdeas: IdeaObject[];
}

export const StockPublishedTable = ({ symbolIdeas }: StockPublishedTableProps) => (
  <div className='grid overflow-x-scroll p-4'>
    <p className='capitalize p-2'>Stock ideas published on this assert</p>
    <table className='w-full'>
      <thead>
        <tr>
          <th align='left' className='uppercase whitespace-nowrap text-green-400 text-sm font-normal px-2'>
            Date
          </th>
          <th
            align='left'
            className='uppercase whitespace-nowrap text-green-400 text-sm font-normal px-2 w-40 overflow-hidden overflow-ellipsis'
          >
            Alpha generator
          </th>
          <th align='center' className='uppercase whitespace-nowrap text-green-400 text-sm font-normal px-2'>
            Status
          </th>
          <th align='left' className='uppercase whitespace-nowrap text-green-400 text-sm font-normal px-2'>
            Entry price
          </th>
          <th align='left' className='uppercase whitespace-nowrap text-green-400 text-sm font-normal px-2'>
            Last price
          </th>
          <th align='left' className='uppercase whitespace-nowrap text-green-400 text-sm font-normal px-2'>
            ITD perf
          </th>
          <th align='center' className='uppercase whitespace-nowrap text-green-400 text-sm font-normal px-2'>
            Target price
          </th>
          <th align='right' className='uppercase whitespace-nowrap text-green-400 text-sm font-normal px-2'>
            Details
          </th>
        </tr>
      </thead>
      <tbody>
        {symbolIdeas &&
          symbolIdeas.map((idea, index) => (
            <tr
              className={classNames({
                'bg-blue-50': index % 2 === 1,
              })}
              key={idea.id}
            >
              <td className='px-2 py-2'>{format(new Date(idea.createdAt), 'MM.dd.y')}</td>
              <td className='px-2 py-2 w-40 overflow-hidden overflow-ellipsis'>{`${idea.author.firstName} ${idea.author.lastName}`}</td>
              <td className='px-2 py-2'>
                <div className='flex w-full justify-center'>
                  <StatusDot status={idea.status} />
                </div>
              </td>
              <td className='px-2 py-2'>{idea.status === 'LIVE' ? idea.livePrice : idea.status === 'PROPOSED' && idea.proposedPrice}</td>
              <td className='px-2 py-2'>{idea.closedPrice}</td>
              <td className='px-2 py-2'>{idea.performance}</td>
              <td className='text-center px-2 py-2'>{idea.targetPrice}</td>
              <td className='text-green-400 font-bold text-right px-2 py-2'>
                <Link to={`/idea/${idea.id}`}>{'--->'}</Link>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  </div>
);
