import { FC } from 'react';
import Select from 'react-select';
import { jobList } from '../utils/data';

interface IJobDropdownProps {
  value: any;
  onChange: (event: any) => void;
}

export const JobDropdown: FC<IJobDropdownProps> = ({ value, onChange }) => {
  const styles = {
    control: (provided: Record<string, any>): Record<string, any> => ({
      ...provided,
      height: '100%',
      padding: '0 0 0 10px',
      border: 0,
      boxShadow: 'none',
    }),
    option: (provided: Record<string, any>, state: { isSelected: boolean }): Record<string, any> => ({
      ...provided,
      paddingLeft: '20px',
      paddingRight: '20px',
      color: !state.isSelected ? '#838eab' : '#fff',
      background: !state.isSelected && 'none',
      cursor: 'pointer',
    }),
  };

  return <Select styles={styles} options={jobList} onChange={onChange} value={value} />;
};
