import { action, Action } from 'easy-peasy';

interface ValuationPayload {
  methodology?: {
    values?: number[];
    description?: string;
    custom?: string;
    current?: string;
  };
  attractiveness?: { values?: number[]; description?: string };
}

export interface ValuationState {
  methodology: {
    values: number[];
    description: string;
    custom: string;
    current: string;
  };
  attractiveness: { values: number[]; description: string; custom: '' };
}

interface ValuationAction {
  setValuationData: Action<this, ValuationPayload>;
}

export interface ValuationModel extends ValuationState, ValuationAction {}

export const valuation: ValuationModel = {
  /**
   * STATE
   */

  methodology: { values: [], description: '', custom: '', current: '' },

  attractiveness: { values: [], description: '', custom: '' },

  /**
   * ACTIONS
   */

  setValuationData: action((state, payload) => {
    state = Object.assign(state, payload);
  }),

  /**
   * THUNKS
   */
};
