import React from 'react';
import { StandardReactProps } from 'interfaces';

interface BannerProps extends StandardReactProps {
  textContent: string;
  className?: string;
}

export const Banner = ({ className, textContent }: BannerProps) => (
  <div className={className}>
    <p>{textContent}</p>
  </div>
);

Banner.defaultProps = {
  className: '',
};
