import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Card, Container, ButtonComponent } from './common';

export const IdeaDraft: FC = () => (
  <div className='flex items-center justify-center flex-1 mx-auto'>
    <Container>
      <div className='max-w-5xl px-2'>
        <h2 className='my-6 text-indigo-800 lg:text-xl'>Submit an Investment Idea</h2>
        <Card className='w-full p-2 m-auto my-3 bg-white border border-gray-500 rounded-sm lg:my-5'>
          <div className='items-center my-3 lg:my-5'>
            <Link className='nav-link' to='/idea/generate/choose-asset'>
              <ButtonComponent
                textContent='SUBMIT AN INVESTMENT IDEA'
                className='p-2 px-6 m-2 text-blue-700 border border-post-border lg:px-12'
              />
            </Link>
          </div>
          <div className='m-2 mt-16 border border-gray-500 rounded-sm'>
            <p className='p-2 text-sm text-blue-700'>DRAFTS</p>

            <div className='p-2 rounded'>
              <textarea
                style={{ resize: 'none', height: '160px' }}
                className='w-full h-full px-4 py-2 text-gray-700 focus:outline-none focus:bg-white'
              />
            </div>
          </div>
        </Card>
      </div>
    </Container>
  </div>
);
