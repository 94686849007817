import { action, Action, thunk, Thunk } from 'easy-peasy';
import { AxiosResponse } from 'axios';
import { IRootModel } from 'store/models/root.model';
import { client } from 'store/api';
import { CommentDto, IdeaObject, IdeasApiResponseObject, IdeaResponse, StatisticsResponse } from 'interfaces';
import { ResponseStatusCode } from 'utils/enums';

interface IIdeaState {
  idea: IdeaObject;
  ideas: IdeasApiResponseObject;
  thesisTab: number;
  ideaComments: any[];
  recentIdeas: IdeasApiResponseObject;
  filterNums: any;
}

interface IIdeaActions {
  setIdea: Action<this, IdeaObject>;
  mergeIdea: Action<this, any>;
  setIdeas: Action<this, IdeasApiResponseObject>;
  setThesisTab: Action<this, number>;
  setIdeaComments: Action<this, any[]>;
  setRecentIdeas: Action<this, IdeasApiResponseObject>;
  setFilterNums: Action<this, any>;
}

interface IIdeaThunks {
  loadIdeaThunk: Thunk<this, number, any, Record<string, unknown>, Promise<void>>;
  loadIdeasThunk: Thunk<this, any, undefined, IRootModel, Promise<AxiosResponse<IdeasApiResponseObject>>>;
  submitCommentThunk: Thunk<this, CommentDto, any, IRootModel, Promise<AxiosResponse>>;
  loadCommentsThunk: Thunk<this, string, any, IRootModel, Promise<AxiosResponse>>;
  loadRecentIdeasThunk: Thunk<
    this,
    undefined,
    any,
    Record<string, unknown>,
    Promise<AxiosResponse<IdeasApiResponseObject>>
  >;
  loadIdeasWithFilters: Thunk<this, string[], undefined, IRootModel, Promise<AxiosResponse>>;
  closeIdeaThunk: Thunk<this, undefined, undefined, IRootModel>;
}

export interface IIdeaModel extends IIdeaState, IIdeaActions, IIdeaThunks {}

const ideaStore: IIdeaModel = {
  idea: {} as IdeaObject,
  ideas: {
    count: 0,
    rows: [],
  },
  thesisTab: 0,
  ideaComments: [],
  recentIdeas: {
    count: 0,
    rows: [],
  },
  filterNums: [],
  /**
   * ACTIONS
   */
  setIdea: action((state, payload: IdeaObject) => {
    state.idea = payload;
  }),
  mergeIdea: action((state, payload: IdeaObject) => {
    state.idea = Object.assign(state.idea, payload);
  }),
  setIdeas: action((state, payload: IdeasApiResponseObject) => {
    state.ideas = payload;
  }),
  setThesisTab: action((state, payload) => {
    state.thesisTab = payload;
  }),
  setIdeaComments: action((state, payload) => {
    state.ideaComments = payload;
  }),
  setRecentIdeas: action((state, payload: IdeasApiResponseObject) => {
    state.recentIdeas = payload;
  }),
  setFilterNums: action((state, payload) => {
    state.filterNums = payload;
  }),
  /**
   * THUNKS
   */
  loadIdeaThunk: thunk(async (actions, ideaId: number) => {
    const ideaResponse = await client.get<any, AxiosResponse<IdeaResponse>>(`/idea/${ideaId}`);
    const authorResponse = ideaResponse.data.author?.id
      ? await client.get<any, AxiosResponse<StatisticsResponse>>(`/statistics/${ideaResponse.data.author.id}`)
      : null;

    if (ideaResponse?.status === ResponseStatusCode.OK && authorResponse?.status === ResponseStatusCode.OK) {
      const idea = {
        ...ideaResponse.data,
        author: {
          ...ideaResponse.data.author,
          statistics: authorResponse.data,
        },
      };
      actions.setIdea(idea);
    }
  }),
  loadIdeasThunk: thunk(async (actions, filters: string) => {
    const response = await client.get<any, AxiosResponse<IdeasApiResponseObject>>(`/idea?${filters}`);
    if (response?.status === ResponseStatusCode.OK) {
      actions.setIdeas(response.data);
    }
    return response;
  }),
  loadRecentIdeasThunk: thunk(async actions => {
    const response = await client.get<any, AxiosResponse<IdeasApiResponseObject>>('/idea?take=10');
    if (response?.status === ResponseStatusCode.OK) {
      actions.setRecentIdeas(response.data);
    }
    return response;
  }),
  submitCommentThunk: thunk(async (actions, payload, { getState }) => {
    const { idea } = getState();

    return client.post<any, AxiosResponse>('/comment', {
      type: payload.type,
      newStatus: null,
      content: payload.content,
      ideaId: idea.id,
      parentCommentId: payload.parentCommentId ? payload.parentCommentId : null,
    });
  }),

  loadCommentsThunk: thunk(async (actions, ideaId) => {
    const response = await client.get<any, AxiosResponse>(`/comment/idea/${ideaId}`);

    if (response?.status === ResponseStatusCode.OK) {
      actions.setIdeaComments(response.data);
    }
    return response;
  }),

  loadIdeasWithFilters: thunk(async (actions, filters) => {
    const response = await client.get<any, AxiosResponse>(`/contribution?${filters.join('&')}`);

    if (response?.status === ResponseStatusCode.OK) {
      actions.setIdeas(response.data.ideas);
      actions.setFilterNums(response.data.count);
    }
    return response;
  }),
  closeIdeaThunk: thunk(async (actions, _, { getState }) => {
    const { idea } = getState();

    try {
      const response = await client.post<any, AxiosResponse>(`/idea/${idea.id}/close`);
      if (response?.status === ResponseStatusCode.OK) {
        void actions.loadIdeaThunk(idea.id);
      }
      return response;
    } catch (error) {
      return error.request;
    }
  }),
};

export default ideaStore;
