import { useEffect } from 'react';
import { getRegionNameByCode } from 'utils/countryHelpers';
import { useStoreActions, useStoreState } from 'store/hooks';
import { Layout, Table } from 'components';
import { topIdeasColumns, topUsersColumns, topIdeaRowData, topUserRowData } from './data';
import { TopIdeaObject, TopUserObject } from 'interfaces';
import { CellType, TablePaginationActionInterface, TablePaginationActionTypeEnum } from 'components/Table/types';
import { UserCellDataType, IdeaCellDataType, CellFunction, TableName } from './types';

const ROWS_PER_PAGE = 10;

const TopIdeasAndUsersPage: React.FC = () => {
  const { topUsersList, topIdeasList } = useStoreState(state => state.topUsersAndIdeasStore);
  const { getTopUsersList, getTopIdeasList } = useStoreActions(actions => actions.topUsersAndIdeasStore);

  const loadTableData = async (type: TableName, offset: number, limit: number) => {
    try {
      return type === TableName.users
        ? await getTopUsersList({ offset, limit })
        : await getTopIdeasList({ offset, limit });
    } catch (err) {
      return console.error(new Error(err));
    }
  };

  const handleTableChange = ({ type, name }: TablePaginationActionInterface) => {
    if (type === TablePaginationActionTypeEnum.next)
      loadTableData(
        name === TableName.users ? TableName.users : TableName.ideas,
        (name === TableName.users ? topUsersList.offset : topIdeasList.offset) + ROWS_PER_PAGE,
        ROWS_PER_PAGE
      ).catch(err => console.error(new Error(err)));

    if (type === TablePaginationActionTypeEnum.previous)
      loadTableData(
        name === TableName.users ? TableName.users : TableName.ideas,
        (name === TableName.users ? topUsersList.offset : topIdeasList.offset) - ROWS_PER_PAGE,
        ROWS_PER_PAGE
      ).catch(err => console.error(new Error(err)));
  };

  const transformTopIdeasList = (topIdeas: TopIdeaObject[]): CellType[][] => {
    const cellsData: IdeaCellDataType[][] = topIdeas.map(
      ({ id, security, entryPrice, closedPrice, targetPrice, performance }) => [
        {
          cellName: 'symbol',
          cellValue: security.symbol,
        },
        {
          cellName: 'geo',
          cellValue: getRegionNameByCode(security.region).label,
        },
        {
          cellName: 'sector',
          cellValue: security.sector,
        },
        {
          cellName: 'entryPrice',
          cellValue: entryPrice,
        },
        {
          cellName: 'livePrice',
          cellValue: closedPrice,
        },
        {
          cellName: 'targetPrice',
          cellValue: targetPrice,
        },
        {
          cellName: 'performance',
          cellValue: performance,
        },
        {
          cellName: 'detailsLink',
          cellValue: id,
        },
      ]
    );

    return cellsData.map((row: IdeaCellDataType[]) =>
      row.map(({ cellName, cellValue }): CellType => {
        const cellFunc: CellFunction = topIdeaRowData[cellName];

        return cellFunc(cellValue);
      })
    );
  };

  const transformTopUsersList = (topUsers: TopUserObject[]): CellType[][] => {
    const cellsData: UserCellDataType[][] = topUsers.map(
      ({ userId, region, sector, avgRealizedPerformance, last, top, ideaCount }) => [
        {
          cellName: 'userId',
          cellValue: userId,
        },
        {
          cellName: 'geo',
          cellValue: region,
        },
        {
          cellName: 'sector',
          cellValue: sector,
        },
        {
          cellName: 'avgPerformance',
          cellValue: avgRealizedPerformance,
        },
        {
          cellName: 'idea', // Last idea
          cellValue: { symbol: last?.security?.symbol, performance: last?.performance },
        },
        {
          cellName: 'idea', // Top idea
          cellValue: { symbol: top?.security?.symbol, performance: top?.performance },
        },
        {
          cellName: 'totalIdeas',
          cellValue: ideaCount,
        },
        {
          cellName: 'detailsLink',
          cellValue: userId,
        },
      ]
    );

    return cellsData.map((row: UserCellDataType[]) =>
      row.map(({ cellName, cellValue }): CellType => {
        const cellFunc: Function = topUserRowData[cellName];

        return cellFunc(cellValue);
      })
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      await loadTableData(TableName.ideas, 0, ROWS_PER_PAGE);
      await loadTableData(TableName.users, 0, ROWS_PER_PAGE);
    };

    fetchData();
  }, []);

  return (
    <Layout>
      <div className='mt-16'>
        <div className={'mb-12'}>
          <Table
            title={'Top Open Ideas'}
            name={TableName.ideas}
            columns={topIdeasColumns}
            data={transformTopIdeasList(topIdeasList.rows)}
            date={new Date().toDateString()}
            offset={topIdeasList.offset}
            total={topIdeasList.count}
            onPageChange={handleTableChange}
            showRanking={true}
          />
        </div>
        <div className={'mb-12'}>
          <Table
            title={'Top Users'}
            name={TableName.users}
            columns={topUsersColumns}
            data={transformTopUsersList(topUsersList.rows)}
            date={new Date().toDateString()}
            offset={topUsersList.offset}
            total={topUsersList.count}
            onPageChange={handleTableChange}
            showRanking={true}
          />
        </div>
      </div>
    </Layout>
  );
};

export default TopIdeasAndUsersPage;
