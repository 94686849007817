import { useEffect } from 'react';
import useClipboard from 'react-use-clipboard';
import { ICopyToClipboardProps } from './types';

export const CopyToClipboard: React.FC<ICopyToClipboardProps> = ({ children, text, onCopy }) => {
  const [isCopied, setIsCopied] = useClipboard(text);

  useEffect(() => {
    onCopy?.(isCopied);
  }, [isCopied]);

  return <div onClick={setIsCopied}>{children}</div>;
};
