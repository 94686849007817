import React from 'react';
import { StandardReactProps } from '../../../interfaces';

interface CheverondownIconProps extends StandardReactProps {
  viewbox?: string;
}
export const CheverondownIcon = ({ viewbox, className }: CheverondownIconProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox={viewbox} className={className}>
    <path d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' />
  </svg>
);

CheverondownIcon.defaultProps = {
  viewbox: '',
};
