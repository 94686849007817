import React from 'react';
import { Link } from 'react-router-dom';
import { IdeaTableType } from '../../utils/enums';

const tableHeader = ['DATE', 'STATUS', 'TICKER', 'ENTRY PRICE', 'LAST PRICE', 'ITD PERF', 'STAKE', 'REWARD', 'MANAGE IDEA'];

interface TableProps {
  data: any;
  type?: IdeaTableType;
  publicView?: boolean;
}

export const Table = ({ data, type, publicView }: TableProps) => (
  <table className='w-full'>
    <thead>
      <tr>
        {tableHeader.map(head => (
          <td className='px-4 text-xs font-normal text-gray-500 whitespace-nowrap' key={head}>
            {head}
          </td>
        ))}
      </tr>
    </thead>
    <tbody>
      {data.map((row: any, rowIndex: number) => (
        <tr key={rowIndex}>
          {row.data.map((cell: any, cellIndex: number) => (
            <td key={cellIndex} className='px-4 py-4'>
              {cell}
            </td>
          ))}
          {type && (
            <td className='py-4'>
              <Link to={`/idea/${row.meta.id}`} className='flex-1'>
                <span className='flex items-center flex-1 px-4'>
                  <button type='button' className='px-4 py-2 text-xs font-light text-center text-white bg-indigo-500 rounded-md'>
                    {publicView && 'DETAILS'}
                    {!publicView && (type === IdeaTableType.GENERATION ? 'TRADE' : 'POST UPDATE')}
                  </button>
                </span>
              </Link>
            </td>
          )}
        </tr>
      ))}
    </tbody>
  </table>
);

Table.defaultProps = {
  type: undefined,
  publicView: false,
};
