import authStore, { Auth0Model } from './authModel/auth0.model';

import userStore, { IUserModel } from './userModel/user.model';

import onboardingStore, { IOnboardingModel } from './onboardingModel/onboarding.model';

import headerStore, { HeaderModel } from './headerModel/header.model';

import notificationStore, { INotificationModel } from './notificationModel/notification.model';

import ideaStore, { IIdeaModel } from './ideaModel/idea.model';

import generationStore, { IGenerateModel } from './generationModel/generation.model';

import voteStore, { IVoteModel } from './voteModel/vote.model';

import leaderboardStore, { LeaderboardModel } from './leaderboardModel/leaderboard.model';

import insightStore, { InsightModel } from './insightModel/insight.model';

import appStore, { AppModel } from './app.model';

import myAlphaswapStore, { IMyAlphaswapModel } from './myalphaswapModel/my-alphaswap.model';

import editThesisStore, { IEditThesisModel } from './ideaModel/editThesis.model';

import contributionStore, { IContributionModel } from './contributionModel/contribution.model';

import stockStore, { IStockModel } from './stockModel/stock.model';

import placeholderStore, { PlaceholderModel } from './placeholderModel/placeholder.model';

import topUsersAndIdeasStore, { TopUsersAndIdeasModel } from './topUsersAndIdeasModel/topUsersAndIdeasModel.model';

export interface IRootModel {
  authStore: Auth0Model;
  headerStore: HeaderModel;
  generationStore: IGenerateModel;
  voteStore: IVoteModel;
  onboardingStore: IOnboardingModel;
  userStore: IUserModel;
  ideaStore: IIdeaModel;
  notificationStore: INotificationModel;
  leaderboardStore: LeaderboardModel;
  insightStore: InsightModel;
  appStore: AppModel;
  myAlphaswapStore: IMyAlphaswapModel;
  editThesisStore: IEditThesisModel;
  contributionStore: IContributionModel;
  stockStore: IStockModel;
  placeholderStore: PlaceholderModel;
  topUsersAndIdeasStore: TopUsersAndIdeasModel;
}

const rootModel: IRootModel = {
  authStore,
  headerStore,
  generationStore,
  voteStore,
  onboardingStore,
  userStore,
  ideaStore,
  notificationStore,
  leaderboardStore,
  insightStore,
  appStore,
  myAlphaswapStore,
  editThesisStore,
  contributionStore,
  stockStore,
  placeholderStore,
  topUsersAndIdeasStore,
};

export default rootModel;
