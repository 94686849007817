import { v4 as uuid } from 'uuid';
import { TabProps, FinancialsTextTypes, TextWithoutOptions } from './types';
import { financialsQuestionsContent } from 'pages/GenerationPage/InvestmentThesisSection/content';
import { ProgressBar } from 'components/common';
import { stringNewlinesToJsxWithBr } from 'utils/stringHelpers';
import { NoDataPlaceholder } from './NoDataPlaceholder';

const text: FinancialsTextTypes = {
  balanceSheet: {
    title: 'What do you think of the company’s balance sheet - please check any statements that apply',
  },
  rates: {
    title: 'Rate the company on the following financial metrics',
  },
};

const reasonWithoutOptionsContainer = (reasonValues: any, reasonQuestionContent: any, sectionKey: 'balanceSheet') => {
  const { title }: TextWithoutOptions = text[sectionKey];

  return (
    <div className={`${sectionKey} mb-12`}>
      <h3 className='text-xl text-blue-500 font-medium mb-4'>{title}</h3>
      <ul className='flex flex-wrap'>
        {reasonValues.values.map((reasonIndex: number) => {
          return (
            <li
              key={uuid()}
              className='mt-2 py-1 px-6 rounded-lg items-center bg-blue-50 text-blue-400 border border-blue-400 mr-4'
            >
              {reasonQuestionContent.options[reasonIndex].label}
            </li>
          );
        })}
      </ul>
      {reasonValues.custom && <p>{reasonValues.custom}</p>}
      {reasonValues.description && (
        <div className='mt-8'>
          <h4 className='font-medium mb-2'>Comment:</h4>
          <p>{stringNewlinesToJsxWithBr(reasonValues.description)}</p>
        </div>
      )}
    </div>
  );
};

const FinancialsTab = ({ contentValues }: TabProps) => {
  if (!Object.keys(contentValues).length) return <NoDataPlaceholder />;

  const { balanceSheet, rates } = contentValues;
  const { balanceSheet: balanceSheetContent, rates: ratesContent } = financialsQuestionsContent;

  return (
    <div>
      <ul className='rates'>
        <h3 className='text-xl text-blue-500 font-medium mb-4'>{text.rates.title}</h3>
        {ratesContent.options.map(({ name, label }) => {
          return (
            <li key={uuid()} className='flex flex-wrap items-center justify-between mb-8'>
              <h4>{label}</h4>
              <div className='flex items-center relative w-64'>
                <p className='mr-6'>{rates[name]} / 5</p>
                <ProgressBar rate={rates[name]} limit={5} />
              </div>
            </li>
          );
        })}
      </ul>
      {rates.description && (
        <div className='mb-12'>
          <h4 className='font-medium mb-2'>Comment:</h4>
          <p>{stringNewlinesToJsxWithBr(rates.description)}</p>
        </div>
      )}
      {reasonWithoutOptionsContainer(balanceSheet, balanceSheetContent, 'balanceSheet')}
    </div>
  );
};
export default FinancialsTab;
