import { action, Action, thunk, Thunk } from 'easy-peasy';
import { AxiosResponse } from 'axios';
import { IWalletHistory, IUser } from '@alphaswap/types';
import { client } from '../../api';

export interface IWallet {
  credits: number;
  stakedCredits: number;
  pendingCash: number;
  cash: number;
  createdAt: string;
  updatedAt: string;
  walletHistory: IWalletHistory[];
}

interface IMyAlphaswapState {
  user: any;
  wallet: IWallet;
  walletHistory: any;
  ideas: any[];
  votes: any[];
}
interface IMyAlphaswapActions {
  setMyAlphaswap: Action<this, any>;
}
interface IMyAlphaswapThunks {
  loadMyAlphaswapThunk: Thunk<this, undefined, any, Record<string, unknown>, any>;
  createShareLinkThunk: Thunk<this, undefined, any, Record<string, unknown>, Promise<AxiosResponse<string>>>;
  getShareLinkThunk: Thunk<this, undefined, any, Record<string, unknown>, Promise<AxiosResponse<string>>>;
  deleteShareLinkThunk: Thunk<this, undefined, any, Record<string, unknown>, Promise<AxiosResponse<void>>>;
  getUserByShareLink: Thunk<this, string, any, Record<string, unknown>, Promise<AxiosResponse<any>>>;
  getUserByUserId: Thunk<this, string | number, any, Record<string, unknown>, any>;
}

export interface IMyAlphaswapModel extends IMyAlphaswapState, IMyAlphaswapActions, IMyAlphaswapThunks {}

const myAlphaswapStore: IMyAlphaswapModel = {
  user: {},
  wallet: {} as IWallet,
  walletHistory: [],
  ideas: [],
  votes: [],
  /**
   * ACTIONS
   */
  setMyAlphaswap: action((state, payload: IMyAlphaswapState) => {
    state = Object.assign(state, payload);
  }),

  loadMyAlphaswapThunk: thunk(async actions => {
    const { data: userData } = await client.get<any, AxiosResponse<IUser>>('/user/me');
    const { data: votes } = await client.get<any, AxiosResponse<any>>(`/vote/?userId=${userData.id}`);
    const { data: ideas } = await client.get<any, AxiosResponse<any>>(`/idea/?userId=${userData.id}`);

    const { wallet, ...user } = userData;
    const myAlphaswapStore = {
      user: {
        ...user,
        wallet,
        votes: votes.rows,
        ideas: ideas.rows,
      },
      votes: votes.rows,
      ideas: ideas.rows,
      wallet,
    };

    actions.setMyAlphaswap(myAlphaswapStore);

    return myAlphaswapStore;
  }),
  getShareLinkThunk: thunk(async () => client.get<any, AxiosResponse<string>>('/track-record')),

  createShareLinkThunk: thunk(async () => client.post<any, AxiosResponse<string>>('/track-record')),

  deleteShareLinkThunk: thunk(async () => client.delete<any, AxiosResponse<void>>('/track-record')),

  getUserByShareLink: thunk(async (actions, link) => client.get<any, AxiosResponse<void>>(`/track-record/${link}`)),

  getUserByUserId: thunk(async (actions, id) => {
    let { data: userData } = await client.get<any, AxiosResponse<IUser>>(`/user/${id}`);
    const { data: votes } = await client.get<any, AxiosResponse<any>>(`/vote/?userId=${userData.id}`);
    const { data: ideas } = await client.get<any, AxiosResponse<any>>(`/idea/?userId=${userData.id}`);

    userData = {
      ...userData,
      votes,
      ideas,
    };

    return userData;
  }),
};

export default myAlphaswapStore;
