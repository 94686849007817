import { FC, useEffect } from 'react';
import { useStore } from 'easy-peasy';

export const Logout: FC = () => {
  const store = useStore() as any;

  async function clearStore() {
    await store.persist.clear();
    window.location.href = '/auth';
  }

  useEffect(() => {
    void clearStore();
    // eslint-disable-next-line
  }, []);

  return null;
};
