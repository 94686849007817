import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { format, parse } from 'date-fns';

interface IAreaChartProps {
  data: any;
  height?: number | string;
}

const unpackTimeseries = (timeseries: any) => {
  const lineData = timeseries?.map((data: any) => {
    return {
      time: Number.isInteger(data.date)
        ? format(new Date(data.date * 1000), 'dd-MMM-yy')
        : format(parse(data.date, 'yyyy-MM-dd', new Date()), 'dd-MMM-yy'),
      price: Math.round(data.open * 100) / 100,
    };
  });

  return lineData;
};

export const RCAreaChart: React.FC<IAreaChartProps> = ({ data, height = 400 }) => (
  <div className='width-10 text-xs ' style={{ height }}>
    <ResponsiveContainer>
      <AreaChart
        data={unpackTimeseries(data)}
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 20,
        }}
      >
        <defs>
          <linearGradient id='chartColor' x1='0' y1='0' x2='0' y2='1'>
            <stop offset='20%' stopColor='#6ebb90' stopOpacity={1} />
            <stop offset='95%' stopColor='#6ebb90' stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis dataKey='time' tick={{ fill: '#4b5563' }} interval='preserveStart' tickLine={false} stroke='#e5e5e5' />
        <YAxis stroke='#e5e5e5' tick={{ fill: '#4b5563' }} orientation='right' tickLine={false} />
        <Tooltip />
        <Area dataKey='price' stroke='#00873c' strokeWidth={3} fill='url(#chartColor)' />
      </AreaChart>
    </ResponsiveContainer>
  </div>
);
