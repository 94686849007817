import React from 'react';
import '../index.scss';
import { Link } from 'react-router-dom';
import { StandardReactProps } from '../../interfaces';
import { LogoutIcon, SettingIcon } from '../icons/header';

export const UserDrop = (props: StandardReactProps) => (
  <div className='absolute top-0 right-0 z-50 mt-6 text-sm bg-white border rounded shadow-2xl w-28'>
    <Link to='/settings/accountSettings'>
      <button
        type='button'
        onClick={() => props.onClick && props.onClick(false)}
        className='flex items-center w-full p-2 px-4 hover:bg-indigo-100 focus:outline-none'
      >
        <SettingIcon />
        <p className='items-center ml-2 text-gray-900 hover:text-indigo-700'>Settings</p>
      </button>
    </Link>

    <Link to='/logout'>
      <button
        type='button'
        onClick={() => props.onClick && props.onClick(false)}
        className='flex items-center w-full p-2 px-4 hover:bg-indigo-100 focus:outline-none'
      >
        <LogoutIcon />
        <p className='flex items-center ml-2 text-gray-900 hover:text-indigo-700'>Logout</p>
      </button>
    </Link>
  </div>
);
