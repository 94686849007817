import { useState } from 'react';
import { IdeaStatsBodyProps } from './types';
import { truncateString, stringToMillions } from 'utils/stringHelpers';
import { numberToBillions } from 'utils/numberHelpers';
import { RCAreaChart } from 'components/charts';
import { BuildingIcon } from 'components/icons/common/BuildingIcon';

const IdeaStatsBody = ({ content }: IdeaStatsBodyProps) => {
  const [isTruncated, setIsTruncated] = useState(true);
  const { timeseries, openPrice, closePrice, lowPrice, highPrice, marketCap, avgVolume, description, week52Range } = content;

  const moreClickHandler = () => setIsTruncated(!isTruncated);

  const overviewContent = [
    { value: openPrice ? `$${openPrice}` : '-', label: 'Open' },
    { value: closePrice ? `$${closePrice}` : '-', label: 'Close' },
    { value: lowPrice && highPrice ? `$${lowPrice} - $${highPrice}` : '-', label: 'Day range' },
    { value: week52Range || '-', label: '52 week range' },
    { value: marketCap ? `$${numberToBillions(marketCap, 'B')}` : '-', label: 'Market Cap' },
    { value: avgVolume ? `$${stringToMillions(avgVolume, ',', 'mm')}` : '-', label: 'Volume' },
  ];

  const descriptionToRender = isTruncated ? truncateString(description, 200) : description;

  return (
    <div className='body flex flex-col-reverse lg:flex-row mt-4'>
      <div className='chart lg:w-6/12 flex-shrink-0'>
        {timeseries?.timeseries && <RCAreaChart data={timeseries.timeseries} height={300} />}
      </div>
      <div className='overview bg-blue-50 p-6 relative mt-8 lg:mt-0'>
        <div className='icon flex items-center justify-center transform absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-blue-100 h-14 w-14 rounded-full'>
          <BuildingIcon className={'text-blue-500'} />
        </div>
        <h3 className='text-blue-500 uppercase font-medium mb-2'>Overview</h3>
        <ul className='stats grid grid-cols-3 gap-y-4 auto-rows-auto'>
          {overviewContent.map(({ value, label }, index) => {
            return (
              <li key={`stats-${index}`} className='text-sm'>
                <p className='title text-gray-400'>{label}</p>
                <p className='data'>{value}</p>
              </li>
            );
          })}
        </ul>
        <div className='about mt-8'>
          <h3 className='text-blue-500 uppercase font-medium mb-2'>About {name}</h3>
          <p className='text-sm'>
            {descriptionToRender}
            <span className='ml-4 text-blue-500 cursor-pointer font-semibold' onClick={moreClickHandler}>
              {isTruncated ? 'more' : 'less'}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default IdeaStatsBody;
