import { useState, useEffect, memo } from 'react';
import { useStoreState, useStoreActions } from 'store/hooks';
import { FilterBody } from './FilterBody';
import { IFilterProps } from './types';

const queryBuilder = (filters: any) => {
  let query = '';

  Object.keys(filters).forEach(filterGroupName => {
    Object.keys(filters[filterGroupName]).forEach(filter => {
      if (filters[filterGroupName][filter].isChecked) {
        query += `&${filterGroupName}=${filter}`;
      }
    });
  });

  return query;
};

const processFilters = (filters: Record<string, Array<string>>) => {
  const proccessedFilters: any = {};

  Object.keys(filters).map((filterCategory: string) => {
    filters[filterCategory].map((filterItem: string) => {
      proccessedFilters[filterCategory] = {
        ...proccessedFilters[filterCategory],
        [filterItem]: {
          isChecked: false,
        },
      };
    });
  });

  return proccessedFilters;
};

export const Filter = memo<IFilterProps>(({ onQueryUpdate }) => {
  const { filters } = useStoreState(store => store.contributionStore);
  const [processedFilters, setProcessedFilters] = useState({});
  const { loadFiltersThunk } = useStoreActions(actions => actions.contributionStore);

  useEffect(() => {
    loadFiltersThunk().catch(err => console.error(new Error(err)));
  }, []);

  useEffect(() => {
    const query = queryBuilder(processedFilters);
    onQueryUpdate(query);
  }, [processedFilters]);

  useEffect(() => {
    const filtersToRender = processFilters(filters);
    setProcessedFilters(filtersToRender);
  }, [filters]);

  const filtersChangeHandler = (selectedFilters?: {}) => {
    if (selectedFilters) {
      setProcessedFilters(selectedFilters);
    } else {
      const filtersToRender = processFilters(filters);
      setProcessedFilters(filtersToRender);
    }
  };

  const isFiltersFetched = Boolean(Object.keys(processedFilters).length);

  return (
    <div className={'content-start hidden grid-cols-1 xl:grid grid-rows-2-auto'}>
      <div className={'grid grid-cols-1 gap-4 p-5 bg-white rounded-lg shadow'}>
        {isFiltersFetched && <FilterBody processedFilters={processedFilters} onFiltersChange={filtersChangeHandler} />}
      </div>
    </div>
  );
});
