import React from 'react';
import { SubmitIdea } from './SubmitIdea';
import { Inspiration } from './Inspiration';

export const CTA = () => (
  <div className='grid gap-4 lg:grid-cols-half-second grid-rows-2 lg:grid-rows-1'>
    <SubmitIdea />
    <Inspiration />
  </div>
);
