import { useEffect, useState } from 'react';
import { UserType } from 'utils/enums';
import { Layout } from 'components';
import { CTA } from './CTA';
import { TradeCard } from './TradeCard';
import { Filter } from './Filter';
import { useStoreActions, useStoreState } from 'store/hooks';

const IDEAS_LIMIT = 20;

export const AlphaFeedPage: React.FC = () => {
  const { loadMyAlphaswapThunk } = useStoreActions(actions => actions.myAlphaswapStore);
  const { loadIdeasThunk } = useStoreActions(actions => actions.ideaStore);
  const { ideas } = useStoreState(state => state.ideaStore);
  const { user } = useStoreState(state => state.userStore);
  const [offset, setOffset] = useState(0);
  const [query, setQuery] = useState('');
  const baseQuery = `take=${IDEAS_LIMIT}&skip=${offset}`;

  useEffect(() => {
    loadMyAlphaswapThunk();
  }, [loadMyAlphaswapThunk]);

  useEffect(() => {
    offset && setOffset(0);
  }, [query]);

  useEffect(() => {
    loadIdeasThunk(`${baseQuery}${query}`).catch(err => console.error(new Error(err)));
  }, [query, offset]);

  const changeNextStep = () => {
    setOffset(offset + 20);
  };

  const changePrevStep = () => {
    setOffset(offset - 20);
  };

  const isNavigationVisible = ideas.count > IDEAS_LIMIT;
  const isNextBtnActive = ideas.count > offset + IDEAS_LIMIT;

  return (
    <Layout>
      <div className={'grid gap-12 mx-auto my-10 xl:main-page-with-filters'}>
        <Filter onQueryUpdate={setQuery} />

        <div>
          {user.type !== UserType.CUSTOMER && (
            <div className='mb-7'>
              <CTA />
            </div>
          )}

          <div className={isNavigationVisible ? 'mb-7' : 'mb-20'}>
            <h4 className={'text-2xl text-blue-900'}>
              <span className={'text-blue-600'}>Alpha Feed</span>
              <span> · Virtually Co-Invest in the best Stock Picks</span>
            </h4>

            {isNavigationVisible && (
              <div className={'flex mt-4 justify-between'}>
                <button
                  type={'button'}
                  onClick={() => changePrevStep()}
                  className={`py-2 px-4 text-sm font-light text-white uppercase rounded-lg bg-gradient-to-r from-blue-500 to-blue-600 sm:justify-self-center whitespace-nowrap ${
                    !offset ? 'opacity-25 pointer-events-none focus:outline-none' : ''
                  }`}
                >
                  &lt;-- Previous
                </button>

                <button
                  type={'button'}
                  onClick={() => changeNextStep()}
                  className={`py-2 px-4 text-sm font-light text-white uppercase rounded-lg bg-gradient-to-r from-blue-500 to-blue-600 sm:justify-self-center whitespace-nowrap ${
                    !isNextBtnActive ? 'opacity-25 pointer-events-none focus:outline-none' : ''
                  }`}
                >
                  Next --&gt;
                </button>
              </div>
            )}
          </div>

          <div className={'grid grid-cols-1 gap-4'}>
            <TradeCard />
          </div>
        </div>
      </div>
    </Layout>
  );
};
