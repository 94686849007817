import { Banner, CardWithTitle } from 'components/common';
import { voteReasonsContent } from 'components/vote/data';
import CheckboxList from 'components/common/Inputs/CheckboxList';

interface ISentimentProps {
  reasons: any;
}

export const Sentiment: React.FC<ISentimentProps> = ({ reasons }) => (
  <div className={'pt-8'}>
    <CardWithTitle title={'Sentiment'}>
      <div className={'grid gap-5'}>
        <Banner
          className={'w-full p-2 text-xs text-gray-800 bg-blue-100 border border-blue-600 rounded lg:text-sm'}
          textContent={
            'It is important for us to gauge the sentiment of our users regarding investment ideas they co-invest in. Please let us know why want to co-invest in this investment idea by selecting one or more of the options below. If you can, please elaborate.'
          }
        />

        <div>
          <p className={'text-sm mb-2 font-semibold'}>
            What is the main reason for your co-investing in this investment idea with regards to the underlying stock?
            (multiple selections allowed)
          </p>

          <CheckboxList content={voteReasonsContent} defaultValues={{ reasons }} />
        </div>
      </div>
    </CardWithTitle>
  </div>
);
