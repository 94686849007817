/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { Controller, useFormContext } from 'react-hook-form';
import { useStoreActions, useStoreState } from '../../store/hooks';

interface StockListDropdownProps {
  errors: any;
  register: Function;
  reset: Function;
  symbol: string;
}

export const StockListDropdown = ({ errors, register, symbol, reset }: StockListDropdownProps) => {
  const { setGenerateData, fetchSecurityListThunk, resetGenerateDataState, selectSecurityThunk } = useStoreActions(
    actions => actions.generationStore
  );
  const { setLoader } = useStoreActions(actions => actions.appStore);
  const { security } = useStoreState(state => state.generationStore);

  const {
    generateData: { securityList },
  } = useStoreState(state => state.generationStore);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    symbol && fetchSecurityListThunk(symbol);
    // eslint-disable-next-line
  }, [symbol]);

  const selectSecurity = async (symbol: string) => {
    reset();
    setLoader(true);

    const security = await selectSecurityThunk(symbol);
    await resetGenerateDataState();

    if (security) {
      const { symbol, name: securityName, openPrice, closePrice } = security;
      setGenerateData({
        securityList: {
          rows: [],
          count: 0,
        },
        symbol,
        securityName,
        entryPrice: openPrice || closePrice,
      });
    }
    setLoader(false);
    setInputValue('');
  };

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const { control } = useFormContext();
  return (
    <div className='relative'>
      <Controller
        render={({ value, onChange, ref }) => (
          <input
            autoComplete='off'
            className='w-full h-10 px-4 text-xs bg-gray-100 border rounded-md'
            placeholder='Name or ticker of the company'
            value={value}
            onChange={event => {
              onChange(event);
              onChangeHandler(event);
            }}
            ref={ref}
          />
        )}
        name={'symbol'}
        control={control}
        rules={{
          validate: {
            isSecuritySelected: () => !isEmpty(security) || 'Please select a security',
          },
        }}
      />

      {errors.symbol && <p className='text-xs text-red-400'>{errors.symbol.message}</p>}
      {/* DROPDOWN */}
      {Boolean(securityList.count) && inputValue && (
        <div className='absolute left-0 z-50 w-full text-xs bg-white border rounded-sm shadow-md cursor-pointer'>
          {securityList.rows.map((security, idx) => (
            <div
              key={idx}
              className='flex px-4 py-2 hover:bg-gray-100'
              onClick={() => {
                selectSecurity(security.symbol);
              }}
            >
              <div className='w-14 font-bold flex-shrink-0'>{security.symbol}</div>
              <div className='ml-5 font-light'>{security.name}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
