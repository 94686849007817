/* eslint-disable */
import React from 'react';
import { StandardReactProps } from '../../interfaces';

interface SignProps extends StandardReactProps {
  icon: Function;
  skinColor?: string;
  iconColor?: string;
  textColor: string;
  stepText: string;
  stepContent: string;
}

export const ProcessStep = (props: SignProps) => {
  const { skinColor, stepText, stepContent, iconColor, textColor } = props;
  return (
    <div>
      <div className={`${skinColor} w-10 h-10 m-auto border rounded-full`}>
        <props.icon
          className={`${iconColor} object-cover w-full h-full px-1 fill-current`}
        />
      </div>
      <p className={`${textColor} mt-5 text-xs`}>{stepText}</p>
      <p className={`${textColor} m-1 text-sm font-semibold`}>{stepContent}</p>
    </div>
  );
};

ProcessStep.defaultProps = {
  skinColor: '',
  iconColor: '',
};
