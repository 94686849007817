import { thunk, Thunk } from 'easy-peasy';
import { AxiosResponse } from 'axios';
import { client } from 'store/api';
import { ResponseStatusCode } from 'utils/enums';

interface ILeaderboardThunks {
  loadLeaderBoardThunk: Thunk<this, undefined, any, {}, Promise<AxiosResponse | null>>;
}

export interface LeaderboardModel extends ILeaderboardThunks {}

const leaderboardStore: LeaderboardModel = {
  /**
   * THUNKS
   */

  loadLeaderBoardThunk: thunk(async () => {
    const response = await client.get<any, AxiosResponse>('/leadboard');

    if (response.status === ResponseStatusCode.OK) return response.data;

    return null;
  }),
};

export default leaderboardStore;
