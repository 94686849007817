import { FC } from 'react';
import { motion } from 'framer-motion';
import { Header } from '.';

interface ILayoutProps {
  slim?: boolean;
}

export const Layout: FC<ILayoutProps> = ({ slim, children }) => (
  <div className='flex flex-col w-full h-full min-h-screen bg-back-gray APP pb-20'>
    <Header />
    <motion.main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={`container px-4 mx-auto ${slim ? '' : 'py-2'}`}
    >
      {children}
    </motion.main>
  </div>
);

Layout.defaultProps = {
  slim: false,
};
