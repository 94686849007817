import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useStoreActions, useStoreState } from 'store/hooks';
import { CreditBalance } from './CreditBalance';
import { LastInsights } from './LastInsights';
import { LastTransactions } from './LastTransactions';
import image from 'img/placeholder-credit.png';

const lastInsightsPlaceholder = () => (
  <>
    <div className={'flex justify-center mx-auto my-16 align-items-center max-w-xl'}>
      <img src={image} alt={'avatar'} className={'object-none object-left mx-4'} />

      <div className={'flex flex-col'}>
        <p className={'px-3 mb-5 text-sm text-center'}>
          The latest Stocks Picks and virtual Co-Investments of the Community are displayed here.
        </p>

        <p className={'px-3 mb-3 text-sm text-center'}>For more, click on &quot;Alpha Feed&quot;</p>

        <div className={'flex justify-center mx-auto'}>
          <Link
            to={'/idea/generate/choose-asset'}
            className={
              'text-center flex-grow p-3 text-white bg-blue-500 hover:bg-blue-700 rounded-lg mt-2 duration-300'
            }
          >
            Submit your stock pick
          </Link>
        </div>
      </div>
    </div>
  </>
);

const DashboardPage: React.FC = () => {
  const { loadRecentIdeasThunk } = useStoreActions(actions => actions.ideaStore);
  const { loadRecentVotesThunk } = useStoreActions(actions => actions.voteStore);
  const { showCreditBalance } = useStoreState(state => state.placeholderStore);
  const { wallet } = useStoreState(state => state.myAlphaswapStore);
  const { recentIdeas } = useStoreState(state => state.ideaStore);
  const { recentVotes } = useStoreState(state => state.voteStore);

  useEffect(() => {
    loadRecentIdeasThunk();
    loadRecentVotesThunk();
  }, []);

  const isPlaceholderVisible = !recentIdeas.count && !recentVotes.length;

  return (
    <div className={'grid gap-8 my-8'}>
      <div className={'grid gap-4 lg:grid-cols-2'}>
        {wallet.walletHistory && <CreditBalance wallet={wallet} showModal={showCreditBalance} />}
        {wallet.walletHistory && <LastTransactions />}
      </div>

      <div className={'relative grid gap-8'}>
        <div className={'p-4 overflow-auto bg-white rounded-md shadow-md'}>
          <h4 className={'mb-4 text-2xl font-bold capitalize'}>Last Insights</h4>

          {isPlaceholderVisible ? (
            lastInsightsPlaceholder()
          ) : (
            <>
              {recentIdeas.count && <LastInsights data={recentIdeas.rows} label={'Last ideas'} />}

              {recentVotes && (
                <LastInsights
                  data={recentVotes.map(vote => ({ ...vote.idea!, createdAt: vote.createdAt }))}
                  label={'Last votes'}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
