import classNames from 'classnames';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { isEven, toFixedCustom } from 'utils/numberHelpers';
import { useStoreState } from 'store/hooks';
import { Arrow } from 'components/icons/alphaswap';
import placeholderInvestmentsImage from 'img/placeholder-investments.png';
import NoRecordsPlaceholder from '../NoRecordsPlaceholder';
import { IdeaObject, VoteObject } from 'interfaces';
import { IVoteTableData } from './types';

const noRecordsPlaceholderConfig = {
  textOne: 'Your last Stock Picks and virtual Co-Investments will appear here.',
  ctaLink: '/idea/generate/choose-asset',
  ctaText: 'Submit your stock pick',
  imgUrl: placeholderInvestmentsImage,
};

export const LastTransactions: React.FC = () => {
  const { user } = useStoreState(state => state.myAlphaswapStore);

  const getSortedVotes = () => {
    const prepareVotesArr = (arr: VoteObject[]) =>
      arr.map(({ createdAt, idea }) => ({
        createdAt,
        tradeType: idea.tradeType,
        symbol: idea.security.symbol,
        status: idea.status,
        generatorStake: idea.generatorStake,
        performance: idea.performance,
        id: idea.id,
      }));

    const prepareIdeasArr = (arr: IdeaObject[]) =>
      arr.map(({ createdAt, tradeType, security, status, performance, generatorStake, id }) => ({
        createdAt,
        tradeType,
        symbol: security?.symbol,
        status,
        generatorStake,
        performance,
        id,
      }));

    const sortedVotes = [...prepareVotesArr(user.votes), ...prepareIdeasArr(user.ideas)].sort(
      (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );

    return sortedVotes;
  };

  const renderTableBodyDefaultContent = () => {
    if ((!user.votes && !user.ideas) || (user.votes?.length === 0 && user.ideas?.length === 0))
      return (
        <tr>
          <td className={'p-2 text-center'} colSpan={7}>
            You have no ideas or votes
          </td>
        </tr>
      );

    return null;
  };

  return (
    <div className={'relative'}>
      <div className={'h-full p-4 overflow-x-scroll bg-white rounded-md shadow-md'}>
        <h4 className={'px-2 mb-2 font-bold capitalize'}>Previous ideas and Co-Investments</h4>
        {!user?.votes.length ? (
          <NoRecordsPlaceholder {...noRecordsPlaceholderConfig}>
            <Link
              to={'/'}
              className={
                'flex-grow text-center p-3 text-white bg-green-500 hover:bg-green-700 rounded-lg mt-2 duration-300'
              }
            >
              Co-Invest in a stock pick
            </Link>
          </NoRecordsPlaceholder>
        ) : (
          <table className={'w-full'}>
            <thead>
              <tr>
                <th className={'p-2 text-xs font-normal text-center text-gray-500 uppercase whitespace-nowrap'}>
                  Date
                </th>
                <th className={'p-2 text-xs font-normal text-center text-gray-500 uppercase whitespace-nowrap'}>
                  Type
                </th>
                <th className={'p-2 text-xs font-normal text-center text-gray-500 uppercase whitespace-nowrap'}>
                  Ticker
                </th>
                <th className={'p-2 text-xs font-normal text-center text-gray-500 uppercase whitespace-nowrap'}>
                  Status
                </th>
                <th className={'p-2 text-xs font-normal text-center text-gray-500 uppercase whitespace-nowrap'}>
                  Virtual Dollars
                </th>
                <th className={'p-2 text-xs font-normal text-center text-gray-500 uppercase whitespace-nowrap'}>
                  Perf
                </th>
                <th className={'p-2 text-xs font-normal text-center text-gray-500 uppercase whitespace-nowrap'}>
                  Details
                </th>
              </tr>
            </thead>

            <tbody className={'text-xs'}>
              {(getSortedVotes() as IVoteTableData[]).map((item, index) => (
                <tr key={index} className={classNames({ 'bg-blue-50': isEven(index) })}>
                  <td className={'p-2 font-medium text-center text-gray-800'}>
                    {format(new Date(item.createdAt), 'MM.dd.yyy')}
                  </td>
                  <td align={'center'} className={'p-2 font-medium text-center text-gray-800'}>
                    {item.tradeType}
                  </td>
                  <td align={'center'} className={'p-2 font-medium text-center text-gray-800'}>
                    {item.symbol}
                  </td>
                  <td align={'center'} className={'p-2 font-medium text-center text-gray-800'}>
                    {item.status}
                  </td>
                  <td align={'center'} className={'p-2 font-medium text-center text-gray-800'}>
                    {item.generatorStake}
                  </td>
                  <td align={'center'} className={'p-2 font-medium text-center text-gray-800'}>
                    {`${toFixedCustom(item.performance, 2)}%`}
                  </td>
                  <td className={'p-2 font-medium text-center text-gray-800'}>
                    <Link to={`/idea/${item.id}`}>
                      <Arrow className={'w-6 m-auto text-green-300 fill-current'} />
                    </Link>
                  </td>
                </tr>
              ))}

              {renderTableBodyDefaultContent()}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};
