import { useEffect, useState } from 'react';
import { getRegionNameByCode } from 'utils/countryHelpers';
import { watchListApi } from 'api/watchList';
import { IGetWatchListInterface } from 'api/types';
import { FavoriteIdeasType } from 'utils/enums';
import { Table } from 'components';
import { CellType, TablePaginationActionInterface, TablePaginationActionTypeEnum } from 'components/Table/types';
import { liveIdeaRowData, liveIdeasColumns } from './data';
import { LiveIdeaCellDataType } from './types';
import { IFavorite } from 'interfaces';

const ROWS_PER_PAGE = 10;

const LiveIdeasTable: React.FC = () => {
  const [tableData, setTableData] = useState<IGetWatchListInterface>({
    count: 0,
    offset: 0,
    rows: [],
  });

  const loadTableData = async (offset: number, limit: number) => {
    try {
      const data = await watchListApi.get({
        offset,
        limit,
        status: [FavoriteIdeasType.LIVE],
      });

      setTableData(data);
    } catch (err) {
      console.error(new Error(err));
    }
  };

  const handleTableChange = ({ type }: TablePaginationActionInterface) => {
    if (type === TablePaginationActionTypeEnum.next)
      loadTableData(tableData.offset + ROWS_PER_PAGE, ROWS_PER_PAGE).catch(err => console.error(new Error(err)));

    if (type === TablePaginationActionTypeEnum.previous)
      loadTableData(tableData.offset - ROWS_PER_PAGE, ROWS_PER_PAGE).catch(err => console.error(new Error(err)));
  };

  const transformList = (liveIdeas: IFavorite[]): CellType[][] => {
    const cellsData: LiveIdeaCellDataType[][] = liveIdeas.map(({ ideaId, idea, createdAt }) => [
      {
        cellName: 'date',
        cellValue: createdAt,
      },
      {
        cellName: 'symbol',
        cellValue: idea.security.symbol,
      },
      {
        cellName: 'geo',
        cellValue: getRegionNameByCode(idea.security.region).label,
      },
      {
        cellName: 'sector',
        cellValue: idea.security.sector,
      },
      {
        cellName: 'entryPrice',
        cellValue: idea.entryPrice,
      },
      {
        cellName: 'lastPrice',
        cellValue: idea.security.closePrice ?? 0,
      },
      {
        cellName: 'itdPerformance',
        cellValue: idea.performance,
      },
      {
        cellName: 'targetPrice',
        cellValue: idea.targetPrice,
      },
      {
        cellName: 'detailsLink',
        cellValue: ideaId,
      },
    ]);

    return cellsData.map((row: LiveIdeaCellDataType[]) =>
      row.map(({ cellName, cellValue }): CellType => {
        const cellFunc: Function = liveIdeaRowData[cellName];

        return cellFunc(cellValue);
      })
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      await loadTableData(0, ROWS_PER_PAGE);
    };

    fetchData();
  }, []);

  return (
    <div className={'mb-12'}>
      <Table
        title={'Live Ideas'}
        columns={liveIdeasColumns}
        date={new Date().toDateString()}
        data={transformList(tableData.rows)}
        total={tableData.count}
        offset={tableData.offset}
        onPageChange={handleTableChange}
      />
    </div>
  );
};

export default LiveIdeasTable;
