import { useState, useEffect } from 'react';
import { IdeasApiResponseObject, IdeaObject } from 'interfaces';
import { ideaApi } from 'api/idea';
import { Table } from 'components';
import { NotFound } from 'components/common/Placeholders/';
import { CellType, TablePaginationActionInterface, TablePaginationActionTypeEnum } from 'components/Table/types';
import { UserIdeasTableProps, IdeaCellDataType } from './types';
import { ideasColumns, ideaTableRowData } from './data';

const TABLE_LIMIT = 10;

const UserIdeasTable = ({ userId }: UserIdeasTableProps) => {
  const [userIdeas, setUserIdeas] = useState<IdeasApiResponseObject | null>(null);
  const [tablePage, setTablePage] = useState(0);

  useEffect(() => {
    const init = async () => {
      try {
        const query = `take=${TABLE_LIMIT}&skip=${TABLE_LIMIT * tablePage}&userId=${userId}`;
        const userIdeasResponse = await ideaApi.getIdeas(query);

        setUserIdeas(userIdeasResponse);
      } catch (error) {
        console.error(error);
      }
    };
    init();
  }, [tablePage]);

  const handleTableChange = ({ type }: TablePaginationActionInterface) => {
    if (type === TablePaginationActionTypeEnum.next) setTablePage(tablePage + 1);
    if (type === TablePaginationActionTypeEnum.previous) setTablePage(tablePage - 1);
  };

  const transformIdeasList = (ideas: IdeaObject[]): CellType[][] => {
    const cellsData: IdeaCellDataType[][] = ideas.map(
      ({ id, createdAt, security, entryPrice, closedPrice, livePrice, status, performance }) => [
        {
          cellName: 'date',
          cellValue: createdAt,
        },
        {
          cellName: 'status',
          cellValue: status,
        },
        {
          cellName: 'symbol',
          cellValue: security?.symbol,
        },
        {
          cellName: 'entryPrice',
          cellValue: entryPrice,
        },
        {
          cellName: 'exitPrice',
          cellValue: closedPrice,
        },
        {
          cellName: 'lastPrice',
          cellValue: livePrice || closedPrice,
        },
        {
          cellName: 'performance',
          cellValue: performance,
        },
        {
          cellName: 'detailsLink',
          cellValue: id,
        },
      ]
    );

    return cellsData.map((row: IdeaCellDataType[]) =>
      row.map(({ cellName, cellValue }): CellType => {
        const cellFunc: Function = ideaTableRowData[cellName];

        return cellFunc(cellValue);
      })
    );
  };

  return (
    <div className='bg-white p-8 rounded-md shadow-md mt-8'>
      {userIdeas?.count ? (
        <Table
          title={'Stock Picks Submitted'}
          columns={ideasColumns}
          data={transformIdeasList(userIdeas.rows)}
          offset={TABLE_LIMIT * tablePage}
          total={userIdeas.count}
          onPageChange={handleTableChange}
        />
      ) : (
        <NotFound text={'No votes submitted'} />
      )}
    </div>
  );
};

export default UserIdeasTable;
