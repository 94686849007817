import { RadioButtonsOptionType } from 'components/fields/types';

export const voteReasonsContent = {
  name: 'reasons',
  options: [
    {
      label: 'The idea generator has a strong track record.',
      name: 'strongTrackRecord',
    },
    {
      label: 'The idea generator has an impressive background.',
      name: 'impressiveBackground',
    },
    {
      label: 'The underlying investment thesis is convincing.',
      name: 'underlyingInvestmentThesisConvincing',
    },
    {
      label: 'You have high conviction in the underlying stock.',
      name: 'highConvictionUnderlyingStock',
    },
    {
      label: 'The underlying stock’s fundamental growth potential is mispriced.',
      name: 'growthPotentialIsMispriced',
    },
    {
      label: 'The management’s strength is underestimated.',
      name: 'managementStrengthIsUnderestimated',
    },
    {
      label: 'The technicals are mispriced.',
      name: 'technicalsMispriced',
    },
    {
      label: 'The underlying company’s competitive edge is mispriced.',
      name: 'underlyingCompanycompetitiveEdgeMispriced',
    },
    {
      label: 'The strength of the company’s balance sheet is mispriced.',
      name: 'companyBalanceSheetMispriced',
    },
    {
      label: 'The macro environment the company operates in is mispriced.',
      name: 'macroEnvironmentMispriced',
    },
    {
      label: 'The underlying company’s corporate action(s) are mispriced.',
      name: 'underlyingCompanyCorporateActionMispriced',
    },
    {
      label: 'Other',
      name: 'other',
    },
  ],
};

export const stockAttributesAspectOptions: RadioButtonsOptionType[] = [
  {
    label: 'The same as the idea generator',
    value: true,
  },
  {
    label: 'Different',
    value: false,
  },
];
