import { useEffect, useState, useMemo } from 'react';
import { merge } from 'lodash';
import { useHistory, useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import { client } from 'store/api';
import { useStoreState } from 'store/hooks';
import { FloatingMenu } from 'components/common/FloatingMenu';
import mixpanel from 'utils/mixpanelIntegration';
import { ResponseStatusCode } from 'utils/enums';
import { Sentiment, StocksAttributes, VoteEsg, VoteCredits, Layout } from 'components';
import { MenuOptionsKeys, IVoteFormState, IVoteIdeaPageParams } from './types';
import { menuOptions } from './data';

const transformVoteData = ({ ideaId, stake, reasons, isTncAccepted, ...voteThesis }) => ({
  ideaId: Number(ideaId),
  stake,
  thesis: {
    ...voteThesis,
    voteReasons: reasons.values,
    reasonCustom: reasons.custom,
    reasonDescription: reasons.description,
  },
  isTncAccepted,
});

export const VoteIdeaPage: React.FC = () => {
  const history = useHistory();
  const { id: ideaId } = useParams<IVoteIdeaPageParams>();
  const [step, setStep] = useState<any>(menuOptions[0]);
  const { idea } = useStoreState(state => state.ideaStore);

  const [formState, setFormState] = useState<IVoteFormState>({
    stake: 0,
    reasons: { values: [], custom: '', description: '' },
    aspect: true,
    upside: idea.targetPrice,
    downside: idea.stopLoss,
    esgScore: undefined,
    esgDescription: '',
    isTncAccepted: false,
  });

  const form = useForm({
    defaultValues: useMemo(() => formState, [formState]),
  });

  const handleOnVoteSubmit = async formData => {
    const response = await client.post('/vote', transformVoteData({ ideaId, ...formData }));

    if (response?.status === ResponseStatusCode.CREATED) {
      mixpanel.dispatchEvent('Co-invest in idea: submitted', { 'Idea ID': ideaId });
      history.goBack();
    } else {
      void Swal.fire('Error', response.data.message, 'error');
    }
  };

  useEffect(() => {
    mixpanel.dispatchEvent('Co-invest in idea: start', { 'Idea ID': ideaId });
  }, []);

  useEffect(() => {
    form.reset(formState);
  }, [formState]);

  const submitFormHandler = async () => {
    const isFormValid = await form.trigger();
    if (isFormValid) {
      if (step.key !== MenuOptionsKeys.VIRTUAL_DOLLARS) {
        setFormState(formState => merge({}, formState, form.getValues()));
        setStep(menuOptions[menuOptions.findIndex(({ key }) => key === step.key) + 1]);
      } else {
        void handleOnVoteSubmit(merge({}, formState, form.getValues()));
      }
    }
  };

  const changeTabHandler = async (selectedCategoryKey: string) => {
    const isFormValid = await form.trigger();
    if (isFormValid) {
      const nextStep = menuOptions.find(menuOption => menuOption.key === selectedCategoryKey);
      setFormState(formState => merge({}, formState, form.getValues()));

      setStep(nextStep);
    }
  };

  const sectionToRender = () => {
    switch (step.key) {
      case MenuOptionsKeys.SENTIMENT:
        return <Sentiment reasons={formState.reasons} />;
      case MenuOptionsKeys.STOCK_ATTRIBUTES:
        return <StocksAttributes aspect={formState.aspect} upside={formState.upside} downside={formState.downside} />;
      case MenuOptionsKeys.ESG:
        return <VoteEsg esgScore={formState.esgScore} esgDescription={formState.esgDescription} />;
      case MenuOptionsKeys.VIRTUAL_DOLLARS:
        return <VoteCredits stake={formState.stake} isTncAccepted={formState.isTncAccepted} />;
      default:
        return null;
    }
  };

  return (
    <Layout>
      <div className={'flex mb-8'}>
        <div className={'flex-shrink-0 pt-8'}>
          <FloatingMenu
            classes={'sticky top-8'}
            menuOptions={menuOptions}
            selectedCategory={step.key}
            onClick={changeTabHandler}
          />
        </div>

        <FormProvider {...form}>
          <form className={'flex-grow flex flex-col'} onSubmit={form.handleSubmit(submitFormHandler)}>
            {sectionToRender()}

            <button
              type={'submit'}
              className={
                'mt-4 px-8 py-2 text-sm font-light text-white uppercase rounded-lg self-end whitespace-nowrap bg-blue-600 rounded-t-md'
              }
            >
              {step.key === MenuOptionsKeys.VIRTUAL_DOLLARS ? 'Submit' : 'Next'}
            </button>
          </form>
        </FormProvider>
      </div>
    </Layout>
  );
};
