import { useEffect } from 'react';
import { businessQuestionsContent } from '../content';
import mixpanel from 'utils/mixpanelIntegration';
import { TextArea } from 'components/fields';
import { CardWithTitle } from 'components/common';

interface BusinessQuestion {
  name: string;
  title: string;
}

interface BusinessSectionProps {
  flowType?: string;
}

const Business = ({ flowType }: BusinessSectionProps) => {
  useEffect(() => {
    mixpanel.dispatchEvent(`${flowType} idea: Business`);
  }, []);

  const businessQuestions = businessQuestionsContent as BusinessQuestion;

  return (
    <div id='business' className={'pt-8'}>
      <CardWithTitle title={'Business'}>
        <p className='mx-1 my-3 text-sm text-gray-700'>{businessQuestions.title}</p>
        <TextArea name='company.companyDescription' />
      </CardWithTitle>
    </div>
  );
};

export default Business;
