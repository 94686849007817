import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Layout } from 'components';
import { useStoreActions, useStoreState } from 'store/hooks';
import { ResponseStatusCode, UserType } from 'utils/enums';
import { UserIdeaPage } from './UserIdeaPage';
import { CustomerIdeaPage } from './CustomerIdeaPage';
import { Page404 } from 'pages/Page404';

const IdeaPage: React.FC = () => {
  const [isIdeaNotFound, setIsIdeaNotFound] = useState<boolean>(false);
  const { id } = useParams<{ id: string }>();
  const { user } = useStoreState(state => state.userStore);
  const { idea } = useStoreState(state => state.ideaStore);
  const { loadIdeaThunk } = useStoreActions(actions => actions.ideaStore);
  const { setLoader } = useStoreActions(actions => actions.appStore);
  const { showLoader } = useStoreState(state => state.appStore);

  const loadIdea = async () => {
    setLoader(true);

    await loadIdeaThunk(parseInt(id));

    setIsIdeaNotFound(false);
    setLoader(false);
  };

  useEffect(() => {
    loadIdea().catch(err => {
      if (err.response?.status === ResponseStatusCode.NOT_FOUND) {
        setLoader(false);
        setIsIdeaNotFound(true);
      }
    });
  }, [id]);

  const ideaPageToRender = (userType: UserType) => {
    switch (userType) {
      case UserType.USER:
        return <UserIdeaPage idea={idea} />;
      case UserType.CUSTOMER:
        return <CustomerIdeaPage idea={idea} />;
      default:
        return <UserIdeaPage idea={idea} />;
    }
  };

  return isIdeaNotFound ? (
    <Page404 />
  ) : Object.keys(idea).length && !showLoader ? (
    <Layout>{ideaPageToRender(user.type)}</Layout>
  ) : null;
};

export default IdeaPage;
