import { FC } from 'react';
import { Link } from 'react-router-dom';

const tableHeader = [
  'RANKING',
  'USER',
  'IDEAS',
  'VOTES',
  'ESG RATING',
  'AV. PERF.',
  'ITD PERF.',
  'VIRTUAL DOLLARS',
  'PROFILE',
];

interface ILeaderTableProps {
  data: any;
}

export const LeaderboardTable: FC<ILeaderTableProps> = ({ data }) => (
  <table className='w-full'>
    <thead>
      <tr>
        {tableHeader.map((head, index) => (
          <td className='px-4 py-4 whitespace-nowrap text-xs font-normal text-gray-500' key={index}>
            {head}
          </td>
        ))}
      </tr>
    </thead>
    <tbody>
      {data.map((row: any, index: number) => {
        const profileLink = `/user/${row.data[row.data.length - 1]}`;

        return (
          <tr key={index}>
            <td className='p-3 text-indigo-800'>{index + 1}</td>
            {row.data.slice(0, -1).map((cell: any, rowIndex: number) => {
              if (rowIndex === 4 || rowIndex === 5) {
                return (
                  <td key={rowIndex} className='p-3 text-center text-green-500'>
                    {cell}
                  </td>
                );
              }

              return (
                <td key={rowIndex} className='p-3 text-center text-indigo-800'>
                  {cell}
                </td>
              );
            })}
            <td className='p-3 text-center'>
              <Link to={profileLink} className='px-4 text-center text-gray-500'>
                {'-->'}
              </Link>
            </td>
          </tr>
        );
      })}
    </tbody>
  </table>
);
