import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { formatDistance } from 'date-fns';
import { NotificationObject } from '../../interfaces';
import { BookIcon } from '../icons/generate';
import { RequestNote } from './RequestNote';
import { useStoreActions } from '../../store/hooks';

interface NotificationProps {
  notification: NotificationObject;
}

export const Notification = ({ notification }: NotificationProps) => {
  const { noteDropdown } = useStoreActions(actions => actions.headerStore);
  const [request, setRequest] = useState(false);

  function showRequest() {
    setRequest(true);
  }

  return (
    <>
      <div>
        <Link to={`/idea/${notification.targetId}`}>
          <button type='button' onClick={() => noteDropdown(false)} className='flex items-center p-2 text-xs'>
            <BookIcon className='fill-current' />
            <div className='ml-4 text-left cursor-pointer'>
              <span className='font-serif text-orange-600 '>{notification.targetName}</span>
              <span className='text-gray-800 '>{` ${notification.description}`}</span>
              <p className='text-indigo-600 '>{`${formatDistance(Date.now(), new Date(notification.days))} ago`}</p>
            </div>
          </button>
        </Link>
      </div>

      <button type='button' className='w-8 h-8 rounded-full cursor-pointer hover:bg-gray-300' onClick={showRequest}>
        ...
      </button>

      {request && <RequestNote notificationId={notification.id} />}

      {request ? (
        <button type='button' onClick={() => setRequest(false)} className='fixed inset-0 w-full h-full cursor-default focus:outline-none' />
      ) : null}
    </>
  );
};
