import { SecurityObject } from '../../../interfaces';

export const initState = {
  generationStep: 0,
  thesisTab: 0,
  security: {} as SecurityObject,
  generateData: {
    securityList: [],
    symbol: '',
    securityName: '',
    tradeType: '',
    entryPrice: 0,
    targetPrice: 0,
    stake: 0,
    timeframe: null,
    stopLoss: null,
    executiveSummary: '',
    thesis: {
      mispricingReason: {
        values: [],
        options: [
          { fundamentals: [], description: '', custom: '' },
          { specials: [], description: '', custom: '' },
          { technicals: [], description: '', custom: '' },
        ],
      },
      catalysts: {
        values: [],
        custom: '',
        description: '',
      },
      risks: {
        values: [],
        custom: '',
        description: '',
      },
    },

    company: {
      companyDescription: '',
      rates: {
        competition: null,
        entrants: null,
        suppliers: null,
        customers: null,
        threat: null,
        description: '',
      },
      management: {
        values: [],
        custom: '',
        description: '',
      },
    },

    financials: {
      rates: {
        revenue: null,
        earnings: null,
        margins: null,
        generation: null,
        strength: null,
        description: '',
      },
      balanceSheet: {
        values: [],
        description: '',
        custom: '',
      },
    },

    valuation: {
      methodology: { values: [], description: '', custom: '', current: '' },

      attractiveness: { values: [], description: '', custom: '' },
    },

    esg: {
      environment: {
        co2Emissions: 4,
        energyEfficiency: 4,
        wasteManagement: 4,
        description: '',
      },

      inclusion: {
        womenTreatment: 4,
        multiCulturality: 4,
        description: '',
      },

      shareholder: {
        corporateGovernance: 4,
        shareholderValue: 4,
        shareholderInterests: 4,
        description: '',
      },

      controversy: {
        values: [],
        description: '',
        custom: '',
      },
    },
  },
};
