import { action, Action } from 'easy-peasy';

interface EsgPayload {
  environment?: {
    co2Emissions?: number;
    energyEfficiency?: number;
    wasteManagement?: number;
    description?: string;
  };

  inclusion?: {
    womenTreatment?: number;
    multiCulturality?: number;
    description?: string;
  };

  shareholder?: {
    corporateGovernance?: number;
    shareholderValue?: number;
    shareholderInterests?: number;
    description?: string;
  };

  controversy?: number[];
  controversyDescription?: string;
}

export interface EsgState {
  environment: {
    co2Emissions: number;
    energyEfficiency: number;
    wasteManagement: number;
    description: string;
  };

  inclusion: {
    womenTreatment: number;
    multiCulturality: number;
    description: string;
  };

  shareholder: {
    corporateGovernance: number;
    shareholderValue: number;
    shareholderInterests: number;
    description: string;
  };

  controversy: {
    values: number[];
    description: string;
    custom: string;
  };
}

interface EsgAction {
  setEsgData: Action<this, EsgPayload>;
  setEnvironment: Action<this, any>;
  setInclusion: Action<this, any>;
  setShareholder: Action<this, any>;
}

export interface EsgModel extends EsgState, EsgAction {}

export const esg: EsgModel = {
  /**
   * STATE
   */
  environment: {
    co2Emissions: 4,
    energyEfficiency: 4,
    wasteManagement: 4,
    description: '',
  },

  inclusion: {
    womenTreatment: 4,
    multiCulturality: 4,
    description: '',
  },

  shareholder: {
    corporateGovernance: 4,
    shareholderValue: 4,
    shareholderInterests: 4,
    description: '',
  },

  controversy: {
    values: [],
    description: '',
    custom: '',
  },

  /**
   * ACTIONS
   */
  setEsgData: action((state, payload) => {
    state = Object.assign(state, payload);
  }),
  setEnvironment: action((state, payload) => {
    state.environment = Object.assign(state.environment, payload);
  }),
  setInclusion: action((state, payload) => {
    state.inclusion = Object.assign(state.inclusion, payload);
  }),
  setShareholder: action((state, payload) => {
    state.shareholder = Object.assign(state.shareholder, payload);
  }),
};
