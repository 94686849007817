import { AxiosResponse } from 'axios';
import { IFavorite } from '@alphaswap/types';
import { IGetWatchListApiResponse, IGetWatchListInterface, IGetWatchListParameters } from 'api/types';
import { client } from 'store/api';
import { fetchErrorHandler } from 'utils/errorHandlers';
import { parameterizeArray } from 'utils/urlHelpers';
import { ResponseStatusCode } from 'utils/enums';

export const watchListApi = {
  get: async ({ offset, limit, status }: IGetWatchListParameters): Promise<IGetWatchListInterface> => {
    const response = await client.get<string, AxiosResponse<IGetWatchListApiResponse>>(
      `/favorite?skip=${offset}&take=${limit}&${status ? parameterizeArray('status', status) : ''}`
    );

    if (response.status === ResponseStatusCode.OK) return { ...response.data, offset };

    throw fetchErrorHandler(response);
  },
  addIdea: async (ideaId: number): Promise<IFavorite> => {
    const response = await client.post<string, AxiosResponse<IFavorite>>(`/favorite/${ideaId}`);

    if (response.status === ResponseStatusCode.CREATED) return response.data;

    throw fetchErrorHandler(response);
  },
  removeIdea: async (ideaId: number): Promise<IFavorite> => {
    const response = await client.delete<string, AxiosResponse<IFavorite>>(`/favorite/${ideaId}`);

    if (response.status === ResponseStatusCode.OK) return response.data;

    throw fetchErrorHandler(response);
  },
};
