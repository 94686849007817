import { IUser } from '@alphaswap/types';
import mixpanel from 'mixpanel-browser';

interface IMP {
  init: () => void;
  login: (user: any) => void;
  dispatchEvent: (eventName: string, eventData?: any) => void;
}

const TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN || '';

const mp: IMP = {
  init() {
    mixpanel.init(TOKEN, { api_host: process.env.REACT_APP_MIXPANEL_URL }, '');
  },
  login(user: IUser) {
    const { id, firstName, lastName, email, createdAt } = user;
    mixpanel.identify(`${id}`);

    mixpanel.people.set_once({
      $name: `${firstName} ${lastName}`,
      $email: email,
      'Sign up date': createdAt,
      USER_ID: id,
    });

    this.dispatchEvent('Login');
  },
  dispatchEvent: function (eventName: string, eventObject: Record<string, any> = {}) {
    mixpanel.track(eventName, eventObject);
  },
};

export default mp;
