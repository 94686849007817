import React from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import classNames from 'classnames';
import { CheverondownIcon, CheveronupIcon } from '../icons/generate';

interface LabelPicker {
  name: string;
  defaultValue: string | number;
  rules?: RegisterOptions;
  withoutControls?: boolean;
}

export const CountInput = ({ name, defaultValue, rules, withoutControls }: LabelPicker) => {
  const { control, setValue, errors } = useFormContext();
  return (
    <div className='grid grid-cols-1 gap-2'>
      <Controller
        rules={rules}
        defaultValue={defaultValue}
        name={name}
        control={control}
        render={({ onChange, ref, value }) => (
          <div className='flex'>
            <input
              ref={ref}
              className={classNames({
                'w-40 h-8 p-2 text-sm placeholder-opacity-75 border text-color-blue border-blue-200 form-control focus:outline-none focus:bg-white':
                  true,
                [withoutControls ? 'rounded' : 'rounded-l']: true,
              })}
              type='number'
              value={value}
              onChange={({ target }) => onChange(parseFloat(target.value))}
            />
            <div className='grid w-8 h-8'>
              {!withoutControls && (
                <>
                  <button
                    type='button'
                    className='w-8 h-4 border border-blue-200 rounded-tr cursor-pointer focus:outline-none'
                    onClick={() => setValue(name, parseFloat(value) + 1)}
                  >
                    <CheveronupIcon className='text-center border-blue-200 fill-current' viewbox='-15 1 50 17' />
                  </button>
                  <button
                    type='button'
                    className='w-8 h-4 border border-blue-200 rounded-br cursor-pointer focus:outline-none'
                    onClick={() => setValue(name, parseFloat(value) - 1)}
                  >
                    <CheverondownIcon className='text-center border-blue-200 fill-current' viewbox='-15 1 50 17' />
                  </button>
                </>
              )}
            </div>
          </div>
        )}
      />
      {errors[name] && <p className='text-xs text-red-600'>{errors[name].message}</p>}
    </div>
  );
};

CountInput.defaultProps = {
  rules: undefined,
  withoutControls: undefined,
};
