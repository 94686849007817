/* eslint-disable */
import React from 'react';

export const ToogleButton2 = () => (
  <div>
    <div className="relative inline-block w-10 mr-2 align-middle transition duration-200 ease-in select-none">
      <input
        type="checkbox"
        name="toggle"
        id="toggle"
        className="absolute block w-5 h-5 bg-white border-2 rounded-full appearance-none cursor-pointer focus:outline-none toggle-checkbox"
      />
      <label className="block h-5 overflow-hidden bg-gray-300 rounded-full cursor-pointer toggle-label" />
    </div>
  </div>
);
