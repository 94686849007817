import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import { Table, TrackSector, TrackUser } from '../../../components/my-alphaswap';
import { useStoreActions } from '../../../store/hooks';
import { renderExpectedReturn } from '../../../utils';
import { IdeaTableType } from '../../../utils/enums';

export const mapStatusToColor = {
  LIVE: 'bg-green-500',
  PENDING: 'bg-yellow-500',
  DECLINED: 'bg-red-500',
};

interface DotProps {
  status: keyof typeof mapStatusToColor;
}

const Dot = ({ status }: DotProps) => <div className={`w-3 h-3 rounded-full ${mapStatusToColor[status]}`} />;

type UserPageType = 'userPage' | 'trackRecordPage';

interface UserPageProps {
  page: UserPageType;
}

export const UserPageDeprecated = ({ page }: UserPageProps) => {
  const { link } = useParams<{ link: string }>();
  const [user, setUser] = useState<any>(null);

  const { getUserByShareLink, getUserByUserId } = useStoreActions(state => state.myAlphaswapStore);

  const getProfileByShareLink = async () => {
    const response = await getUserByShareLink(link);
    setUser(response.data);
  };

  const getProfileByUserId = async () => {
    const user = await getUserByUserId(link);
    setUser(user);
  };

  const getProfile = async () => {
    switch (page) {
      default:
      case 'trackRecordPage':
        getProfileByShareLink();
        break;
      case 'userPage':
        getProfileByUserId();
        break;
    }
  };

  useEffect(() => {
    if (link) {
      getProfile();
    }
  }, []);

  function buildSectorsForTrackSector() {
    const sectors = new Set();
    user.ideas?.forEach((idea: any) => {
      sectors.add(idea.security.sector);
    });
    return Array.from(sectors).slice(0, 3);
  }

  function buildGeographiesForTrackSector() {
    const geographies = new Set();
    user.ideas?.forEach((idea: any) => {
      geographies.add(idea.security.region);
    });
    return Array.from(geographies).slice(0, 3);
  }

  function buildGeneratedIdeasData() {
    return user.ideas?.map((idea: any) => ({
      meta: {
        id: idea.id,
      },
      data: [
        format(new Date(idea.createdAt), 'yyyy.MM.dd'),
        <div className='flex justify-center'>
          <Dot status={idea.status} />
        </div>,
        idea.security.symbol,
        idea.livePrice,
        idea.security.closePrice,
        renderExpectedReturn(idea.status, idea.tradeType, idea.livePrice, idea.closedPrice, idea.security.closePrice),
        idea.generatorStake.toString(),
        '-',
      ],
    }));
  }

  function buildVotedIdeasData() {
    return user.votes?.map((vote: any) => ({
      meta: {
        id: vote.idea?.id,
      },
      data: [
        format(new Date(vote.idea.createdAt), 'yyyy.MM.dd'),
        vote.idea.status,
        vote.idea.security.symbol,
        vote.idea.livePrice,
        vote.idea.security.closePrice,
        renderExpectedReturn(
          vote.idea.status,
          vote.idea.tradeType,
          vote.idea.livePrice,
          vote.idea.closedPrice,
          vote.idea.security.closePrice
        ),
        vote.idea.generatorStake.toString(),
        '-',
      ],
    }));
  }

  return user ? (
    <div className='max-w-6xl p-10 mx-auto my-8 bg-white border rounded-md'>
      <div className='flex items-center justify-between mb-10'>
        <p>
          <span>Track Record of </span>
          <span className='ml-1 text-xl font-medium cursor-pointer'>
            {user?.firstName} {user?.lastName}
          </span>
        </p>
      </div>

      <div className='grid justify-center gap-4 my-4 sm:grid-cols-2 sm:justify-start'>
        {user && <TrackUser user={user} />}
        <TrackSector sectors={buildSectorsForTrackSector()} geographies={buildGeographiesForTrackSector()} />
      </div>

      <div className='py-4 mx-auto my-8 rounded'>
        <div className='mb-10'>
          <div className='overflow-x-scroll bg-white border border-indigo-100 rounded-md'>
            <p className='p-4 text-gray-800'>Idea submitted ({user?.ideas?.length})</p>
            {user.ideas && buildGeneratedIdeasData().length > 0 && (
              <Table publicView data={buildGeneratedIdeasData()} type={IdeaTableType.GENERATION} />
            )}
          </div>
        </div>

        <div>
          <div className='overflow-x-scroll bg-white border border-indigo-100 rounded-md'>
            <p className='p-4 text-gray-800'>Ideas voted for ({user?.votes?.length})</p>
            {user.votes && buildVotedIdeasData().length > 0 && (
              <Table publicView data={buildVotedIdeasData()} type={IdeaTableType.VOTE} />
            )}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div />
  );
};
