interface ArrowProps {
  className?: string;
}

export const Arrow = ({ className }: ArrowProps) => (
  <svg
    className={className}
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    x='0px'
    y='0px'
    viewBox='0 0 769.2 476.2'
    xmlSpace='preserve'
  >
    <polygon points='638.6,107.5 617.4,128.7 711.8,223.1 0,223.1 0,253.1 711.8,253.1 617.4,347.5 638.6,368.7 769.2,238.1' />
  </svg>
);

Arrow.defaultProps = {
  className: '',
};
