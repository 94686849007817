export const menuOptions = [
  {
    key: 'generalInformation',
    title: 'General Information',
  },
  {
    key: 'investmentThesis',
    title: 'Investment Thesis',
  },
  {
    key: 'communityComments',
    title: "Community's Comments",
  },
  {
    key: 'votersInsights',
    title: 'Voters Insights',
  },
  {
    key: 'trackRecord',
    title: 'Generator Track Record',
  },
];
