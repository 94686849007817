/* eslint-disable */
import React, { useState } from 'react';
import { UserDrop } from './UserDropdown';
import {useStoreState} from '../../store/hooks';

export const UserProfile = () => {
  const [showMenu, setShowMenu] = useState(false);
  const { user } = useStoreState((state) => state.userStore);

  function showUser() {
    setShowMenu(!showMenu);
  }
  return (
    <div className="flex items-center">
      <button
        type='button'
        onClick={showUser}
        className="w-10 h-10 ml-3 overflow-hidden rounded-full focus:border-indigo-200 focus:outline-none"
      >
        <img src={user?.profilePicture ?? ''} alt="avatar" className="w-full h-full" />
      </button>

      {showMenu ? (
        <button
          type='button'
          onClick={() => setShowMenu(false)}
          className="fixed inset-0 w-full h-full cursor-default focus:outline-none"
        />
      ) : null}

      <div className="relative ">
        {showMenu ? <UserDrop onClick={showUser} /> : null}
      </div>
    </div>
  );
};
