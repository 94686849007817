import { FC } from 'react';
import { NotFoundProps } from './types';
import SearchImage from 'img/searching-man.png';

export const NotFound: FC<NotFoundProps> = ({ text = 'Information is missing', children, imageSize = 'w-32' }) => {
  return (
    <div className='py-8 flex flex-col items-center'>
      <div className='image'>
        <img src={SearchImage} alt='search image' className={`${imageSize} max-w-full"`} />
      </div>
      <p className='mt-4 font-bold'>{text}</p>
      {children}
    </div>
  );
};
