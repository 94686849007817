import { AxiosResponse } from 'axios';
import { IdeasApiResponseObject } from 'interfaces';
import { client } from 'store/api';
import { ResponseStatusCode } from 'utils/enums';

export const ideaApi = {
  getIdeas: async (query: string): Promise<any> => {
    const response = await client.get<any, AxiosResponse<IdeasApiResponseObject>>(`/idea?${query}`);
    if (response?.status === ResponseStatusCode.OK) {
      return response.data;
    }

    throw new Error("Can't fetch idea(s). Something went wrong");
  },
};
