import React from 'react';
import classNames from 'classnames';
import { CardBackground } from '../../../components/icons/contribution/CardBackground';

interface CardProps {
  children: React.ReactNode;
  className?: string;
}

export const Card = ({ children, className }: CardProps) => (
  <div className={classNames('overflow-hidden rounded-lg relative  p-6', className)}>
    {children}
    <CardBackground className='absolute top-0 bottom-0 right-0 z-10 h-full transform scale-150 sm:scale-100' />
  </div>
);

Card.defaultProps = {
  className: '',
};
