export const numberToBillions = (number = 0, suffix = ''): string => {
  return `${Math.floor(number / 100000000) / 100}${suffix}`;
};

export const numberToMillions = (number = 0, suffix = ''): string => {
  return `${Math.floor(number / 10000) / 100}${suffix}`;
};

export const getMedian = (numbers: number[]): number => {
  const arrayLength = numbers.length;
  if (!arrayLength || arrayLength === 1) return numbers[0] || 0;

  const sortNumbers = numbers.sort((a, b) => a - b);
  const index = Math.ceil((arrayLength - 1) / 2);

  return arrayLength % 2 ? sortNumbers[index] : (sortNumbers[index] + sortNumbers[index - 1]) / 2;
};

export const toFixedCustom = (
  number: string | number | null | undefined,
  decimalQty: number,
  fallbackSymbol: string | number = '-'
): string => {
  return typeof number === 'number' ? number.toFixed(decimalQty) : `${fallbackSymbol}`;
};

export const isEven = (index: number): boolean => index % 2 === 1;
