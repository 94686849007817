import { numberToMillions } from './numberHelpers';

export const truncateString = (string = '', length: number): string => {
  if (typeof string !== 'string') return '';

  return `${string.slice(0, length).trim()}...`;
};

export const stringToNumber = (string: string, separator = ' '): number => {
  return parseInt(string.split(separator).join(''));
};

export const stringToMillions = (string: string, separator: string, suffix: string): string => {
  if (!string) return '-';
  const number = stringToNumber(string, separator);

  return numberToMillions(number, suffix);
};

export const stringNewlinesToJsxWithBr = (string: string): JSX.Element[] =>
  string.split('\n').map((line, index) => (
    <span key={`string-brake-index-${index}`}>
      {line}
      <br />
    </span>
  ));
