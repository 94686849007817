import { useStoreState } from 'store/hooks';
import { placeholderText } from 'utils/data';
import { Banner, ButtonComponent, Card, Pill } from 'components/common';
import { PlayIcon } from 'components/icons/insights/PlayIcon';

export const PodcastTitle: React.FC = () => {
  const { user } = useStoreState(state => state.userStore);

  return (
    <Card className={'relative p-8 border border-indigo-400 rounded-lg shadow md:flex bg-pod-back md:justify-between'}>
      <Card className={'w-full border-b border-indigo-400 md:flex md:w-3/5 md:border-r md:border-b-0'}>
        <img src={user.profilePicture} alt={'avatar'} className={'w-16 h-16 mr-5 rounded-full'} />

        <div className={'w-full px-4 md:p-0 md:w-3/4'}>
          <div className={'flex items-center'}>
            <h2 className={'mr-3 text-lg text-indigo-800'}>GAN Limited (GAN)</h2>
            <Pill size={'medium'} color={'yellow'} textContent={'New'} />
          </div>

          <p className={'text-xs font-light text-gray-500'}>PUBLISHED 10.10.20</p>

          <Banner className={'w-full my-3 text-xs font-light text-gray-800 lg:text-sm'} textContent={placeholderText} />

          <a href={'https://youtu.be/T8U9nOA1eM4'} rel={'noreferrer noopener'} target={'_blank'}>
            <ButtonComponent
              className={'flex px-4 py-2 text-white bg-blue-400 rounded shadow'}
              textContent={'LISTEN THE PODCAST'}
            >
              <PlayIcon className={'w-5 h-5 text-white fill-current'} />
            </ButtonComponent>
          </a>
        </div>
      </Card>

      <Card className={'w-full px-4 md:w-2/5'}>
        <p className={'mt-2 text-xs font-light text-gray-500'}>LAST PODCASTS</p>

        <div className={'my-4'}>
          <a href={'https://youtu.be/T8U9nOA1eM4'} rel={'noreferrer noopener'} target={'_blank'}>
            <p className={'font-medium text-indigo-800'}>GAN Limited (GAN)</p>
            <p className={'text-xs font-light text-gray-500'}>PUBLISHED 10.10.20</p>
          </a>
        </div>

        <div className={'my-4'}>
          <a href={'https://youtu.be/5SRib6yLTos'} rel={'noreferrer noopener'} target={'_blank'}>
            <p className={'font-medium text-indigo-800'}>Ebro Foods S.A (EBRO)</p>
            <p className={'text-xs font-light text-gray-500'}>PUBLISHED 10.10.20</p>
          </a>
        </div>

        <div className={'my-4'}>
          <a href={'https://youtu.be/2_Tmfb5-480'} rel={'noreferrer noopener'} target={'_blank'}>
            <p className={'font-medium text-indigo-800'}>Lynas Rare Earths Ltd (LYC)</p>
            <p className={'text-xs font-light text-gray-500'}>PUBLISHED 10.10.20</p>
          </a>
        </div>
      </Card>
    </Card>
  );
};
