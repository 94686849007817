import React, { useEffect, useImperativeHandle } from 'react';
import mixpanel from 'utils/mixpanelIntegration';
import { TextArea } from '../fields';
import { Banner } from '../common';

interface ExecutiveSummarySectionProps {
  innerRef: React.RefObject<{ saveFormData(formData: any): void }>;
  formatRefactorCallback?: (data: any) => Object;
  defaultValues?: any;
  flowType?: string;
}

export const ExecutiveSummarySection = ({
  innerRef,
  formatRefactorCallback = (data: any) => data,
  defaultValues,
  flowType,
}: ExecutiveSummarySectionProps) => {
  useEffect(() => {
    mixpanel.dispatchEvent(`${flowType} idea: Executive summary`);
  }, []);

  useImperativeHandle(innerRef, () => ({
    saveFormData(formData: any) {
      return formatRefactorCallback(formData);
    },
  }));

  return (
    <div>
      <Banner
        className='w-full p-2 mt-3 text-xs text-gray-800 bg-blue-100 border border-blue-600 rounded lg:text-sm'
        textContent={
          'The executive summary should be a concise and convincing summary of your investment idea that catches a potential co-investor’s eye. Because it is the first thing  others see of your investment idea, it is a very important part of the overall thesis.'
        }
      />
      <p className='mx-1 my-3 text-sm text-gray-700'>Please write your Executive Summary here</p>
      <TextArea
        defaultValue={defaultValues.executiveSummary}
        name='executiveSummary'
        rules={{
          required: {
            value: true,
            message: 'Field is required',
          },
          maxLength: 400,
        }}
      />
    </div>
  );
};

ExecutiveSummarySection.defaultProps = {
  formatRefactorCallback: (data: any) => data,
  defaultValues: '',
};
