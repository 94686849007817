import { useEffect } from 'react';
import { Banner } from 'components/common/';
import mixpanel from 'utils/mixpanelIntegration';
import { valuationQuestionsContent } from '../content';
import { CardWithTitle } from 'components/common';
import TextInput from 'components/common/Inputs/TextInput';
import CheckboxList from 'components/common/Inputs/CheckboxList';

interface ValuationSectionProps {
  defaultValues: any;
  flowType?: string;
}

const Valuation = ({ defaultValues, flowType }: ValuationSectionProps) => {
  useEffect(() => {
    mixpanel.dispatchEvent(`${flowType} idea: Valuation`);
  }, []);

  return (
    <div id='valuation' className={'pt-8'}>
      <CardWithTitle title={'Valuation'}>
        <Banner
          className='w-full p-2 mt-3 text-xs text-gray-800 bg-blue-100 border border-blue-600 rounded lg:text-sm'
          textContent={valuationQuestionsContent.banner}
        />

        <div className='grid gap-4'>
          <p className='mt-6 text-sm font-semibold'>{valuationQuestionsContent.methodology.title}</p>
          <CheckboxList
            content={valuationQuestionsContent.methodology}
            defaultValues={defaultValues}
            sectionType='valuation'
          />
          <div>
            <p className='py-2 text-sm font-semibold'>{valuationQuestionsContent.methodology.currentTrade}</p>

            <TextInput name={'valuation.methodology.current'} fullWidth />
          </div>
        </div>
        <div className='grid gap-4'>
          <p className='mt-6 text-sm font-semibold'>{valuationQuestionsContent.attractiveness.title}</p>
          <CheckboxList
            content={valuationQuestionsContent.attractiveness}
            defaultValues={defaultValues}
            sectionType='valuation'
          />
        </div>
      </CardWithTitle>
    </div>
  );
};

export default Valuation;
