import { action, Action, Thunk, thunk } from 'easy-peasy';
import { AxiosResponse } from 'axios';
import { VoteIdeaPayload, VotesApiResponseObject } from 'interfaces';
import { client } from 'store/api';
import { IRootModel } from 'store/models/root.model';
import { ResponseStatusCode } from 'utils/enums';
import { initState } from './initValues';

interface IVoteState {
  recentVotes: VoteIdeaPayload[];
  step: number;
  voteData: {
    stake: number;
    voteReasons: number[];
    reasonCustom: string;
    reasonDescription: string;
    aspect: boolean;
    upside: number;
    downside: number;
    esgScore: number | undefined;
    esgDescription: string;
  };
}

interface IVoteAction {
  setStep: Action<this, number>;
  setVotedata: Action<this, VoteIdeaPayload>;
  setRecentVotes: Action<this, VotesApiResponseObject>;
  resetState: Action<this>;
}

interface IVoteThunks {
  submitVoteThunk: Thunk<this, undefined, undefined, IRootModel>;
  loadRecentVotesThunk: Thunk<
    this,
    undefined,
    any,
    Record<string, unknown>,
    Promise<AxiosResponse<VotesApiResponseObject>>
  >;
}

export interface IVoteModel extends IVoteState, IVoteAction, IVoteThunks {}

const voteStore: IVoteModel = {
  /**
   * STATE
   */
  recentVotes: [] as VoteIdeaPayload[],
  step: 0,
  voteData: {
    ...initState,
  },

  /**
   * ACTIONS
   */
  setStep: action((state, payload) => {
    state.step = payload;
  }),

  setVotedata: action((state, payload) => {
    state.voteData = Object.assign(state.voteData, payload);
  }),

  setRecentVotes: action((state, payload: VotesApiResponseObject) => {
    state.recentVotes = payload.rows;
  }),

  resetState: action(state => {
    state.step = 0;
    state.voteData = { ...initState };
  }),

  submitVoteThunk: thunk(async (actions, _, { getState, getStoreState }) => {
    const { voteData } = getState();
    const { idea } = getStoreState().ideaStore;
    const { stake, ...voteThesis } = voteData;
    const response = await client.post('/vote', {
      ideaId: idea.id,
      thesis: voteThesis,
      stake,
    });
    actions.resetState();

    return response;
  }),
  loadRecentVotesThunk: thunk(async actions => {
    const response = await client.get<any, AxiosResponse<VotesApiResponseObject>>('/vote?take=10');

    if (response?.status === ResponseStatusCode.OK) {
      actions.setRecentVotes(response.data);
    }

    return response;
  }),
};

export default voteStore;
