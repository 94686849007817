import React from 'react';
import { motion } from 'framer-motion';
import classNames from 'classnames';
import { Header, MyAlphaswapSubMenu } from 'components';

interface IMyAlphaSwapPageProps {
  hideTab?: boolean;
  raducedTopMargin?: boolean;
}

export const MyAlphaSwapPage: React.FC<IMyAlphaSwapPageProps> = ({ hideTab, raducedTopMargin, children }) => (
  <div className='min-h-screen pb-2 bg-back-gray APP'>
    <Header />
    {!hideTab && <MyAlphaswapSubMenu />}
    <motion.main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={classNames('container px-4 mx-auto', {
        'mt-28': hideTab && !raducedTopMargin,
        'mt-20': raducedTopMargin,
      })}
    >
      {children}
    </motion.main>
  </div>
);

MyAlphaSwapPage.defaultProps = {
  hideTab: false,
};
