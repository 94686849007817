import { FC } from 'react';
import { VotersAllocationProps } from './types';
import { toFixedCustom } from 'utils/numberHelpers';

const VotersAllocation: FC<VotersAllocationProps> = ({ votersAverageAllocation }) => {
  const allocationToRender = toFixedCustom(votersAverageAllocation, 2, 0);

  return (
    <div className='bg-white p-8 rounded-md shadow-md flex flex-col items-center justify-center'>
      <h2 className='mb-4 font-medium'>Average allocation</h2>
      <p className='font-bold text-7xl text-blue-500'>{allocationToRender}%</p>
    </div>
  );
};

export default VotersAllocation;
