import { FC } from 'react';

interface ICreditsCardProps {
  color: string;
  title: string;
  value: number;
}

export const CreditsCard: FC<ICreditsCardProps> = ({ color, title, value }) => (
  <div className='px-4 py-2 m-3 bg-gray-100 border border-gray-400 rounded-sm shadow-sm sm:w-1/3'>
    <p className='text-xs text-gray-800 sm:text-sm'>{title}</p>
    <p className={`${color} mt-2 text-base font-semibold md:text-lg`}>{value}</p>
    {/* <p className="mt-2 text-xs text-gray-500">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit
    </p>
    <p className="mt-2 text-xs text-gray-500">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit
    </p>
    <p className="mt-2 text-xs text-gray-500">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit
    </p> */}
  </div>
);
