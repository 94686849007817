import React from 'react';
import { StandardReactProps } from '../../../interfaces';

export const GoogleIcon = ({ className }: StandardReactProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 47 48' className={className}>
    <g transform='translate(-401-860)'>
      <g transform='translate(401 860)'>
        <g fillRule='evenodd' fill='#FBBC05'>
          <path d='M9.82 24c0-1.53.25-2.99.7-4.36L2.61 13.6C1.06 16.72.2 20.25.2 23.99c-.001 3.73.86 7.26 2.4 10.38l7.9-6.06c-.45-1.37-.7-2.83-.7-4.34' />
          <path
            fill='#EB4335'
            d='M23.71 10.13c3.31 0 6.3 1.17 8.65 3.09l6.83-6.83C35.02 2.76 29.68.52 23.7.52 14.41.52 6.43 5.83 2.6 13.59l7.9 6.03c1.82-5.54 7.01-9.52 13.18-9.52'
          />
          <path
            fill='#34A853'
            d='M23.71 37.86c-6.17 0-11.36-3.98-13.19-9.52l-7.91 6.03c3.82 7.76 11.8 13.07 21.09 13.07 5.73 0 11.2-2.04 15.31-5.85l-7.51-5.81c-2.12 1.33-4.79 2.05-7.81 2.05'
          />
          <path
            fill='#4285F4'
            d='M46.14 24c0-1.39-.22-2.88-.54-4.27H23.7v9.06h12.6c-.64 3.09-2.35 5.46-4.81 7.01l7.5 5.8c4.31-4.01 7.12-9.97 7.12-17.62'
          />
        </g>
      </g>
    </g>
  </svg>
);
