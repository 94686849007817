export enum CategoryType {
  CATEGORY = 'category',
  SUBCATEGORY = 'subcategory',
}

export interface IMenuOption {
  key: string;
  title: string;
  children?: IMenuOption[];
}

export type ClickHandlerEvent =
  | React.MouseEvent<HTMLHeadingElement>
  | React.MouseEvent<HTMLLIElement>
  | React.MouseEvent<HTMLAnchorElement>;

export interface IFloatingMenuProps {
  menuOptions: IMenuOption[];
  selectedCategory: string;
  selectedSubCategory?: string;
  onClick: (menuOptionKey: string, menuOptionType: string) => void;
  classes?: string;
}
