import { useState } from 'react';
import { FloatingMenu } from 'components/common/FloatingMenu';
import { GeneralInformation } from './GeneralInformation';
import { UserPage } from 'pages/UserPage';
import { InvestmentThesis } from './InvestmentThesis';
import { CommunityComments } from './CommunityComments';
import { VotersInsights } from './VotersInsights';
import { menuOptions } from './menuOptions';
import { CustomerIdeaPageProps } from './types';

const CustomerIdeaPage = ({ idea }: CustomerIdeaPageProps) => {
  const { security, executiveSummary, entryPrice, timeframe, votes, author, isFavorite } = idea;
  const [selectedCategory, setSelectedCategory] = useState<any>(menuOptions[0]);

  const menuClickHandler = (selectedCategoryName: string) => {
    const selectedCategory = menuOptions.find(menuOption => menuOption.key === selectedCategoryName);

    setSelectedCategory(selectedCategory);
  };

  const sectionToRender = () => {
    switch (selectedCategory.key) {
      case 'generalInformation':
        return (
          <GeneralInformation
            security={security}
            executiveSummary={executiveSummary}
            entryPrice={entryPrice}
            timeframe={timeframe}
            isFavorite={isFavorite}
          />
        );
      case 'investmentThesis':
        return <InvestmentThesis security={security} isFavorite={isFavorite} />;
      case 'communityComments':
        return <CommunityComments security={security} isFavorite={isFavorite} />;
      case 'votersInsights':
        return <VotersInsights security={security} isFavorite={isFavorite} votes={votes} />;
      case 'trackRecord':
        return <UserPage author={author} />;
      default:
        return null;
    }
  };

  return (
    <div className='flex mb-8'>
      <div className='flex-shrink-0 pt-8'>
        <FloatingMenu classes='sticky top-8' menuOptions={menuOptions} selectedCategory={selectedCategory.key} onClick={menuClickHandler} />
      </div>
      <div className='flex-grow pt-8 ml-8'>{sectionToRender()}</div>
    </div>
  );
};
export default CustomerIdeaPage;
