import { IFloatingMenuProps, IMenuOption, CategoryType, ClickHandlerEvent } from './types';

const FloatingMenu: React.FC<IFloatingMenuProps> = ({
  menuOptions,
  selectedCategory,
  selectedSubCategory,
  onClick,
  classes,
}) => {
  const clickHandler = (event: ClickHandlerEvent) => {
    const { categoryName = '', categoryType = '' } = event.currentTarget.dataset;

    onClick(categoryName, categoryType);
  };

  return (
    <ul className={`bg-white flex-shrink-0 px-8 py-6 divide-y divide-light-grey-200 shadow-md rounded-md ${classes}`}>
      {menuOptions.map(({ key: categoryName, title: categoryTitle, children }: IMenuOption) => (
        <li key={categoryName} className='cursor-pointer'>
          <h4
            data-category-name={categoryName}
            data-category-type={CategoryType.CATEGORY}
            onClick={clickHandler}
            className={`${categoryName === selectedCategory ? 'text-blue-600' : ''} py-3 uppercase font-medium`}
          >
            {categoryTitle}
          </h4>

          {children && selectedCategory === categoryName && (
            <ul className='pb-4'>
              {children.map(({ key: subCategoryName, title: subCategoryTitle }) => (
                <li key={subCategoryName}>
                  <a
                    className={`${
                      subCategoryName === selectedSubCategory ? 'bg-gray-100' : ''
                    } block py-2 px-4 rounded-md w-full`}
                    data-name={subCategoryName}
                    data-category-type={CategoryType.SUBCATEGORY}
                    onClick={clickHandler}
                    href={`#${subCategoryName}`}
                  >
                    {subCategoryTitle}
                  </a>
                </li>
              ))}
            </ul>
          )}
        </li>
      ))}
    </ul>
  );
};

export default FloatingMenu;
