import { useStoreState } from 'store/hooks';
import { GeneralInfo } from './GeneralInfo';
import { Details } from './Details';
import { Summary } from './Summary';
import { AdditionInfo } from './AdditionInfo';

export const TradeCard: React.FC = () => {
  const { ideas } = useStoreState(state => state.ideaStore);

  return (
    <>
      {ideas?.count ? (
        ideas.rows.map(idea => (
          <div key={idea.id} className={'grid grid-cols-1 gap-8 p-8 bg-white rounded-lg shadow'}>
            <GeneralInfo idea={idea} />
            <Details idea={idea} />
            <Summary idea={idea} />
            <AdditionInfo idea={idea} />
          </div>
        ))
      ) : (
        <div>No Ideas</div>
      )}
    </>
  );
};
