import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { RadialButton } from './RadialButton';
import { IRadioButtonsOptionsProps } from './types';

export const RadioButtonsOptions: React.FC<IRadioButtonsOptionsProps> = ({ options, name, defaultValue, rules }) => {
  const { errors, control } = useFormContext();
  return (
    <div>
      <Controller
        name={name}
        rules={rules}
        defaultValue={options.find(({ value }) => value === defaultValue)}
        control={control}
        render={({ value, onChange }) => (
          <div className='grid gap-4 md:grid-cols-2'>
            <div className='grid gap-4'>
              {options?.map(option => (
                <label
                  key={`${option.value}-${option.label}`}
                  className='flex items-center text-xs text-indigo-900 cursor-pointer'
                >
                  <RadialButton active={option.value === value} onClick={() => onChange(option.value)} />
                  <span className='ml-4'>{option.label}</span>
                </label>
              ))}
            </div>
          </div>
        )}
      />
      {errors[name] && <p className='mt-2 text-xs text-red-600'>{errors[name].message}</p>}
    </div>
  );
};

RadioButtonsOptions.defaultProps = {
  rules: {},
};
