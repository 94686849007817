import { TextField } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';

interface CheckboxProps {
  label?: string;
  name: string;
  defaultValue: string | number;
  fullWidth?: boolean;
  rules?: any;
}

const NumberInput = ({ label, name, defaultValue, rules, ...inputProps }: CheckboxProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      render={({ value, onChange, ref }) => (
        <TextField
          variant='outlined'
          type='number'
          label={label}
          value={value}
          onChange={({ target }) => onChange(target.value ? parseFloat(target.value) : '')}
          inputRef={ref}
          {...inputProps}
        />
      )}
      name={name}
      defaultValue={defaultValue}
      control={control}
      rules={rules}
    />
  );
};
export default NumberInput;
