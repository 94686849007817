import { action, Action } from 'easy-peasy';

interface HeaderState {
  searchOption: string;
  noteDrop: boolean;
}
interface HeaderActions {
  setOption: Action<this, string>;
  noteDropdown: Action<this, boolean>;
}

export interface HeaderModel extends HeaderState, HeaderActions {}

const headerStore: HeaderModel = {
  searchOption: 'Assets',
  setOption: action((state, payload) => {
    state.searchOption = payload;
  }),

  noteDrop: false,
  noteDropdown: action((state, payload) => {
    state.noteDrop = payload;
  }),
};

export default headerStore;
