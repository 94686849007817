import Select from 'react-select';
import { useFormContext, Controller, RegisterOptions } from 'react-hook-form';

interface SelectInputProps {
  options: SelectOption[];
  name: string;
  rules?: RegisterOptions;
  defaultValue?: string | number;
  placeholder?: string;
}

type SelectOption = {
  value: string | number;
  label: string | number;
};

export const SelectInput = ({ options, name, rules, defaultValue, placeholder }: SelectInputProps) => {
  const { control, errors } = useFormContext();
  const styles = {
    control: (provided: any) => ({
      ...provided,
      height: '100%',
      padding: '0 0 0 10px',
      border: 0,
      boxShadow: 'none',
    }),
    option: (provided: any, state: { isSelected: any }) => ({
      ...provided,
      paddingLeft: '20px',
      paddingRight: '20px',
      color: !state.isSelected ? '#838eab' : '#fff',
      background: !state.isSelected && 'none',
      cursor: 'pointer',
    }),
  };

  return (
    <div>
      <div className='border border-blue-200 rounded-sm'>
        <Controller
          rules={rules}
          name={name}
          control={control}
          defaultValue={defaultValue}
          render={({ value, onChange, ref }) => (
            <Select
              defaultInputValue={`${defaultValue}`}
              placeholder={placeholder}
              styles={styles}
              options={options}
              onChange={selected => {
                if ('value' in selected!) {
                  onChange(selected.value);
                }
              }}
              setValue={() => value.value}
            />
          )}
        />
      </div>
      {errors[name] && <p className='text-xs text-red-600'>{errors[name].message}</p>}
    </div>
  );
};

SelectInput.defaultProps = {
  rules: undefined,
  defaultValue: undefined,
};
