import React from 'react';
import { Link } from 'react-router-dom';

const tableHeader = ['DATE', 'STATUS', 'TICKER', 'ENTRY PRICE', 'LAST PRICE', 'ITD PERF', 'STAKE', 'REWARD', 'UPDATE', 'MANAGE IDEA'];

interface IdeaManagementTableProps {
  data: any;
}

export const IdeaManagementTable = ({ data }: IdeaManagementTableProps) => (
  <table className='w-full'>
    <thead>
      <tr>
        {tableHeader.map(head => (
          <td className='px-4 text-xs font-normal text-gray-500 whitespace-nowrap' key={head}>
            {head}
          </td>
        ))}
      </tr>
    </thead>
    <tbody>
      {data.map((row: any, rowIndex: number) => (
        <tr key={rowIndex}>
          {row.data.map((cell: any, cellIndex: number) => (
            <td key={cellIndex} className='px-4 py-4'>
              {cell}
            </td>
          ))}
          <td className='px-4'>
            <Link to={`/idea/${row.meta.id}`} className='flex-1'>
              <span className='flex items-center flex-1'>
                <button
                  type='button'
                  className='px-4 py-2 text-xs font-light text-center text-white bg-green-500 rounded-md whitespace-nowrap'
                >
                  UPDATE
                </button>
              </span>
            </Link>
          </td>
          <td className='px-4'>
            <Link to={`/idea/${row.meta.id}`} className='flex-1'>
              <span className='flex items-center flex-1'>
                <button
                  type='button'
                  className='px-4 py-2 text-xs font-light text-center text-white bg-indigo-500 rounded-md whitespace-nowrap'
                >
                  CLOSE TRADE
                </button>
              </span>
            </Link>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);
