import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useStoreActions } from 'store/hooks';
import { UserPageProps } from './types';
import { UserCard } from 'components/common/UserCard';
import { UserIdeasTable } from './UserIdeasTable';
import { UserVotesTable } from './UserVotesTable';

const UserPage = ({ author }: UserPageProps) => {
  const { id: userId } = useParams<{ id: string }>();
  const [user, setUser] = useState(null);
  const { getUserByUserId } = useStoreActions(state => state.myAlphaswapStore);
  const userToRender = author || user;
  const userToRenderId = author?.id || userId;

  useEffect(() => {
    const init = async () => {
      const user = await getUserByUserId(userToRenderId);
      setUser(user);
    };

    !author && init();
  }, []);

  return userToRender ? (
    <div>
      <UserCard user={userToRender} />
      <UserIdeasTable userId={userToRenderId} />
      <UserVotesTable userId={userToRenderId} />
    </div>
  ) : null;
};

export default UserPage;
