import React from 'react';
import { Link } from 'react-router-dom';
import { useStoreActions, useStoreState } from 'store/hooks';

export const FinalBoard = () => {
  const { step } = useStoreState(state => state.onboardingStore);
  const { setStep } = useStoreActions(actions => actions.onboardingStore);

  return (
    <div className='w-full h-full mt-12 text-center '>
      <div className='my-2 md:my-4'>
        <p className='text-2xl font-semibold text-indigo-800 md:text-3xl'>Thank you for passing the onboarding.</p>
        <div className='my-12'>
          <p className='py-1 text-gray-800'>We take the onboarding seriously and manually verify every submission.</p>
          <p className='py-1 text-gray-800'>You can now access the platform</p>
        </div>
        <div className='items-center justify-between sm:flex'>
          <button type='button' className='my-2 focus:outline-none' onClick={() => setStep(step - 1)}>
            <p className='p-2 px-6 mx-6 text-gray-700 border border-gray-400 rounded-sm'>Back</p>
          </button>

          <Link to='/' className='p-2 px-6 mx-6 text-gray-700 border border-gray-400 rounded-sm'>
            Start your journey with AlphaSwap
          </Link>
        </div>
      </div>
    </div>
  );
};
