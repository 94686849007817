import React from 'react';
import { Card } from '../../components/common';
import { WelcomeBoard } from './WelcomeBoard';
import { BasicInfoBoard } from './BasicInfoBoard';
import { FinalBoard } from './FinalBoard';
import { useStoreState } from '../../store/hooks';

export const OnboardingPage = () => {
  const { step } = useStoreState(state => state.onboardingStore);

  return (
    <div className='flex flex-col w-full h-full min-h-screen bg-back-gray'>
      <div className='container flex items-center justify-center flex-1 w-full mx-auto'>
        <Card className='w-full max-w-5xl my-24 md:flex'>
          <div className='relative w-full h-full px-4 py-6 bg-white border border-gray-500 md:px-10 xl:py-8'>
            {step === 0 && <WelcomeBoard />}
            {step === 1 && <BasicInfoBoard />}
            {step === 2 && <FinalBoard />}
          </div>
        </Card>
      </div>
    </div>
  );
};
