import { format } from 'date-fns';
import Avatar from 'img/AvatarTemplate.png';
import { UserDetailsCardProps } from './types';

const UserDetailsCard = ({ id, city, country, job, onboardedAt, profilePicture }: UserDetailsCardProps) => {
  const location = city ? `${city}, ${country}` : country;

  const userDetails = [
    { title: 'name', value: `xxx${id}xxx` },
    { title: 'location', value: location },
    { title: 'job', value: job || '–' },
    { title: 'joined', value: format(new Date(onboardedAt), 'MMMM dd, yyyy') },
  ];

  return (
    <div className='user-details-card bg-white p-8 rounded-md shadow-md grid grid-cols-auto-first gap-x-8'>
      <div className='avatar'>
        <img src={profilePicture || Avatar} alt='avatar' className='h-full object-cover w-40' />
      </div>
      <ul className='details flex-grow'>
        {userDetails.map(({ title, value }) => {
          return (
            <li key={`user-details-${title}`} className={`user-details-${title} mb-2 last:mb-0`}>
              <h4 className='title uppercase text-gray-400 text-xs'>{title}</h4>
              <p className='text text-sm'>{value}</p>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default UserDetailsCard;
