import { AxiosResponse } from 'axios';
import { IIntercomMessageRequestObject, IIntercomMessageResponseObject } from 'interfaces';
import { client } from 'store/api';
import { ResponseStatusCode } from 'utils/enums';

export const intercomApi = {
  sendMessage: async (formData: IIntercomMessageRequestObject): Promise<IIntercomMessageResponseObject> => {
    const data: IIntercomMessageRequestObject = { ...formData };
    const response = await client.post<any, AxiosResponse<IIntercomMessageResponseObject>>('/intercom', data);

    if (response?.status === ResponseStatusCode.CREATED) {
      return response.data;
    }

    throw new Error("Can't send a report message. Something went wrong");
  },
};
