/* eslint-disable */
import React from 'react';
import { StandardReactProps } from '../../interfaces';

interface ButtonProps extends StandardReactProps {
  textContent: string;
}

export const ButtonComponent = (props: ButtonProps) => {
  const { className, textContent, onClick, active } = props;

  return (
    <button
      type='button'
      className={`${className} ${
        active ? 'bg-indigo-100' : ''
      } items-center rounded-sm focus:outline-none`}
      onClick={() => onClick && onClick(textContent)}
    >
      {props.children}
      <p className="items-center ml-2 text-xs text-center sm:text-sm">
        {textContent}
      </p>
    </button>
  );
};
