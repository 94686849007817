import React from 'react';
import { StandardReactProps } from '../../../interfaces';

export const LinkedinIcon = ({ className }: StandardReactProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 44 40' className={className}>
    <path
      fill='#3079B4'
      fillRule='evenodd'
      d='M746 305h-9.73v-14.07c0-3.69-1.53-6.2-4.87-6.2 -2.56 0-3.99 1.69-4.65 3.32 -.25.58-.21 1.4-.21 2.22v14.7h-9.64s.12-24.91 0-27.18h9.63v4.26c.56-1.87 3.64-4.53 8.56-4.53 6.09 0 10.88 3.9 10.88 12.32v15.1 0Zm-38.83-30.58h-.07c-3.11 0-5.12-2.08-5.12-4.72 0-2.69 2.07-4.72 5.23-4.72 3.16 0 5.1 2.02 5.17 4.71 0 2.63-2.01 4.71-5.24 4.71v0 0Zm-4.07 3.39h8.57v27.17h-8.58V277.8v0Z'
      transform='translate(-702-265)'
    />
  </svg>
);
