/* eslint-disable no-console */
import React from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { range } from 'lodash';
import { RadialButton } from './RadialButton';

type RadioOption = {
  label: string;
  field: string;
};

interface RadioWithCommentProps {
  id: number;
  optionPoints: string[];
  options: RadioOption[];
  name: string;
  query: string;
  rules?: RegisterOptions;
  defaultValue: Object;
  title?: string;
}

export const RadioWithComment = ({ id, options, name, optionPoints, query, rules, defaultValue, title }: RadioWithCommentProps) => {
  const { control } = useFormContext();
  return (
    <div className='grid gap-y-4'>
      <Controller
        defaultValue={defaultValue}
        name={name}
        control={control}
        rules={rules}
        render={({ value, onChange }) => (
          <>
            {options.map((option, index) => (
              <React.Fragment key={option.field}>
                {index === 0 && (
                  <>
                    <div className='md:hidden uppercase'>
                      <span className='font-normal text-indigo-500 '>{`${id} --> `}</span>
                      {query}
                    </div>
                    {options.map(firstOption => (
                      <p key={firstOption.field} className='md:hidden text-xs text-gray-800'>
                        {firstOption.field}
                      </p>
                    ))}
                    <div key={option.field} className='grid grid-cols-4 md:grid-cols-2fr-4x lg:grid-cols-3fr-4x items-center'>
                      <div className='hidden md:block uppercase'>
                        <span className='font-normal text-indigo-500 '>{`${id} --> `}</span>
                        {query}
                      </div>
                      {optionPoints.map(points => (
                        <div key={points} className='flex justify-center uppercase'>
                          {points}
                        </div>
                      ))}
                    </div>
                  </>
                )}
                <div key={option.field} className='grid grid-cols-4 md:grid-cols-2fr-4x lg:grid-cols-3fr-4x items-center'>
                  <p className='hidden md:block text-xs text-gray-800'>{option.label}</p>
                  {range(0, optionPoints.length).map((point, pointsIndex) => (
                    <div className='flex justify-center' key={point}>
                      <RadialButton
                        onClick={() =>
                          onChange({
                            ...value,
                            [option.field]: point + 1,
                          })
                        }
                        active={pointsIndex + 1 === value[option.field]}
                      />
                    </div>
                  ))}
                </div>
              </React.Fragment>
            ))}
            <div>
              <p className='mt-6 pb-2 text-sm font-semiboldtext-sm text-gray-700'>{title}</p>
              <textarea
                value={value.description}
                onChange={({ target }) =>
                  onChange({
                    ...value,
                    description: target.value,
                  })
                }
                className='w-full h-48 p-4 text-xs text-gray-800 border border-indigo-500 rounded-md'
              />
            </div>
          </>
        )}
      />
    </div>
  );
};

RadioWithComment.defaultProps = {
  rules: undefined,
  title: '',
};
