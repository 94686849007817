import React from 'react';
import { StandardReactProps } from '../../../interfaces';

export const ChartTickIcon = ({ className }: StandardReactProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 60 60' className={className}>
    <path fill='none' d='M-1-1h62v302H-1z' />
    <circle cx='31.922' cy='31.922' r='22.318' fill='#b0c3f4' stroke='null' />
    <path
      fill='#7d91a5'
      d='M32 9C19.318 9 9 19.317 9 32s10.318 23 23 23 23-10.317 23-23S44.682 9 32 9zm0 44c-11.58 0-21-9.421-21-21s9.42-21 21-21 21 9.421 21 21-9.42 21-21 21z'
    />
    <path
      fill='#007AFF'
      d='M32 15c-9.374 0-17 7.626-17 17s7.626 17 17 17 17-7.626 17-17-7.626-17-17-17zm0 32c-8.271 0-15-6.729-15-15s6.729-15 15-15 15 6.729 15 15-6.729 15-15 15z'
    />
    <path
      fill='#007AFF'
      d='M37.168 25.445L29.845 36.43l-3.138-3.138a.999.999 0 10-1.414 1.414l4 4a.993.993 0 00.806.289.998.998 0 00.733-.44l8-12a1.001 1.001 0 00-1.664-1.11z'
    />
  </svg>
);
