import React from 'react';
import { NoteDropdown } from './NoteDropdown';
import { NoteIcon } from '../icons/header';
import { useStoreActions, useStoreState } from '../../store/hooks';

export const Notifications = () => {
  const { notifications } = useStoreState(state => state.notificationStore);
  const { noteDrop } = useStoreState(state => state.headerStore);
  const { noteDropdown } = useStoreActions(actions => actions.headerStore);

  const unreadNotifications = notifications.filter(notification => !notification.seen).length;
  return (
    <div>
      <div className='flex items-center cursor-pointer'>
        <button
          type='button'
          className='relative w-10 h-10 p-2 border rounded-full bg-note-purple focus:outline-none focus:border-indigo-200'
          onClick={() => noteDropdown(!noteDrop)}
        >
          <NoteIcon className='object-cover object-center text-indigo-600 fill-current ' />
          {unreadNotifications > 0 ? (
            <div className='absolute top-0 right-0 flex items-center justify-center w-4 h-4 rounded-full bg-note-pink' style={{ top: -6 }}>
              <p className='text-white' style={{ fontSize: 9 }}>
                {unreadNotifications}
              </p>
            </div>
          ) : null}
        </button>

        <div className='relative'>{noteDrop ? <NoteDropdown /> : null}</div>
      </div>

      {noteDrop ? (
        <button
          type='button'
          onClick={() => noteDropdown(false)}
          className='fixed inset-0 w-full h-full cursor-default focus:outline-none'
        />
      ) : null}
    </div>
  );
};
