import { isValidDate } from 'utils/guards';
import { dateFormatter } from 'utils/dateFormat';
import { TablePaginationActionTypeEnum, TablePropsInterface } from './types';

const Table = ({
  title: tableTitle,
  name,
  date,
  columns,
  data,
  total,
  offset,
  onPageChange,
  showRanking = false,
  withPagination = true,
}: TablePropsInterface) => (
  <>
    {(tableTitle || date) && (
      <div className={`flex ${tableTitle ? 'justify-between' : 'justify-end'} mb-6`}>
        {tableTitle && <p className={'text-2xl text-table-dark-blue'}>{tableTitle}</p>}

        {date && isValidDate(date) && <p className={'text-lg text-table-dark-blue font-light'}>As of {dateFormatter(date)}</p>}
      </div>
    )}

    <table
      cellSpacing={0}
      className={`table-fixed border-separate border-table-gray border rounded-t-md ${
        withPagination ? '' : 'rounded-b-md'
      } w-full bg-white overflow-hidden`}
    >
      <thead>
        <tr className={'p-1'}>
          {showRanking && (
            <th
              className={
                'bg-table-head-light-blue pb-4 pt-6 px-2 text-sm font-medium text-table-head-dark-blue whitespace-nowrap first:rounded-tl-md last:rounded-tr-md border-b border-table-gray uppercase'
              }
            >
              Ranking
            </th>
          )}

          {columns.map(({ title }, index) => (
            <th
              key={`column-${title}-${index}`}
              className={
                'bg-table-head-light-blue pb-4 pt-6 px-2 text-sm font-medium text-table-head-dark-blue whitespace-nowrap first:rounded-tl-md last:rounded-tr-md border-b border-table-gray uppercase'
              }
            >
              {title}
            </th>
          ))}
        </tr>
      </thead>

      <tbody>
        {data.length
          ? data.map((row, rowIndex) => (
              <tr key={`row-${rowIndex}`} className={'even:bg-table-content-light-gray border-t-8 border-b-8'}>
                {showRanking && (
                  <td className={'px-2 py-2 font-light text-center text-table-content-dark-blue'}>{(offset || 0) + rowIndex + 1}</td>
                )}

                {row.map(({ title, showTooltip }, cellIndex) => (
                  <td
                    key={`call-${cellIndex}`}
                    className={
                      'max-w-full px-2 py-2 text-sm font-light text-center text-table-content-dark-blue whitespace-nowrap overflow-ellipsis overflow-hidden'
                    }
                    title={showTooltip && typeof title === 'string' ? title : undefined}
                  >
                    {title || title === 0 ? title : '-'}
                  </td>
                ))}
              </tr>
            ))
          : null}
      </tbody>
    </table>

    {withPagination && (
      <div className={'w-full flex justify-between items-center py-4 border border-t-0 rounded-b-md border-table-gray bg-white'}>
        {onPageChange && (total || total === 0) && (offset || offset === 0) && data.length ? (
          <>
            {offset ? (
              <button
                className={'ml-10 w-28 py-2 text-white text-sm bg-button-blue rounded-md'}
                onClick={() => onPageChange({ type: TablePaginationActionTypeEnum.previous, name })}
              >
                Previous
              </button>
            ) : (
              <div className={'ml-10 w-28'} />
            )}

            <span className={'text-sm px-10'}>
              {offset + 1} - {offset + data.length}/{total}
            </span>

            {total - offset === data.length ? (
              <div className={'mr-10 w-28'} />
            ) : (
              <button
                className={'mr-10 w-28 py-2 text-white text-sm bg-button-blue rounded-md'}
                onClick={() => onPageChange({ type: TablePaginationActionTypeEnum.next, name })}
              >
                Next
              </button>
            )}
          </>
        ) : null}
      </div>
    )}
  </>
);

export default Table;
