import { action, Action, thunk, Thunk } from 'easy-peasy';
import { AxiosResponse } from 'axios';
import { client } from 'store/api';
import { IRootModel } from 'store/models/root.model';
import { ResponseStatusCode } from 'utils/enums';
import { NotificationObject } from 'interfaces';

interface INotificationState {
  notification: NotificationObject;
  notifications: NotificationObject[];
  thesisTab: number;
}

interface INotificationActions {
  setNotification: Action<this, NotificationObject>;
  setNotifications: Action<this, NotificationObject[]>;
  readNotification: Action<this, number>;
  deleteNotification: Action<this, number>;
}

interface INotificationThunks {
  loadNotificationThunk: Thunk<this, number, any, Record<string, unknown>, Promise<AxiosResponse<NotificationObject>>>;
  loadNotificationsThunk: Thunk<
    this,
    undefined,
    any,
    Record<string, unknown>,
    Promise<AxiosResponse<NotificationObject[]>>
  >;
  readNotificationThunk: Thunk<this, number, any, Record<string, unknown>, Promise<AxiosResponse<NotificationObject>>>;
  readAllNotificationsThunk: Thunk<this, undefined, any, IRootModel, Promise<void>>;
  deleteNotificationThunk: Thunk<this, number, any, Record<string, unknown>, Promise<AxiosResponse['status']>>;
}

export interface INotificationModel extends INotificationState, INotificationActions, INotificationThunks {}

const notificationStore: INotificationModel = {
  notification: {} as NotificationObject,
  notifications: [],
  thesisTab: 0,
  /**
   * ACTIONS
   */
  setNotification: action((state, payload: NotificationObject) => {
    state.notification = payload;
  }),
  setNotifications: action((state, payload: NotificationObject[]) => {
    state.notifications = payload;
  }),
  readNotification: action((state, notificationId) => {
    const notificationIndex = state.notifications.findIndex(notification => notification.id === notificationId);
    state.notifications[notificationIndex] = {
      ...state.notifications[notificationIndex],
      seen: true,
    };
  }),
  deleteNotification: action((state, notificationId) => {
    state.notifications = state.notifications.filter(notification => notification.id !== notificationId);
  }),
  /**
   * THUNKS
   */
  loadNotificationThunk: thunk(async (actions, NotificationId) => {
    const response = await client.get<any, AxiosResponse<NotificationObject>>(`/notification/${NotificationId}`);

    if (response?.status === ResponseStatusCode.OK) {
      actions.setNotification(response.data);
    }

    return response;
  }),
  readNotificationThunk: thunk(async (actions, NotificationId) => {
    const response = await client.get<any, AxiosResponse<NotificationObject>>(`/notification/read/${NotificationId}`);

    if (response?.status === ResponseStatusCode.OK) {
      actions.setNotification(response.data);
      actions.readNotification(NotificationId);
    }
    return response;
  }),
  deleteNotificationThunk: thunk(async (actions, NotificationId) => {
    const response = await client.delete<any, AxiosResponse>(`/notification/${NotificationId}`);
    if (response?.status === ResponseStatusCode.OK) {
      actions.deleteNotification(NotificationId);
    }
    return response.status;
  }),
  readAllNotificationsThunk: thunk(async (actions, NotificationId, { getStoreState }) => {
    const { notifications } = getStoreState().notificationStore;
    const response = await client.get<any, AxiosResponse>('/notification/read');
    if (response?.status === ResponseStatusCode.OK) {
      notifications.forEach(notification => actions.readNotification(notification.id));
    }
  }),
  loadNotificationsThunk: thunk(async actions => {
    const response = await client.get<any, AxiosResponse<NotificationObject[]>>('/notification');

    if (response?.status === ResponseStatusCode.OK) {
      actions.setNotifications(response.data);
    }
    return response;
  }),
};

export default notificationStore;
