import { CommunityCommentsProps } from './types';
import { Comments } from 'components/comments';
import { IdeaHeader } from '../GeneralInformation/IdeaHeader';
import { IdeaStatsBody } from '../GeneralInformation/IdeaStatsBody';

export const CommunityComments = ({ security, isFavorite }: CommunityCommentsProps) => {
  const {
    name,
    exchangeSymbol,
    region,
    sector,
    timeseries,
    openPrice,
    closePrice,
    lowPrice,
    highPrice,
    marketCap,
    avgVolume,
    description,
    week52Range,
  } = security;

  return (
    <>
      <div className='bg-white p-8 rounded-md shadow-md'>
        <div className='stock-info'>
          <IdeaHeader name={name} exchangeSymbol={exchangeSymbol} region={region} sector={sector} isFavorite={isFavorite} />
          <IdeaStatsBody
            content={{
              timeseries,
              openPrice,
              closePrice,
              lowPrice,
              highPrice,
              marketCap,
              avgVolume,
              description,
              week52Range,
            }}
          />
        </div>
      </div>

      <div className='bg-white p-8 rounded-md shadow-md mt-8'>
        <p className='my-4 text-gray-800 sm:my-0'>Community’s comments</p>
        <Comments />
      </div>
    </>
  );
};

export default CommunityComments;
