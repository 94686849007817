import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { ICommitCreditsSection } from './types';
import { Checkbox } from 'components/common/Inputs/Checkbox';
import mixpanel from 'utils/mixpanelIntegration';
import { CreditsCard } from 'containers/CreditsCard';
import NumberInput from 'components/common/Inputs/NumberInput';
import { useStoreState } from 'store/hooks';
import { CardWithTitle } from 'components/common';

const CommitCreditsSection: React.FC<ICommitCreditsSection> = ({ isTncAccepted, stake }) => {
  const { errors } = useFormContext();
  const { wallet } = useStoreState(state => state.myAlphaswapStore);

  useEffect(() => {
    mixpanel.dispatchEvent('Create idea: Commit Credits');
  }, []);

  return (
    <div className={'pt-8'}>
      <CardWithTitle title={'Commit virtual dollars'}>
        <div className='justify-between'>
          <div className='w-full '>
            <p className='p-3 text-sm text-gray-800'>Please allocate virtual dollars to this co-investment.</p>
            <div className='items-center sm:justify-between sm:flex'>
              <CreditsCard
                title='VIRTUAL DOLLARS BALANCE'
                color='text-gray-700'
                value={wallet.credits + wallet.stakedCredits}
              />
              <CreditsCard title='VIRTUAL DOLLARS STAKED' color='text-yellow-500' value={wallet.stakedCredits} />
              <CreditsCard title='VIRTUAL DOLLARS AVAILABLE' color='text-green-500' value={wallet.credits} />
            </div>

            <p className='mb-4 text-sm text-blue-700'>How many virtual dollars do you want to allocate?</p>

            <NumberInput
              name={'stake'}
              defaultValue={stake}
              fullWidth
              rules={{
                required: {
                  value: true,
                  message: 'Field is required',
                },
                min: {
                  value: 1,
                  message: 'Min value is 1 virtual dollar',
                },
                max: {
                  value: wallet.credits,
                  message: "You don't have sufficient balance",
                },
              }}
            />

            {errors.stake && <p className='text-xs text-red-600'>{errors.stake.message}</p>}
          </div>
        </div>
      </CardWithTitle>
      <div className='flex flex-col items-end'>
        <Checkbox
          labelClasses={'mr-0'}
          label={"I confirm that this submission conforms with AlphaSwap's terms and conditions"}
          name={'isTncAccepted'}
          rules={{
            required: {
              value: true,
              message: 'You must confirm T&C',
            },
          }}
          defaultValue={isTncAccepted}
        />
        {errors.isTncAccepted && <p className={'text-red-500'}>{errors.isTncAccepted.message}</p>}
      </div>
    </div>
  );
};

export default CommitCreditsSection;
