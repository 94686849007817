import { EventDot } from './EventDot';
import { ITimelineProps } from './types';

export const Timeline: React.FC<ITimelineProps> = ({ isEnd, isFirstComment }) => (
  <>
    {isEnd && (
      <div className={'absolute items-center justify-center w-3 h-full mt-3 hidden sm:flex'}>
        <div className={'inset-0 h-full bg-gray-100 border pointer-events-none'} />
      </div>
    )}

    <EventDot first={isFirstComment} />
  </>
);
