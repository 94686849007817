import { action, Action } from 'easy-peasy';

interface ThesisPayload {
  mispricingReason: {
    values: [];
    options: [
      { fundamentals: number[]; description: string; custom: string },
      { specials: number[]; description: string; custom: string },
      { technicals: number[]; description: string; custom: string }
    ];
  };
  catalysts: {
    values: number[];
    custom: string;
    description: string;
  };
  risks: {
    values: number[];
    custom: string;
    description: string;
  };
}

export interface ThesisState {
  mispricingReason: {
    values: [];
    options: [
      { fundamentals: number[]; description: string; custom: string },
      { specials: number[]; description: string; custom: string },
      { technicals: number[]; description: string; custom: string }
    ];
  };
  catalysts: {
    values: number[];
    custom: string;
    description: string;
  };
  risks: {
    values: number[];
    custom: string;
    description: string;
  };
}

export interface ThesisActions {
  setThesisData: Action<this, ThesisPayload>;
}

export interface ThesisModel extends ThesisState, ThesisActions {}

export const thesis: ThesisModel = {
  /**
   * STATE
   */
  mispricingReason: {
    values: [],
    options: [
      { fundamentals: [], description: '', custom: '' },
      { specials: [], description: '', custom: '' },
      { technicals: [], description: '', custom: '' },
    ],
  },
  catalysts: {
    values: [],
    custom: '',
    description: '',
  },
  risks: {
    values: [],
    custom: '',
    description: '',
  },
  /**
   * ACTIONS
   */
  setThesisData: action((state, payload) => {
    state = Object.assign(state, payload);
  }),
};
