import { action, Action } from 'easy-peasy';

interface AppState {
  showLoader: boolean;
}

interface AppActions {
  toggleLoader: Action<this>;
  setLoader: Action<this, boolean>;
}

export interface AppModel extends AppState, AppActions {}

const appStore: AppModel = {
  showLoader: false,
  /**
   * ACTIONS
   */
  toggleLoader: action(state => {
    state.showLoader = !state.showLoader;
  }),
  setLoader: action((state, payload) => {
    state.showLoader = payload;
  }),
};

export default appStore;
