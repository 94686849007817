import axios from 'axios';
import { ResponseStatusCode } from 'utils/enums';
import { store } from 'store';

const apiUrl = process.env.REACT_APP_API_URL;

export const client = axios.create({
  baseURL: apiUrl,
});

client.interceptors.request.use(async config => {
  const { jwt: currentJwt } = store.getState().authStore;
  const { refreshTokenThunk } = store.dispatch.authStore;

  if (currentJwt && currentJwt.accessTokenExpiresAt < new Date().getTime()) {
    await refreshTokenThunk(currentJwt?.refreshToken);
  }

  const { jwt: newJwt } = store.getState().authStore;

  config.headers.Authorization = `Bearer ${newJwt?.accessToken || ''}`;
  return config;
});

client.interceptors.response.use(
  response => response,
  error => {
    if (error.response?.status === ResponseStatusCode.UNAUTHORIZED) window.location.replace('/logout');

    return Promise.reject(error);
  }
);
