import React from 'react';
import { StandardReactProps } from '../../../interfaces';

export const UploadArrow = ({ className }: StandardReactProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='14.796' height='13.305' viewBox='0 0 14.796 13.305' className={className}>
    <path
      d='M39.892.079A.408.408,0,0,0,39.643,0h0a.406.406,0,0,0-.25.081L32.358,5.627a.238.238,0,0,0,.006.392.407.407,0,0,0,.243.079h3.88v6.93a.322.322,0,0,0,.353.277h5.643a.322.322,0,0,0,.353-.277V6.1H46.7a.322.322,0,0,0,.353-.277.251.251,0,0,0-.1-.2Z'
      transform='translate(-32.257 0)'
    />
  </svg>
);
