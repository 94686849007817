import React from 'react';
import { StandardReactProps } from '../../../interfaces';

export const BoardIcon = ({ className }: StandardReactProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' className={className}>
    <path d='M7.323 10.5h-2.5a.75.75 0 010-1.5h2.5a.75.75 0 010 1.5zM19.323 10.5h-7.5a.75.75 0 010-1.5h7.5a.75.75 0 010 1.5zM7.323 14.5h-2.5a.75.75 0 010-1.5h2.5a.75.75 0 010 1.5zM19.323 14.5h-7.5a.75.75 0 010-1.5h7.5a.75.75 0 010 1.5zM7.323 18.5h-2.5a.75.75 0 010-1.5h2.5a.75.75 0 010 1.5zM19.323 18.5h-7.5a.75.75 0 010-1.5h7.5a.75.75 0 010 1.5z' />
    <path d='M21.25 23H2.75A2.752 2.752 0 010 20.25V3.75A2.752 2.752 0 012.75 1h18.5A2.752 2.752 0 0124 3.75v16.5A2.752 2.752 0 0121.25 23zM2.75 2.5c-.689 0-1.25.561-1.25 1.25v16.5c0 .689.561 1.25 1.25 1.25h18.5c.689 0 1.25-.561 1.25-1.25V3.75c0-.689-.561-1.25-1.25-1.25z' />
    <path d='M23.25 6H.75a.75.75 0 010-1.5h22.5a.75.75 0 010 1.5z' />
  </svg>
);
