import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { Table, TrackSector, TrackUser } from 'components/my-alphaswap';
import { useStoreState, useStoreActions } from 'store/hooks';
import { renderExpectedReturn } from 'utils';
import { IdeaTableType } from 'utils/enums';
import { TrackRecordModal } from '../TrackRecordModal';
import NoRecordsPlaceholder from '../NoRecordsPlaceholder';
import trackRecordPlaceholderImage from 'img/placeholder-record.png';

export const mapStatusToColor = {
  LIVE: 'bg-green-500',
  PENDING: 'bg-yellow-500',
  DECLINED: 'bg-red-500',
};

const noRecordsPlaceholderConfig = {
  textOne: 'You track record will be displayed here.',
  textTwo: 'Click on the button bellow to start building your track record.',
  ctaLink: '/idea/generate/choose-asset',
  ctaText: 'Submit your stock pick',
  imgUrl: trackRecordPlaceholderImage,
};

interface DotProps {
  status: keyof typeof mapStatusToColor;
}

const Dot = ({ status }: DotProps) => <div className={`w-3 h-3 rounded-full ${mapStatusToColor[status]}`} />;

const TrackRecordPage = () => {
  const [trackModal, setTrackModal] = useState(false);
  const [shareLink, setShareLink] = useState('');
  const { user, ideas, votes } = useStoreState(state => state.myAlphaswapStore);

  const { showTrackRecord } = useStoreState(state => state.placeholderStore);

  const { createShareLinkThunk, getShareLinkThunk, deleteShareLinkThunk } = useStoreActions(state => state.myAlphaswapStore);

  const { setTrackRecord } = useStoreActions(state => state.placeholderStore);

  const getShareLink = async () => {
    const response = await getShareLinkThunk();
    setShareLink(response.data);
  };

  useEffect(() => {
    getShareLink();
  }, []);

  function buildSectorsForTrackSector() {
    const sectors = new Set();
    ideas?.forEach((idea: any) => {
      sectors.add(idea.security.sector);
    });
    return Array.from(sectors).slice(0, 3);
  }

  function buildGeographiesForTrackSector() {
    const geographies = new Set();
    ideas?.forEach((idea: any) => {
      geographies.add(idea.security.region);
    });
    return Array.from(geographies).slice(0, 3);
  }

  function buildGeneratedIdeasData() {
    return ideas?.map((idea: any) => ({
      meta: {
        id: idea.id,
      },
      data: [
        format(new Date(idea.createdAt), 'yyyy.MM.dd'),
        <div className='flex justify-center'>
          <Dot status={idea.status} />
        </div>,
        idea.security.symbol,
        idea.livePrice,
        idea.security.closePrice,
        renderExpectedReturn(idea.status, idea.tradeType, idea.livePrice, idea.closedPrice, idea.security.closePrice),
        idea.generatorStake.toString(),
        '-',
      ],
    }));
  }

  function buildVotedIdeasData() {
    return votes?.map((vote: any) => ({
      meta: {
        id: vote.idea.id,
      },
      data: [
        format(new Date(vote.idea.createdAt), 'yyyy.MM.dd'),
        vote.idea.status,
        vote.idea.security.symbol,
        vote.idea.livePrice,
        vote.idea.security.closePrice,
        renderExpectedReturn(
          vote.idea.status,
          vote.idea.tradeType,
          vote.idea.livePrice,
          vote.idea.closedPrice,
          vote.idea.security.closePrice
        ),
        vote.idea.generatorStake.toString(),
        '-',
      ],
    }));
  }

  const handleCloseTrackRecordModal = () => {
    setTrackModal(false);
  };

  const onShare = async () => {
    const response = await createShareLinkThunk();
    setShareLink(response.data);
  };

  const onDeleteShareLink = async () => {
    await deleteShareLinkThunk();
    setShareLink('');
  };

  return (
    <>
      <div className='max-w-6xl p-10 mx-auto my-8 bg-white border rounded-md'>
        <div className='flex items-center justify-between mb-10'>
          <p>
            <span>Track Record of </span>
            <span className='ml-1 text-xl font-medium cursor-pointer'>
              {user?.firstName} {user?.lastName}
            </span>
          </p>
          {/* <button
            onClick={() => setTrackModal(!trackModal)}
            type="button"
            className="items-center hidden w-64 p-2 px-3 bg-indigo-100 border rounded shadow-xs md:flex focus:outline-none focus:shadow-inner"
          >
            <ShareIcon className="w-6 h-6 mx-2" />
            <p className="text-sm text-indigo-800">SHARE MY TRACK RECORD</p>
          </button> */}
        </div>

        <div className='grid justify-center pb-8 gap-4 my-4 border border-t-0 border-l-0  border-r-0 sm:grid-cols-2 sm:justify-start'>
          <TrackUser user={user} />
          <TrackSector sectors={buildSectorsForTrackSector()} geographies={buildGeographiesForTrackSector()} />
        </div>

        {ideas.length === 0 && votes.length === 0 ? (
          <NoRecordsPlaceholder {...noRecordsPlaceholderConfig} />
        ) : (
          <div className='py-4 mx-auto rounded'>
            <div className='overflow-x-scroll bg-white border border-indigo-100 rounded-md mb-10'>
              <p className='p-4 text-gray-800'>Idea submitted ({ideas.length})</p>
              {buildGeneratedIdeasData().length > 0 && <Table data={buildGeneratedIdeasData()} type={IdeaTableType.GENERATION} />}
            </div>

            <div className='overflow-x-scroll bg-white border border-indigo-100 rounded-md'>
              <p className='p-4 text-gray-800'>Ideas voted for ({votes.length})</p>
              {buildVotedIdeasData().length > 0 && <Table data={buildVotedIdeasData()} type={IdeaTableType.VOTE} />}
            </div>
          </div>
        )}
      </div>
      {trackModal && (
        <TrackRecordModal onShare={onShare} onClose={handleCloseTrackRecordModal} onDelete={onDeleteShareLink} shareLink={shareLink} />
      )}
    </>
  );
};

export default TrackRecordPage;
