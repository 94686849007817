import { IVotersInsightsProps } from './types';
import { VoteObject } from 'interfaces';
import { getMedian } from 'utils/numberHelpers';
import { INITIAL_WALLET_BALANCE } from 'utils/globalConstants';
import { NotFound } from 'components/common/Placeholders/';
import { IdeaHeader } from '../GeneralInformation/IdeaHeader';
import { IdeaStatsBody } from '../GeneralInformation/IdeaStatsBody';
import { MainReasonCoInvest } from './MainReasonCoInvest';
import { UpsideDownsideStock } from './UpsideDownsideStock';
import { VotersAllocation } from './VotersAllocation';
import { EsgScoring } from './EsgScoring';

const arrayDuplicatesToObject = (array: any[]) =>
  array.reduce((accumulator: any, value: number) => {
    accumulator[value] = (accumulator[value] || 0) + 1;
    return accumulator;
  }, {});

const mainReasonsStats = (votes: VoteObject[]) => {
  const answers = votes.reduce((allAnswers: number[], { thesis }) => allAnswers.concat(thesis.voteReasons), []);
  const statsObject = arrayDuplicatesToObject(answers);

  return {
    questions: { ...statsObject },
    total: votes.length,
  };
};

const upsideDownsideStats = (votes: VoteObject[]) => {
  const optionAnswers = votes.map(({ thesis }) => thesis.aspect);
  const upsides = votes.map(({ thesis }) => thesis.upside);
  const downsides = votes.map(({ thesis }) => thesis.downside);
  const optionsObject = arrayDuplicatesToObject(optionAnswers);

  return {
    options: optionsObject,
    total: votes.length,
    upsideMedian: getMedian(upsides),
    downsideMedian: getMedian(downsides),
  };
};

const votersAllocationStats = (votes: VoteObject[]) => {
  const stakesTotal = votes.reduce((total, { stake }) => total + stake, 0);
  return (stakesTotal / votes.length / INITIAL_WALLET_BALANCE) * 100;
};

const esgStats = (votes: VoteObject[]) => {
  const esgAnswers = votes.map(({ thesis }) => thesis.esgScore);
  const esgObject = arrayDuplicatesToObject(esgAnswers);

  return {
    esg: esgObject,
    total: votes.length,
  };
};

const VotersInsights = ({ security, votes, isFavorite }: IVotersInsightsProps) => {
  const {
    name,
    exchangeSymbol,
    region,
    sector,
    timeseries,
    openPrice,
    closePrice,
    lowPrice,
    highPrice,
    marketCap,
    avgVolume,
    description,
    week52Range,
  } = security;

  const mainReasonsAnswers = mainReasonsStats(votes);
  const upsideDownsideAnswers = upsideDownsideStats(votes);
  const votersAverageAllocation = votersAllocationStats(votes);
  const esgAnswers = esgStats(votes);

  return (
    <>
      <div className='bg-white p-8 rounded-md shadow-md'>
        <div className='stock-info'>
          <IdeaHeader
            name={name}
            exchangeSymbol={exchangeSymbol}
            region={region}
            sector={sector}
            isFavorite={isFavorite}
          />
          <IdeaStatsBody
            content={{
              timeseries,
              openPrice,
              closePrice,
              lowPrice,
              highPrice,
              marketCap,
              avgVolume,
              description,
              week52Range,
            }}
          />
        </div>
      </div>

      {votes.length ? (
        <>
          <div className='vote-insights mt-8 grid grid-cols-2 gap-4'>
            <MainReasonCoInvest mainReasonsAnswers={mainReasonsAnswers} />
            <UpsideDownsideStock upsideDownsideAnswers={upsideDownsideAnswers} />
          </div>
          <div className='vote-insights mt-4 grid grid-cols-half-second gap-4'>
            <VotersAllocation votersAverageAllocation={votersAverageAllocation} />
            <EsgScoring esgAnswers={esgAnswers} />
          </div>
        </>
      ) : (
        <div className='bg-white p-8 mt-8 rounded-md shadow-md'>
          <NotFound text={'No votes submitted yet for this idea'} />
        </div>
      )}
    </>
  );
};

export default VotersInsights;
