import { thunk, Thunk } from 'easy-peasy';
import { AxiosResponse } from 'axios';
import { client } from 'store/api';
import { ResponseStatusCode } from 'utils/enums';

interface IInsightThunk {
  loadSectorThunk: Thunk<this, undefined, any, {}, Promise<AxiosResponse | null>>;
  loadRegionThunk: Thunk<this, undefined, any, {}, Promise<AxiosResponse | null>>;
  loadLiveTopIdeas: Thunk<this, undefined, any, {}, Promise<AxiosResponse | null>>;
  loadClosedTopIdeas: Thunk<this, undefined, any, {}, Promise<AxiosResponse | null>>;
}

export interface InsightModel extends IInsightThunk {}

const insight: InsightModel = {
  /**
   * THUNKS
   */

  loadSectorThunk: thunk(async () => {
    const response = await client.get<any, AxiosResponse>('/insight/sector');

    if (response.status === ResponseStatusCode.OK) return response.data;

    return null;
  }),

  loadRegionThunk: thunk(async () => {
    const response = await client.get<any, AxiosResponse>('/insight/region');

    if (response.status === ResponseStatusCode.OK) return response.data;

    return null;
  }),
  loadLiveTopIdeas: thunk(async () => {
    const response = await client.get<any, AxiosResponse>('/insight/livetop');

    if (response.status === ResponseStatusCode.OK) return response.data;

    return null;
  }),
  loadClosedTopIdeas: thunk(async () => {
    const response = await client.get<any, AxiosResponse>('/insight/closedtop');

    if (response.status === ResponseStatusCode.OK) return response.data;

    return null;
  }),
};

export default insight;
