import cn from 'classnames';
import { StandardReactProps } from 'interfaces';

interface PillProps extends StandardReactProps {
  textContent: string;
  classes?: string;
  color: 'gray' | 'green' | 'blue' | 'indigo' | 'yellow';
  size: 'small' | 'medium' | 'large';
}

const classesOptions = {
  color: {
    blue: 'text-cyan-700 bg-cyan-100',
    green: 'text-green-700 bg-green-100',
    gray: 'text-indigo-800 bg-gray-100',
    indigo: 'text-indigo-800 bg-indigo-50',
    yellow: 'text-white bg-yellow-400',
  },
  size: {
    small: 'text-xs px-4 py-1 rounded-sm',
    medium: 'text-sm px-8 py-2 rounded-md',
    large: '',
  },
};

export const Pill = ({ textContent, color, size, classes = '' }: PillProps) => {
  const pillClasses = cn('inline-block text-center', {
    [classesOptions.color[color]]: color,
    [classesOptions.size[size]]: size,
    [classes]: classes,
  });

  return (
    <span className={pillClasses}>
      <p>{textContent}</p>
    </span>
  );
};
