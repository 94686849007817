import React from 'react';
import { StandardReactProps } from '../../../interfaces';

export const ArrowLeftIcon = ({ className }: StandardReactProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='30' height='15' className={className}>
    <g data-name='arrow (4)'>
      <g data-name='组 1706'>
        <path
          d='M.353 8.325l6.3 6.267a1.205 1.205 0 001.7-1.708L4.125 8.676h25.523a1.205 1.205 0 100-2.41H4.125l4.228-4.207a1.205 1.205 0 00-1.7-1.708l-6.3 6.267a1.206 1.206 0 000 1.707z'
          data-name='路径 841'
        />
      </g>
    </g>
  </svg>
);
