import { InView } from 'react-intersection-observer';
import { InvestmentThesisSectionsProps } from './types';
import ExecutiveSummary from './ExecutiveSummary';
import Thesis from './Thesis';
import Business from './Business';
import Company from './Company';
import Financials from './Financials';
import Valuation from './Valuation';
import Esg from './ESG';

const InvestmentThesisSections = ({ setSelectedSubCategory, generateData }: InvestmentThesisSectionsProps) => {
  const inViewHandler = (isInView: boolean, sectionName: string) => {
    isInView && setSelectedSubCategory(sectionName);
  };

  return (
    <div>
      <InView threshold={0.6} delay={800} onChange={isInView => inViewHandler(isInView, 'executiveSummary')}>
        <ExecutiveSummary flowType={'create'} />
      </InView>
      <InView threshold={0.3} delay={800} onChange={isInView => inViewHandler(isInView, 'thesis')}>
        <Thesis flowType={'Create'} defaultValues={generateData.thesis} />
      </InView>
      <InView threshold={0.6} delay={800} onChange={isInView => inViewHandler(isInView, 'business')}>
        <Business flowType={'Create'} />
      </InView>
      <InView threshold={0.6} delay={800} onChange={isInView => inViewHandler(isInView, 'company')}>
        <Company flowType={'Create'} defaultValues={generateData.company} />
      </InView>
      <InView threshold={0.6} delay={800} onChange={isInView => inViewHandler(isInView, 'financials')}>
        <Financials flowType={'Create'} defaultValues={generateData.financials} />
      </InView>
      <InView threshold={0.6} delay={800} onChange={isInView => inViewHandler(isInView, 'valuation')}>
        <Valuation flowType={'Create'} defaultValues={generateData.valuation} />
      </InView>
      <InView threshold={0.1} delay={800} onChange={isInView => inViewHandler(isInView, 'esg')}>
        <Esg flowType={'Create'} defaultValues={generateData.esg} />
      </InView>
    </div>
  );
};

export default InvestmentThesisSections;
