import { FC } from 'react';
import { EsgScoringProps } from './types';
import { ProgressBar } from 'components/common';
import { toFixedCustom } from 'utils/numberHelpers';

const MAX_ESG_SCORE = 5;

const EsgScoring: FC<EsgScoringProps> = ({ esgAnswers }) => {
  const { esg, total } = esgAnswers;
  const esgKeyDescOrder = Object.keys(esg).sort((a: string, b: string) => parseInt(b) - parseInt(a));

  return (
    <div className='bg-white p-8 rounded-md shadow-md flex flex-col'>
      <h2 className='mb-8 font-medium'>ESG Scoring</h2>
      <ul>
        {esgKeyDescOrder.map((option, index) => {
          const percentage = toFixedCustom((100 / total) * esg[option], 1, 0);

          return (
            <li key={`mainReasonCoInvest-${index}`} className='mb-6'>
              <div className='flex justify-between text-sm mb-4'>
                <p>
                  {option}/{MAX_ESG_SCORE}
                </p>
                <p className='ml-2'>{percentage}%</p>
              </div>
              <ProgressBar rate={esg[option]} limit={total} />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default EsgScoring;
