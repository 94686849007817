import { useEffect } from 'react';
import { format } from 'date-fns';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import { useStoreActions, useStoreState } from 'store/hooks';
import mixpanel from 'utils/mixpanelIntegration';
import { CommentType } from 'utils/enums';
import { NotFound } from 'components/common/Placeholders';
import { CommentReplyField } from './CommentReplyField';
import { CommentBlock } from './CommentBlock';
import { Timeline } from './Timeline';
import { IComment, ISubmitComment, ICommentsParams, ICommentsProps } from './types';

export const Comments: React.FC<ICommentsProps> = ({ isCommentInputVisible }) => {
  const { id } = useParams<ICommentsParams>();
  const { user } = useStoreState(state => state.userStore);
  const { ideaComments } = useStoreState(state => state.ideaStore);

  const { submitCommentThunk, loadCommentsThunk } = useStoreActions(actions => actions.ideaStore);

  const parentComments = ideaComments?.filter(e => !e.parentComment);

  useEffect(() => {
    loadCommentsThunk(id);
  }, []);

  const handleOnCommentSubmit = async ({ comment, type, root }: ISubmitComment) => {
    if (comment) {
      mixpanel.dispatchEvent('Comment: submitted', {
        'Idea ID': id,
        'Comment type': type,
        'Parent Comment ID': root,
      });

      await submitCommentThunk({
        content: comment,
        type,
        parentCommentId: root,
      });
      await loadCommentsThunk(id);
    }
  };

  const renderCommentBody = (rootComment: IComment) => {
    const childComments = ideaComments.filter(e => e.parentComment?.id === rootComment.id);

    return (
      <div className={'px-6 pt-2 pb-6 mt-4 border rounded-lg shadow-md sm:py-8 lg:mt-6'}>
        {/* PARENT */}
        <CommentBlock last={childComments.length === 0} user={rootComment.author} comment={rootComment} />

        {/* CHILDREN */}
        {childComments?.map((comment: IComment, index) => (
          <CommentBlock
            last={index === childComments.length - 1}
            key={comment.id}
            user={comment.author}
            comment={comment}
          />
        ))}

        {isCommentInputVisible && (
          <CommentReplyField
            userAvatar={user.profilePicture}
            isChild
            placeholder={'Add reply'}
            onSubmit={(comment: string) =>
              handleOnCommentSubmit({
                comment,
                type: CommentType.Reply,
                root: rootComment.id,
              })
            }
          />
        )}
      </div>
    );
  };

  const commentInputHandler = (comment: string) => handleOnCommentSubmit({ comment, type: CommentType.Default });

  return (
    <>
      {isCommentInputVisible && (
        <CommentReplyField
          placeholder={'Add a comment on this idea'}
          userAvatar={user.profilePicture}
          onSubmit={commentInputHandler}
        />
      )}

      {ideaComments.length ? (
        <div className='sm:px-6.5 mt-10'>
          {parentComments?.map((comment: IComment, index) => (
            <div className='flex w-full' key={comment.id}>
              {/* COMMENT SIDE */}
              <div className='relative flex lg:w-1/5'>
                <Timeline isEnd={index < parentComments.length - 1} isFirstComment={index === 0} />
                <div className={classNames('flex-grow hidden ml-4 lg:flex', { 'pt-10': index === 0 })}>
                  <h2 className='text-sm font-medium tracking-wider text-gray-600 '>
                    {format(new Date(comment.createdAt), 'MMM dd, yyyy')}
                  </h2>
                </div>
              </div>

              {/* COMMENT BODY */}
              <div className={classNames('w-full sm:ml-8 text-gray-600 pb-6', { 'sm:pt-13': index === 0 })}>
                <h2 className='pb-2 text-sm font-medium tracking-wider text-gray-500 lg:hidden'>
                  {format(new Date(comment.createdAt), 'MMM dd, yyyy')}
                </h2>

                <h2 className='text-sm font-medium tracking-wider text-gray-500'>
                  {`${comment.author.nickname ? comment.author.nickname : comment.author.firstName} has `}
                  <span className='font-semibold text-gray-600'>left a comment </span>
                  <span>on this idea</span>
                </h2>

                {renderCommentBody(comment)}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <NotFound text={'No comments yet for this idea'} />
      )}
    </>
  );
};
