import { Info } from './Info';
import { Chart } from './Chart';
import { Description } from './Description';
import { StockPublishedTable } from './StockPublishedTable';
import { ISymbolComponentProps } from './types';

export const SymbolComponent: React.FC<ISymbolComponentProps> = ({ symbol, symbolIdeas }) => (
  <>
    <div className='bg-white mt-6 rounded-lg p-6 grid grid-cols-1 gap-4 items-center'>
      {symbol && (
        <div className='grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3-middle-4/5 items-start'>
          <Info symbol={symbol} />
          <Chart symbol={symbol} />
          <Description className='sm:col-span-2 lg:col-auto' symbol={symbol} />
        </div>
      )}
      <div className='text-center'>
        <button type='button' className='bg-blue-700 text-white px-4 py-2 rounded-lg'>
          Submit a stock idea on this Asset
        </button>
      </div>
    </div>
    <div className='bg-white mt-6 rounded-lg'>{symbolIdeas && <StockPublishedTable symbolIdeas={symbolIdeas} />}</div>
  </>
);
