import { FC } from 'react';
import { UpsideDownsideStockProps } from './types';
import { upsideDownsideStockOptions, mediansLabels } from './content';
import { ProgressBar } from 'components/common';
import { toFixedCustom } from 'utils/numberHelpers';

export const UpsideDownsideStock: FC<UpsideDownsideStockProps> = ({ upsideDownsideAnswers }) => {
  const { options, upsideMedian, downsideMedian, total }: any = upsideDownsideAnswers;

  return (
    <div className='bg-white p-8 rounded-md shadow-md flex flex-col'>
      <h2 className='mb-8 font-medium'>Upside and downside of the stock</h2>
      <div className='body flex flex-col flex-grow'>
        <ul className='flex-grow'>
          {Object.keys(options).map((optionType, index) => {
            const percentage = (100 / total) * options[optionType];

            return (
              <li key={`mainReasonCoInvest-${index}`} className='mb-6'>
                <div className='flex justify-between text-sm mb-4'>
                  <p>{upsideDownsideStockOptions[optionType]}</p>
                  <p className='ml-2'>{toFixedCustom(percentage, 1, 0)}%</p>
                </div>
                <ProgressBar rate={options[optionType]} limit={total} />
              </li>
            );
          })}
        </ul>
        <div className='medians'>
          <p className='mb-4 text-sm flex justify-between'>
            <span className='font-medium'>{mediansLabels.upside}</span>
            <span className='ml-4'>${toFixedCustom(upsideMedian, 2, 0)}</span>
          </p>
          <p className='mb-4 text-sm flex justify-between'>
            <span className='font-medium'>{mediansLabels.downside}</span>
            <span className='ml-4'>${toFixedCustom(downsideMedian, 2, 0)}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default UpsideDownsideStock;
