import React, { useEffect } from 'react';
import { LogoIcon } from '../../components/icons/header';
import { Auth0Icon, GoogleIcon, LinkedinIcon } from '../../components/icons/auth';
import { useStoreActions } from '../../store/hooks';

export const AuthPage = () => {
  const { auth0LocalThunk, auth0GoogleThunk, auth0LinkedinThunk } = useStoreActions(actions => actions.authStore);
  const { setLoader } = useStoreActions(state => state.appStore);

  useEffect(() => {
    setLoader(false);
  }, [setLoader]);

  function handleOnGoogleAuth() {
    setLoader(true);
    auth0GoogleThunk();
  }

  function handleOnLinkedinAuth() {
    setLoader(true);
    auth0LinkedinThunk();
  }

  function handleOnLocalAuth() {
    setLoader(true);
    auth0LocalThunk();
  }

  return (
    <div className='flex h-screen'>
      <div className='flex items-center w-full lg:w-2/5'>
        <div className='max-w-md mx-auto '>
          <LogoIcon className='h-10 mx-auto my-5' />
          <h4 className='py-2 text-3xl font-semibold text-center text-teal-700'>Welcome to AlphaSwap</h4>
          <p className='my-4 font-light text-center text-md'>Connect with:</p>

          {/* SOCIAL AUTH */}
          <div className='flex justify-between max-w-xs py-3 mx-auto my-4'>
            <button
              type='button'
              onClick={() => handleOnGoogleAuth()}
              className='flex items-center justify-center px-6 py-3 font-normal transition-colors duration-500 border rounded-md shadow-sm hover:bg-gray-100'
            >
              <GoogleIcon className='h-5' />
              <p className='ml-4 text-gray-600'>Google</p>
            </button>
            <button
              type='button'
              onClick={handleOnLinkedinAuth}
              className='flex items-center justify-center px-6 py-3 font-normal transition-colors duration-500 border rounded-md shadow-sm hover:bg-gray-100'
            >
              <LinkedinIcon className='h-5' />
              <p className='ml-4 text-gray-600'>LinkedIn</p>
            </button>
          </div>

          {/* DIVIDER */}
          <div className='relative my-6'>
            <div className='absolute inset-0 flex items-center'>
              <div className='w-full border-t border-gray-300' />
            </div>
            <div className='relative flex justify-center text-sm'>
              <span className='px-2 text-gray-500 bg-white'>OR</span>
            </div>
          </div>

          {/* LOCAL AUTH */}
          <button
            type='button'
            onClick={() => handleOnLocalAuth()}
            className='flex items-center justify-center px-6 py-3 mx-auto font-normal transition-colors duration-500 border rounded-md shadow-sm hover:bg-gray-100'
          >
            <Auth0Icon className='h-5' />
            <p className='ml-4 text-gray-600'>Click here to use an email / password</p>
          </button>
        </div>
      </div>
      <div className='hidden w-3/5 bg-indigo-100 bg-no-repeat bg-cover lg:block bg-auth-pattern' />
    </div>
  );
};
