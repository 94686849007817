import React from 'react';
import { Card } from './Card';

export const Inspiration = () => (
  <Card className='grid border border-green-100 bg-green-50'>
    <p className='relative z-20 mb-6 text-2xl'>Need some Inspirations of Stock Picks?</p>
    <div className='relative z-20 flex items-end justify-end'>
      <a rel='noreferrer' href='https://intercom.help/alphaswap-app/en' target='_blank' className='p-3 text-green-500 bg-white rounded-lg'>
        Watch our Guidelines <span className=''>--&gt;</span>
      </a>
    </div>
  </Card>
);
