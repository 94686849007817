import { v4 as uuid } from 'uuid';
import { TabProps, ThesisQuestion, ThesisTextTypes, TextWithOptions, TextWithoutOptions } from './types';
import { thesisQuestionsContent } from 'pages/GenerationPage/InvestmentThesisSection/content';
import { stringNewlinesToJsxWithBr } from 'utils/stringHelpers';
import { NoDataPlaceholder } from './NoDataPlaceholder';

const text: ThesisTextTypes = {
  mispricingReason: {
    title: 'Reasons of mispricing',
    options: {
      fundamentals: 'The stock is mispriced due to the following fundamental reasons',
      specials: 'The stock is mispriced due to the following special reasons',
      technicals: 'The stock is mispriced due to the following technical reasons',
    },
  },
  catalysts: {
    title: 'Catalyst',
  },
  risks: {
    title: 'Main Risk(s)',
  },
};

const reasonWithOptionsContainer = (reasonValues: any, reasonQuestionContent: any, sectionKey: 'mispricingReason') => {
  const { title, options }: TextWithOptions = text[sectionKey];

  return (
    <div className={`${sectionKey} mb-12`}>
      <h3 className='text-xl text-blue-500 font-medium mb-4'>{title}</h3>
      <ul>
        {Object.entries(options).map(([key, text], index) => {
          const option = reasonValues?.options[index];

          return option
            ? Boolean(option[key].length) && (
                <li key={uuid()} className='mb-8'>
                  <h4 className='font-semibold'>{text}</h4>
                  <ul className='flex flex-wrap'>
                    {option[key].map((optionIndex: number) => {
                      return (
                        <li
                          key={uuid()}
                          className='mt-2 py-1 px-6 rounded-lg items-center bg-blue-50 text-blue-400 border border-blue-400 mr-4'
                        >
                          {reasonQuestionContent.options[index].options?.[optionIndex]}
                        </li>
                      );
                    })}
                  </ul>

                  {option.custom && <p className='mt-4'>{option.custom}</p>}
                  {option.description && (
                    <div className='mt-8'>
                      <h4 className='font-medium mb-2'>Comment:</h4>
                      <p>{stringNewlinesToJsxWithBr(option.description)}</p>
                    </div>
                  )}
                </li>
              )
            : null;
        })}
      </ul>
    </div>
  );
};

const reasonWithoutOptionsContainer = (
  reasonValues: any,
  reasonQuestionContent: any,
  sectionKey: 'catalysts' | 'risks'
) => {
  const { title }: TextWithoutOptions = text[sectionKey];

  return (
    <div className={`${sectionKey} mb-12`}>
      <h3 className='text-xl text-blue-500 font-medium mb-4'>{title}</h3>
      <ul className='flex flex-wrap'>
        {reasonValues.values.map((reasonIndex: number) => {
          return (
            <li
              key={uuid()}
              className='mt-2 py-1 px-6 rounded-lg items-center bg-blue-50 text-blue-400 border border-blue-400 mr-4'
            >
              {reasonQuestionContent.options[reasonIndex].label}
            </li>
          );
        })}
      </ul>
      {reasonValues.custom && <p className='mt-4'>{reasonValues.custom}</p>}
      {reasonValues.description && (
        <div className='mt-8'>
          <h4 className='font-medium mb-2'>Comment:</h4>
          <p>{stringNewlinesToJsxWithBr(reasonValues.description)}</p>
        </div>
      )}
    </div>
  );
};

const ThesisTab: React.FC<TabProps> = ({ contentValues }) => {
  if (!Object.keys(contentValues).length) return <NoDataPlaceholder />;

  const { mispricingReason, catalysts, risks } = contentValues;
  const [mispricingQuestionContent, catalystQuestionContent, risksQuestionContent] =
    thesisQuestionsContent as ThesisQuestion[];

  return (
    <div>
      {reasonWithOptionsContainer(mispricingReason, mispricingQuestionContent, 'mispricingReason')}
      {reasonWithoutOptionsContainer(catalysts, catalystQuestionContent, 'catalysts')}
      {reasonWithoutOptionsContainer(risks, risksQuestionContent, 'risks')}
    </div>
  );
};
export default ThesisTab;
