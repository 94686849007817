import { action, Action, thunk, Thunk } from 'easy-peasy';
import { isObject, merge, cloneDeep } from 'lodash';
import { AxiosResponse } from 'axios';
import { ISymbol } from '@alphaswap/types';
import { IRootModel } from '../root.model';
import { client } from 'store/api';
import { SecurityObject } from 'interfaces';
import { ThesisModel, thesis } from './parts/thesis.model';
import { CompanyModel, company } from './parts/company.model';
import { FinancialsModel, financials } from './parts/financials.model';
import { ValuationModel, valuation } from './parts/valuation.model';
import { EsgModel, esg } from './parts/esg.model';
import { initState } from './initValues';
import { DraftEnum, ResponseStatusCode } from 'utils/enums';

export interface IGenerateData {
  securityList: {
    rows: ISymbol[];
    count: number;
  };
  symbol: string;
  securityName: string;
  tradeType: string;
  entryPrice: number;
  targetPrice: number;
  stake: number;
  timeframe: string | null;
  stopLoss: number | null;
  executiveSummary: string;
  thesis: ThesisModel;
  company: CompanyModel;
  financials: FinancialsModel;
  valuation: ValuationModel;
  esg: EsgModel;
  isTncAccepted: boolean;
}

interface IGenerateIdeaPayload {
  securityList?: {
    rows: ISymbol[];
    count: number;
  };
  symbol?: string;
  securityName?: string;
  tradeType?: string;
  entryPrice?: number;
  targetPrice?: number;
  stake?: number;
  timeframe?: string | null;
  stopLoss?: number | null;
  executiveSummary?: string;
}

interface IGenerateState {
  generationStep: number;
  thesisTab: number;
  security: SecurityObject;
  generateData: IGenerateData;
}

interface IGenerateAction {
  setGenerateData: Action<this, IGenerateIdeaPayload>;
  setSecurity: Action<this, SecurityObject>;
  cleanTimeseries: Action<this>;
  setThesisTab: Action<this, number>;
  setGenerationStep: Action<this, number>;
  setGenerateDataState: Action<this, IGenerateData>;
  resetGenerateDataState: Action<this>;
  resetState: Action<this>;
  setData: Action<this, any>;
}

interface IGenerateThunks {
  fetchSecurityListThunk: Thunk<this, string, any, Record<string, unknown>, Promise<void>>;
  selectSecurityThunk: Thunk<this, string, any, Record<string, unknown>, Promise<SecurityObject | null>>;
  submitIdeaThunk: Thunk<this, undefined, any, IRootModel, Promise<AxiosResponse>>;
  saveDraftThunk: Thunk<this, undefined, any, IRootModel, Promise<AxiosResponse>>;
}

export interface IGenerateModel extends IGenerateState, IGenerateAction, IGenerateThunks {}

const generateStore: IGenerateModel = {
  /**
   * STATE
   */
  generationStep: 0,
  thesisTab: 0,
  security: {} as SecurityObject,
  generateData: {
    securityList: {
      rows: [],
      count: 0,
    },
    symbol: '',
    securityName: '',
    tradeType: '',
    entryPrice: 0,
    targetPrice: 0,
    stake: 0,
    timeframe: null,
    stopLoss: null,
    executiveSummary: '',
    thesis,
    company,
    financials,
    valuation,
    esg,
    isTncAccepted: false,
  },
  /**
   * SUB STORES
   */

  /**
   * ACTIONS
   */
  setData: action((state, payload) => {
    state = Object.assign(state, payload);
  }),
  setGenerateDataState: action((state, payload) => {
    state.generateData = merge({}, state.generateData, payload);
  }),

  resetGenerateDataState: action(state => {
    state.generateData = merge({}, state.generateData, cloneDeep(initState.generateData));
  }),

  setThesisTab: action((state, payload) => {
    state.thesisTab = payload;
  }),

  setGenerationStep: action((state, payload) => {
    state.generationStep = payload;
  }),

  setGenerateData: action((state, payload: IGenerateIdeaPayload) => {
    state.generateData = Object.assign(state.generateData, payload);
  }),

  setSecurity: action((state, payload: SecurityObject) => {
    state.security = Object.assign(state.security, payload);
  }),

  cleanTimeseries: action(state => {
    state.security.timeseries = {
      timeseries: null,
    };
  }),

  resetState: action(state => {
    Object.keys(initState).forEach(key => {
      // @ts-ignore
      state[key] = isObject(initState[key]) ? { ...initState[key] } : initState[key];
    });
  }),

  /**
   * THUNKS
   */
  fetchSecurityListThunk: thunk(async (actions, payload) => {
    const response = await client.get<any, AxiosResponse<IGenerateIdeaPayload['securityList']>>(
      `/symbol?query=${payload}&take=10`
    );
    if (response?.status === ResponseStatusCode.OK) {
      actions.setGenerateData({
        securityList: response.data,
      });
    }
  }),
  selectSecurityThunk: thunk(async (actions, payload) => {
    const response = await client.get<any, AxiosResponse<SecurityObject>>(`/security/symbol/${payload}`);
    if (response?.status === ResponseStatusCode.OK) {
      actions.setSecurity(response.data);
      return response.data;
    } else {
      actions.cleanTimeseries();
      return null;
    }
  }),
  submitIdeaThunk: thunk(async (actions, _, { getState }) => {
    const { security, generateData } = getState();
    try {
      return client.post<any, AxiosResponse>('/idea/', {
        symbol: security.symbol,
        tradeType: generateData.tradeType,
        entryPrice: generateData.entryPrice,
        targetPrice: generateData.targetPrice,
        stopLoss: generateData.stopLoss,
        stake: generateData.stake,
        timeframe: generateData.timeframe,
        executiveSummary: generateData.executiveSummary,
        thesis: {
          thesis: generateData.thesis,
          company: generateData.company,
          financials: generateData.financials,
          valuation: generateData.valuation,
          esg: generateData.esg,
        },
        isTncAccepted: generateData.isTncAccepted,
      });
    } catch (error) {
      return error.response;
    }
  }),
  saveDraftThunk: thunk(async (actions, payload, { getStoreState }) => {
    const draft = getStoreState().generationStore.security;
    draft.timeseries.timeseries = null;
    return client.post<any, AxiosResponse<SecurityObject>>('/draft', {
      draft,
      type: DraftEnum.IDEA_GENERATION,
    });
  }),
};
export default generateStore;
