import { UserDetailsCard } from './UserDetailsCard';
import { UserStatsCard } from './UserStatsCard';
import { IUserCardProps } from './types';

const UserCard: React.FC<IUserCardProps> = ({ user }) => {
  const { id, city, country, job, onboardedAt, statistics, profilePicture } = user;

  return (
    <div className={'user-card grid grid-cols-2 gap-4'}>
      <UserDetailsCard
        id={id}
        city={city}
        country={country}
        job={job}
        onboardedAt={onboardedAt.toString()}
        profilePicture={profilePicture}
      />

      <UserStatsCard
        sector={statistics?.sector || ''}
        region={statistics?.region || ''}
        unrealizedPerformance={statistics?.avgUnrealizedPerformance || 0}
        realizedPerformance={statistics?.avgRealizedPerformance || 0}
      />
    </div>
  );
};

export default UserCard;
