export type IVoteFormState = {
  stake: number;
  reasons: {
    values: number[];
    custom: string;
    description: string;
  };
  aspect: boolean;
  upside: number;
  downside: number;
  esgScore?: number;
  esgDescription: string;
  isTncAccepted: boolean;
};

export type IVoteIdeaPageParams = {
  id: string;
};

export enum MenuOptionsKeys {
  SENTIMENT = 'SENTIMENT',
  STOCK_ATTRIBUTES = 'STOCK_ATTRIBUTES',
  ESG = 'ESG',
  VIRTUAL_DOLLARS = 'VIRTUAL_DOLLARS',
}
