import { Action, action, Thunk, thunk } from 'easy-peasy';
import { AxiosResponse } from 'axios';
import { client } from 'store/api';
import { IdeaObject } from 'interfaces';
import { ResponseStatusCode } from 'utils/enums';

interface IContributionState {
  ideas: IdeaObject[];
  filters: any;
}

interface IContributionActions {
  setIdeas: Action<this, any>;
  setFilters: Action<this, any>;
}

interface IContributionThunks {
  loadFiltersThunk: Thunk<this, undefined, any, Record<string, unknown>, Promise<AxiosResponse<string[]>>>;
}

export interface IContributionModel extends IContributionState, IContributionActions, IContributionThunks {}

const contributionStore: IContributionModel = {
  /**
   * STATE
   */
  ideas: [],
  filters: [],

  /**
   * ACTIONS
   */
  setIdeas: action((state, payload) => {
    state.ideas = payload;
  }),
  setFilters: action((state, payload) => {
    state.filters = payload;
  }),

  /**
   * THUNKS
   */
  loadFiltersThunk: thunk(async actions => {
    const response = await client.get<any, AxiosResponse<string[]>>('/contribution/filter/stats');
    if (response?.status === ResponseStatusCode.OK) {
      actions.setFilters(response.data);
    }

    return response;
  }),
};

export default contributionStore;
