import { useStore } from 'easy-peasy';
import { WebAuth } from 'auth0-js';
import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import params from 'auth0-params.json';
import { useStoreActions } from 'store/hooks';
import { showErrorModal } from 'utils';
import { ResponseStatusCode } from 'utils/enums';
import mixpanel from 'utils/mixpanelIntegration';

const webAuth = new WebAuth({
  domain: params.domain,
  clientID: params.clientId,
});

export const AuthCallbackPage = () => {
  const history = useHistory();
  const store = useStore();

  const { connectWithAuth0Thunk } = useStoreActions(actions => actions.authStore);
  const { getMeThunk } = useStoreActions(actions => actions.userStore);
  const { loadNotificationsThunk } = useStoreActions(actions => actions.notificationStore);

  const { setLoader } = useStoreActions(actions => actions.appStore);

  const [auth0AccessToken, setAuth0AccessToken] = useState('');

  function getAuth0AccessToken() {
    webAuth.parseHash({ hash: window.location.hash }, (err, res) => {
      setLoader(false);
      setAuth0AccessToken(res?.accessToken!);
    });
  }

  async function connectWithAuth0(token: string) {
    try {
      const response: AxiosResponse = await connectWithAuth0Thunk(token);
      if (response?.status === ResponseStatusCode.CREATED) {
        const { data: userObject } = await getMeThunk();
        await loadNotificationsThunk();
        history.push('/');

        return userObject;
      }
    } catch (error) {
      console.log('error.request', error.request);
      await store.persist.clear();
      history.replace('/');
      showErrorModal(error.request);
    }
  }

  useEffect(() => {
    getAuth0AccessToken();
  }, []);

  useEffect(() => {
    const init = async () => {
      if (auth0AccessToken?.length > 0) {
        const user = await connectWithAuth0(auth0AccessToken);
        user && mixpanel.login(user);
      }
    };
    init();
  }, [auth0AccessToken]);

  return null;
};
