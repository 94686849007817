import React from 'react';
import { StandardReactProps } from '../../../interfaces';

export const ContributeGrowthIcon = ({ className }: StandardReactProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='22.079' height='22.079' className={className}>
    <g data-name='growth (1)'>
      <path
        d='M17.094 7.835v-.712a1.069 1.069 0 100-2.137h-.712a.356.356 0 110-.712h1.068v.356h.712V3.561h-1.068v-.712h-.712v.712a1.069 1.069 0 100 2.137h.712a.356.356 0 010 .712h-1.069v-.356h-.712v1.068h1.068v.712z'
        data-name='路径 211'
      />
      <path
        d='M20.655 5.341a3.917 3.917 0 10-3.917 3.917 3.922 3.922 0 003.917-3.917zm-7.122 0a3.205 3.205 0 113.205 3.205 3.209 3.209 0 01-3.206-3.205z'
        data-name='路径 212'
      />
      <path
        d='M22.079 5.342a5.342 5.342 0 10-7.263 4.979l-.719.719h-2.863l-2.493 2.849H4.474L.095 18.632l.523.483L4.786 14.6h4.279l2.493-2.849h2.835l1.2-1.2a5.317 5.317 0 001.009.121l-1.792 1.792h-3.236l-2.137 2.849H5.18L0 21.233v.846h20.655V8.961a5.316 5.316 0 001.424-3.62zm-9.971 0a4.63 4.63 0 114.63 4.63 4.635 4.635 0 01-4.63-4.63zm5.7 5.234v10.791h-1.427V11.9l1.305-1.305c.04-.008.081-.011.12-.019zm-2.7 2.6l.565-.565v8.756h-1.428v-8.191zM4.986 21.367H3.561v-3.122l1.424-1.628zm.714-5.342h1.422v5.342H5.7zm2.137 0h1.424v5.342H7.835zm2.137-.238l1.424-1.9v7.478H9.971zm2.137-2.611h1.424v8.191h-1.427zm-9.262 5.883v2.308H.829zm15.669 2.308v-11a5.332 5.332 0 001.424-.767v11.767z'
        data-name='路径 213'
      />
      <path
        d='M2.136 13.176h.712v-1.422h.712V7.478h-.712V6.054h-.712v1.424h-.712v4.276h.712zm0-4.986h.712v2.849h-.712z'
        data-name='路径 214'
      />
      <path d='M4.985 11.396h.712V9.972h.712V6.41h-.712V4.986h-.712V6.41h-.712v3.561h.712zm0-4.273h.712V9.26h-.712z' data-name='路径 215' />
      <path
        d='M7.834 8.905h.712V7.478h.712V4.629h-.712V3.205h-.712v1.424h-.712v2.849h.712zm0-3.561h.712v1.424h-.712z'
        data-name='路径 216'
      />
    </g>
  </svg>
);
