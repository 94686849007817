import { INITIAL_WALLET_BALANCE } from 'utils/globalConstants';
import { toFixedCustom } from 'utils/numberHelpers';
import { IDetailsProps } from './types';

export const Details: React.FC<IDetailsProps> = ({ idea }) => {
  const alocationValue = (idea.generatorStake / INITIAL_WALLET_BALANCE) * 100;

  return (
    <div className={'grid grid-cols-2 gap-2 sm:grid-cols-3'}>
      <div className={'grid gap-2 text-center'}>
        <p className={'font-light text-blue-900 uppercase'}>Time Frame</p>
        <p className={'text-xs font-light text-gray-600'}>{idea.timeframe}</p>
      </div>
      <div className={'grid gap-2 text-center'}>
        <p className={'font-light text-blue-900 uppercase'}>Allocation</p>
        <p className={'text-xs uppercase'}>
          <span className={'px-6 py-1 font-bold text-green-800 bg-green-200 rounded-full'}>
            {toFixedCustom(alocationValue, 2, 0)}%
          </span>
        </p>
      </div>
    </div>
  );
};
