import { Crop } from 'react-image-crop';

export function getCroppedImg(image: HTMLImageElement, crop: Crop) {
  const canvas: HTMLCanvasElement = document.createElement('canvas');
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width as number;
  canvas.height = crop.height as number;
  const ctx: CanvasRenderingContext2D = canvas.getContext('2d') as CanvasRenderingContext2D;

  ctx.drawImage(
    image,
    (crop.x as number) * scaleX,
    (crop.y as number) * scaleY,
    (crop.width as number) * scaleX,
    (crop.height as number) * scaleY,
    0,
    0,
    crop.width as number,
    crop.height as number
  );

  return new Promise(resolve => {
    canvas.toBlob(_blob => {
      const blob = _blob as Blob;

      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    }, 'image/jpeg');
  });
}

export function resetInput(input: HTMLInputElement | null, value: string) {
  if (input) {
    input.value = value;
  }
}
