import React from 'react';
import { Link } from 'react-router-dom';
import { Card } from './Card';
import { Checkbox } from '../../../components/icons/contribution/Checkbox';

export const SubmitIdea = () => (
  <Card className='grid border border-blue-100 bg-blue-50'>
    <h1 className='relative z-20 mb-6 text-2xl text-center text-gray-800 md:text-left'>
      Submit your Stock Pick <br /> in three simple steps
    </h1>
    <div className='relative z-20 flex flex-col items-start justify-between sm:flex-row sm:items-end'>
      <div>
        <p className='flex mb-4'>
          <Checkbox className='p-2 bg-blue-200 rounded-full w-7 h-7' />
          <span className='ml-2 font-light'>Select a stock</span>
        </p>
        <p className='flex'>
          <Checkbox className='p-2 mb-4 bg-blue-200 rounded-full w-7 h-7' />
          <span className='ml-2 font-light'>Write your analysis</span>
        </p>
        <p className='flex '>
          <Checkbox className='p-2 bg-blue-200 rounded-full w-7 h-7' />
          <span className='ml-2 font-light'>Allocate Virtual Dollars</span>
        </p>
      </div>
      <Link to='/idea/generate/choose-asset' className='p-3 mt-4 text-blue-500 bg-white rounded-lg sm:mt-0'>
        Submit a stock idea <span className=''>--&gt;</span>
      </Link>
    </div>
  </Card>
);
