import { useState, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import Swal from 'sweetalert2';

import { SelectedCategory } from './types';
import { FloatingMenu } from 'components/common/FloatingMenu';
import mixpanel from 'utils/mixpanelIntegration';
import { CategoryType } from 'components/common/FloatingMenu/types';
import { menuOptions } from './menuOptions';
import { useStoreActions, useStoreState } from 'store/hooks';
import SymbolSelectionWithChart from './SymbolSelectionWithChart';
import TradeDetailsSection from './TradeDetailsSection';
import InvestmentThesisSections from './InvestmentThesisSection';
import CommitCreditsSection from './CommitCreditsSection';
import { ResponseStatusCode } from 'utils/enums';

const categories = menuOptions.map(({ key: name }, index) => ({ name, index }));

const GenerationPage: React.FC = () => {
  const { setGenerateDataState, submitIdeaThunk, resetState } = useStoreActions(action => action.generationStore);
  const { generateData } = useStoreState(state => state.generationStore);

  const form = useForm({
    defaultValues: useMemo(() => {
      return generateData;
    }, [generateData]),
  });

  const history = useHistory();
  const [selectedCategory, setSelectedCategory] = useState({ name: 'selectAnAsset', index: 0 });
  const [selectedSubCategory, setSelectedSubCategory] = useState('');

  useEffect(() => {
    form.reset(generateData);

    window.scrollTo(0, 0);
  }, [selectedCategory]);

  const menuClickHandler = async (categoryName: string, categoryType: string) => {
    switch (categoryType) {
      case CategoryType.CATEGORY: {
        const isFormValid = await form.trigger();

        if (isFormValid) {
          const formData: any = form.getValues();
          const indexOfSelectedCategory = categories.findIndex(({ name }) => name === categoryName);
          setGenerateDataState(formData);

          history.push({ hash: '' });
          setSelectedCategory(categories[indexOfSelectedCategory]);
          setSelectedSubCategory('');
        }

        break;
      }
      case CategoryType.SUBCATEGORY: {
        setSelectedSubCategory(categoryName);
        break;
      }
      default:
        break;
    }
  };

  const categoryContent = () => {
    switch (selectedCategory.name) {
      case SelectedCategory.SelectAnAsset:
        return <SymbolSelectionWithChart />;
      case SelectedCategory.TradeDetails:
        return <TradeDetailsSection />;
      case SelectedCategory.InvestmentThesis:
        return <InvestmentThesisSections setSelectedSubCategory={setSelectedSubCategory} generateData={generateData} />;
      case SelectedCategory.CommitDollars:
        return <CommitCreditsSection isTncAccepted={generateData.isTncAccepted} stake={generateData.stake} />;
      default:
        return null;
    }
  };

  const submitIdea = async () => {
    const response = await submitIdeaThunk();
    if (response?.status === ResponseStatusCode.CREATED) {
      mixpanel.dispatchEvent('Create idea: submitted');
      resetState();
      history.push('/');
    } else {
      void Swal.fire('Error', response.data.message, 'error');
    }
  };

  const submitFormHandler = async (data: any) => {
    const isFormValid = await form.trigger();

    if (isFormValid) {
      setGenerateDataState(data);

      if (selectedCategory.name === 'commitDollars') {
        void submitIdea();
      } else {
        setSelectedCategory(categories[selectedCategory.index + 1]);
      }
    }
  };

  return (
    <div className='flex mb-8'>
      <div className='flex-shrink-0 pt-8'>
        <FloatingMenu
          classes='sticky top-8'
          menuOptions={menuOptions}
          selectedCategory={selectedCategory.name}
          selectedSubCategory={selectedSubCategory}
          onClick={menuClickHandler}
        />
      </div>

      <FormProvider {...form}>
        <form className={'flex-grow flex flex-col'} onSubmit={form.handleSubmit(submitFormHandler)}>
          {categoryContent()}
          <button
            type='submit'
            className='mt-4 px-8 py-2 text-sm font-light text-white uppercase rounded-lg self-end whitespace-nowrap bg-blue-600 text-white rounded-t-md'
          >
            {selectedCategory.name === 'commitDollars' ? 'Submit' : 'NEXT'}
          </button>
        </form>
      </FormProvider>
    </div>
  );
};

export default GenerationPage;
