import { Checkbox as CheckboxMui, FormControlLabel } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import { ICheckboxListItemProps } from './types';

export const CheckboxListItem: React.FC<ICheckboxListItemProps> = ({
  label,
  name,
  rules,
  isChecked,
  labelClasses,
  onChange,
  ...otherProps
}) => {
  const { control } = useFormContext();

  return (
    <FormControlLabel
      className={labelClasses}
      control={
        <Controller
          name={name}
          control={control}
          rules={rules}
          render={({ onChange: onCheckChange }) => (
            <CheckboxMui checked={isChecked} onChange={() => onCheckChange(onChange?.())} {...otherProps} />
          )}
        />
      }
      label={label}
    />
  );
};
