import React from 'react';
import { StandardReactProps } from '../../../interfaces';

export const Auth0Icon = ({ className }: StandardReactProps) => (
  <svg className={className} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
    <path
      fill='#4A5568'
      d='M21.98 7.448L19.62 0H4.34L2.01 7.448c-1.352 4.31.03 9.206 3.81 12.015l6.17 4.53 6.15-4.552c3.75-2.81 5.18-7.69 3.81-12.015L15.79 12l2.343 7.45 -6.157-4.6 -6.16 4.58L8.16 12 1.97 7.45 9.6 7.4l2.36-7.42L14.32 7.38l7.615.04Z'
    />
  </svg>
);
