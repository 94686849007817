import { useState, useRef } from 'react';
import Swal from 'sweetalert2';
import avatar from 'components/icons/common/avatar.jpg';
import { CountrySelect } from 'components/CountrySelect';
import { JobDropdown } from 'components/JobDropdown';
import { findSelectOptionByValue } from 'utils';
import { countryList, jobList } from 'utils/data';
import { ResponseStatusCode } from 'utils/enums';
import { useStoreActions, useStoreState } from 'store/hooks';
import { UploadAvatar } from './UploadAvatar';

export const AccountPage: React.FC = () => {
  const { user } = useStoreState(state => state.userStore);
  const { updateUserThunk } = useStoreActions(actions => actions.userStore);

  const [firstName, setFirstName] = useState(user?.firstName);
  const [lastName, setLastName] = useState(user?.lastName);
  const [nickname, setNickname] = useState(user?.nickname);

  const [profileDescription, setProfileDescription] = useState(user?.profileDescription);

  const jobObject = findSelectOptionByValue(user?.job, jobList);
  const countryObject = findSelectOptionByValue(user?.country, countryList);

  const [job, setJob] = useState(jobObject);
  const [country, setCountry] = useState(countryObject);

  const uploadInput = useRef<HTMLInputElement | null>(null);

  async function handleOnUserUpdate() {
    const response = await updateUserThunk({
      firstName,
      lastName,
      nickname,
      profileDescription,
      job: job.value,
      country: country.value,
    });

    if (response?.status === ResponseStatusCode.OK) {
      Swal.fire('Success', 'User profile updated', 'success');
    }
  }

  return (
    <div>
      <h1 className='text-3xl font-semibold'>Account</h1>
      <div className='my-6'>
        <p className='w-20 font-semibold text-center'>Avatar</p>
        <div className='items-center my-1 sm:flex'>
          <div className='w-20 h-20 overflow-hidden rounded-full'>
            <img src={user.profilePicture || avatar} alt='avatar' className='w-full h-full' />
          </div>
          <div className='flex justify-center my-3 sm:my-0'>
            <div className='mx-3 ml-6'>
              <button
                type='button'
                onClick={() => {
                  uploadInput.current?.click();
                }}
                className='px-4 py-2 text-sm text-blue-500 border border-gray-500 rounded-md sm:text-base focus:outline-none'
              >
                Upload
              </button>
            </div>
            <div className='mx-3'>
              <button
                type='button'
                className='px-4 py-2 text-sm text-gray-500 border border-gray-500 rounded-md sm:text-base focus:outline-none'
              >
                Remove
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className='py-4 my-4'>
        <div className='my-2'>
          <div className='flex justify-between w-full my-2 lg:w-1/2'>
            <p className='text-sm font-bold text-gray-800'>Display name</p>
            <p className='text-sm text-gray-600'>Visible to other members</p>
          </div>
          <input
            value={nickname}
            onChange={e => setNickname(e.target.value)}
            name='name'
            placeholder='Leave blank if you want to be public'
            className='w-full px-3 py-2 text-sm border rounded-md md:text-base lg:w-1/2 focus:border-indigo-500 focus:outline-none'
          />
        </div>

        <div className='flex justify-between my-6'>
          <div className='w-5/12'>
            <p className='my-2 text-sm font-bold text-gray-800'>First name</p>
            <input
              value={firstName}
              onChange={e => setFirstName(e.target.value)}
              name='firstName'
              placeholder='First name'
              className='w-full px-3 py-2 text-sm border rounded-md md:text-base focus:border-indigo-500 focus:outline-none'
            />
          </div>
          <div className='w-5/12'>
            <p className='my-2 text-sm font-bold text-gray-800'>Last name</p>
            <input
              value={lastName}
              onChange={e => setLastName(e.target.value)}
              name='lastName'
              placeholder='Last name'
              className='w-full px-3 py-2 text-sm border rounded-md md:text-base focus:border-indigo-500 focus:outline-none'
            />
          </div>
        </div>

        <div className='justify-between my-6 sm:flex'>
          <div className='mt-4 sm:w-5/12'>
            <p className='my-2 text-sm font-bold text-gray-800'>Title</p>
            <div className='mt-2 text-gray-800 border rounded'>
              <JobDropdown value={job} onChange={setJob} />
            </div>
          </div>
          <div className='mt-4 sm:w-5/12'>
            <p className='my-2 text-sm font-bold text-gray-800'>Location</p>
            <div className='my-2 text-gray-800 border rounded'>
              <CountrySelect value={country} onChange={setCountry} />
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className='flex justify-between'>
          <p className='text-sm font-bold '>Bio</p>
          <p className='text-sm text-gray-500'>Characters left:255</p>
        </div>
        <textarea
          value={profileDescription}
          onChange={e => setProfileDescription(e.target.value)}
          className='w-full p-3 my-3 text-gray-700 border rounded-md h-42 min-h-100 focus:outline-none'
        />
      </div>

      <div className='relative pb-16 my-4'>
        <div className='my-2'>
          <div className='flex justify-between w-full my-2 lg:w-1/2'>
            <p className='text-sm font-bold text-gray-800'>Email address</p>
            <p className='text-sm text-gray-600'>For notifications and logging in</p>
          </div>
          <input
            name='email'
            disabled
            type='email'
            placeholder='Email'
            value={user?.email}
            className='w-full px-3 py-2 text-sm border rounded-md cursor-not-allowed md:text-base lg:w-1/2 focus:border-indigo-500 focus:outline-none'
          />
        </div>

        <button
          type='button'
          onClick={() => handleOnUserUpdate()}
          className='absolute bottom-0 right-0 p-2 px-6 text-sm bg-indigo-100 border rounded-md sm:text-base focus:outline-none'
        >
          <p className='text-indigo-600 '>Save changes</p>
        </button>
      </div>
      <UploadAvatar innerRef={uploadInput} />
    </div>
  );
};
