import { thunk, action, Thunk, Action } from 'easy-peasy';
import { AxiosResponse } from 'axios';
import { PaginationParametersInterface, TopIdeasApiResponseObject, TopUsersApiResponseObject } from 'interfaces';
import { client } from 'store/api';
import { ResponseStatusCode } from 'utils/enums';

interface ITopUsersAndIdeasThunk {
  getTopUsersList: Thunk<
    this,
    PaginationParametersInterface,
    undefined,
    {},
    Promise<AxiosResponse<TopUsersApiResponseObject>>
  >;
  getTopIdeasList: Thunk<
    this,
    PaginationParametersInterface,
    undefined,
    {},
    Promise<AxiosResponse<TopIdeasApiResponseObject>>
  >;
}

interface ITopUsersState extends TopUsersApiResponseObject {
  offset: number;
}
interface ITopIdeasState extends TopIdeasApiResponseObject {
  offset: number;
}

interface ITopUsersAndIdeasActions {
  setTopUsersList: Action<this, ITopUsersState>;
  setTopIdeasList: Action<this, ITopIdeasState>;
}

export interface TopUsersAndIdeasModel extends ITopUsersAndIdeasThunk, ITopUsersAndIdeasActions {
  topUsersList: ITopUsersState;
  topIdeasList: ITopIdeasState;
}

const topUsersAndIdeasStore: TopUsersAndIdeasModel = {
  topUsersList: {
    rows: [],
    count: 0,
    offset: 0,
  },
  topIdeasList: {
    rows: [],
    count: 0,
    offset: 0,
  },

  /**
   * ACTIONS
   */
  setTopUsersList: action((state, payload) => {
    state.topUsersList = payload;
  }),
  setTopIdeasList: action((state, payload) => {
    state.topIdeasList = payload;
  }),

  /**
   * THUNKS
   */
  getTopUsersList: thunk(async (actions, { offset, limit }) => {
    const response = await client.get<any, AxiosResponse<TopUsersApiResponseObject>>(
      `/statistics?take=${limit}&skip=${offset}`
    );

    if (response.status === ResponseStatusCode.OK) actions.setTopUsersList({ ...response.data, offset });

    return response;
  }),
  getTopIdeasList: thunk(async (actions, { offset, limit }) => {
    const response = await client.get<any, AxiosResponse<TopIdeasApiResponseObject>>(
      `/idea/top?take=${limit}&skip=${offset}`
    );

    if (response.status === ResponseStatusCode.OK) actions.setTopIdeasList({ ...response.data, offset });

    return response;
  }),
};

export default topUsersAndIdeasStore;
