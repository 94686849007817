import React from 'react';
import { StandardReactProps } from '../../interfaces';

interface ContainerProps extends StandardReactProps {
  children: any;
}

export const Container = (props: ContainerProps) => {
  const { children, className } = props;
  return <div className={`container py-2 mx-auto px-4 ${className}`}>{children}</div>;
};
