import { FormProvider, useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import { TextArea } from 'components/fields';
import { Layout } from 'components';
import TextInput from 'components/common/Inputs/TextInput';
import { intercomApi } from 'api/intercom';
import { IIntercomMessageRequestObject } from 'interfaces';
import { useHistory } from 'react-router';

const ReportPage: React.FC = () => {
  const history = useHistory();
  const form = useForm<IIntercomMessageRequestObject>();

  const submitForm = async () => {
    try {
      await intercomApi.sendMessage(form.getValues());

      Swal.fire({ title: 'Thank you!', text: 'Report is sent' })
        .then(() => history.goBack())
        .catch(() => 'Operation is canceled.');
    } catch (err) {
      console.error(new Error(err));
    }
  };

  return (
    <Layout>
      <div className={'mt-4'}>
        <p className={'text-2xl mb-7'}>Report</p>

        <FormProvider {...form}>
          <form className={'flex-grow flex flex-col'} onSubmit={form.handleSubmit(submitForm)}>
            <TextInput
              name={'subject'}
              rules={{
                required: true,
              }}
              label={'Subject'}
              placeholder={'Subject'}
              className={'mb-6'}
            />
            <TextArea
              name={'message'}
              rules={{
                required: true,
              }}
              label={'Content'}
              placeholder={'Tell us your problem'}
            />

            <button
              type={'submit'}
              className={
                'mt-4 px-8 py-2 text-sm font-light text-white uppercase rounded-lg self-end whitespace-nowrap bg-blue-600 rounded-t-md'
              }
            >
              Submit
            </button>
          </form>
        </FormProvider>
      </div>
    </Layout>
  );
};

export default ReportPage;
