import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import * as H from 'history';

type ScrollTopProps = RouteComponentProps & {
  location: H.History;
};

const ScrollToTop: React.FC<ScrollTopProps> = ({ location, children }) => {
  useEffect(() => window.scrollTo(0, 0), [location.pathname, location.state]);
  return <>{children}</>;
};

export default withRouter(ScrollToTop);
