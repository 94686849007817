import { action, Action } from 'easy-peasy';

interface FinancialsPayload {
  revenue?: number;
  earnings?: number;
  margins?: number;
  generation?: number;
  strength?: number;
  ratingsDescription?: string;
  balance?: number[];
  balanceDescription?: string;
}

export interface FinancialsState {
  rates: {
    revenue: number | null;
    earnings: number | null;
    margins: number | null;
    generation: number | null;
    strength: number | null;
    description: string;
  };
  balanceSheet: {
    values: number[];
    custom?: string;
    description?: string;
  };
}

interface FinancialsAction {
  setFinancialsData: Action<this, FinancialsPayload>;
}

export interface FinancialsModel extends FinancialsState, FinancialsAction {}

export const financials: FinancialsModel = {
  /**
   * STATE
   */
  rates: {
    revenue: null,
    earnings: null,
    margins: null,
    generation: null,
    strength: null,
    description: '',
  },
  balanceSheet: {
    values: [],
    description: '',
    custom: '',
  },

  /**
   * ACTIONS
   */
  setFinancialsData: action((state, payload) => {
    state = Object.assign(state, payload);
  }),
};
