import { IWalletHistory } from '@alphaswap/types';
import { AreaChart } from 'components';
import { INITIAL_WALLET_BALANCE } from 'utils/globalConstants';
import { toFixedCustom } from 'utils/numberHelpers';
import { IWallet } from 'store/models/myalphaswapModel/my-alphaswap.model';
import image from 'img/placeholder-graphs.png';

interface ICreditBalanceProps {
  wallet: IWallet;
  showModal: boolean;
}

const CreditBalancePlacehoder = () => (
  <div className='flex-grow flex items-center justify-center m-8 mx-auto'>
    <img src={image} alt='avatar' className='object-none object-left pl-8' />
    <div className='flex flex-col px-10 mb-3 text-start'>
      <p className='mb-3 text-sm text-start'>You start with 100,000 virtual dollars in your wallet.</p>
      <p className='mr-2 text-sm text-start'>Submit and co-invest in Stock Picks to grow your balance.</p>
    </div>
  </div>
);

export const CreditBalance: React.FC<ICreditBalanceProps> = ({ wallet }) => {
  const isPlaceholderVisible = wallet?.walletHistory.length === 1;
  const performance = (wallet.credits / INITIAL_WALLET_BALANCE - 1) * 100;

  return (
    <div className='p-4 overflow-x-scroll bg-white rounded-md shadow-md flex flex-col'>
      <h4 className='mb-2 font-bold'>Credits Balance</h4>
      {isPlaceholderVisible ? (
        <CreditBalancePlacehoder />
      ) : (
        <>
          <div className='grid gap-4 sm:grid-cols-4 sm:gap-0'>
            <div>
              <p className='text-xs text-gray-500 uppercase'>Balance</p>
              <p>{wallet.credits + wallet.stakedCredits}</p>
            </div>
            <div>
              <p className='text-xs text-gray-500 uppercase'>Staked</p>
              <p>{wallet.stakedCredits}</p>
            </div>
            <div>
              <p className='text-xs text-gray-500 uppercase'>Available</p>
              <p>{wallet.credits}</p>
            </div>
            <div>
              <p className='text-xs text-gray-500 uppercase'>Performance</p>
              <p>{toFixedCustom(performance, 2)}%</p>
            </div>
          </div>
          <AreaChart
            data={wallet.walletHistory?.map((history: IWalletHistory): any[] => [
              new Date(history.createdAt),
              history.credits,
            ])}
          />
        </>
      )}
    </div>
  );
};
