import { StandardReactProps } from 'interfaces';

export const BuildingIcon = ({ className }: StandardReactProps) => {
  return (
    <div className={className}>
      <svg xmlns='http://www.w3.org/2000/svg' width='24' height='23.819' viewBox='0 0 24 23.819'>
        <g id='XMLID_1_' transform='translate(0 -0.181)'>
          <path
            id='Path_969'
            data-name='Path 969'
            d='M14,9.09l8.81,1.75A1.477,1.477,0,0,1,24,12.3V22.5A1.5,1.5,0,0,1,22.5,24h-9a.5.5,0,0,0,.5-.5V23h8.5a.5.5,0,0,0,.5-.5V12.3a.5.5,0,0,0-.39-.49L14,10.11Z'
            fill='currentColor'
          />
          <path id='Path_970' data-name='Path 970' d='M19.5,14a.5.5,0,0,1,0,1h-2a.5.5,0,0,1,0-1Z' fill='currentColor' />
          <path id='Path_971' data-name='Path 971' d='M19.5,17a.5.5,0,0,1,0,1h-2a.5.5,0,0,1,0-1Z' fill='currentColor' />
          <path id='Path_972' data-name='Path 972' d='M19.5,20a.5.5,0,0,1,0,1h-2a.5.5,0,0,1,0-1Z' fill='currentColor' />
          <path
            id='Path_973'
            data-name='Path 973'
            d='M14,23.5a.5.5,0,0,1-1,0V9.5a.532.532,0,0,1,.18-.39.526.526,0,0,1,.42-.1l.4.08V23.5Z'
            fill='currentColor'
          />
          <path id='Path_974' data-name='Path 974' d='M13,23v.5a.5.5,0,0,0,.5.5h-4a.5.5,0,0,0,.5-.5V23Z' fill='currentColor' />
          <path id='Path_975' data-name='Path 975' d='M10.5,5a.5.5,0,0,1,0,1h-2a.5.5,0,0,1,0-1Z' fill='currentColor' />
          <path id='Path_976' data-name='Path 976' d='M11,8.5a.5.5,0,0,1-.5.5h-2a.5.5,0,0,1,0-1h2A.5.5,0,0,1,11,8.5Z' fill='currentColor' />
          <path id='Path_977' data-name='Path 977' d='M10.5,11a.5.5,0,0,1,0,1h-2a.5.5,0,0,1,0-1Z' fill='currentColor' />
          <path id='Path_978' data-name='Path 978' d='M10.5,14a.5.5,0,0,1,0,1h-2a.5.5,0,0,1,0-1Z' fill='currentColor' />
          <path id='Path_979' data-name='Path 979' d='M6,14.5a.5.5,0,0,1-.5.5h-2a.5.5,0,0,1,0-1h2A.5.5,0,0,1,6,14.5Z' fill='currentColor' />
          <path id='Path_980' data-name='Path 980' d='M5.5,5a.5.5,0,0,1,0,1h-2a.5.5,0,0,1,0-1Z' fill='currentColor' />
          <path id='Path_981' data-name='Path 981' d='M5.5,8a.5.5,0,0,1,0,1h-2a.5.5,0,0,1,0-1Z' fill='currentColor' />
          <path id='Path_982' data-name='Path 982' d='M5.5,11a.5.5,0,0,1,0,1h-2a.5.5,0,0,1,0-1Z' fill='currentColor' />
          <path
            id='Path_983'
            data-name='Path 983'
            d='M9,18.5a.5.5,0,0,0-.5-.5h-3a.5.5,0,0,0-.5.5V23H4V18.5A1.5,1.5,0,0,1,5.5,17h3A1.5,1.5,0,0,1,10,18.5V23H9Z'
            fill='currentColor'
          />
          <path id='Path_984' data-name='Path 984' d='M5,23h5v.5a.5.5,0,0,1-.5.5h-5a.5.5,0,0,1-.5-.5V23Z' fill='currentColor' />
          <path
            id='Path_985'
            data-name='Path 985'
            d='M1.75.2,12.74,1.87A1.494,1.494,0,0,1,14,3.35V9.09l-.4-.08a.526.526,0,0,0-.42.1A.532.532,0,0,0,13,9.5V3.35a.506.506,0,0,0-.42-.5L1.59,1.19a.277.277,0,0,0-.09-.01.472.472,0,0,0-.32.12A.478.478,0,0,0,1,1.68V22.5a.5.5,0,0,0,.5.5H4v.5a.5.5,0,0,0,.5.5h-3A1.5,1.5,0,0,1,0,22.5V1.68A1.472,1.472,0,0,1,.53.54,1.5,1.5,0,0,1,1.75.2Z'
            fill='currentColor'
          />
        </g>
      </svg>
    </div>
  );
};
