import { ILink } from './types';

export const userLinks: ILink[] = [
  {
    path: '/my-alphaswap',
    name: 'My AlphaSwap',
    children: ['/my-alphaswap/idea-management', '/my-alphaswap/track-record'],
  },
  {
    path: '/',
    name: 'Alpha Feed',
  },
  {
    path: '/insights',
    name: 'Insights',
  },
  {
    path: '/leaderboard',
    name: 'Leaderboard',
  },
];

export const customerLinks: ILink[] = [
  {
    path: '/top',
    name: 'Top ideas & users',
  },
  {
    path: '/',
    name: 'Alpha Feed',
  },
  {
    path: '/watchlist',
    name: 'Watchlist',
  },
];
