import { action, Action } from 'easy-peasy';

interface PlaceholderState {
  showCreditBalance: boolean;
  showLastInsights: boolean;
  showIdeaManagement: boolean;
  showTrackRecord: boolean;
  showVotes: boolean;
}

interface PlaceholderActions {
  setCreditBalance: Action<this, boolean>;
  setLastInsights: Action<this, boolean>;
  setIdeaManagement: Action<this, boolean>;
  setTrackRecord: Action<this, boolean>;
  setVotes: Action<this, boolean>;
}

export interface PlaceholderModel extends PlaceholderState, PlaceholderActions {}

const placeholderStore: PlaceholderModel = {
  showCreditBalance: true,
  showLastInsights: true,
  showIdeaManagement: true,
  showTrackRecord: true,
  showVotes: true,
  /**
   * ACTIONS
   */
  setCreditBalance: action((state, payload) => {
    state.showCreditBalance = payload;
  }),

  setLastInsights: action((state, payload) => {
    state.showLastInsights = payload;
  }),

  setIdeaManagement: action((state, payload) => {
    state.showIdeaManagement = payload;
  }),

  setTrackRecord: action((state, payload) => {
    state.showTrackRecord = payload;
  }),

  setVotes: action((state, payload) => {
    state.showVotes = payload;
  }),
};

export default placeholderStore;
