import { Link } from 'react-router-dom';

const Page404 = () => {
  return (
    <div className='h-screen w-screen flex items-center justify-center flex-col'>
      <h1 className='text-8xl'>404</h1>
      <p>page not found</p>
      <Link to='/'>
        <p className='mt-8 text-blue-500'> {'<--'} Back to Home</p>
      </Link>
    </div>
  );
};

export default Page404;
