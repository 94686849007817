import { Link } from 'react-router-dom';
import { CellType } from 'components/Table/types';
import { ClosedIdeaRowDataType, LiveIdeaRowDataType } from './types';
import { dateFormatter } from 'utils/dateFormat';
import { toFixedCustom } from 'utils/numberHelpers';

export const liveIdeasColumns: CellType[] = [
  { title: 'Date' },
  { title: 'Symbol' },
  { title: 'Geo' },
  { title: 'Sector' },
  { title: 'Entry price' },
  { title: 'Last price' },
  { title: 'ITD Perf' },
  { title: 'Target price' },
  { title: 'Details' },
];

export const closedIdeasColumns: CellType[] = [
  { title: 'Date' },
  { title: 'Symbol' },
  { title: 'Geo' },
  { title: 'Sector' },
  { title: 'Entry price' },
  { title: 'Exit price' },
  { title: 'Last price' },
  { title: 'Perf' },
  { title: 'Details' },
];

export const liveIdeaRowData: LiveIdeaRowDataType = {
  date: date => ({
    title: <span className={'font-medium'}>{dateFormatter(date, 'dd-MMM-y')}</span>,
  }),
  symbol: symbol => ({
    title: symbol ? <span className={'font-medium'}>{symbol}</span> : null,
  }),
  geo: geo => ({ title: geo || null, showTooltip: true }),
  sector: sector => ({ title: sector || null, showTooltip: true }),
  entryPrice: price => ({ title: toFixedCustom(price, 2) }),
  lastPrice: price => ({ title: toFixedCustom(price, 2) }),
  itdPerformance: performance => {
    if (performance === null) return { title: '-' };

    return {
      title: (
        <span className={`font-medium ${performance > 0 ? 'text-green-500' : performance < 0 ? 'text-red-500' : ''}`}>
          {performance > 0 ? `+${toFixedCustom(performance, 2)}%` : `${toFixedCustom(performance, 2)}%`}
        </span>
      ),
    };
  },
  targetPrice: price => ({ title: toFixedCustom(price, 2) }),
  detailsLink: id => ({
    title: (
      <Link className={'font-semibold text-table-content-gray'} to={`/idea/${id}`} target={'_blank'} rel='noreferrer'>
        {'--->'}
      </Link>
    ),
  }),
};

export const closedIdeaRowData: ClosedIdeaRowDataType = {
  date: date => ({
    title: <span className={'font-medium'}>{dateFormatter(date, 'dd-MMM-y')}</span>,
  }),
  symbol: symbol => ({
    title: symbol ? <span className={'font-medium'}>{symbol}</span> : null,
  }),
  geo: geo => ({ title: geo || null, showTooltip: true }),
  sector: sector => ({ title: sector || null, showTooltip: true }),
  entryPrice: price => ({ title: toFixedCustom(price, 2) }),
  exitPrice: price => ({ title: toFixedCustom(price, 2) }),
  lastPrice: price => ({ title: toFixedCustom(price, 2) }),
  performance: performance => {
    if (performance === null) return { title: '-' };

    return {
      title: (
        <span className={`font-medium ${performance > 0 ? 'text-green-500' : performance < 0 ? 'text-red-500' : ''}`}>
          {performance > 0 ? `+${toFixedCustom(performance, 2)}%` : `${toFixedCustom(performance, 2)}%`}
        </span>
      ),
    };
  },
  detailsLink: id => ({
    title: (
      <Link className={'font-semibold text-table-content-gray'} to={`/idea/${id}`} target={'_blank'} rel='noreferrer'>
        {'--->'}
      </Link>
    ),
  }),
};
