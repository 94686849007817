import { useState } from 'react';
import classNames from 'classnames';
import { IDescriptionProps } from './types';

export const Description: React.FC<IDescriptionProps> = ({ symbol, className }) => {
  const [showMore, setShowMore] = useState(false);
  return (
    <div
      className={classNames({
        'bg-blue-50 rounded-lg p-4 flex flex-col items-end': true,
        [className!]: true,
      })}
    >
      <div className='grid gap-2 grid-rows-auto-first mb-2'>
        <p className='uppercase text-blue-600 text-sm font-bold'>Description</p>
        <p
          className={classNames({
            'text-xs text-blue-500 leading-5': true,
            ...(showMore
              ? {}
              : {
                  'overflow-hidden max-h-24': true,
                }),
          })}
        >
          {symbol.description}
        </p>
      </div>
      <button type='button' className='text-sm text-blue-600' onClick={() => setShowMore(!showMore)}>
        {showMore ? 'Hide' : 'Show more...'}
      </button>
    </div>
  );
};

Description.defaultProps = {
  className: '',
};
