import { format } from 'date-fns';
import { getRegionNameByCode } from 'utils/countryHelpers';
import { useStoreState } from 'store/hooks';
import { renderExpectedReturn } from 'utils';
import { IdeaStatus } from 'utils/enums';
import { RCAreaChart } from 'components/charts';
import { MoreButton } from 'components/MoreButton';
import { moreButtonMenuOptions } from 'pages/IdeaPage/UserIdeaPage/data';

const statusClasses = {
  [IdeaStatus.LIVE]: 'text-white bg-green-400',
  [IdeaStatus.PENDING]: 'text-white bg-indigo-400',
  [IdeaStatus.CLOSED]: 'text-gray-900 bg-gray-300',
  [IdeaStatus.DECLINED]: 'text-gray-900 bg-gray-300',
};

export const IdeaDetailsHeader: React.FC = () => {
  const { idea } = useStoreState(state => state.ideaStore);

  return (
    <div className={'my-10'}>
      {/* SECURITY NAME / TICKER */}
      <div className={'flex items-center'}>
        <h2 className={'my-2 text-4xl font-light text-gray-800'}>
          <span>{idea.security.name}</span>
          <span className={'ml-4'}>{idea.security.symbol}</span>
        </h2>

        <div className={`px-4 py-2 ml-4 rounded-md ${statusClasses[idea?.status]}`}>{idea?.status || ''}</div>

        <div className={'ml-auto'}>
          <MoreButton options={moreButtonMenuOptions} />
        </div>
      </div>

      {/* SECURITY PILLS */}
      <div className={'flex w-2/4 my-10 text-sm font-normal text-center text-gray-800'}>
        <div className={'px-10 py-3 bg-white rounded-md shadow-md'}>{idea.security.exchangeSymbol}</div>

        {idea.security?.region && (
          <div className={'px-10 py-3 ml-5 bg-blue-100 rounded-md shadow-md'}>
            {getRegionNameByCode(idea.security.region).label}
          </div>
        )}

        {idea.security?.sector && (
          <div className={'px-10 py-3 ml-5 bg-yellow-100 rounded-md shadow-md'}>{idea.security.sector}</div>
        )}
      </div>

      {/* SECURITY CHART */}
      <div className={'grid grid-cols-6 gap-10'}>
        <div className={'col-span-4 p-6 bg-white rounded-md shadow-md'}>
          {idea.security.timeseries?.timeseries && <RCAreaChart data={idea.security.timeseries.timeseries} />}
        </div>

        <div className={'grid h-full col-span-2 grid-rows-4 gap-5'}>
          <div
            className={
              'px-3 py-1 my-2 mb-4 border border-green-200 rounded shadow-sm bg-green-50 md:my-0 sm:w-1/2 xl:py-6 lg:py-4 md:w-full'
            }
          >
            <p className={'text-lg text-green-500'}>{idea.tradeType}</p>
            <p className={'text-xs text-green-700'}>
              Idea created the {format(new Date(idea.security.updatedAt), 'yyyy.MM.dd')}
            </p>
          </div>

          <div
            className={
              'px-3 py-1 my-2 mb-4 border border-indigo-200 rounded md:my-0 bg-card-back-indigo sm:w-1/2 xl:py-6 lg:py-4 md:w-full'
            }
          >
            <p className={'text-lg text-indigo-500'}>
              {idea.security.currency} {idea.security.openPrice}
            </p>
            <p className={'text-xs text-indigo-700'}>
              Price as of {format(new Date(idea.security.updatedAt), 'yyyy.MM.dd')}
            </p>
          </div>

          <div
            className={
              'px-3 py-1 my-2 mb-4 border border-indigo-200 rounded shadow-sm md:my-0 bg-card-back-indigo sm:w-1/2 xl:py-6 lg:py-4 md:w-full'
            }
          >
            <p className={'text-lg text-indigo-500'}>
              {idea.security.currency} {idea.targetPrice}
            </p>
            <p className={'text-xs text-indigo-700'}>{idea.timeframe} PRICE TARGET</p>
          </div>

          <div
            className={
              'px-3 py-1 my-2 border border-indigo-200 rounded shadow-sm md:my-0 bg-card-back-indigo sm:w-1/2 xl:py-6 lg:py-4 md:w-full'
            }
          >
            <p className={'text-lg text-indigo-500'}>
              {Math.round(
                renderExpectedReturn(idea.status, idea.tradeType, idea.entryPrice, idea.closedPrice, idea.targetPrice) *
                  100
              )}
              %
            </p>
            <p className={'text-xs text-indigo-700'}>UPSIDE</p>
          </div>
        </div>
      </div>
    </div>
  );
};
