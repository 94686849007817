export const menuOptions = [
  {
    key: 'investmentThesis',
    title: 'Investment Thesis',
    children: [
      { key: 'executiveSummary', title: 'Executive Summary' },
      { key: 'thesis', title: 'Thesis' },
      { key: 'business', title: 'Business' },
      { key: 'company', title: 'Company' },
      { key: 'financials', title: 'Financials' },
      { key: 'valuation', title: 'Valuation' },
      { key: 'esg', title: 'Esg' },
    ],
  },
];
