import { InvestmentThesisProps } from './types';
import { IdeaThesis } from 'components/idea/IdeaThesis';
import { IdeaHeader } from '../GeneralInformation/IdeaHeader';

const InvestmentThesis = ({ security, isFavorite }: InvestmentThesisProps) => {
  const { exchangeSymbol, region, sector, name } = security;

  return (
    <div className='bg-white p-8 rounded-md shadow-md'>
      <div className='stock-info'>
        <IdeaHeader name={name} exchangeSymbol={exchangeSymbol} region={region} sector={sector} isFavorite={isFavorite} />
        <IdeaThesis />
      </div>
    </div>
  );
};

export default InvestmentThesis;
