import { useState, useEffect } from 'react';
import { IVote } from '@alphaswap/types';
import { NotFound } from 'components/common/Placeholders/';
import { voteApi } from 'api/vote';
import { Table } from 'components';
import { CellType, TablePaginationActionInterface, TablePaginationActionTypeEnum } from 'components/Table/types';
import { UserVotesTableProps, VoteCellDataType } from './types';
import { ideasColumns, voteTableRowData } from './data';

// FIXME: move to separate file
interface IPaginationResult<T> {
  rows: Array<T>;
  count: number;
}

const TABLE_LIMIT = 10;

const UserVotesTable: React.FC<UserVotesTableProps> = ({ userId }) => {
  const [userVotes, setUserVotes] = useState<IPaginationResult<IVote> | null>(null);
  const [tablePage, setTablePage] = useState(0);

  useEffect(() => {
    const init = async () => {
      try {
        const query = `take=${TABLE_LIMIT}&skip=${TABLE_LIMIT * tablePage}&userId=${userId}`;
        const userVotesResponse = await voteApi.getVotes(query);

        setUserVotes(userVotesResponse);
      } catch (error) {
        console.error(error);
      }
    };
    void init();
  }, [tablePage]);

  const handleTableChange = ({ type }: TablePaginationActionInterface) => {
    if (type === TablePaginationActionTypeEnum.next) setTablePage(tablePage + 1);
    if (type === TablePaginationActionTypeEnum.previous) setTablePage(tablePage - 1);
  };

  const transformIdeasList = (votes: IVote[]): CellType[][] => {
    const cellsData: VoteCellDataType[][] = votes.map(({ createdAt, idea }) => {
      const { id, security, status, entryPrice, closedPrice, livePrice, performance } = idea || {};
      return [
        {
          cellName: 'date',
          cellValue: createdAt,
        },
        {
          cellName: 'status',
          cellValue: status,
        },
        {
          cellName: 'symbol',
          cellValue: security?.symbol,
        },
        {
          cellName: 'entryPrice',
          cellValue: entryPrice,
        },
        {
          cellName: 'exitPrice',
          cellValue: closedPrice,
        },
        {
          cellName: 'lastPrice',
          cellValue: livePrice || closedPrice,
        },
        {
          cellName: 'performance',
          cellValue: performance,
        },
        {
          cellName: 'detailsLink',
          cellValue: id,
        },
      ];
    });

    return cellsData.map((row: VoteCellDataType[]) =>
      row.map(({ cellName, cellValue }): CellType => {
        const cellFunc: Function = voteTableRowData[cellName];

        return cellFunc(cellValue);
      })
    );
  };

  return (
    <div className='bg-white p-8 rounded-md shadow-md mt-8'>
      {userVotes?.count ? (
        <Table
          title={'Virtual Co-Invesment Made'}
          columns={ideasColumns}
          data={transformIdeasList(userVotes.rows)}
          offset={TABLE_LIMIT * tablePage}
          total={userVotes.count}
          onPageChange={handleTableChange}
        />
      ) : (
        <NotFound text={'No votes submitted'} />
      )}
    </div>
  );
};

export default UserVotesTable;
