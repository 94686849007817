import { MenuItem } from '@material-ui/core';
import { MoreButtonOption } from 'components/MoreButton/types';
import { CopyToClipboard } from 'components/CopyToClipboard';

const CopyPathnameMenuItemComponent: React.FC = () => (
  <CopyToClipboard text={window.location.href}>
    <MenuItem>Copy idea link</MenuItem>
  </CopyToClipboard>
);

export const moreButtonMenuOptions: MoreButtonOption[] = [
  {
    title: 'Copy idea link',
    customElement: CopyPathnameMenuItemComponent,
  },
  {
    title: 'Report',
    onClickAction: (): void => {
      window.location.href = window.location.origin + '/report';
    },
  },
];
