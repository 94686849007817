import React from 'react';
import { StandardReactProps } from '../../../interfaces';

export const LogoIcon = ({ className }: StandardReactProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='157.432' height='12.801' className={className}>
    <g data-name='Group 1777' fillRule='evenodd'>
      <path
        data-name='Path 967'
        d='M10.892 12.613h1.534L6.98.219H5.446L0 12.613h1.534l1.252-2.817H9.7l1.189 2.817zM3.318 8.45l2.879-6.51 2.879 6.51zm17.559 2.817V.219h-1.408v12.394h8.169v-1.346zM34.9 12.613h1.408V8.169h3.631c2.566 0 4.57-1.628 4.57-3.975 0-2.316-2-3.975-4.57-3.975H34.9v12.394zm1.408-5.79V1.565h3.349c2 0 3.38.97 3.38 2.629s-1.377 2.629-3.38 2.629zm24.131-6.6v5.415h-7.324V.219h-1.408v12.394h1.408V6.979h7.293v5.634h1.408V.219zm19.53 12.394H81.5L76.056.219h-1.534l-5.446 12.394h1.534l1.252-2.817h6.917l1.189 2.817zm-7.574-4.163l2.879-6.51 2.879 6.51z'
        fill='#b0adad'
      />
      <path
        data-name='Path 968'
        d='M93.02 12.8c2.879 0 4.35-1.6 4.35-3.568 0-2.191-1.69-3.036-4.069-3.6-2.1-.5-3.349-.97-3.349-2.222a2.454 2.454 0 012.66-2.16 5.067 5.067 0 013.537 1.6l.876-1.1a6.2 6.2 0 00-4.35-1.753c-2.379 0-4.131 1.565-4.131 3.537 0 2.16 1.565 2.911 4.069 3.505 2.16.5 3.349 1.033 3.349 2.347 0 1.127-.97 2.16-2.879 2.16a5.66 5.66 0 01-4.069-1.784l-.876 1.1a6.723 6.723 0 004.883 1.941zm15.462-.188h1.377l3.478-10.546 3.443 10.548h1.377L122.382.22h-1.6l-3.412 10.016L114.084.22h-1.565l-3.282 10.015L105.825.219h-1.6l4.257 12.394zm30.579 0h1.534L135.149.218h-1.534l-5.446 12.394h1.534l1.252-2.817h6.917l1.189 2.817zm-7.543-4.163l2.879-6.51 2.879 6.51zm16.307 4.163h1.408V8.169h3.631c2.566 0 4.57-1.628 4.57-3.975 0-2.316-2-3.975-4.57-3.975h-5.039v12.394zm1.408-5.79V1.565h3.38c2 0 3.38.97 3.38 2.629s-1.377 2.629-3.38 2.629z'
        fill='#52b6e3'
      />
    </g>
  </svg>
);
