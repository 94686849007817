import { action, Action } from 'easy-peasy';

interface CompanyPayload {
  companyDescription?: string;
  competition?: number;
  entrants?: number;
  suppliers?: number;
  customers?: number;
  threat?: number;
  ratingsDescription?: string;
  management?: number[];
  managementDescription?: string;
}

export interface CompanyState {
  companyDescription: string;
  rates: {
    competition: number | null;
    entrants: number | null;
    suppliers: number | null;
    customers: number | null;
    threat: number | null;
    description: string;
  };
  management: {
    values: number[];
    custom?: string;
    description?: string;
  };
}

interface CompanyAction {
  setCompanyData: Action<this, CompanyPayload>;
}

export interface CompanyModel extends CompanyState, CompanyAction {}

export const company: CompanyModel = {
  /**
   * STATE
   */
  companyDescription: '',
  rates: {
    competition: null,
    entrants: null,
    suppliers: null,
    customers: null,
    threat: null,
    description: '',
  },
  management: {
    values: [],
    custom: '',
    description: '',
  },

  /**
   * ACTIONS
   */
  setCompanyData: action((state, payload) => {
    state = Object.assign(state, payload);
  }),
};
