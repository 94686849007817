/* eslint-disable */
import React, { useState } from 'react';
import { useStoreState } from '../../store/hooks';
import { StandardReactProps } from '../../interfaces';
import { SearchIcon } from '../icons/header';

interface SearchbarProps extends StandardReactProps {
  searchOption?: string;
  placeholder?: string;
  color?: string;
  searchFuntion: Function;
  setValue?: Function;
}

export const SearchBar = (props: SearchbarProps) => {
  // search option

  const { searchOption } = useStoreState((state) => state.headerStore);

  const { searchFuntion, setValue, placeholder } = props;

  const [data, dataSet] = useState([]);

  const getData = async (c: string) => {
    const searchValue = await searchFuntion(c);
    dataSet(searchValue === undefined ? [] : searchValue.data);
  };

  return (
    <div className="relative">
      <div className="flex items-center justify-between h-8 overflow-hidden bg-blue-100 border">
        <span className="flex items-center w-10 h-full p-3">
          <SearchIcon className="text-blue-700 fill-current" />
        </span>
        <input
          className="h-8 p-2 px-0 text-xs text-gray-800 placeholder-blue-700 bg-blue-100 outline-none xl:w-full lg:w-32 sm:text-xs xl:text-sm"
          type="text"
          placeholder={placeholder}
          onChange={(e) => {
            getData(e.target.value);
          }}
        />
      </div>
      {data === null || data.length === 0 ? null : (
        <div
          style={{ maxHeight: '260px' }}
          className="absolute right-0 z-10 overflow-y-scroll text-sm bg-white border sm:left-0 sm:right-auto"
        >
          {searchOption === 'Assets'
            ? (data || []).map((item: any, i: number) => (
              <div
                onClick={() => {
                  setValue && setValue(item.symbol);
                  dataSet([]);
                }}
                key={i}
                className="flex justify-between p-2 mx-auto text-sm text-gray-800 border-b cursor-pointer hover:bg-blue-100"
              >
                <p className="w-20 mr-5">{item.symbol}</p>
                <p className="w-32 ">
                  {item.companyName.length > 15
                    ? `${item.companyName.substring(0, 15)}...`
                    : `${item.companyName}`}
                </p>
              </div>
            ))
            : (data || []).map((item: any, i: number) => (
              <div
                key={i}
                className="flex justify-between p-2 mx-auto text-sm text-gray-800 border-b hover:bg-blue-100"
              >
                <div className="w-12 ">
                  <div className="w-6 h-6 mr-5 overflow-hidden rounded-full">
                    <img
                      className="object-cover object-center w-full h-full"
                      src={item.profilePicture}
                      alt="Italian Trulli"
                    />
                  </div>
                </div>
                {item.visibility === 'PUBLIC' ? (
                  <div className="flex w-40">
                    <p className="">{item.firstName}</p>
                    <p className="mx-1">{item.lastName}</p>
                  </div>
                ) : (
                  <p>{item.nickname}</p>
                )}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};
