import { useStoreActions } from 'store/hooks';
import { Link, useHistory } from 'react-router-dom';
import { tc } from './content';

export const TermsAndConditionsPage: React.FC = () => {
  const history = useHistory();
  const { updateUserThunk } = useStoreActions(actions => actions.userStore);
  const { title, subTitle, note, versionStamp, sections } = tc;

  const acceptButtonHandler = async () => {
    await updateUserThunk({
      isTncAccepted: true,
    });
    history.push('/');
  };

  return (
    <div className={'w-11/12 max-w-screen-lg mx-auto mt-4 p-8 bg-white rounded-md shadow-md'}>
      <h1 className={'font-bold text-center text-3xl'}>{title}</h1>
      <h2 className={'text-center'}>{subTitle}</h2>
      <p className={'text-center my-4'}>{note}</p>
      <ul>
        {sections.map(({ title, header, subSections }, index) => {
          const sectionsIndex = index + 1;

          return (
            <span key={`sub-sections-${index}`} className={'sections grid grid-cols-auto-first gap-x-8 gap-y-4'}>
              <p className={'font-bold'}>{sectionsIndex}</p>
              <li className={'section'}>
                <h3 className={'section-title font-bold mb-2'}>{title}</h3>
                {header && <h4 className={'mb-2'}>{header}</h4>}
                <ul>
                  {subSections.map(({ title, header, paragraphs, afterWords }: any, index: number) => {
                    const subSectionsIndex = index + 1;

                    return (
                      <span
                        key={`sub-sections-${index}`}
                        className={'sub-sections grid grid-cols-auto-first gap-x-8 gap-y-4 mt-2'}
                      >
                        <p className={'font-bold mb-2'}>
                          {sectionsIndex}.{subSectionsIndex}
                        </p>
                        <li>
                          <h3 className={'sub-section-title font-bold mb-2'}>{title}</h3>
                          {header && <h4 className={'mb-2'}>{header}</h4>}
                          <ul>
                            {paragraphs.list.map((item, index: number) => {
                              return (
                                <span
                                  key={`sub-sections-${index}`}
                                  className={`grid gap-y-4 ${
                                    paragraphs.hasOrder ? 'grid-cols-auto-first gap-x-8' : ''
                                  }`}
                                >
                                  {paragraphs.hasOrder && (
                                    <p>
                                      {sectionsIndex}.{subSectionsIndex}.{index + 1}
                                    </p>
                                  )}
                                  <p>{item}</p>
                                </span>
                              );
                            })}
                          </ul>
                          {afterWords &&
                            afterWords.map((afterWord, index) => {
                              return (
                                <p key={`afterwords-${index}`} className={'after-word mt-2'}>
                                  {afterWord}
                                </p>
                              );
                            })}
                        </li>
                      </span>
                    );
                  })}
                </ul>
              </li>
            </span>
          );
        })}
      </ul>
      <div className='cta-container mt-8 flex justify-end items-center'>
        <Link to='/logout' className={'text-gray-400 hover:text-gray-600 mr-8 underline font-semibold duration-300'}>
          Decline
        </Link>
        <button
          onClick={acceptButtonHandler}
          className={'text-center py-3 px-12 text-white bg-blue-500 hover:bg-blue-700 rounded-lg duration-300'}
        >
          Accept
        </button>
      </div>
      <p className={'mt-16 text-gray-400'}>{versionStamp}</p>
    </div>
  );
};
