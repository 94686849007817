import React from 'react';
import { StandardReactProps } from '../../../interfaces';

export const FlashIcon = ({ className }: StandardReactProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='27.221' height='48.727' viewBox='0 0 40 40' className={className}>
    <g data-name='组 1641'>
      <path
        fill='#b9c3de'
        d='M27.221 14.706h-9.8L24.77 0H9.079L0 16.34h9.871l-4.9 9.8h9.276l-4.039 22.587zM7.608 24.51l4.9-9.8h-9.73L10.04 1.638h12.087L14.773 16.34h9.8L13.808 37.876l2.39-13.366z'
        data-name='路径 774'
      />
    </g>
  </svg>
);
