import { useState } from 'react';
import { TextField } from '@material-ui/core';
import { useFormContext, Controller, RegisterOptions } from 'react-hook-form';
import cn from 'classnames';

interface ITextAreaProps {
  name: string;
  rules?: RegisterOptions;
  defaultValue?: string;
  label?: string;
  placeholder?: string;
}

const ERROR_MESSAGES = {
  required: 'Field is required',
  maxLength: 'Message is too long',
};

export const TextArea: React.FC<ITextAreaProps> = ({ name, rules, defaultValue, ...otherProps }) => {
  const allowedCharactersQty = parseInt(`${rules?.maxLength}`);
  const { control, errors, getValues } = useFormContext();
  const [inputValue, setInputValue] = useState(defaultValue || getValues(name) || '');

  const isError = !!errors[name]?.type;
  const errorType = errors[name]?.type;
  const errorMessage = errorType ? ERROR_MESSAGES[errorType] : '';
  const ramainingCharactersQty = allowedCharactersQty - inputValue.length;
  const inputLimitClassName = cn('text-right text-xs flex-grow', {
    'text-red-500': ramainingCharactersQty <= 0,
  });

  return (
    <div className={'w-full'}>
      <Controller
        defaultValue={defaultValue}
        rules={rules}
        name={name}
        control={control}
        render={({ onChange, value, ref, ...controllerProps }) => {
          return (
            <TextField
              value={value}
              multiline
              rows={7}
              variant={'outlined'}
              fullWidth
              onChange={event => {
                setInputValue(event.target.value);
                onChange(event);
              }}
              inputRef={ref}
              error={isError}
              helperText={isError ? errorMessage : null}
              {...controllerProps}
              {...otherProps}
            />
          );
        }}
      />

      <div className={'flex mt-2 justify-end'}>
        {Boolean(allowedCharactersQty) && (
          <p className={inputLimitClassName}>{ramainingCharactersQty} characters left</p>
        )}
      </div>
    </div>
  );
};
