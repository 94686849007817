export enum IdeaStatus {
  LIVE = 'LIVE',
  PENDING = 'PENDING',
  DECLINED = 'DECLINED',
  CLOSED = 'CLOSED',
  PROPOSED = 'PROPOSED',
}

export enum IdeaTableType {
  LIVE = 'LIVE',
  PENDING = 'PENDING',
  DECLINED = 'DECLINED',
  CLOSED = 'CLOSED',
  GENERATION = 'GENERATION',
  VOTE = 'VOTE',
}

export enum FavoriteIdeasType {
  LIVE = 'LIVE',
  PENDING = 'PENDING',
  DECLINED = 'DECLINED',
  CLOSED = 'CLOSED',
  PROPOSED = 'PROPOSED',
  DRAFT = 'DRAFT',
}

export enum CommentType {
  Default = 'DEFAULT',
  Reply = 'REPLY',
  Update = 'UPDATE',
  Status = 'STATUS',
}

export enum DraftEnum {
  IDEA_GENERATION = 'IDEA_GENERATION',
}

export enum UserType {
  ADMIN = 'ADMIN',
  USER = 'USER',
  CUSTOMER = 'CUSTOMER',
}

export enum LimitOrderType {
  MARKET = 'MARKET',
  LIMIT = 'LIMIT',
}

export enum TakeProfitType {
  MARKET = 'MARKET',
  LIMIT = 'LIMIT',
}

export enum TradeType {
  LONG = 'LONG',
  SHORT = 'SHORT',
}

export enum HearAboutUsType {
  SOCIAL = 'SOCIAL',
  INVITE = 'INVITE',
  SEARCH = 'SEARCH',
  FRIEND = 'FRIEND',
  OTHER = 'OTHER',
}

export enum VisibilityType {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
}

export enum JobType {
  AMATEUR = 'AMATEUR',
  FINANCE_PROFESSIONAL = 'FINANCE_PROFESSIONAL',
  RETIRED = 'RETIRED',
  INVESTMENT_PROFESSIONAL = 'INVESTMENT_PROFESSIONAL',
  STUDENT = 'STUDENT',
}

export enum IdeaTimeframe {
  LessThanOneWeek = '<1w',
  OneWeekToOneMonth = '1w-1m',
  OneMonthToThreeMonths = '1m-3m',
  ThreeMonthsToSixMonths = '3m-6m',
  SixMonthsToOneYear = '6m-1y',
  MoreThanOneYear = '>1y',
}

export enum IdeaHistoryPropertyType {
  entryPrice = 'entryPrice',
  targetPrice = 'targetPrice',
  stopLoss = 'stopLoss',
  timeframe = 'timeframe',
  tradeType = 'tradeType',
}

export enum StatusColor {
  LIVE = 'bg-green-500',
  PENDING = 'bg-yellow-500',
  CLOSED = 'bg-gray-500',
  DECLINED = 'bg-red-500',
}

export enum ResponseStatusCode {
  OK = 200,
  CREATED = 201,
  UNAUTHORIZED = 401,
  NOT_FOUND = 404,
}
