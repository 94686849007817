export const initState = {
  stake: 0,
  voteReasons: [],
  reasonCustom: '',
  reasonDescription: '',
  aspect: true,
  upside: 0,
  downside: 0,
  esgScore: undefined,
  esgDescription: '',
};
