import React from 'react';
import { useFormContext, Controller, RegisterOptions } from 'react-hook-form';

interface ITextFieldProps {
  name: string;
  defaultValue?: string;
  rules?: RegisterOptions;
  placeholder?: string;
  className?: string;
}

export const TextField: React.FC<ITextFieldProps> = ({ name, rules, placeholder, className, defaultValue }) => {
  const { errors, control } = useFormContext();

  return (
    <div>
      <Controller
        name={name}
        defaultValue={defaultValue}
        rules={rules}
        control={control}
        render={({ ref, onChange, value }) => (
          <input ref={ref} onChange={onChange} placeholder={placeholder} className={className} defaultValue={value} />
        )}
      />
      {errors[name] && <p className='text-xs text-red-500'>{errors[name].message}</p>}
    </div>
  );
};
