import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { cloneDeep } from 'lodash';
import { TextArea } from 'components/fields';
import { CheckboxListItem } from './CheckboxListItem';
import TextInput from '../TextInput';

interface IOption {
  label: string;
  name: string;
  options?: string[];
}

interface IOptionsProps {
  content: any;
  sectionType?: string;
  defaultValues: any;
}

const filterSelectedOptions = (options: number[] = [], newSelectedIndex: number): number[] =>
  options.includes(newSelectedIndex)
    ? options.filter(index => index !== newSelectedIndex)
    : [...options, newSelectedIndex];

const filterSelectedSubOptions = (options: any, newSelectedIndex: number, optionIndex: number, optionName: string) => {
  if (options[optionIndex]?.[optionName]) {
    options[optionIndex][optionName] = filterSelectedOptions(options[optionIndex][optionName], newSelectedIndex);
  }

  return [...options];
};

const CheckboxList: React.FC<IOptionsProps> = ({ content, sectionType = '', defaultValues }) => {
  const { options, name: listName, query } = content;
  const { errors } = useFormContext();
  const [checkedOptions, setCheckedOptions] = useState(defaultValues[listName].values);
  const [checkedSubOptions, setSubCheckedOptions] = useState(defaultValues[listName].options);

  const optionChangeHandler = (checkedIndex: number, optionName: string) => {
    const localCheckedOptions = cloneDeep(checkedOptions);
    const localCheckedSubOptions = cloneDeep(checkedSubOptions);
    const newCheckedValues = filterSelectedOptions(localCheckedOptions, checkedIndex);

    if (localCheckedSubOptions) {
      localCheckedSubOptions[checkedIndex] = {
        [optionName]: [],
        custom: '',
      };
    }

    setCheckedOptions(newCheckedValues);
    setSubCheckedOptions(localCheckedSubOptions);

    return newCheckedValues;
  };

  const subOptionChangeHandler = (checkedIndex: number, optionIndex: number, optionName: string) => {
    const newCheckedValues = filterSelectedSubOptions(checkedSubOptions, checkedIndex, optionIndex, optionName);
    setSubCheckedOptions(newCheckedValues);

    return newCheckedValues[optionIndex];
  };

  const listErrors = sectionType ? errors[sectionType]?.[listName] : errors[listName];
  const optionName = sectionType ? `${sectionType}.${listName}.values` : `${listName}.values`;
  const isOptionOtherIncluded = options?.find((option: IOption) => option.name.toLocaleLowerCase() === 'other');

  return (
    <ul>
      <div>
        {options.map(({ name, label, options: subOptions }: IOption, index: number) => {
          const isChecked = checkedOptions?.includes(index);
          const subListErrors = listErrors?.options;
          const isSubOptionOtherIncluded = subOptions?.find(option => option.toLocaleLowerCase() === 'other');

          return (
            <li key={name}>
              <CheckboxListItem
                label={label}
                name={optionName}
                isChecked={isChecked}
                onChange={() => optionChangeHandler(index, name)}
                rules={{
                  validate: {
                    checkRequired: () => checkedOptions.length || 'You need to check one',
                  },
                }}
              />

              {isChecked && subOptions && (
                <>
                  <ul className={'pl-8'}>
                    {subOptions.map((subOption, subOptionIndex) => {
                      const isChecked = checkedSubOptions[index]?.[name]?.includes(subOptionIndex);

                      return (
                        <li key={`${name}-subOption-${subOptionIndex}`}>
                          <CheckboxListItem
                            label={subOption}
                            name={`${sectionType && `${sectionType}.`}${listName}.options[${index}]`}
                            rules={{
                              validate: {
                                checkRequired: () =>
                                  checkedSubOptions[index]?.[name]?.length || 'You need to check one',
                              },
                            }}
                            isChecked={isChecked}
                            onChange={() => subOptionChangeHandler(subOptionIndex, index, name)}
                          />
                        </li>
                      );
                    })}

                    {isSubOptionOtherIncluded && (
                      <TextInput
                        name={`${sectionType && `${sectionType}.`}${listName}.options[${index}].custom`}
                        label={'Other? Please input here...'}
                        fullWidth
                      />
                    )}

                    {subListErrors && <p className={'mt-2 text-xs text-red-600'}>{subListErrors[index]?.message}</p>}
                  </ul>

                  {Boolean(checkedOptions.length) && (
                    <div>
                      <p className={'mt-6 mb-2 text-sm font-semibold'}>{query}</p>
                      <TextArea
                        name={`${sectionType && `${sectionType}.`}${listName}.options[${index}].description`}
                        label={'Fill your research here'}
                      />
                    </div>
                  )}
                </>
              )}
            </li>
          );
        })}

        {isOptionOtherIncluded && (
          <TextInput
            name={`${sectionType && `${sectionType}.`}${listName}.custom`}
            label={'Other? Please input here...'}
            fullWidth
          />
        )}

        {!checkedOptions.length && listErrors && <p className={'mt-2 text-xs text-red-600'}>You need to check one</p>}
      </div>

      {Boolean(checkedOptions.length) && !defaultValues[listName].options && (
        <div>
          <p className={'mt-6 mb-2 text-sm font-semibold'}>{query}</p>
          <TextArea
            label={'Fill your research here'}
            name={`${sectionType && `${sectionType}.`}${listName}.description`}
          />
        </div>
      )}
    </ul>
  );
};

export default CheckboxList;
