import { FC } from 'react';
import ReactApexCharts from 'react-apexcharts';
import { compileChartDataByTimeframe } from 'utils';
import { SecurityTimeseries } from 'interfaces';

const options = {
  chart: {
    type: 'candlestick',
    toolbar: {},
  },
  xaxis: {
    type: 'datetime',
  },
  yaxis: {
    tooltip: {
      enabled: true,
    },
  },
};

interface ICandlestickChartProps {
  data?: SecurityTimeseries | null;
}

export const CandlestickChart: FC<ICandlestickChartProps> = ({ data }) =>
  data ? (
    <div>
      <ReactApexCharts
        options={options}
        series={[{ data: compileChartDataByTimeframe(data) }]}
        type={'candlestick'}
        height={400}
      />
    </div>
  ) : (
    <div className={'text-center'}>no chart data available</div>
  );
