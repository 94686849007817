import { useState, memo } from 'react';
import { cloneDeep } from 'lodash';
import { useStoreState } from 'store/hooks';
import { getRegionNameByCode } from 'utils/countryHelpers';
import { RegionType } from '@alphaswap/types';
import { IFilterBodyProps, ShowMoreFilterType } from './types';

const initShowMoreState = processedFilters => {
  const showMoreObject: ShowMoreFilterType = {};

  for (const key in processedFilters) {
    showMoreObject[key] = false;
  }

  return showMoreObject;
};

export const FilterBody = memo(({ processedFilters, onFiltersChange }: IFilterBodyProps) => {
  const [checkedFiltersQuantity, setCheckedFiltersQuantity] = useState<number>(0);
  const [showMore, setShowMore] = useState<ShowMoreFilterType>(initShowMoreState(processedFilters));
  const { ideas } = useStoreState(state => state.ideaStore);

  const setInitialFiltersState = () => {
    onFiltersChange();
    setCheckedFiltersQuantity(0);
  };

  const toggleShowMore = (groupName: string) => setShowMore(state => ({ ...state, [groupName]: !state[groupName] }));

  const inputChangeHandler = (filtersGroupName: string, filterName: string) => {
    const localIdeasFilters: any = cloneDeep(processedFilters);
    const previousCheckState = localIdeasFilters[filtersGroupName][filterName].isChecked;
    const changeCheckedFiltersQuantity = previousCheckState ? -1 : 1;

    localIdeasFilters[filtersGroupName][filterName].isChecked = !previousCheckState;

    onFiltersChange(localIdeasFilters);
    setCheckedFiltersQuantity(checkedFiltersQuantity + changeCheckedFiltersQuantity);
  };

  const renderFilters = (filtersGroup: any, filtersGroupName: string) => {
    const filtersToRender = showMore[filtersGroupName]
      ? Object.entries(filtersGroup)
      : (Object.entries(filtersGroup).slice(0, 4) as [string, { isChecked: boolean }][]);

    return filtersToRender.map((filter, index) => {
      const [filterName, { isChecked }] = filter as [string | RegionType, { isChecked: boolean }];
      const filterNameToRender =
        filtersGroupName === 'region' ? getRegionNameByCode(filterName as RegionType).label || filterName : filterName;

      return (
        <div key={index} className={'w-full flex justify-between items-center'}>
          <div className={'w-full flex justify-between pr-5'}>
            <p>{filterNameToRender}</p>
          </div>

          <input
            className={'form-checkbox h-6 w-6 m-2'}
            type={'checkbox'}
            checked={isChecked}
            data-filter-group={filtersGroupName}
            data-filter-name={filterName}
            onChange={() => inputChangeHandler(filtersGroupName, filterName)}
          />
        </div>
      );
    });
  };

  return (
    <div className={'filters-container'}>
      <div className={'flex items-end justify-between'}>
        <p className={'text-3xl text-gray-800 font-bold leading-8'}>Filters</p>

        {checkedFiltersQuantity ? (
          <button
            className={'px-2 py-1 text-xs font-light text-center text-white bg-blue-500 rounded-md'}
            onClick={setInitialFiltersState}
          >
            Clear filters
          </button>
        ) : (
          <span className={'text-gray-800 font-medium text-sm'}>
            Total ideas: <span className={'font-bold'}>{ideas.count}</span>
          </span>
        )}
      </div>

      <hr className={'mt-4 mb-6'} />

      {Object.entries(processedFilters).map(([filtersGroupName, filtersGroup]: [string, any]) => (
        <div key={filtersGroupName} className={'w-full flex flex-col justify-between mt-6'}>
          <p className={'uppercase text-sm text-gray-800 font-bold'}>
            {filtersGroupName === 'isTop' ? 'Proprietary algorithm' : filtersGroupName}
          </p>

          <div className={'flex flex-col'}>
            {renderFilters(filtersGroup, filtersGroupName)}

            {Object.keys(filtersGroup).length > 4 && (
              <button
                type={'button'}
                className={'text-sm text-left text-blue-500'}
                onClick={() => toggleShowMore(filtersGroupName)}
              >
                {showMore[filtersGroupName] ? 'Hide' : 'Show more...'}
              </button>
            )}
          </div>
        </div>
      ))}
    </div>
  );
});
