import { getRegionNameByCode } from 'utils/countryHelpers';
import { getSymbolLogo } from 'components/helper';
import { IInfoProps } from './types';

export const Info: React.FC<IInfoProps> = ({ symbol }) => (
  <div className={'p-6 grid gap-6 grid-cols-1 md:grid-cols-auto-first'}>
    <img alt={symbol.name} src={getSymbolLogo(symbol.websiteUrl)} />
    <div className={'grid gap-4'}>
      <div>
        <p className={'text-3xl uppercase'}>{symbol.name}</p>
        <p className={'text-xs text-gray-400'}>{symbol.symbol}</p>
      </div>
      <div className={'flex flex-wrap'}>
        <p className={'px-4 py-2 mb-4 mr-4 text-xs font-light text-blue-900 uppercase rounded-sm bg-blue-50'}>
          {getRegionNameByCode(symbol.region).label}
        </p>
        <p className={'px-4 py-2 mr-4 text-xs font-light text-blue-900 uppercase rounded-sm bg-blue-50'}>
          {symbol.sector}
        </p>
      </div>
      <div className={'text-sm grid gap-3 max-w-xs'}>
        <div className={'flex justify-between'}>
          <p className={'capitalize text-gray-400'}>Current price</p>
          <p className={'text-indigo-900'}>{`$${symbol.openPrice}`}</p>
        </div>
        <div className={'flex justify-between'}>
          <p className={'capitalize text-gray-400'}>Market cap</p>
          <p className={'text-indigo-900'}>{symbol.marketCap}</p>
        </div>
        <div className={'flex justify-between'}>
          <p className={'capitalize text-gray-400'}>Volume</p>
          <p className={'text-indigo-900'}>{`$${symbol.avgVolume}`}</p>
        </div>
      </div>
    </div>
  </div>
);
