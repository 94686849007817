import { TextField } from '@material-ui/core';
import { useFormContext, Controller, RegisterOptions } from 'react-hook-form';

interface ITextInputProps {
  label?: string;
  placeholder?: string;
  className?: string;
  name: string;
  defaultValue?: string | number;
  fullWidth?: boolean;
  rules?: RegisterOptions;
}

const ERROR_MESSAGES = {
  required: 'Field is required',
  maxLength: 'Message is too long',
};

const TextInput: React.FC<ITextInputProps> = ({ name, rules, defaultValue, ...inputProps }) => {
  const { control, errors } = useFormContext();

  const isError = !!errors[name]?.type;
  const errorType = errors[name]?.type;
  const errorMessage = errorType ? ERROR_MESSAGES[errorType] : null;

  return (
    <Controller
      defaultValue={defaultValue}
      rules={rules}
      name={name}
      control={control}
      render={({ value, onChange, ref, ...controllerProps }) => (
        <TextField
          value={value}
          variant={'outlined'}
          fullWidth
          onChange={onChange}
          inputRef={ref}
          error={isError}
          helperText={isError ? errorMessage : null}
          {...controllerProps}
          {...inputProps}
        />
      )}
    />
  );
};
export default TextInput;
