import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Menu, MenuItem } from '@material-ui/core';
import classNames from 'classnames';

import { useStoreState } from 'store/hooks';
import { ILink } from './types';
import { userLinks, customerLinks } from './linksList';
import { UserType } from 'utils/enums';
import { UserProfile } from './UserProfile';
import { Notifications } from '../notifications';
import { LogoIcon, MenuIcon } from '../icons/header';

const getLinksByUserType = (userType: UserType) => {
  switch (userType) {
    case UserType.USER:
      return userLinks;
    case UserType.CUSTOMER:
      return customerLinks;
    default:
      return userLinks;
  }
};

export const Header = () => {
  const { user } = useStoreState(state => state.userStore);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const history = useHistory();

  function hasPath(link: ILink) {
    const currentPath = history.location.pathname;
    return currentPath === link.path || link.children?.includes(currentPath);
  }

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const linksToRender = getLinksByUserType(user.type);

  const elementLinks = linksToRender.map(link => (
    <Link
      key={link.path}
      to={link.path}
      className={classNames({
        'font-normal text-gray-700 transition duration-150 ease-in-out focus:outline-none hover:text-indigo-600': true,
        'text-indigo-600 border-b border-indigo-600': hasPath(link),
      })}
    >
      {link.name}
    </Link>
  ));

  const elementMenuLinks = elementLinks.map(element => <MenuItem key={element.key}>{element}</MenuItem>);

  return (
    <header className='text-gray-800 bg-white shadow-sm'>
      <nav className='container flex items-center h-16 px-4 mx-auto text-gray-900 bg-white md:h-20'>
        <div className='flex items-center justify-between w-full'>
          <div className='hidden cursor-pointer lg:block'>
            <Link to='/'>
              <LogoIcon />
            </Link>
          </div>
          <button className='sm:hidden' type='button' onClick={handleOpenMenu}>
            <MenuIcon className='w-6 h-6' />
          </button>
          <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleCloseMenu}>
            {elementMenuLinks}
          </Menu>
          <div className='hidden uppercase gap-4 text-sm text-gray-700 sm:grid grid-cols-4-auto lg:gap-8 lg:text-base'>{elementLinks}</div>
          <div className='flex items-center'>
            <Notifications />
            <UserProfile />
          </div>
        </div>
      </nav>
    </header>
  );
};
