export interface TabProps {
  contentValues: any;
}

export interface ThesisQuestionOption {
  name: string;
  label: string;
  options?: string[];
}

export interface ThesisQuestion {
  name: string;
  title: string;
  query: string;
  options: ThesisQuestionOption[];
}

export interface TextWithOptions {
  title: string;
  options: {
    fundamentals: string;
    specials: string;
    technicals: string;
  };
}

export interface TextWithoutOptions {
  title: string;
}

export interface ThesisTextTypes {
  mispricingReason: TextWithOptions;
  catalysts: TextWithoutOptions;
  risks: TextWithoutOptions;
}

export interface CompanyTextTypes {
  management: TextWithoutOptions;
  businessDescription: TextWithoutOptions;
  rates: TextWithoutOptions;
}

export interface FinancialsTextTypes {
  balanceSheet: TextWithoutOptions;
  rates: TextWithoutOptions;
}

export interface ValuationTextTypes {
  methodology: TextWithoutOptions;
  attractiveness: TextWithoutOptions;
}

export interface EsgTextTypes {
  controversy: TextWithoutOptions;
  radial: TextWithoutOptions[];
}

export enum PositivityOptions {
  Negative = 'Negative',
  Neutral = 'Neutral',
  Positive = 'Positive',
  Unknown = 'Unknown',
}
