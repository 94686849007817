import React from 'react';
import { StandardReactProps } from '../../../interfaces';

export const CalendarIcon = ({ className }: StandardReactProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='45.221' height='45.221' viewBox='0 0 55 55' className={className}>
    <path
      fill='#b9c3de'
      d='M44.556 3.625h-8.532V.666A.666.666 0 0035.358 0H32.52a.665.665 0 00-.666.666v2.96h-7.159V.666A.665.665 0 0024.029 0h-2.838a.665.665 0 00-.666.666v2.96h-7.157V.666A.665.665 0 0012.702 0H9.863a.666.666 0 00-.666.666v2.96H.666A.665.665 0 000 4.292v40.264a.665.665 0 00.666.666h43.89a.665.665 0 00.666-.666V4.291a.665.665 0 00-.666-.666zM33.185 1.331h1.507V7.25h-1.507zm-11.329 0h1.507V7.25h-1.506zm-11.329 0h1.509V7.25h-1.508zm-9.2 3.625h7.866v2.96a.666.666 0 00.666.666h2.838a.665.665 0 00.666-.666V4.957h7.159v2.96a.665.665 0 00.666.666h2.838a.665.665 0 00.666-.666v-2.96h7.158v2.96a.665.665 0 00.666.666h2.838a.666.666 0 00.666-.666v-2.96h7.866v7.617H1.331zm0 38.933V13.905H43.89V43.89z'
      data-name='路径 775'
    />
    <path
      fill='#b9c3de'
      d='M8.788 16.911a3.056 3.056 0 103.056 3.056 3.056 3.056 0 00-3.056-3.056zm0 4.78a1.724 1.724 0 111.724-1.724 1.724 1.724 0 01-1.724 1.724z'
      data-name='路径 776'
    />
    <path
      fill='#b9c3de'
      d='M18.004 16.911a3.056 3.056 0 103.056 3.056 3.056 3.056 0 00-3.056-3.056zm0 4.78a1.724 1.724 0 111.724-1.724 1.724 1.724 0 01-1.724 1.724z'
      data-name='路径 777'
    />
    <path
      fill='#b9c3de'
      d='M27.219 16.911a3.056 3.056 0 103.056 3.056 3.056 3.056 0 00-3.056-3.056zm0 4.78a1.724 1.724 0 111.724-1.724 1.724 1.724 0 01-1.724 1.724z'
      data-name='路径 778'
    />
    <path
      fill='#b9c3de'
      d='M36.433 16.911a3.056 3.056 0 103.055 3.055 3.056 3.056 0 00-3.055-3.055zm0 4.78a1.724 1.724 0 111.724-1.724 1.724 1.724 0 01-1.724 1.724z'
      data-name='路径 779'
    />
    <path
      fill='#b9c3de'
      d='M8.787 25.844a3.056 3.056 0 103.056 3.056 3.056 3.056 0 00-3.056-3.056zm0 4.78a1.725 1.725 0 111.724-1.725 1.725 1.725 0 01-1.724 1.725z'
      data-name='路径 780'
    />
    <path
      fill='#b9c3de'
      d='M18.003 25.844a3.056 3.056 0 103.056 3.056 3.056 3.056 0 00-3.056-3.056zm0 4.78a1.725 1.725 0 111.724-1.725 1.725 1.725 0 01-1.724 1.725z'
      data-name='路径 781'
    />
    <path
      fill='#b9c3de'
      d='M27.218 25.844a3.056 3.056 0 103.056 3.056 3.056 3.056 0 00-3.056-3.056zm0 4.78a1.725 1.725 0 111.724-1.725 1.725 1.725 0 01-1.724 1.725z'
      data-name='路径 782'
    />
    <path
      fill='#b9c3de'
      d='M36.434 25.844a3.056 3.056 0 103.055 3.056 3.056 3.056 0 00-3.055-3.056zm0 4.78a1.725 1.725 0 111.724-1.724 1.725 1.725 0 01-1.724 1.724z'
      data-name='路径 783'
    />
    <path
      fill='#b9c3de'
      d='M8.788 34.773a3.056 3.056 0 103.056 3.056 3.056 3.056 0 00-3.056-3.056zm0 4.78a1.724 1.724 0 111.724-1.724 1.724 1.724 0 01-1.724 1.724z'
      data-name='路径 784'
    />
    <path
      fill='#b9c3de'
      d='M18.004 34.773a3.056 3.056 0 103.056 3.056 3.056 3.056 0 00-3.056-3.056zm0 4.78a1.724 1.724 0 111.724-1.724 1.724 1.724 0 01-1.724 1.724z'
      data-name='路径 785'
    />
    <path
      fill='#b9c3de'
      d='M27.219 34.773a3.056 3.056 0 103.056 3.056 3.056 3.056 0 00-3.056-3.056zm0 4.78a1.724 1.724 0 111.724-1.724 1.724 1.724 0 01-1.724 1.724z'
      data-name='路径 786'
    />
    <path
      fill='#b9c3de'
      d='M36.433 34.773a3.056 3.056 0 103.055 3.055 3.056 3.056 0 00-3.055-3.055zm0 4.78a1.724 1.724 0 111.724-1.724 1.724 1.724 0 01-1.724 1.724z'
      data-name='路径 787'
    />
  </svg>
);
