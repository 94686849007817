import cn from 'classnames';
import { v4 as uuid } from 'uuid';
import { IdeaThesisHeaderProps, TabsContent } from './types';
import { CalculatorIcon, CompanyIcon, ContributeGrowthIcon, GlobeIcon, TargetIcon } from 'components/icons/contribution';

const tabsContent: TabsContent = [
  {
    name: 'thesis',
    text: 'Thesis',
    icon: TargetIcon,
  },
  {
    name: 'company',
    text: 'Company',
    icon: CompanyIcon,
  },
  {
    name: 'financials',
    text: 'Financials',
    icon: CalculatorIcon,
  },
  {
    name: 'valuation',
    text: 'Valuation',
    icon: ContributeGrowthIcon,
  },
  {
    name: 'esg',
    text: 'ESG',
    icon: GlobeIcon,
  },
];

const IdeaThesisHeader = ({ onClick, selectedTabName }: IdeaThesisHeaderProps) => {
  return (
    <div className='flex justify-around p-4 overflow-x-auto text-gray-900 bg-gray-100'>
      {tabsContent.map(({ name, text, icon: Icon }) => {
        const isActive = selectedTabName === name;
        const tabClasses = cn('flex items-center p-4 cursor-pointer rounded-md', {
          'text-blue-700 bg-white': isActive,
        });

        return (
          <div key={uuid()} className={tabClasses} onClick={() => onClick(name)}>
            <span>{<Icon className={isActive ? 'text-blue-700 fill-current' : ''} />}</span>
            <span className='ml-2'>{text}</span>
          </div>
        );
      })}
    </div>
  );
};

export default IdeaThesisHeader;
