import { useEffect } from 'react';
import mixpanel from 'utils/mixpanelIntegration';
import { useFormContext } from 'react-hook-form';
import { Banner } from 'components/common/';
import { esgQuestionsContent } from '../content';
import { CardWithTitle } from 'components/common';
import { RadioWithComment } from 'components/fields';
import CheckboxList from 'components/common/Inputs/CheckboxList';

interface EsgSectionProps {
  defaultValues: any;
  flowType?: string;
}

const optionPoints = ['negative', 'neutral', 'positive', "don't know"];

const Esg = ({ defaultValues, flowType }: EsgSectionProps) => {
  const { errors } = useFormContext();

  useEffect(() => {
    mixpanel.dispatchEvent(`${flowType} idea: Esg`);
  }, []);

  return (
    <div id='esg' className={'pt-8'}>
      <CardWithTitle title={'Esg'}>
        <Banner
          className='w-full p-2 mt-3 text-xs text-gray-800 bg-blue-100 border border-blue-600 rounded lg:text-sm'
          textContent={esgQuestionsContent.banner}
        />

        {esgQuestionsContent.radial.map((options, index) => (
          <div key={options.name}>
            <RadioWithComment
              defaultValue={defaultValues[options.name]}
              id={index + 1}
              query={options.query}
              name={`esg.${options.name}`}
              optionPoints={optionPoints}
              options={options.content}
              title={options.title}
              rules={{
                validate: {
                  checkRequired: value =>
                    Object.keys(value).length >= options.content.length || 'You must select points',
                },
              }}
            />
            {errors[`esg.${options.name}`] && (
              <p className='text-xs text-red-500'>{errors[`esg.${options.name}`].message}</p>
            )}
          </div>
        ))}

        <div className='grid gap-4'>
          <p className='mt-6 text-sm font-semibold'>{esgQuestionsContent.controversy.title}</p>
          <CheckboxList content={esgQuestionsContent.controversy} defaultValues={defaultValues} sectionType='esg' />
        </div>
      </CardWithTitle>
    </div>
  );
};

export default Esg;
