import React from 'react';
import { StandardReactProps } from '../../../interfaces';

export const CompanyIcon = ({ className }: StandardReactProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='17.601' height='19.591' className={className}>
    <path
      d='M17.218 4.592h-7.27V.383A.383.383 0 009.566 0H.383A.383.383 0 000 .383v18.825a.383.383 0 00.383.383h16.835a.383.383 0 00.383-.383V4.974a.383.383 0 00-.383-.382zm-7.27.765h6.887V15H9.948zM3.826 18.825v-3.061h2.3v3.061zM6.5 15H3.444a.383.383 0 00-.383.383v3.444h-2.3V.765h8.418v18.06h-2.3v-3.443A.383.383 0 006.5 15zm3.444 3.826v-3.062h6.887v3.061z'
      data-name='路径 803'
    />
    <path
      d='M4.592 3.062h.765a.383.383 0 00.383-.383v-.765a.383.383 0 00-.383-.383h-.765a.383.383 0 00-.383.383v.765a.383.383 0 00.383.383z'
      data-name='路径 804'
    />
    <path
      d='M4.592 5.74h.765a.383.383 0 00.383-.383v-.765a.383.383 0 00-.383-.383h-.765a.383.383 0 00-.383.383v.765a.383.383 0 00.383.383z'
      data-name='路径 805'
    />
    <path
      d='M4.592 8.418h.765a.383.383 0 00.383-.383V7.27a.383.383 0 00-.383-.383h-.765a.383.383 0 00-.383.383v.765a.383.383 0 00.383.383z'
      data-name='路径 806'
    />
    <path
      d='M4.592 11.097h.765a.383.383 0 00.383-.383v-.765a.383.383 0 00-.383-.383h-.765a.383.383 0 00-.383.383v.765a.383.383 0 00.383.383z'
      data-name='路径 807'
    />
    <path
      d='M4.592 13.775h.765a.383.383 0 00.383-.383v-.765a.383.383 0 00-.383-.383h-.765a.383.383 0 00-.383.383v.765a.383.383 0 00.383.383z'
      data-name='路径 808'
    />
    <path
      d='M1.914 3.062h.765a.383.383 0 00.383-.383v-.765a.383.383 0 00-.383-.384h-.765a.383.383 0 00-.383.383v.765a.383.383 0 00.383.384z'
      data-name='路径 809'
    />
    <path
      d='M1.914 5.74h.765a.383.383 0 00.383-.383v-.765a.383.383 0 00-.383-.383h-.765a.383.383 0 00-.383.383v.765a.383.383 0 00.383.383z'
      data-name='路径 810'
    />
    <path
      d='M1.914 8.418h.765a.383.383 0 00.383-.383V7.27a.383.383 0 00-.383-.383h-.765a.383.383 0 00-.383.383v.765a.383.383 0 00.383.383z'
      data-name='路径 811'
    />
    <path
      d='M1.914 11.097h.765a.383.383 0 00.383-.383v-.765a.383.383 0 00-.383-.383h-.765a.383.383 0 00-.383.383v.765a.383.383 0 00.383.383z'
      data-name='路径 812'
    />
    <path
      d='M1.914 13.775h.765a.383.383 0 00.383-.383v-.765a.383.383 0 00-.383-.383h-.765a.383.383 0 00-.383.383v.765a.383.383 0 00.383.383z'
      data-name='路径 813'
    />
    <path
      d='M8.035 1.53H7.27a.383.383 0 00-.383.383v.765a.383.383 0 00.383.383h.765a.383.383 0 00.383-.383v-.765a.383.383 0 00-.383-.383z'
      data-name='路径 814'
    />
    <path
      d='M8.035 4.209H7.27a.383.383 0 00-.383.383v.765a.383.383 0 00.383.383h.765a.383.383 0 00.383-.383v-.765a.383.383 0 00-.383-.383z'
      data-name='路径 815'
    />
    <path
      d='M8.035 6.887H7.27a.383.383 0 00-.383.383v.765a.383.383 0 00.383.383h.765a.383.383 0 00.383-.383V7.27a.383.383 0 00-.383-.383z'
      data-name='路径 816'
    />
    <path
      d='M8.035 9.566H7.27a.383.383 0 00-.383.383v.765a.383.383 0 00.383.383h.765a.383.383 0 00.383-.383v-.765a.383.383 0 00-.383-.383z'
      data-name='路径 817'
    />
    <path
      d='M8.035 12.244H7.27a.383.383 0 00-.383.383v.765a.383.383 0 00.383.383h.765a.383.383 0 00.383-.383v-.765a.383.383 0 00-.383-.383z'
      data-name='路径 818'
    />
    <path
      d='M15.114 6.887h-.765a.383.383 0 00-.383.383v.765a.383.383 0 00.383.383h.765a.383.383 0 00.383-.383V7.27a.383.383 0 00-.383-.383z'
      data-name='路径 819'
    />
    <path
      d='M15.114 9.566h-.765a.383.383 0 00-.383.383v.765a.383.383 0 00.383.383h.765a.383.383 0 00.383-.383v-.765a.383.383 0 00-.383-.383z'
      data-name='路径 820'
    />
    <path
      d='M15.114 12.244h-.765a.383.383 0 00-.383.383v.765a.383.383 0 00.383.383h.765a.383.383 0 00.383-.383v-.765a.383.383 0 00-.383-.383z'
      data-name='路径 821'
    />
    <path
      d='M12.436 6.887h-.765a.383.383 0 00-.383.383v.765a.383.383 0 00.383.383h.765a.383.383 0 00.383-.383V7.27a.383.383 0 00-.383-.383z'
      data-name='路径 822'
    />
    <path
      d='M12.436 9.566h-.765a.383.383 0 00-.383.383v.765a.383.383 0 00.383.383h.765a.383.383 0 00.383-.383v-.765a.383.383 0 00-.383-.383z'
      data-name='路径 823'
    />
    <path
      d='M12.436 12.244h-.765a.383.383 0 00-.383.383v.765a.383.383 0 00.383.383h.765a.383.383 0 00.383-.383v-.765a.383.383 0 00-.383-.383z'
      data-name='路径 824'
    />
  </svg>
);
