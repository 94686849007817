import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

export const Sidebar = () => {
  const [content, setContent] = useState(true);
  return (
    <nav className='flex justify-center w-full mx-auto'>
      <div>
        <h1 className='text-3xl font-semibold sm:text-4xl'>Settings</h1>
        <div className='my-6 sm:my-10'>
          <ul>
            <li className='my-1 sm:my-4'>
              <NavLink to='/settings/accountSettings'>
                <button
                  type='button'
                  onClick={() => setContent(true)}
                  className={`${content ? 'bg-indigo-200' : ''} p-1 pl-4 w-full  rounded-full flex justify-start focus:outline-none`}
                >
                  Account
                </button>
              </NavLink>
            </li>
            <li className='my-1 sm:my-4'>
              <NavLink to='/settings/noteSettings'>
                <button
                  type='button'
                  onClick={() => setContent(false)}
                  className={`${content ? '' : 'bg-indigo-200'} p-1 pl-4 w-full rounded-full flex justify-start focus:outline-none`}
                >
                  Notifications
                </button>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
