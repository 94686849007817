import { Action, action, thunk, Thunk } from 'easy-peasy';
import { AxiosResponse } from 'axios';
import { IRootModel } from 'store/models/root.model';
import { client } from 'store/api';
import { ResponseStatusCode } from 'utils/enums';

interface IUserUpdateObject {
  firstName?: string;
  lastName?: string;
  nickname?: string;
  job?: string;
  country?: string;
  profileDescription?: string;
  profilePicture?: string;
  isTncAccepted?: boolean;
}

interface IUserState {
  user: any;
  firstName?: string;
  lastName?: string;
  nickname?: string;
  job?: string;
  country?: string;
  profileDescription?: string;
  profilePicture?: string;
  createdAt: string;
}

interface IUserActions {
  setUser: Action<this, any>;
}

interface IUserThunks {
  getMeThunk: Thunk<this>;
  updateUserThunk: Thunk<this, IUserUpdateObject, undefined, IRootModel, Promise<AxiosResponse<IUserState>>>;
}

export interface IUserModel extends IUserState, IUserActions, IUserThunks {}

const userStore: IUserModel = {
  user: null,
  createdAt: '',

  /**
   * ACTIONS
   */

  setUser: action((state, payload: IUserState) => {
    state.user = payload;
  }),
  /**
   * THUNKS
   */
  getMeThunk: thunk(async actions => {
    const response = await client.get<any, AxiosResponse<IUserState>>('/user/me');

    if (response.status === ResponseStatusCode.OK) {
      actions.setUser(response.data);
    }

    return response;
  }),
  updateUserThunk: thunk(async (actions, payload) => {
    const response = await client.put<any, AxiosResponse<IUserState>>('/user', payload);

    if (response?.status === ResponseStatusCode.OK) {
      actions.setUser(response.data);
    }

    return response;
  }),
};

export default userStore;
