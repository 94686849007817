import { useState } from 'react';
import { useStoreState } from 'store/hooks';
import { IdeaThesisHeader } from './IdeaThesisHeader';
import { IdeaThesisBody } from './IdeaThesisBody';
import IdeaArchived from 'pages/IdeaPage/IdeaArchived';
import ideaArchivedImage from 'img/ideaArchived.png';

const IdeaThesis = () => {
  const [selectedTabName, setSelectedTabName] = useState('thesis');
  const { idea }: any = useStoreState(state => state.ideaStore);

  const tabClickHandler = (name: string) => setSelectedTabName(name);

  const isThesisExist = Boolean(Object.keys(idea.thesis).length);

  return isThesisExist ? (
    <div className='p-4 bg-white'>
      <IdeaThesisHeader onClick={tabClickHandler} selectedTabName={selectedTabName} />
      <div className='px-10 my-10'>
        <IdeaThesisBody selectedTabName={selectedTabName} thesis={idea.thesis} />
      </div>
    </div>
  ) : (
    <IdeaArchived
      textOne={'This idea has been archived.'}
      textTwo={'Click on the button bellow to access the idea.'}
      ctaLink={idea?.attachments?.[0]?.url || ''}
      ctaText={'Read more'}
      imgUrl={ideaArchivedImage}
    />
  );
};

export default IdeaThesis;
