import { IContributionsTableProps } from './types';

const renderTableHeader = (header: string[]) => (
  <div className={'flex justify-between px-2 mb-5 text-xs font-normal text-gray-500'}>
    {header.map((cell, index) => (
      <span key={index} className={'flex justify-center flex-1 text-center'}>
        {cell}
      </span>
    ))}
  </div>
);

const renderTableBody = (body: any[]) =>
  body.map((row: any, index) => (
    <div
      key={index}
      className={
        'flex justify-between items-center py-2 px-2 text-sm font-medium text-gray-700 rounded-md my-2 even:bg-gray-100'
      }
    >
      <span className={'flex justify-center flex-1 text-center'}>{index + 1}</span>

      {(row.data as any[]).map((cell: string, cellIndex) => (
        <span key={cellIndex} className={'flex justify-center flex-1 text-center'}>
          {cell}
        </span>
      ))}
    </div>
  ));

export const ContributionsTable: React.FC<IContributionsTableProps> = ({ body, header }) => (
  <div>
    {body?.length && renderTableHeader(header)}
    {renderTableBody(body)}
  </div>
);
