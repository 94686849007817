import { stringNewlinesToJsxWithBr } from 'utils/stringHelpers';
import { ISummaryProps } from './types';

export const Summary: React.FC<ISummaryProps> = ({ idea }) => (
  <div className={'grid gap-2 p-4 rounded-lg bg-blue-50'}>
    <p className={'text-sm font-bold text-blue-600 uppercase'}>Executive Summary</p>
    <p className={'text-xs font-light leading-5 text-indigo-800 line-clamp-3'}>
      {stringNewlinesToJsxWithBr(idea.executiveSummary)}
    </p>
  </div>
);
