import React, { useState, useEffect } from 'react';
import { Layout } from 'components';
import ClosedIdeasTable from './ClosedIdeasTable';
import LiveIdeasTable from './LiveIdeasTable';

const TopIdeasAndUsersPage = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  if (isLoading) return null;

  return (
    <Layout>
      <ClosedIdeasTable />

      <LiveIdeasTable />
    </Layout>
  );
};

export default TopIdeasAndUsersPage;
