import { IdeaObject } from 'interfaces';
import { Table } from 'components';
import { CellType } from 'components/Table/types';
import { insightColumns, insightRowData } from './data';
import { InsightCellDataType } from './types';

interface ILastInsightsProps {
  data: IdeaObject[];
  label: string;
}

export const LastInsights: React.FC<ILastInsightsProps> = ({ data, label }) => {
  const transformInsightsList = (topUsers: IdeaObject[]): CellType[][] => {
    const cellsData: InsightCellDataType[][] = topUsers.map(idea => [
      {
        cellName: 'date',
        cellValue: idea.createdAt,
      },
      {
        cellName: 'status',
        cellValue: idea.status,
      },
      {
        cellName: 'symbol',
        cellValue: idea.security.symbol,
      },
      {
        cellName: 'entryPrice',
        cellValue: idea.entryPrice,
      },
      {
        cellName: 'lastPrice',
        cellValue: idea.security.closePrice,
      },
      {
        cellName: 'allocation',
        cellValue: idea.totalStake,
      },
      {
        cellName: 'perf',
        cellValue: idea.performance,
      },
      {
        cellName: 'detailsLink',
        cellValue: idea.id,
      },
    ]);

    return cellsData.map((row: InsightCellDataType[]) =>
      row.map(({ cellName, cellValue }): CellType => {
        const cellFunc: Function = insightRowData[cellName];

        return cellFunc(cellValue);
      })
    );
  };

  return (
    <div className={'mt-7'}>
      <Table title={label} columns={insightColumns} data={transformInsightsList(data)} withPagination={false} />
    </div>
  );
};
