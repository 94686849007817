export type CellType = {
  title: React.ReactNode;
  showTooltip?: boolean;
};

export enum TablePaginationActionTypeEnum {
  next = 'NEXT',
  previous = 'PREV',
}

export interface TablePaginationActionInterface {
  type: TablePaginationActionTypeEnum;
  name?: string;
}

export interface TablePropsInterface {
  title?: string;
  name?: string;
  total?: number;
  offset?: number;
  date?: string;
  columns: CellType[];
  data: CellType[][];
  onPageChange?(action: TablePaginationActionInterface): any;
  showRanking?: boolean;
  withPagination?: boolean;
}
