/* eslint-disable */
import React from 'react';
import { ToogleButton2 } from '../../components/common';

const tradeOptions = [
  'Your trade has been submitted',
  'Your trade is live',
  'Your trade has closed with positive performance',
  ' Your idea has closed underperforming',
];

const investOptions = [
  'Your trade is live',
  'Your trade has closed with positive performance',
  'Your idea has closed underperforming',
];

const activityOptions = [
  'Your trade is live',
  'A user you follow has submitted a trade',
  'A user you follow has co-invested in an trade',
];

export const NotificationPage = () => (
  <div className="relative pb-16">
    <div>
      <h1 className="text-3xl font-semibold">Notifications</h1>
      <div className="my-6">
        <p className="font-semibold">Trade submitted</p>
        {tradeOptions.map((item: string, idx: number) => (
          <div
            className="flex items-center justify-between w-full md:w-2/3"
            key={idx}
          >
            <p className="my-2 mr-8 text-sm text-gray-600">{item}</p>
            <ToogleButton2 />
          </div>
        ))}
      </div>

      <div className="my-6">
        <p className="font-semibold">Co-invested trade</p>
        {investOptions.map((item: string, idx: number) => (
          <div
            className="flex items-center justify-between w-full md:w-2/3"
            key={idx}
          >
            <p className="my-2 mr-8 text-sm text-gray-600">{item}</p>
            <ToogleButton2 />
          </div>
        ))}
      </div>
    </div>

    <div>
      <h1 className="py-5 text-3xl font-semibold border-t">Mailing</h1>
      <div>
        <p className="font-semibold">User activity</p>
        {activityOptions.map((item: string, idx: number) => (
          <div
            className="flex items-center justify-between w-full md:w-3/5"
            key={idx}
          >
            <p className="my-2 mr-8 text-sm text-gray-600">{item}</p>
            <ToogleButton2 />
          </div>
        ))}
      </div>
    </div>
    <button className="absolute bottom-0 right-0 p-2 px-6 text-sm bg-indigo-100 border rounded-md sm:text-base focus:outline-none">
      <p className="text-indigo-600 ">Save changes</p>
    </button>
  </div>
);
