import { GeneralInformationProps } from './types';
import { IdeaHeader } from './IdeaHeader';
import { IdeaStatsBody } from './IdeaStatsBody';
import { IdeaStatsBanner } from 'components/idea';

const GeneralInformation = ({ security, executiveSummary, entryPrice, timeframe, isFavorite }: GeneralInformationProps) => {
  const {
    name,
    exchangeSymbol,
    region,
    sector,
    timeseries,
    openPrice,
    closePrice,
    lowPrice,
    highPrice,
    currency,
    marketCap,
    avgVolume,
    description,
    week52Range,
  } = security;

  return (
    <div className='bg-white p-8 rounded-md shadow-md'>
      <div className='stock-info'>
        <IdeaHeader name={name} exchangeSymbol={exchangeSymbol} region={region} sector={sector} isFavorite={isFavorite} />
        <IdeaStatsBody
          content={{
            timeseries,
            openPrice,
            closePrice,
            lowPrice,
            highPrice,
            marketCap,
            avgVolume,
            description,
            week52Range,
          }}
        />
      </div>
      <div className='idea-info'>
        <IdeaStatsBanner
          entryPrice={entryPrice}
          currency={currency}
          timeframe={timeframe}
          classes='px-3 py-4 mt-10 mb-8 text-gray-800 bg-white border-t border-b border-bluegrey-400'
        />
      </div>
      <div className='executiveSummary'>
        <h4 className='text-blue-500 font-medium'>Executive Summary</h4>
        <p className='text-sm mt-2'>{executiveSummary}</p>
      </div>
    </div>
  );
};

export default GeneralInformation;
