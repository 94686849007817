/* eslint-disable */
import React from 'react';
import { Pill, Card } from '../common';

interface TrackSectorProps {
  sectors?: any[];
  geographies?: any[];
  esg?: string;
}
export const TrackSector = (props: TrackSectorProps) => (
  <Card className="text-sm">
    <p className="mx-1 text-sm text-gray-400">SECTORS</p>
    <div className="flex my-2">
      {props.sectors?.map((sector: any, idx: number) => (
        <Pill
          key={idx}
          size="medium"
          color="blue"
          textContent={sector}
          classes="mr-4"
        />
      ))}
    </div>
    <p className="mx-1 text-sm text-gray-400">GEOGRAPHY</p>
    <div className="flex my-2">
      {props.geographies?.map((region: any, idx: number) => (
        <Pill
          key={idx}
          size="medium"
          color="green"
          textContent={region}
          classes="mr-4"
        />
      ))}
    </div>
  </Card>
);
