import React from 'react';
import { StandardReactProps } from '../../interfaces';

interface CardProps extends StandardReactProps {
  style?: Object;
  children: any;
  className?: string;
}

export const Card = ({ style, children, className }: CardProps) => (
  <div className={className} style={style}>
    {children}
  </div>
);

Card.defaultProps = {
  style: {},
  className: '',
};
