import TextField from '@material-ui/core/TextField';
import { useFormContext, Controller, RegisterOptions } from 'react-hook-form';

interface DatePickerProps {
  defaultValue?: string;
  name: string;
  rules?: RegisterOptions;
}

export const DatePicker = ({ name, defaultValue, rules }: DatePickerProps) => {
  const { errors, control } = useFormContext();

  return (
    <div>
      <Controller
        name={name}
        defaultValue={defaultValue}
        control={control}
        rules={rules}
        render={({ ref, onChange, value }) => <TextField fullWidth type='date' innerRef={ref} onChange={onChange} defaultValue={value} />}
      />
      {errors[name] && <p className='text-xs text-red-500'>{errors[name].message}</p>}
    </div>
  );
};

DatePicker.defaultProps = {
  defaultValue: '',
};
