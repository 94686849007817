import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useStoreState } from 'store/hooks';
import { CardWithTitle } from 'components/common/CardWithTitle';
import { StockListDropdown, StockTree } from 'components/generation';
import { RCAreaChart } from 'components/charts';
import mixpanel from 'utils/mixpanelIntegration';

const SymbolSelectionWithChart: React.FC = () => {
  const { reset, watch, errors, register } = useFormContext();
  const { security } = useStoreState(state => state.generationStore);

  useEffect(() => {
    mixpanel.dispatchEvent('Create idea: Select a stock');
  }, []);

  return (
    <div className={'pt-8'}>
      <CardWithTitle title={'Select a stock'}>
        <div className={'w-full p-4 md:hidden'}>
          <StockListDropdown reset={reset} symbol={watch('symbol')} errors={errors} register={register} />
        </div>

        <div className={'md:flex'}>
          <div className={'w-full pr-3 md:pr-1 lg:pr-3 md:w-2/5 lg:w-72 md:mr-6 lg:mr-12'}>
            <StockTree />
          </div>

          <div className={'flex-grow'}>
            <div className={'hidden md:block'}>
              <StockListDropdown reset={reset} symbol={watch('symbol')} errors={errors} register={register} />
            </div>

            <div className={'mt-4 mb-12'}>
              <h4 className={'h-full my-10 text-xl text-center'}>{security?.name}</h4>
              {security?.timeseries?.timeseries && <RCAreaChart data={security.timeseries.timeseries} />}
            </div>
          </div>
        </div>
      </CardWithTitle>
    </div>
  );
};

export default SymbolSelectionWithChart;
