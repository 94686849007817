import React from 'react';
import classNames from 'classnames';
import { IEventDotProps } from './types';

export const EventDot: React.FC<IEventDotProps> = ({ first }) => (
  <div
    className={classNames(
      'relative z-10 hidden sm:inline-flex items-center justify-center flex-shrink-0 w-3 h-3 mt-1 text-white bg-gray-500 rounded-full',
      { 'mt-14': first }
    )}
  />
);
