interface IdeaArchivedProps {
  textOne: string;
  textTwo?: string;
  ctaLink: string;
  ctaText: string;
  imgUrl: string;
  isBorder?: boolean;
  children?: any;
}

const IdeaArchived = (props: IdeaArchivedProps) => {
  const { textOne, textTwo, ctaLink, ctaText, imgUrl, isBorder, children } = props;

  return (
    <div className={`flex flex-col items-center py-8 px-4 mx-auto rounded-md bg-white ${isBorder ? 'border' : ''}`}>
      <div className='flex flex-col flex-wrap items-center justify-center mx-auto mt-3'>
        <img src={imgUrl} alt='avatar' className='mb-6 max-h-52' />
        <p className='px-20 text-center text-gray-400'>{textOne}</p>
      </div>
      {ctaLink && (
        <>
          {textTwo && <p className='text-center max-w-sm text-gray-400 mb-3'>{textTwo}</p>}
          <div className='flex flex-col'>
            <a
              href={ctaLink}
              target='_blank'
              className={`uppercase text-center flex-grow py-3 px-9 mt-4 text-white bg-blue-500 hover:bg-blue-700 rounded-lg mt-2 duration-300 ${
                ctaLink ? '' : 'pointer-events-none opacity-50'
              }`}
              rel='noreferrer'
            >
              {ctaText}
            </a>
            {children}
          </div>
        </>
      )}
    </div>
  );
};

export default IdeaArchived;
