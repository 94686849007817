/* eslint-disable */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AlphaGrowthIcon, BoardIcon, TrackRecordIcon } from '../icons/alphaswap';

export const MyAlphaswapSubMenu = () => {
  const history = useHistory();
  return (
    <div className="px-4 py-4 bg-blue-50">
      <div className="container px-4 mx-auto">
        <div className="grid items-center justify-center w-full sm:flex">
          <MenuTab
            active={window.location.pathname === '/my-alphaswap'}
            icon={BoardIcon}
            text="Dashboard"
            onClick={() => history.push('/my-alphaswap')}
          />
          <MenuTab
            active={window.location.pathname === '/my-alphaswap/idea-management'}
            icon={AlphaGrowthIcon}
            text="Idea Management"
            onClick={() => history.push('/my-alphaswap/idea-management')}
          />
          <MenuTab
            active={window.location.pathname === '/my-alphaswap/track-record'}
            icon={TrackRecordIcon}
            text="Track Record"
            onClick={() => history.push('/my-alphaswap/track-record')}
          />
        </div>
      </div>
    </div>
  );
};

interface MenuTabProps {
  icon: any;
  text: string;
  active?: boolean;
  onClick: Function;
}

function MenuTab(props: MenuTabProps) {
  if (props.active) {
    return (
      <div
        onClick={() => props.onClick()}
        className="flex items-center px-10 py-2 text-indigo-700 bg-blue-100 rounded-md cursor-pointer sm:justify-center"
      >
        <props.icon className="h-5 text-indigo-600 fill-current" />
        <span className="ml-3">{props.text}</span>
      </div>
    );
  }
  return (
    <div className="flex items-center px-10 py-4 cursor-pointer" onClick={() => props.onClick()}>
      <span>
        <props.icon className="h-5 text-indigo-800 fill-current" />
      </span>
      <span className="ml-3 text-indigo-800">{props.text}</span>
    </div>
  );
}
