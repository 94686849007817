import { v4 as uuid } from 'uuid';
import { TabProps, ValuationTextTypes, TextWithoutOptions } from './types';
import { valuationQuestionsContent } from 'pages/GenerationPage/InvestmentThesisSection/content';
import { stringNewlinesToJsxWithBr } from 'utils/stringHelpers';
import { NoDataPlaceholder } from './NoDataPlaceholder';

const text: ValuationTextTypes = {
  methodology: {
    title: 'Which valuation methodology did you use?',
  },
  attractiveness: {
    title: 'Why do you find this stock’s valuation attractive (up to 5 reasons)?',
  },
};

const reasonWithoutOptionsContainer = (
  reasonValues: any,
  reasonQuestionContent: any,
  sectionKey: 'methodology' | 'attractiveness'
) => {
  const { title }: TextWithoutOptions = text[sectionKey];
  return (
    <div className={`${sectionKey} mb-12`}>
      <h3 className='text-xl text-blue-500 font-medium mb-4'>{title}</h3>
      <ul className='flex flex-wrap'>
        {reasonValues.values.map((reasonIndex: number) => {
          return (
            <li
              key={uuid()}
              className='mt-2 py-1 px-6 rounded-lg items-center bg-blue-50 text-blue-400 border border-blue-400 mr-4'
            >
              {reasonQuestionContent.options[reasonIndex].label}
            </li>
          );
        })}
      </ul>
      {reasonValues.custom && <p className='mt-4'>{reasonValues.custom}</p>}
      {reasonValues.description && (
        <div className='mt-8'>
          <h4 className='font-medium mb-2'>Comment:</h4>
          <p>{stringNewlinesToJsxWithBr(reasonValues.description)}</p>
        </div>
      )}
    </div>
  );
};

const ValuationTab = ({ contentValues }: TabProps) => {
  if (!Object.keys(contentValues).length) return <NoDataPlaceholder />;

  const { methodology, attractiveness } = contentValues;
  const { methodology: methodologyContent, attractiveness: attractivnessContent } = valuationQuestionsContent;

  return (
    <div>
      {reasonWithoutOptionsContainer(methodology, methodologyContent, 'methodology')}
      {methodology.current && (
        <div className='flex flex-col items-start mb-12'>
          <h3 className='text-xl text-blue-500 font-medium mb-4'>{methodologyContent.currentTrade}</h3>
          <p className='mt-2 py-1 px-6 rounded-lg items-center bg-blue-50 text-blue-400 border border-blue-400 mr-4'>
            {methodology.current}
          </p>
        </div>
      )}
      {reasonWithoutOptionsContainer(attractiveness, attractivnessContent, 'attractiveness')}
    </div>
  );
};
export default ValuationTab;
