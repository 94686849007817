import { range, get } from 'lodash';
import { Controller, useFormContext } from 'react-hook-form';
import classNames from 'classnames';
import { IRateSelectProps } from './types';

export const RateSelect: React.FC<IRateSelectProps> = ({
  name,
  rate,
  rules,
  activeClass,
  defaultValue,
  sectionType,
}) => {
  const { control, errors } = useFormContext();

  const fieldName = sectionType ? `${sectionType}.${name}` : name;
  const inputError = get(sectionType ? errors?.[sectionType] : errors, name);

  return (
    <div>
      <Controller
        rules={rules}
        defaultValue={defaultValue || ''}
        name={fieldName}
        control={control}
        render={({ onChange, ref, value }) => (
          <div className={`grid sm:grid-cols-${rate}-min ml-5 gap-4`}>
            {range(0, rate).map(option => (
              <button
                className={'focus:outline-none'}
                type={'button'}
                onClick={() => onChange(option + 1)}
                key={option}
              >
                <span
                  className={classNames({
                    'rounded-sm cursor-pointer border text-color-blue border-post-border w-10 h-10 flex justify-center items-center':
                      true,
                    [activeClass]: value === option + 1,
                  })}
                >
                  {option + 1}
                </span>

                <input className={'hidden'} type={'radio'} ref={ref} name={name} value={option} />
              </button>
            ))}
          </div>
        )}
      />

      {inputError && <p className={'mt-2 ml-5 text-red-500 text-xs'}>{inputError?.message}</p>}
    </div>
  );
};
