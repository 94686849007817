import classNames from 'classnames';
import { getRegionNameByCode } from 'utils/countryHelpers';
import { IdeaStatus, TradeType } from 'utils/enums';
import { UploadArrow } from 'components/icons/contribution/UplodaArrow';
import { commarize } from 'components/helper';
import { IGeneralInfoProps } from './types';

export const GeneralInfo: React.FC<IGeneralInfoProps> = ({ idea }) => {
  const isIdeaClosed = idea?.status === IdeaStatus.CLOSED;

  const getExpectedReturns = () => {
    if (idea.tradeType === TradeType.LONG) return Math.round((idea.targetPrice / idea.entryPrice - 1) * 100);

    return Math.round((idea.entryPrice / idea.targetPrice - 1) * 100);
  };

  return (
    <div className={'grid grid-cols-1 gap-4 p-4 border border-blue-200 rounded-lg sm:grid-cols-2'}>
      <div className={'grid grid-cols-1 gap-6'}>
        <div>
          <p className={'text-2xl uppercase break-words'}>{idea.security.name}</p>
          <p className={'text-sm font-light text-gray-600 uppercase'}>{idea.security.symbol}</p>
        </div>

        <div className={'flex flex-wrap'}>
          <p className={'px-4 py-2 mb-4 mr-4 text-xs font-light text-blue-900 uppercase rounded-sm bg-blue-50'}>
            {getRegionNameByCode(idea.security.region).label}
          </p>

          <p className={'px-4 py-2 mb-4 mr-4 text-xs font-light text-blue-900 uppercase rounded-sm bg-blue-50'}>
            {idea.security.sector}
          </p>
        </div>

        <div className={'grid max-w-sm gap-2'}>
          <div className={'flex justify-between'}>
            <p className={'text-sm text-gray-400'}>Current Price</p>
            <p className={'text-sm text-blue-900'}>{`$${idea.security.closePrice}`}</p>
          </div>

          <div className={'flex justify-between'}>
            <p className={'text-sm text-gray-400'}>Target Price</p>
            <p className={'text-sm text-blue-900'}>{`$${idea.targetPrice}`}</p>
          </div>

          <div className={'flex justify-between'}>
            <p className={'text-sm text-gray-400'}>Market Cap</p>
            <p className={'text-sm text-blue-900'}>{commarize(idea.security.marketCap)}</p>
          </div>
        </div>
      </div>

      <div className={'grid items-start justify-end gap-4 grid-rows-auto-first justify-items-end'}>
        <p
          className={classNames({
            'uppercase py-2 px-6 rounded-xl grid grid-cols-auto-first items-center gap-2': true,
            'bg-green-100 text-green-400 border border-green-400': idea.tradeType === TradeType.LONG,
            'bg-red-100 text-red-400 border border-red-400': idea.tradeType === TradeType.SHORT,
          })}
        >
          <UploadArrow
            className={classNames({
              'text-green-400 fill-current': idea.tradeType === TradeType.LONG,
              'transform rotate-180 text-red-400 fill-current': idea.tradeType === TradeType.SHORT,
            })}
          />

          <span className={'flex'}>
            <span>{idea.tradeType}</span>
            <span className={'ml-2'}>position</span>
          </span>
        </p>

        <div className={'grid gap-2'}>
          <div className={'grid justify-end justify-items-end'}>
            <div className={'grid items-center gap-2 grid-cols-auto-first'}>
              <div className={'w-3 h-3 bg-blue-600 rounded-full'} />
              <p className={'text-blue-600'}>{isIdeaClosed ? 'Performance' : 'Expected Return'}</p>
            </div>

            <p>{`${getExpectedReturns()}%`}</p>
          </div>

          <div className={'grid justify-end justify-items-end'}>
            <div className={'grid items-center gap-2 grid-cols-auto-first'}>
              <div className={'w-3 h-3 bg-blue-600 rounded-full'} />
              <p className={'text-blue-600'}>Inception Price</p>
            </div>

            <p>{`$${idea.entryPrice}`}</p>
          </div>

          <div className={'grid justify-end justify-items-end'}>
            <div className={'grid items-center gap-2 grid-cols-auto-first'}>
              <div className={'w-3 h-3 bg-blue-600 rounded-full'} />
              <p className={'text-blue-600'}>Target Price</p>
            </div>

            <p>{`${idea.targetPrice}`}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
