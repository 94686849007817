import { action, Action, thunk, Thunk } from 'easy-peasy';
import { AxiosResponse } from 'axios';
import { IRootModel } from '../root.model';
import { IdeaObject } from 'interfaces';
import { client } from 'store/api';

type ThesisPayload = IdeaObject;

interface IThesisState {
  thesisTab: number;
  idea: IdeaObject;
}

interface IThesisAction {
  setThesisTab: Action<this, number>;
  setIdea: Action<this, ThesisPayload>;
  setIdeaState: Action<this, any>;
  resetThesisTab: Action<this>;
}

interface IThesisThunks {
  updateThesisThunk: Thunk<this, undefined, any, IRootModel, Promise<AxiosResponse['data']>>;
}

export interface IEditThesisModel extends IThesisState, IThesisAction, IThesisThunks { }

const editThesisStore: IEditThesisModel = {
  /**
   * STATE
   */
  thesisTab: 0,
  idea: {} as IdeaObject,

  /**
   * ACTIONS
   */
  setIdeaState: action((state, payload) => {
    state.idea = {
      ...state.idea,
      ...payload,
    };
  }),
  setIdea: action((state, payload: IdeaObject) => {
    state.idea = { ...payload };
  }),
  setThesisTab: action((state, payload) => {
    state.thesisTab = payload;
  }),
  resetThesisTab: action(state => {
    state.thesisTab = 0;
  }),
  /**
   * THUNKS
   */
  updateThesisThunk: thunk(async (actions, payload: any) => {
    const { id, executiveSummary, ...thesis } = payload;

    try {
      const response = await client.patch<any, AxiosResponse>('/idea', {
        id,
        thesis,
        executiveSummary,
      });

      return response;
    } catch (error) {
      return error.response;
    }
  }),
};
export default editThesisStore;
