import React from 'react';
import { StandardReactProps } from '../../../interfaces';

export const Checkbox = ({ className }: StandardReactProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='11.189' height='7.956' viewBox='0 0 11.189 7.956' className={className}>
    <path
      d='M10.853,15.956a.74.74,0,0,1-.527-.219L7.094,12.5A.746.746,0,0,1,8.149,11.45l2.7,2.7,5.937-5.937a.746.746,0,1,1,1.055,1.055l-6.464,6.464a.748.748,0,0,1-.529.219Z'
      transform='translate(-6.875 -7.999)'
      fill='#2a52bf'
    />
  </svg>
);
