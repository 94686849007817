import React from 'react';
import { StandardReactProps } from '../../../interfaces';

export const LikeIcon = ({ className }: StandardReactProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='53.476' height='51.248' viewBox='0 0 55 55' className={className}>
    <g data-name='组 1643'>
      <g data-name='组 1642'>
        <path
          fill='#b9c3de'
          d='M53.476 30.633a4.951 4.951 0 00-1.45-3.5 5.532 5.532 0 001.426-4.254 5.759 5.759 0 00-5.811-5.051H33.877a32.036 32.036 0 001.774-8.913c0-4.833-4.106-8.913-6.685-8.913a7 7 0 00-4.037 1.357 1.115 1.115 0 00-.419.871v7.556l-6.419 13.9-.265.136v-.426a1.115 1.115 0 00-1.114-1.114H5.57A5.576 5.576 0 000 27.852v17.825a5.575 5.575 0 005.57 5.57h6.685a5.583 5.583 0 005.245-3.682 14.525 14.525 0 005.9 1.455h20.448a5.126 5.126 0 005.052-3.887 5.03 5.03 0 00-.4-3.349 4.995 4.995 0 002.23-6.685 4.993 4.993 0 002.746-4.466zm-4.7 2.75a1.114 1.114 0 00-.637 1.918 2.76 2.76 0 01-1.589 4.766 1.114 1.114 0 00-.637 1.918 2.755 2.755 0 01.807 2.658 2.883 2.883 0 01-2.872 2.144H23.4c-1.809 0-4.844-.849-5.9-1.9a1.115 1.115 0 00-1.9.789 3.346 3.346 0 01-3.342 3.342H5.57a3.346 3.346 0 01-3.342-3.342V27.852A3.346 3.346 0 015.57 24.51H15.6v1.114a1.119 1.119 0 00.53.949 1.139 1.139 0 001.083.047l2.228-1.114a1.112 1.112 0 00.513-.528l6.685-14.483a1.135 1.135 0 00.1-.468V2.833a4.7 4.7 0 012.228-.6c1.221 0 4.456 3.035 4.456 6.685 0 3.922-2.135 9.574-2.155 9.63a1.112 1.112 0 001.04 1.511h15.333a3.518 3.518 0 013.592 3.028 3.329 3.329 0 01-1.587 3.146 1.116 1.116 0 00.076 1.941 2.759 2.759 0 01-.945 5.212z'
          data-name='路径 788'
        />
      </g>
    </g>
    <g data-name='组 1645'>
      <g data-name='组 1644'>
        <path
          fill='#b9c3de'
          d='M16.711 24.51a1.115 1.115 0 00-1.114 1.114v20.053a1.114 1.114 0 002.228 0V25.624a1.115 1.115 0 00-1.114-1.114z'
          data-name='路径 789'
        />
      </g>
    </g>
  </svg>
);
