import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import classNames from 'classnames';
import { UserType } from 'utils/enums';
import { useStore } from 'store/hooks';
import { IAdditionInfoProps } from './types';

const mapStatusToColor = {
  LIVE: {
    text: 'text-green-600',
    background: 'bg-green-400',
  },
  CLOSED: {
    text: 'text-gray-800',
    background: 'bg-gray-400',
  },
  PENDING: {
    text: 'text-yellow-400',
    background: 'bg-yellow-400',
  },
  DECLINED: {
    text: 'text-gray-800',
    background: 'bg-gray-400',
  },
};

export const AdditionInfo: React.FC<IAdditionInfoProps> = ({ idea }) => {
  const store = useStore();
  const { user } = store.getState().userStore;
  // @TODO: add isClosed key to API
  const hasVoted = idea.votes?.find((vote: any) => vote.user.id === user.id);
  const isIdeaClosed = idea?.status === 'CLOSED' || hasVoted;
  const ctaTextConstructor = () => {
    if (isIdeaClosed || user.type === UserType.CUSTOMER) return 'Read More';
    if (idea?.author?.id === user.id) return 'Edit my idea';

    return 'Co-invest in this idea';
  };

  return (
    <div className='grid items-center gap-4 text-xs sm:grid-cols-3 justify-items-center sm:justify-items-auto'>
      <p className='sm:justify-self-start'>
        <span>Idea submitted</span>
        <span className='ml-2 text-gray-500'>{format(new Date(idea.createdAt), 'MM.dd.yy')}</span>
      </p>
      <Link
        to={`/idea/${idea.id}`}
        className='px-6 py-3 text-sm font-light text-white uppercase rounded-lg bg-gradient-to-r from-blue-500 to to-blue-600 sm:justify-self-center whitespace-nowrap'
      >
        {ctaTextConstructor()}
      </Link>
      <div className='grid items-center gap-2 grid-cols-auto-first sm:justify-self-end'>
        <div
          className={classNames({
            'w-4 h-4 rounded-full': true,
            [mapStatusToColor[idea.status as keyof typeof mapStatusToColor].background]: true,
          })}
        />
        <p
          className={classNames({
            [mapStatusToColor[idea.status as keyof typeof mapStatusToColor].text]: true,
            capitalize: true,
          })}
        >
          {`Idea ${idea.status}`}
        </p>
      </div>
    </div>
  );
};
