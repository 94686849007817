import { Link } from 'react-router-dom';
import { CellType } from 'components/Table/types';
import { toFixedCustom } from 'utils/numberHelpers';

export type UserCellIdeaType = {
  symbol?: string;
  performance?: number;
};

export type IdeaCellLivePriceType = {
  price?: number;
  isLive: boolean;
};

type TopIdeaRowDataType = {
  symbol: (symbol?: string) => CellType;
  geo: (geo?: string) => CellType;
  sector: (sector?: string) => CellType;
  entryPrice: (price: number) => CellType;
  livePrice: (price: number) => CellType;
  targetPrice: (price: number) => CellType;
  performance: (performance: number) => CellType;
  detailsLink: (id: number) => CellType;
};

type TopUserRowDataType = {
  userId: (userId: number) => CellType;
  geo: (geoTitle: string) => CellType;
  sector: (sectorName: string) => CellType;
  avgPerformance: (performance: number) => CellType;
  idea: (ideaObject: UserCellIdeaType) => CellType;
  totalIdeas: (ideasCount: number) => CellType;
  detailsLink: (userId: number) => CellType;
};

export const topIdeasColumns: CellType[] = [
  { title: 'Symbol' },
  { title: 'Geo' },
  { title: 'Sector' },
  { title: 'Entry price' },
  { title: 'Last price' },
  { title: 'Target price' },
  { title: 'ITD Perf' },
  { title: 'Details' },
];

export const topUsersColumns: CellType[] = [
  { title: 'User id' },
  { title: 'Top geo' },
  { title: 'Top sector' },
  { title: 'Av. Perf' },
  { title: 'Last idea' },
  { title: 'Top winner' },
  { title: '# Ideas' },
  { title: 'Details' },
];

export const topIdeaRowData: TopIdeaRowDataType = {
  symbol: symbol => ({
    title: symbol ? <span className={'font-medium'}>{symbol}</span> : null,
  }),
  geo: geo => ({ title: geo || null, showTooltip: true }),
  sector: sector => ({
    title: sector?.toUpperCase(),
    showTooltip: true,
  }),
  entryPrice: price => ({ title: toFixedCustom(price, 2) }),
  livePrice: price => ({ title: toFixedCustom(price, 2) }),
  targetPrice: price => ({ title: toFixedCustom(price, 2) }),
  performance: performance => ({
    title: (
      <span className={`font-medium ${performance > 0 ? 'text-green-500' : performance < 0 ? 'text-red-500' : ''}`}>
        {performance > 0 ? `+${toFixedCustom(performance, 2, 0)}%` : `${toFixedCustom(performance, 2, 0)}%`}
      </span>
    ),
  }),
  detailsLink: id => ({
    title: (
      <Link className={'font-semibold text-table-content-gray'} to={`/idea/${id}`}>
        {'--->'}
      </Link>
    ),
  }),
};

export const topUserRowData: TopUserRowDataType = {
  userId: userId => ({
    title: <span className={'font-medium'}>{userId}</span>,
  }),
  geo: geoTitle => ({ title: geoTitle, showTooltip: true }),
  sector: sectorName => ({
    title: sectorName?.toUpperCase(),
    showTooltip: true,
  }),
  avgPerformance: performance => ({ title: `${toFixedCustom(performance, 2, 0)}%` }),
  idea: ({ symbol, performance }) => ({
    title: (
      <span className={'font-medium'}>
        {performance || performance === 0 ? (
          <>
            {symbol} (
            <span className={performance > 0 ? 'text-green-500' : performance < 0 ? 'text-red-500' : ''}>
              {performance > 0 ? `+${toFixedCustom(performance, 2, 0)}%` : `${toFixedCustom(performance, 2, 0)}%`}
            </span>
            )
          </>
        ) : (
          '-'
        )}
      </span>
    ),
  }),
  totalIdeas: ideasCount => ({ title: ideasCount }),
  detailsLink: userId => ({
    title: (
      <Link className={'font-semibold text-table-content-gray'} to={`/user/${userId}`}>
        {'--->'}
      </Link>
    ),
  }),
};
