import React from 'react';
import ReactApexCharts from 'react-apexcharts';

const options = {
  chart: { type: 'candlestick' },
  grid: { padding: { left: 35, bottom: 0 } },
  xaxis: { type: 'datetime' },
  yaxis: { tooltip: { enabled: true } },
};

interface AreaChartProps {
  data?: number[][];
}

export const AreaChart = ({ data }: AreaChartProps) =>
  data ? (
    <ReactApexCharts type='area' height={350} options={options} series={[{ data }]} />
  ) : (
    <div className='text-center'>no chart data available</div>
  );
