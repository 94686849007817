import { format } from 'date-fns';
import { getCountryNameByCode } from 'utils/countryHelpers';
import { Card } from 'components/common';
import { ITrackUserProps } from './types';

export const TrackUser: React.FC<ITrackUserProps> = ({ user }) => (
  <Card className={'rounded-md'}>
    <div className={'grid sm:grid-cols-2 gap-4'}>
      <div>
        <img src={user.profilePicture} alt={'avatar'} className={'object-cover object-center w-48 border rounded-md'} />
      </div>

      <div className={'text-sm'}>
        <div className={'flex my-1 md:block'}>
          <p className={'text-gray-400'}>NAME: </p>
          <p className={'font-medium text-gray-900'}>{`${user.firstName || ''} ${user.lastName || ''}`}</p>
        </div>

        <div className={'flex my-1 md:block'}>
          <p className={'text-gray-400'}>LOCATION: </p>
          <p className={'font-medium text-gray-900'}>
            {user.country ? getCountryNameByCode(user.country)?.label : null}
          </p>
        </div>

        <div className={'flex my-1 md:block'}>
          <p className={'text-gray-400'}>JOB: </p>
          <p className={'font-medium text-gray-900'}>{user.job}</p>
        </div>

        <div className={'flex my-1 md:block'}>
          <p className={'text-gray-400'}>JOINED: </p>
          <p className={'font-medium text-gray-900'}>
            {user?.createdAt && format(new Date(user.createdAt), 'MMMM dd, yyyy')}
          </p>
        </div>
      </div>
    </div>
  </Card>
);
