import { useParams } from 'react-router-dom';
import { Layout } from '../../components';
import { Container } from '../../components/common';
import { Sidebar } from '../../components/settings';
import { AccountPage } from './AccountPage';
import { NotificationPage } from './NotificationPage';

export enum SettingRoutes {
  AccountPageItem = 'accountSettings',
  NotificationPageItem = 'noteSettings',
}

export const SettingsPage = () => {
  const { section } = useParams<{section: string}>();
  return (
    <Layout>
      <Container className='my-6 sm:my-12'>
        <div className='justify-between max-w-6xl mx-auto sm:flex'>
          <div className='sm:w-2/5'>
            <Sidebar />
          </div>
          <div className='w-full px-4 py-6 bg-white border border-gray-400 rounded-md sm:px-12 '>
            <div>{section === SettingRoutes.AccountPageItem && <AccountPage />}</div>
            <div>{section === SettingRoutes.NotificationPageItem && <NotificationPage />}</div>
          </div>
        </div>
      </Container>
    </Layout>
  );
};
