import React from 'react';
import { format } from 'date-fns';
import { useStoreState, useStoreActions } from 'store/hooks';
import { IdeaManagementTable, Table } from 'components/my-alphaswap';
import { renderExpectedReturn } from 'utils';
import NoRecordsPlaceholder from '../NoRecordsPlaceholder';
import ideaManagementPlaceholderImage from 'img/placeholder-stock.png';
import placeholder from 'img/placeholder-credit.png';
import { IdeaTableType } from 'utils/enums';

const noRecordsPlaceholderConfig = {
  textOne: 'The Stocks Picks you have submitted appear in this section.',
  textTwo: 'You can monitor their performance and manage them there.',
  ctaLink: '/idea/generate/choose-asset',
  ctaText: 'Submit your stock pick',
  imgUrl: ideaManagementPlaceholderImage,
};

const returnContent = (setIdeaManagement: Function) => (
  <>
    <div className='flex flex-wrap justify-center px-5 py-5 mx-auto align-items-center '>
      <img src={ideaManagementPlaceholderImage} alt='avatar' className='mb-5 h-36 w-35' />
      <div className='flex flex-col ml-5 '>
        <p className='flex pb-3 text-sm text-center'>The Stocks Picks you have submitted appear in this section.</p>
        <p className='flex mb-3 text-sm text-center'>You can monitor their performance and manage them there.</p>
        <div className='flex justify-center mx-auto'>
          <div>
            <button
              type='button'
              onClick={() => setIdeaManagement(false)}
              className='px-3 py-2 text-sm font-light text-white uppercase rounded-lg sm:justify-self-center whitespace-nowrap bg-gradient-to-r from-blue-500 to to-blue-600'
            >
              Submit your first stock pick
            </button>
          </div>
        </div>
      </div>
    </div>
  </>
);

const returnVotesContent = (setVotes: Function) => (
  <>
    <div className='flex flex-wrap justify-center px-5 py-5 mx-auto align-items-center '>
      <img src={placeholder} alt='avatar' className='mb-5 h-36 w-35' />
      <div className='flex flex-col ml-5 '>
        <p className='flex pb-3 text-sm text-center'>Your virtual Co-investments appear in this section.</p>
        <p className='flex mb-3 text-sm text-center'>You can monitor their performance and add updates here.</p>
        <div className='flex justify-center mx-auto'>
          <div>
            <button
              type='button'
              onClick={() => setVotes(false)}
              className='px-3 py-2 text-sm font-light text-white uppercase bg-green-500 rounded-lg sm:justify-self-center whitespace-nowrap'
            >
              Co-invest in a stock pick
            </button>
          </div>
        </div>
      </div>
    </div>
  </>
);

const IdeaManagementPage = () => {
  const { ideas, votes } = useStoreState(state => state.myAlphaswapStore);
  const { showIdeaManagement, showVotes } = useStoreState(state => state.placeholderStore);

  const { setIdeaManagement, setVotes } = useStoreActions(actions => actions.placeholderStore);

  function buildGeneratedIdeasData() {
    return ideas?.map((idea: any) => ({
      meta: {
        id: idea.id,
      },
      data: [
        format(new Date(idea.createdAt), 'yyyy.MM.dd'),
        idea.status,
        idea.security.symbol,
        idea.livePrice || idea.proposedPrice,
        idea.security.closePrice,
        renderExpectedReturn(idea.status, idea.tradeType, idea.livePrice, idea.closedPrice, idea.security.closePrice),
        idea.generatorStake.toString(),
        '-',
      ],
    }));
  }

  function buildVotedIdeasData() {
    return votes?.map((vote: any) => ({
      meta: {
        id: vote.idea.id,
      },
      data: [
        format(new Date(vote.idea.createdAt), 'yyyy.MM.dd'),
        vote.idea.status,
        vote.idea.security.symbol,
        vote.idea.livePrice || vote.idea.proposedPrice,
        vote.idea.security.closePrice,
        renderExpectedReturn(
          vote.idea.status,
          vote.idea.tradeType,
          vote.idea.livePrice,
          vote.idea.closedPrice,
          vote.idea.security.closePrice
        ),
        vote.stake,
        '-',
      ],
    }));
  }

  const generatedIdeasData = buildGeneratedIdeasData();
  const votedIdeasData = buildVotedIdeasData();

  return (
    <div className='max-w-6xl mx-auto my-8 rounded'>
      {ideas.length === 0 && votes.length === 0 ? (
        <NoRecordsPlaceholder {...noRecordsPlaceholderConfig} isBorder />
      ) : (
        <>
          <div className='mb-10'>
            <div className='py-2 overflow-x-scroll bg-white border border-indigo-100 rounded-md'>
              <p className='p-4 text-gray-800'>Stock Picks Submitted ({ideas.length})</p>
              {generatedIdeasData.length > 0 && <IdeaManagementTable data={generatedIdeasData} />}
            </div>
          </div>

          <div className='overflow-x-scroll bg-white border border-indigo-100 rounded-md'>
            <p className='p-4 text-gray-800'>Virtual Co-Investments Made ({votes.length})</p>
            {votedIdeasData.length > 0 && <Table type={IdeaTableType.VOTE} data={votedIdeasData} />}
          </div>
        </>
      )}
    </div>
  );
};

export default IdeaManagementPage;
