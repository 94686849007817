import { createStore, persist } from 'easy-peasy';
import model from './models';
import * as apiService from './api';

export interface IInjections {
  apiService: typeof apiService;
}

export const store = createStore(
  persist(model, {
    storage: 'localStorage',
    mergeStrategy: 'overwrite',
  })
);

// Wrapping dev only code like this normally gets stripped out by bundlers
// such as Webpack when creating a production build.
if (process.env.NODE_ENV === 'development') {
  // @ts-ignore
  if (module.hot) {
    // @ts-ignore
    module.hot.accept('./models/index.ts', () => {
      store.reconfigure(model); // 👈 Here is the magic
    });
  }
}

export default store;
