import { Link } from 'react-router-dom';

interface NoRecordsPlaceholderProps {
  textOne: string;
  textTwo?: string;
  ctaLink: string;
  ctaText: string;
  imgUrl: string;
  isBorder?: boolean;
  children?: any;
}

const NoRecordsPlaceholder = (props: NoRecordsPlaceholderProps) => {
  const { textOne, textTwo, ctaLink, ctaText, imgUrl, isBorder, children } = props;

  return (
    <div className={`flex flex-col items-center py-8 px-4 mx-auto rounded-md bg-white ${isBorder ? 'border' : ''}`}>
      <div className='flex flex-col flex-wrap items-center justify-center mx-auto mt-3'>
        <img src={imgUrl} alt='avatar' className='mb-3 max-h-52' />
        <p className='px-20 text-center text-gray-400'>{textOne}</p>
      </div>
      {textTwo && <p className='text-center max-w-sm text-gray-400'>{textTwo}</p>}
      <div className='flex flex-col'>
        <Link to={ctaLink} className='text-center flex-grow p-3 text-white bg-blue-500 hover:bg-blue-700 rounded-lg mt-2 duration-300'>
          {ctaText}
        </Link>
        {children}
      </div>
    </div>
  );
};

export default NoRecordsPlaceholder;
