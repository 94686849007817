import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { CellType } from 'components/Table/types';
import { StatusColor } from 'utils/enums';
import { toFixedCustom } from 'utils/numberHelpers';
import { StatusDot } from 'components/common/StatusDot';
import { IdeaTableRowDataType } from './types';

export const ideasColumns: CellType[] = [
  { title: 'Date' },
  { title: 'Status' },
  { title: 'Symbol' },
  { title: 'Entry price' },
  { title: 'Exit price' },
  { title: 'Last price' },
  { title: 'Perf' },
  { title: 'Details' },
];

export const ideaTableRowData: IdeaTableRowDataType = {
  date: (date: string) => ({ title: format(new Date(date), 'dd.MM.yyyy') }),
  status: (status?: keyof typeof StatusColor) => ({
    title: status ? <StatusDot status={status} /> : '',
  }),
  symbol: symbol => ({
    title: symbol ? <span className={'font-medium'}>{symbol}</span> : null,
  }),
  entryPrice: price => ({ title: toFixedCustom(price, 2) }),
  exitPrice: price => ({ title: toFixedCustom(price, 2) }),
  lastPrice: price => ({ title: toFixedCustom(price, 2) }),
  performance: performance => ({
    title: (
      <span className={`font-medium ${performance > 0 ? 'text-green-500' : performance < 0 ? 'text-red-500' : ''}`}>
        {performance > 0 ? `+${toFixedCustom(performance, 2, 0)}%` : `${toFixedCustom(performance, 2, 0)}%`}
      </span>
    ),
  }),
  detailsLink: id => ({
    title: (
      <Link className={'font-semibold text-table-content-gray'} to={`/idea/${id}`}>
        {'--->'}
      </Link>
    ),
  }),
};
