import React, { useEffect } from 'react';
import mixpanel from 'utils/mixpanelIntegration';
import { TextArea, RateSelect } from 'components/fields';
import { Banner, CardWithTitle } from 'components/common';
import CheckboxList from 'components/common/Inputs/CheckboxList';
import { companyQuestionsContent } from '../content';
import { ICompanySectionProps } from './types';

const Company: React.FC<ICompanySectionProps> = ({ defaultValues, flowType }) => {
  useEffect(() => {
    mixpanel.dispatchEvent(`${flowType} idea: Company`);
  }, []);

  return (
    <div id={'company'} className={'pt-8'}>
      <CardWithTitle title={'Company'}>
        <Banner
          className={'w-full p-2 mt-3 text-xs text-gray-800 bg-blue-100 border border-blue-600 rounded lg:text-sm'}
          textContent={companyQuestionsContent.banner}
        />

        <div>
          <div className={'mt-6'}>
            <div className={'md:flex md:justify-between'}>
              <div className={'grid gap-4'}>
                {companyQuestionsContent.rates.options.map((rateOption, index) => (
                  <React.Fragment key={rateOption.name}>
                    <div className={'text-sm'}>
                      <span className={'font-normal text-indigo-500'}>
                        {index + 1} {'-->'}
                      </span>
                      <span className={'ml-3 text-blue-800'}>{rateOption.label}</span>
                    </div>

                    <RateSelect
                      defaultValue={defaultValues.rates[rateOption.name]}
                      rate={5}
                      name={`rates.${rateOption.name}`}
                      activeClass={'bg-indigo-100'}
                      sectionType={'company'}
                      rules={{
                        validate: {
                          rated: value => Boolean(value) || 'You need to pick one',
                        },
                      }}
                    />
                  </React.Fragment>
                ))}
              </div>

              <div className={'w-full my-6 md:w-1/2'}>
                <p className={'text-sm text-gray-700'}>
                  Please comment on your ratings of this company with regards to Porter&apos;s Five Forces (optional)
                </p>

                <div>
                  <TextArea name={'company.rates.description'} />
                </div>
              </div>
            </div>
          </div>

          <div className={'grid gap-4'}>
            <p className={'mt-6 text-sm font-semibold'}>{companyQuestionsContent.management.title}</p>

            <CheckboxList
              content={companyQuestionsContent.management}
              defaultValues={defaultValues}
              sectionType={'company'}
            />
          </div>
        </div>
      </CardWithTitle>
    </div>
  );
};

export default Company;
