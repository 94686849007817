import React from 'react';
import { StandardReactProps } from '../../../interfaces';

export const RouteIcon = ({ className }: StandardReactProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='62.555' height='51.232' viewBox='0 0 60 60' className={className}>
    <path
      fill='#b9c3de'
      d='M22.667 24.282a.916.916 0 00-1.281-.2 12.245 12.245 0 01-3.374 1.748.917.917 0 00-.527.454l-3.358 6.6-3.359-6.6a.916.916 0 00-.527-.454 12.3 12.3 0 1113.835-4.452.92.92 0 101.484 1.085A14.123 14.123 0 109.3 27.447l4.008 7.88a.916.916 0 001.634 0l4.008-7.88a14.078 14.078 0 003.521-1.884.916.916 0 00.196-1.281z'
      data-name='路径 790'
    />
    <path
      fill='#b9c3de'
      d='M17.176 20.745a.916.916 0 00.916-.916v-9.163a3.966 3.966 0 10-7.931 0v9.163a.916.916 0 101.833 0v-5.193h4.266v5.193a.916.916 0 00.916.916zm-5.184-7.939v-2.138a2.133 2.133 0 114.266 0v2.138z'
      data-name='路径 791'
    />
    <path
      fill='#b9c3de'
      d='M59.329 5.231a.916.916 0 00-1.414 1.165 12.326 12.326 0 01-5.6 19.519.917.917 0 00-.527.454l-3.358 6.6-3.359-6.6a.916.916 0 00-.527-.454 12.314 12.314 0 1110.443-22.1.916.916 0 00.98-1.548 14.143 14.143 0 10-12.363 25.266l4.008 7.88a.916.916 0 001.634 0l4.008-7.88a14.16 14.16 0 006.076-22.3z'
      data-name='路径 792'
    />
    <path
      fill='#b9c3de'
      d='M52.76 10.753a3.973 3.973 0 00-3.966-3.971h-3.05a.916.916 0 00-.916.916v12.218a.916.916 0 00.916.916h3.05a3.97 3.97 0 002.531-7.025 3.966 3.966 0 001.435-3.054zM48.794 19h-2.133v-4.276h2.133a2.138 2.138 0 010 4.276zm0-6.109h-2.133V8.613h2.133a2.138 2.138 0 010 4.276z'
      data-name='路径 793'
    />
    <path
      fill='#b9c3de'
      d='M48.427 38.191a.916.916 0 00-.916.916v3.482a.916.916 0 101.833 0v-3.482a.916.916 0 00-.917-.916z'
      data-name='路径 794'
    />
    <path
      fill='#b9c3de'
      d='M48.428 45.917a.916.916 0 00-.916.916 2.566 2.566 0 01-2.56 2.566.916.916 0 100 1.833 4.4 4.4 0 004.393-4.4.916.916 0 00-.917-.915z'
      data-name='路径 795'
    />
    <path fill='#b9c3de' d='M28.525 49.399h-5.478a.916.916 0 000 1.833h5.478a.916.916 0 000-1.833z' data-name='路径 796' />
    <path fill='#b9c3de' d='M39.477 49.399h-5.479a.916.916 0 000 1.833h5.479a.916.916 0 000-1.833z' data-name='路径 797' />
    <path
      fill='#b9c3de'
      d='M17.604 49.399a2.566 2.566 0 01-2.56-2.566.916.916 0 10-1.833 0 4.4 4.4 0 004.393 4.4.916.916 0 100-1.833z'
      data-name='路径 798'
    />
    <path
      fill='#b9c3de'
      d='M14.127 43.506a.916.916 0 00.916-.916v-3.482a.916.916 0 10-1.833 0v3.482a.916.916 0 00.917.916z'
      data-name='路径 799'
    />
  </svg>
);
