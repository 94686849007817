import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { UserType } from 'utils/enums';
import { useStoreState } from 'store/hooks';
import { Page404 } from 'pages/Page404';

interface IPrivateRouteProps {
  userTypes?: UserType[];
}

export const PrivateRoute: React.FC<IPrivateRouteProps> = ({ children, userTypes = [] }) => {
  const history = useHistory();
  const { user } = useStoreState(state => state.userStore);
  const { jwt } = useStoreState(state => state.authStore);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  if (!loading) {
    if (!jwt?.accessToken) {
      history.push('/auth');
      return null;
    }

    if (!user?.onboardedAt && history.location.pathname !== '/onboarding') {
      history.push('/onboarding');
    }

    if (!user.tncAcceptedAt) {
      history.push('/terms-and-conditions');
    }

    if (!userTypes.length || userTypes.includes(user.type) || user.type === UserType.ADMIN) {
      return <>{children}</>;
    }

    return <Page404 />;
  }

  return null;
};
