/* eslint-disable */

export function orderASC(leaderboardArray: any) {
  return leaderboardArray.sort((a: any, b: any) => {
    if (a.data[6] < b.data[6]) {
      return 1;
    }

    if (a.data[6] > b.data[6]) {
      return -1;
    }

    return 0;
  });
}

export function orderDESC(leaderboardArray: any) {
  return leaderboardArray.sort((a: any, b: any) => {
    if (a.data[6] > b.data[6]) {
      return 1;
    }

    if (a.data[6] < b.data[6]) {
      return -1;
    }

    return 0;
  });
}
