import { Banner, CardWithTitle } from 'components/common';
import { TextArea, RateSelect } from 'components/fields';

type VoteEsgProps = {
  esgScore?: number;
  esgDescription: string;
};

export const VoteEsg: React.FC<VoteEsgProps> = ({ esgScore, esgDescription }) => (
  <div className={'pt-8'}>
    <CardWithTitle title={'ESG'}>
      <div className={'grid gap-5'}>
        <Banner
          className={'w-full p-2 text-xs text-gray-800 bg-blue-100 border border-blue-600 rounded lg:text-sm'}
          textContent={
            'Please tell us what you think about the underlying company’s ESG-related efforts. If you are aware of any controversies surrounding the company that deserve public attention, please let us know.'
          }
        />

        <div>
          <p className={'mb-4 text-sm text-gray-700'}>
            In light of the investment idea you want to co-invest in, how do you rate the company overall from an ESG
            perspective?
          </p>

          <RateSelect
            name={'esgScore'}
            defaultValue={esgScore}
            rate={5}
            activeClass={'bg-indigo-100'}
            rules={{
              required: {
                value: true,
                message: 'You must select point',
              },
            }}
          />
        </div>

        <div>
          <p className={'mb-4 text-sm text-gray-700'}>Anything to add on ESG or controversy?</p>
          <TextArea
            name={'esgDescription'}
            defaultValue={esgDescription}
            rules={{
              required: {
                value: true,
                message: 'This field is required',
              },
            }}
          />
        </div>
      </div>
    </CardWithTitle>
  </div>
);
