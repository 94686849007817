import React from 'react';
import '../index.scss';
import { CheckmarkIcon } from '../icons/generate';
import { useStoreActions } from '../../store/hooks';
import { GearIcon } from '../icons/header';

export const NoteSettings = () => {
  const { readAllNotificationsThunk } = useStoreActions(actions => actions.notificationStore);

  const { noteDropdown } = useStoreActions(actions => actions.headerStore);
  const handleReadNotification = () => {
    readAllNotificationsThunk();
  };

  return (
    <div className='absolute top-0 z-10 mt-10 text-sm bg-white border rounded shadow-2xl w-52 ml-14 NoteMenu'>
      <button
        type='button'
        className='flex items-center w-full p-2 border-b hover:bg-gray-100 focus:outline-none'
        onClick={() => {
          handleReadNotification();
          noteDropdown(false);
        }}
      >
        <CheckmarkIcon className='w-5 h-5 ml-2 text-gray-500 fill-current' />
        <p className='ml-4 text-gray-600 '>Mark all as read</p>
      </button>
      <button type='button' className='flex items-center w-full p-2 hover:bg-gray-100 focus:outline-none'>
        <GearIcon className='w-5 h-5 ml-2 text-gray-700 fill-current' />
        <p className='ml-4 text-gray-600 '>Notification settings</p>
      </button>
    </div>
  );
};
