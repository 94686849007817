import { action, Action, thunk, Thunk } from 'easy-peasy';
import { AxiosResponse } from 'axios';
import { IRootModel } from '../root.model';
import { client } from '../../api';

interface IOnboardingPayload {
  firstName?: string;
  lastName?: string;
  birthdate?: string;
  nickname?: string;
  job?: string;
  country?: string;
  hearAboutUs?: string;
}

interface IOnboardingState {
  step: number;
  onboardingData: {
    firstName: string;
    lastName: string;
    birthdate: string;
    nickname: string;
    job: string;
    country: string;
    hearAboutUs: string;
  };
}

interface IOnboardingActions {
  setOnboardingData: Action<this, IOnboardingPayload>;
  setStep: Action<this, number>;
}

interface IOnboardingThunks {
  submitOnboardingThunk: Thunk<this, undefined, undefined, IRootModel, Promise<AxiosResponse['status']>>;
}

export interface IOnboardingModel extends IOnboardingState, IOnboardingActions, IOnboardingThunks {}

const onboardingStore: IOnboardingModel = {
  step: 0,
  onboardingData: {
    firstName: '',
    lastName: '',
    nickname: '',
    birthdate: '',
    job: '',
    country: '',
    hearAboutUs: '',
  },

  /**
   * ACTIONS
   */
  setOnboardingData: action((state, payload: IOnboardingPayload) => {
    state.onboardingData = Object.assign(state.onboardingData, payload);
  }),
  setStep: action((state, payload) => {
    state.step = payload;
  }),

  /**
   * THUNKS
   */
  submitOnboardingThunk: thunk(async (actions, _, { getState, getStoreActions }) => {
    const { onboardingData } = getState();
    const { setUser } = getStoreActions().userStore;
    const response = await client.post<any, AxiosResponse<IOnboardingPayload>>('/user/onboard', onboardingData);

    if (response?.status >= 200 && response?.status < 300) {
      setUser(response.data);
    }

    return response?.status;
  }),
};

export default onboardingStore;
