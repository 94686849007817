import React from 'react';

interface RadialButtonProps {
  active: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const RadialButton = ({ active, onClick }: RadialButtonProps) => (
  <button type='button' className='focus:outline-none w-5 h-5 border border-indigo-500 rounded-full cursor-pointer' onClick={onClick}>
    {active && <div className='w-full h-full bg-indigo-500 border-2 border-white rounded-full' />}
  </button>
);
