import { action, Action, Thunk, thunk } from 'easy-peasy';
import { WebAuth } from 'auth0-js';
import axios, { AxiosResponse } from 'axios';
import { ResponseStatusCode } from 'utils/enums';
import params from 'auth0-params.json';

const webAuth = new WebAuth({
  domain: params.domain,
  clientID: params.clientId,
  redirectUri: process.env.REACT_APP_CALLBACK_URL,
});

interface IJwtToken {
  accessToken: string;
  accessTokenExpiresAt: number;
  refreshToken: string;
  refreshTokenExpiresAt: number;
}

interface IAuthState {
  jwt: IJwtToken | null;
}

interface IAuthActions {
  setJwtToken: Action<this, IJwtToken>;
}

interface IAuthThunk {
  auth0LocalThunk: Thunk<this>;
  auth0GoogleThunk: Thunk<this>;
  auth0LinkedinThunk: Thunk<this>;
  connectWithAuth0Thunk: Thunk<this, string, any, {}, Promise<AxiosResponse<{ accessToken: string }>>>;
  refreshTokenThunk: Thunk<this, string, any, {}, Promise<AxiosResponse<{ accessToken: string }>>>;
}
export interface Auth0Model extends IAuthState, IAuthActions, IAuthThunk {}

const authStore: Auth0Model = {
  jwt: null,

  /**
   * ACTIONS
   */
  setJwtToken: action((state, payload: IJwtToken) => {
    state.jwt = payload;
  }),
  /**
   * THUNKS
   */
  auth0LocalThunk: thunk(() => {
    webAuth.authorize({
      domain: params.domain,
      redirectUri: process.env.REACT_APP_CALLBACK_URL,
      responseType: 'token id_token',
      connection: 'Username-Password-Authentication',
      audience: process.env.REACT_APP_API_URL,
      prompt: 'login',
    });
  }),
  auth0GoogleThunk: thunk(() => {
    webAuth.authorize({
      domain: params.domain,
      redirectUri: process.env.REACT_APP_CALLBACK_URL,
      responseType: 'token id_token',
      connection: 'google-oauth2',
      audience: process.env.REACT_APP_API_URL,
      prompt: 'login',
    });
  }),
  auth0LinkedinThunk: thunk(() => {
    webAuth.authorize({
      domain: params.domain,
      redirectUri: process.env.REACT_APP_CALLBACK_URL,
      responseType: 'token id_token',
      connection: 'linkedin',
      audience: process.env.REACT_APP_API_URL,
      prompt: 'login',
    });
  }),
  connectWithAuth0Thunk: thunk(async (actions, payload, { getState }) => {
    const response = await axios.post<any, AxiosResponse<IJwtToken>>(
      `${process.env.REACT_APP_API_URL}/auth/auth0/connect`,
      null,
      {
        headers: {
          authorization: `Bearer ${payload}`,
        },
      }
    );

    if (response?.status === ResponseStatusCode.CREATED) {
      actions.setJwtToken(response.data);
    }

    return response;
  }),

  refreshTokenThunk: thunk(async (actions, payload) => {
    const response = await axios.post<any, AxiosResponse<IJwtToken>>(`${process.env.REACT_APP_API_URL}/auth/refresh`, {
      refreshToken: payload,
    });

    if (response?.status === ResponseStatusCode.OK) {
      actions.setJwtToken(response.data);
    }

    return response;
  }),
};
export default authStore;
