export const thesisQuestionsContent = [
  {
    name: 'mispricingReason',
    title: 'A. The stock is mispriced due to the following:',
    query: 'Please comment on said mispricing (optional)',
    options: [
      {
        name: 'fundamentals',
        label: 'Fundamental reasons',
        options: [
          'Revenue growth',
          'Earnings growth',
          'Cash flow generation',
          'Balance sheet strength',
          'Competitive edge',
          'Product development',
          'Management quality',
          'Sector/macro trends',
          'Valuation Multiples',
          'Other',
        ],
      },
      {
        name: 'specials',
        label: 'Special situations or corporate actions',
        options: ['M&A', 'Restructuring/Bankruptcy', 'Spin-Off', 'Dividend Payment', 'Debt Refinancing', 'Other'],
      },
      {
        name: 'technicals',
        label: 'Technical reasons',
        options: [
          'Poor sellside coverage',
          'Market cap too small',
          'Trading volume too small',
          'Index inclusion',
          'Index exclusion',
          'Shareholder buying',
          'Shareholder selling',
          'Other',
        ],
      },
    ],
  },
  {
    name: 'catalysts',
    title:
      'B. What are the potential catalysts that will trigger the market to reprice the value identified in the items above? (more than one allowed)',
    query: 'Please comment on these potential catalysts (optional)',
    options: [
      {
        name: 'financialResults',
        label: 'Announcement of financial results',
      },
      {
        name: 'newsRelease',
        label: 'News release',
      },
      {
        name: 'corporateActionAnnouncement',
        label: 'Corporate action announcement',
      },
      {
        name: 'transactionAnnouncement',
        label: 'Transaction announcement',
      },
      {
        name: 'other',
        label: 'Other',
      },
    ],
  },
  {
    name: 'risks',
    title: 'C. What are the main risks to your thesis? (more than one allowed)',
    query: 'Please comment on said main risks to your thesis (optional)',
    options: [
      {
        name: 'strategyRisk',
        label: 'Strategy execution risk',
      },
      {
        name: 'transactionRisk',
        label: 'Transaction execution risk',
      },
      {
        name: 'customerRisk',
        label: 'Customer/product risk',
      },
      {
        name: 'marketRisk',
        label: 'Market risk',
      },
      {
        name: 'solvencyRisk',
        label: 'Solvency risk',
      },
      {
        name: 'liquidityRisk',
        label: 'Liquidity risk',
      },
      {
        name: 'valuationRisk',
        label: 'Valuation risk',
      },
      {
        name: 'esgRisk',
        label: 'ESG-related risk',
      },
      {
        name: 'other',
        label: 'Other',
      },
    ],
  },
];

export const businessQuestionsContent = {
  name: 'companyDescription',
  title: 'A. Please provide the company’s business description? (optional)',
};

export const companyQuestionsContent = {
  banner:
    'Understanding a company’s business model and the competitive environment it operates in are paramount to a good investment thesis. The Porter’s Five Forces Model is the gold standard framework for assessing a company’s competitive strength. It is also important to know the strengths and weaknesses of a company’s management.',
  rates: {
    title: 'A. Please rate the company with regards to Porter’s Five Forces (on a scale of up to 5: most favorable to the company)',
    options: [
      {
        name: 'competition',
        label: 'How favorable is the level of competition in the industry to the company?',
      },
      {
        name: 'entrants',
        label: 'How favorable are the barriers to new entrants into the industry to the company?',
      },
      {
        name: 'suppliers',
        label: 'How much power does the company have over its suppliers?',
      },
      {
        name: 'customers',
        label: 'How much (pricing) power does the company have over its customers?',
      },
      {
        name: 'threat',
        label: 'How well is the company protected from the threat of substitute products?',
      },
    ],
  },

  management: {
    name: 'management',
    title: 'C. Which of the following applies to the company’s management? (more than one allowed)',
    query: "Please comment on the company's management (optional)",
    options: [
      { name: 'visionary', label: 'Visionary' },
      { name: 'leadership', label: 'Strong leadership' },
      { name: 'solid', label: 'Solid' },
      { name: 'weak', label: 'Weak' },
      { name: 'lessRelevant', label: 'Less relevant' },
      { name: 'technical', label: 'Technical' },
      { name: 'political', label: 'Political' },
      { name: 'notConsidered', label: 'Have not considered' },
    ],
  },
};

export const financialsQuestionsContent = {
  banner:
    'Robust projections and forecasts of revenues, earnings, margins and cash flows are based on a thorough analysis of a company’s income statement, cash flow statement and balance sheet.',
  rates: {
    title: 'Rate the company on the following financial metrics',
    options: [
      {
        name: 'revenue',
        label: 'Expected revenue growth',
      },
      {
        name: 'earnings',
        label: 'Expected earnings growth',
      },
      {
        name: 'margins',
        label: 'Profit margins vs competitors',
      },
      {
        name: 'generation',
        label: 'Free cash flow generation',
      },
      {
        name: 'strength',
        label: 'Balance sheet strength',
      },
    ],
  },

  balanceSheet: {
    name: 'balanceSheet',
    title: ' What do you think of the company’s balance sheet - please check any statements that apply',
    query: "Please comment on the company's balance sheet (optional)",
    options: [
      {
        name: 'highDebtSustainable',
        label: 'High debt burden but sustainable',
      },
      {
        name: 'highDebtUnsustainable',
        label: 'High debt burden and potentially unsustainable',
      },
      {
        name: 'lowDebtNotAnIssue',
        label: 'Low debt burden, really not an issue',
      },
      {
        name: 'lowDebtIncreaseProfitability',
        label: 'Low debt burden, should be higher to increase profitability',
      },
      {
        name: 'withIndustryPeers',
        label: 'Average and in line with industry peers',
      },
      {
        name: 'upcomingDebtEasy',
        label: 'Upcoming debt maturity, should be easy to refinance',
      },
      {
        name: 'upcomingDebtChallenging',
        label: 'Upcoming debt maturity, refinancing may be challenging',
      },
    ],
  },
};

export const valuationQuestionsContent = {
  banner:
    'Different companies and situations may require different valuation techniques. The rationale behind a choice of such a technique is important as well as the conclusions drawn from a valuation.',
  methodology: {
    name: 'methodology',
    title: 'Which valuation methodology did you use?',
    query: 'Please comment on your valuation methodology (optional)',
    currentTrade: 'Which multiple does the company currently trade at (if applicable)?',
    options: [
      { name: 'peMultiple', label: 'P/E Multiple' },
      { name: 'evMultiple', label: 'EV/EBITDA Multiple' },
      { name: 'revenueMultiple', label: 'Revenue Multiple' },
      { name: 'dividendYeald', label: 'Dividend Yield' },
      { name: 'dcf', label: 'DCF' },
      { name: 'sumOfTheParts', label: 'Sum-of-the-Parts' },
      { name: 'assetValuation', label: 'Asset Valuation' },
      { name: 'other', label: 'Other' },
    ],
  },
  attractiveness: {
    name: 'attractiveness',
    title: 'Why do you find this stock’s valuation attractive (up to 5 reasons)?',
    query: 'Please comment on your valuation (optional)',
    options: [
      {
        name: 'fundamentalsCreatingMoreValue',
        label: 'The company´s stock is fairly valued by has many fundamental qualities that will keep creating more value',
      },
      { name: 'mustHave', label: 'This stock is fairly valued but a must have in a portfolio' },
      {
        name: 'underTheRadar',
        label: 'The stock is undervalued as it is under the radar for most investors',
      },
      {
        name: 'paysDividends',
        label: 'The stock is attractive for investors as it pays a regular dividend',
      },
      {
        name: 'undervaluedStock',
        label: 'The stock is undervalued and has great growth prespects which will justify a higher valuation',
      },
      {
        name: 'valueStock',
        label: 'The stock is a value stock, steady fundamentals but attractively priced',
      },
      { name: 'sectorDynamics', label: 'This stock is a good play on its sector dynamics' },
      { name: 'currentEconomy', label: 'This stock is a good play current economy' },
      { name: 'valuationVersusSector', label: 'Valuation versus the sector' },
      {
        name: 'valuationVersusCompetitors',
        label: 'Valuation versus the company’s direct competitors',
      },
      { name: 'valuationVersusMarket', label: 'Valuation versus the overal market' },
    ],
  },
};

export const esgQuestionsContent = {
  banner:
    'Please tell us what you think about the underlying company’s ESG-related efforts. If you are aware of any controversies surrounding the company that deserve public attention, please let us know.',
  radial: [
    {
      query: 'Please rate the company’s environmental efforts(’E’ of ESG)',
      name: 'environment',
      title: 'Please comment on the company’s environmental efforts (optional)',
      content: [
        {
          label: 'The company actively tries to reduce its CO2 emissions',
          field: 'co2Emissions',
        },
        {
          label: 'The company actively tries to be more energy efficient',
          field: 'energyEfficiency',
        },
        {
          label: 'The company actively tries to optimize its waste management',
          field: 'wasteManagement',
        },
      ],
    },
    {
      query: 'Please rate the company’s inclusion efforts(‘S’ of ESG)',
      name: 'inclusion',
      title: 'Please comment on the company’s inclusion efforts (optional)',
      content: [
        {
          label: 'The company is known for treating women fairly in terms of pay and career prospects',
          field: 'womenTreatment',
        },
        {
          label: 'The company is known for being an equal opportunity employer and for furthering diversity and inclusion',
          field: 'multiCulturality',
        },
      ],
    },
    {
      query: 'Please rate the company’s shareholder value creation(‘G’ of ESG)',
      name: 'shareholder',
      title: 'Please comment on the company’s shareholder value creation (optional)',
      content: [
        {
          label: 'How do you rate the company’s corporate governance?',
          field: 'corporateGovernance',
        },
        {
          label: 'How do you rate management’s focus on creating shareholder value?',
          field: 'shareholderValue',
        },
        {
          label: 'What do you think of the company’s effort to balance all stakeholders’ interests?',
          field: 'shareholderInterests',
        },
      ],
    },
  ],
  controversy: {
    name: 'controversy',
    title: 'Are there any controversies or other issues the public should be aware of?',
    query: 'Please comment on potential controversies (optional)',
    options: [
      { name: 'environmentalIssues', label: 'The company has caused serious environmental issues' },
      { name: 'legalIssues', label: 'There are material legal proceedings against the company' },
      { name: 'mistreatedEmployees', label: 'The company has knowingly mistreated employees' },
      { name: 'childLabour', label: 'The company uses child labour' },
      { name: 'harassment', label: 'The company’s management is involved in (sexual) harassment' },
      { name: 'governmentTarget', label: 'The company is the target of a government' },
      { name: 'shareholderValue', label: 'The company has not respected shareholder value' },
      { name: 'illegalActivity', label: 'The company is involved in an illegal activity' },
      { name: 'laborCondition', label: 'The company has unacceptable labor conditions' },
      { name: 'exploitingPeople', label: 'The company is exploiting people' },
      { name: 'productDefect', label: 'The company has a material product defect' },
      { name: 'discrimination', label: 'The company has a record of discrimination' },
      { name: 'none', label: 'None of the above' },
    ],
  },
};
