import { FC } from 'react';
import { IdeaStatus } from '../utils/enums';

type ColorMap = {
  [key in IdeaStatus]?: string;
};

interface IStatusDotProps {
  colorMap?: ColorMap;
  status: keyof typeof IdeaStatus;
}

export const StatusDot: FC<IStatusDotProps> = ({ status, colorMap }) => (
  <div className={`w-3 h-3 rounded-full ${colorMap?.[status] || ''}`} />
);

StatusDot.defaultProps = {
  colorMap: {
    [IdeaStatus.LIVE]: 'bg-green-500',
    [IdeaStatus.PENDING]: 'bg-yellow-500',
    [IdeaStatus.DECLINED]: 'bg-red-500',
  },
};
