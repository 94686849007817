import { Link } from 'react-router-dom';
import { CellType } from 'components/Table/types';
import { InsightRowDataType } from './types';
import { StatusDot } from 'components/common/StatusDot';
import { StatusColor } from 'utils/enums';
import { dateFormatter } from 'utils/dateFormat';
import { toFixedCustom } from 'utils/numberHelpers';

export const insightColumns: CellType[] = [
  { title: 'Date' },
  { title: 'Status' },
  { title: 'Symbol' },
  { title: 'Entry price' },
  { title: 'Last price' },
  { title: 'Allocation' },
  { title: 'Perf' },
  { title: 'Add updates' },
];

export const insightRowData: InsightRowDataType = {
  date: date => ({
    title: <span className={'font-medium'}>{dateFormatter(date, 'MM.dd.yyy')}</span>,
  }),
  status: status => ({
    title: (
      <span className={'flex items-center justify-center text-center'}>
        <StatusDot status={status as keyof typeof StatusColor} />
      </span>
    ),
  }),
  symbol: symbol => ({
    title: <span className={'font-medium'}>{symbol}</span>,
  }),
  entryPrice: entryPrice => ({ title: toFixedCustom(entryPrice, 2) }),
  lastPrice: lastPrice => ({ title: toFixedCustom(lastPrice, 2) }),
  allocation: totalStake => ({ title: totalStake }),
  perf: performance => {
    if (performance === null) return { title: '-' };

    return {
      title: (
        <span className={`font-medium ${performance > 0 ? 'text-green-500' : performance < 0 ? 'text-red-500' : ''}`}>
          {performance > 0 ? `+${toFixedCustom(performance, 2)}%` : `${toFixedCustom(performance, 2)}%`}
        </span>
      ),
    };
  },
  detailsLink: ideaId => ({
    title: (
      <Link
        to={`/idea/${ideaId}`}
        className={'px-3 py-1 text-xs font-light text-center text-white bg-indigo-500 rounded-md'}
      >
        Read more
      </Link>
    ),
  }),
};
