import { CalendarIcon, FlashIcon, LikeIcon, RouteIcon } from 'components/icons/contribution';
import { IIdeaStatsBannerProps } from './types';

export const IdeaStatsBanner: React.FC<IIdeaStatsBannerProps> = ({ entryPrice, currency, timeframe, classes }) => (
  <div className={`sm:flex ${classes}`}>
    <div className={'mt-2 border-b-2 sm:border-none sm:mt-0 sm:text-center sm:w-1/4'}>
      <div className={'justify-center m-auto sm:flex'}>
        <FlashIcon className={'mb-3 w-8 h-8 text-gray-900 opacity-25 stroke-current'} />
      </div>
      <p className={'text-lg'}>Eearnings</p>
      <p className={'text-xs text-indigo-700'}>CATALYST</p>
    </div>
    <div className={'mt-2 border-b-2 sm:border-none sm:mt-0 sm:text-center sm:w-1/4'}>
      <div className={'justify-center m-auto sm:flex'}>
        <RouteIcon className={'mb-3 w-8 h-8 text-gray-900 opacity-25 stroke-current'} />
      </div>
      <p className={'text-lg'}>
        {entryPrice} {currency}
      </p>
      <p className={'text-xs text-indigo-700'}>INCEPTION PRICE</p>
    </div>
    <div className={'mt-2 border-b-2 sm:border-none sm:mt-0 sm:text-center sm:w-1/4'}>
      <div className={'justify-center m-auto sm:flex'}>
        <LikeIcon className={'mb-3 w-8 h-8 text-gray-900 opacity-25 stroke-current'} />
      </div>
      <p className={'text-lg'}>High</p>
      <p className={'text-xs text-indigo-700'}>AG CONVICTION LEVEL</p>
    </div>
    <div className={'mt-2 border-b-2 sm:border-none sm:mt-0 sm:text-center sm:w-1/4'}>
      <div className={'justify-center m-auto sm:flex'}>
        <CalendarIcon className={'mb-3 w-8 h-8 text-gray-900 opacity-25 stroke-current'} />
      </div>
      <p className={'text-lg'}>{timeframe}</p>
      <p className={'text-xs text-indigo-700'}>TIMEFRAME</p>
    </div>
  </div>
);
