export const flatObject = (object: Record<string, any>): Record<string, any> =>
  Object.keys(object).reduce((acc, key) => ({ ...acc, [key]: object[key] }), {});

export const flatRootObject = (object: Record<string, any>): Record<string, any> =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  Object.keys(object).reduce((acc, key) => ({ ...acc, ...object[key] }), {});

export const getSymbolLogo = (tickerWebsite: string): string => `https://logo.clearbit.com/${tickerWebsite}`;

export const commarize = (input: number): string => {
  if (input && input >= 1e6) {
    const units = ['mm', 'B'];
    const order = Math.floor(Math.log(input) / Math.log(1000));
    const unitName = units[order - 1];
    if (unitName) {
      const num = Math.floor(input / 1000 ** order);
      return `${num}${unitName}`;
    }
    return input.toLocaleString();
  }
  return '0';
};
