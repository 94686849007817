import React, { useEffect } from 'react';
import mixpanel from 'utils/mixpanelIntegration';
import { TextArea, RateSelect } from 'components/fields';
import { Banner, CardWithTitle } from 'components/common';
import CheckboxList from 'components/common/Inputs/CheckboxList';
import { financialsQuestionsContent } from '../content';
import { IFinancialsSectionProps } from './types';

const Financials: React.FC<IFinancialsSectionProps> = ({ defaultValues, flowType }) => {
  useEffect(() => {
    mixpanel.dispatchEvent(`${flowType} idea: Financials`);
  }, []);

  return (
    <div id={'financials'} className={'pt-8'}>
      <CardWithTitle title={'Financials'}>
        <Banner
          className={'w-full p-2 mt-3 text-xs text-gray-800 bg-blue-100 border border-blue-600 rounded lg:text-sm'}
          textContent={financialsQuestionsContent.banner}
        />
        <div>
          <div className={'mt-6'}>
            <div className={'md:flex md:justify-between'}>
              <div className={'grid gap-4'}>
                {financialsQuestionsContent.rates.options.map((rateOption, index) => {
                  return (
                    <React.Fragment key={rateOption.name}>
                      <div className={'text-sm'}>
                        <span className={'font-normal text-indigo-500'}>
                          {index + 1} {'-->'}
                        </span>
                        <span className={'ml-3 text-blue-800'}>{rateOption.label}</span>
                      </div>
                      <RateSelect
                        defaultValue={defaultValues.rates[rateOption.name]}
                        rate={5}
                        name={`rates.${rateOption.name}`}
                        activeClass={'bg-indigo-100'}
                        sectionType={'financials'}
                        rules={{
                          validate: {
                            rated: value => Boolean(value) || 'You need to pick one',
                          },
                        }}
                      />
                    </React.Fragment>
                  );
                })}
              </div>
              <div className={'w-full my-6 md:w-1/2'}>
                <p className={'text-sm text-gray-700'}>
                  Please comment on your ratings of this financials with regards to Porter&apos;s Five Forces (optional)
                </p>
                <div>
                  <TextArea name={'financials.rates.description'} />
                </div>
              </div>
            </div>
          </div>

          <div className={'grid gap-4'}>
            <p className={'mt-6 text-sm font-semibold'}>{financialsQuestionsContent.balanceSheet.title}</p>
            <CheckboxList
              content={financialsQuestionsContent.balanceSheet}
              defaultValues={defaultValues}
              sectionType={'financials'}
            />
          </div>
        </div>
      </CardWithTitle>
    </div>
  );
};

export default Financials;
