import { MainReasonCoInvestProps } from './types';
import { ProgressBar } from 'components/common';
import { toFixedCustom } from 'utils/numberHelpers';
import { voteReasonsContent } from 'components/vote/data';

export const MainReasonCoInvest: React.FC<MainReasonCoInvestProps> = ({ mainReasonsAnswers }) => {
  const { questions, total } = mainReasonsAnswers;

  return (
    <div className={'bg-white p-8 rounded-md shadow-md'}>
      <h2 className={'mb-8 font-medium'}>Main reason(s) of the co-investment</h2>
      <ul>
        {Object.keys(questions).map((questionIndex, index) => {
          const parsedQuestionIndex = parseInt(questionIndex);
          const percentage = (100 / total) * questions[questionIndex];

          return (
            <li key={`mainReasonCoInvest-${index}`} className={'mb-6'}>
              <div className={'flex justify-between text-sm mb-4'}>
                <p>{voteReasonsContent[parsedQuestionIndex]}</p>
                <p className={'ml-2'}>{toFixedCustom(percentage, 1, 0)}%</p>
              </div>
              <ProgressBar rate={questions[questionIndex]} limit={total} />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default MainReasonCoInvest;
