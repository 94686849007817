import React from 'react';
import { StandardReactProps } from '../../../interfaces';

export const TrackRecordIcon = ({ className }: StandardReactProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' className={className}>
    <path d='M404.288 274.636H143.597a7.5 7.5 0 000 14.998h260.692a7.5 7.5 0 10-.001-14.998zM404.288 322.961H143.597a7.5 7.5 0 000 14.998h260.692a7.5 7.5 0 10-.001-14.998zM404.288 371.286H143.597a7.5 7.5 0 000 14.998h260.692a7.5 7.5 0 10-.001-14.998zM404.288 419.611H143.597a7.5 7.5 0 000 14.998h260.692a7.5 7.5 0 10-.001-14.998zM404.288 175.702h-95.793a7.5 7.5 0 000 14.998h95.793a7.5 7.5 0 000-14.998zM404.288 136.06h-95.793a7.5 7.5 0 000 14.998h95.793a7.5 7.5 0 000-14.998z' />
    <path d='M456.229 417.08a7.5 7.5 0 007.499-7.499V73.248c0-16.262-13.23-29.492-29.492-29.492h-14.264V29.492C419.973 13.23 406.743 0 390.481 0H77.764C61.502 0 48.272 13.23 48.272 29.492v409.26c0 16.262 13.23 29.492 29.492 29.492h14.263v14.263c0 16.262 13.23 29.492 29.492 29.492h312.716c16.262 0 29.492-13.23 29.492-29.492V444.64a7.5 7.5 0 00-14.998 0v37.868c0 7.992-6.502 14.493-14.493 14.493H121.52c-7.992 0-14.493-6.501-14.493-14.493V73.248c0-7.992 6.502-14.493 14.493-14.493h312.716c7.992 0 14.493 6.502 14.493 14.493v336.333a7.5 7.5 0 007.5 7.499zM92.027 73.248v379.998H77.764c-7.992 0-14.493-6.502-14.493-14.493V29.492c0-7.992 6.502-14.493 14.493-14.493h312.717c7.992 0 14.493 6.502 14.493 14.493v14.263H121.52c-16.262.001-29.493 13.231-29.493 29.493z' />
    <path d='M142.996 148.151a7.5 7.5 0 00-7.499 7.499v56.637a22.901 22.901 0 0020.466 22.826c2.829.309 83.658.011 83.778.01l.082-.009a22.9 22.9 0 0020.477-22.827v-97.813c0-12.661-10.3-22.961-22.961-22.961h-78.882c-12.661 0-22.961 10.3-22.961 22.961v5.871a7.5 7.5 0 0014.998 0v-5.871c0-4.39 3.572-7.962 7.962-7.962h78.882c4.39 0 7.962 3.571 7.962 7.962v76.779a64.904 64.904 0 00-20.28-14.509 32.075 32.075 0 005.004-17.208c0-17.714-14.412-32.126-32.127-32.126-17.714 0-32.126 14.412-32.126 32.126a32.073 32.073 0 005.004 17.208 64.883 64.883 0 00-20.281 14.51V155.65a7.497 7.497 0 00-7.498-7.499zm54.902-5.743c9.445 0 17.128 7.683 17.128 17.128a17.123 17.123 0 01-7.601 14.237c-2.819 1.891-6.113 2.891-9.527 2.891s-6.708-.999-9.526-2.891a17.121 17.121 0 01-7.601-14.237c0-9.445 7.683-17.128 17.127-17.128zm-14.739 45.655a31.977 31.977 0 0029.478 0c14.197 4.34 25.686 14.707 31.484 28.409a7.916 7.916 0 01-5.93 3.731c-.484.03-79.274.11-80.414.016l-.164-.015-.012-.001a7.918 7.918 0 01-5.927-3.731c5.799-13.702 17.288-24.069 31.485-28.409z' />
  </svg>
);
