import { useEffect } from 'react';
import { hot } from 'react-hot-loader/root';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { UserType } from 'utils/enums';
import mixpanel from 'utils/mixpanelIntegration';
import { GlobalLoader } from 'components';
import { PrivateRoute } from 'routers/PrivateRoute';

// Authentication part
import { AuthCallbackPage, AuthPage } from 'pages/authPage';
import { Logout } from 'components/Logout';
import { ScrollToTop } from 'components/ScrollToTop';

// Platform
import { useStoreActions } from 'store/hooks';
import { Page404 } from 'pages/Page404';
import { TermsAndConditionsPage } from 'pages/TermsAndConditionsPage';
import { AlphaFeedPage } from 'pages/AlphaFeedPage';
import { EditIdeaPage } from 'pages/EditIdeaPage';
import GenerateIdeaPage from 'pages/GenerationPage';
import { TopIdeasAndUsersPage } from 'pages/TopIdeasAndUsersPage';
import { WatchListPage } from 'pages/WatchListPage';
import { IdeaDraft } from 'components/IdeaDraft';
import { IdeaPage } from 'pages/IdeaPage';
import { InsightsPage } from 'pages/InsightsPage';
import { LeaderBoardPage } from 'pages/leaderboardPage';

import { MyAlphaSwapPage } from 'pages/MyAlphaSwapPage';
import { UserPage } from 'pages/UserPage';
import { DashboardPage, IdeaManagementPage, TrackRecordPage } from 'pages/MyAlphaSwapPage/parts';
import { OnboardingPage } from 'pages/onboardingPage';
import { SettingsPage } from 'pages/SettingsPage';
import { Symbol } from 'pages/stockPage';
import { UserPageDeprecated } from 'pages/MyAlphaSwapPage/parts/UserPageDeprecated';
import { VoteIdeaPage } from 'pages/VoteIdeaPage';
import { ReportPage } from 'pages/ReportPage';

const App = () => {
  const { setLoader } = useStoreActions(actions => actions.appStore);

  useEffect(() => {
    mixpanel.init();
  }, []);

  useEffect(() => {
    setLoader(false);
  }, [setLoader]);

  return (
    <div className={'app'}>
      <GlobalLoader />
      <Router>
        <ScrollToTop>
          <Switch>
            <Route path={'/auth/callback'}>
              <AuthCallbackPage />
            </Route>

            <Route path={'/auth'}>
              <AuthPage />
            </Route>

            <Route path={'/logout'}>
              <Logout />
            </Route>

            {/* ONBOARDING */}
            <Route path={'/onboarding'}>
              <PrivateRoute>
                <OnboardingPage />
              </PrivateRoute>
            </Route>

            {/* PLATFORM */}
            <Route path={'/insights'}>
              <PrivateRoute userTypes={[UserType.USER]}>
                <InsightsPage />
              </PrivateRoute>
            </Route>
            <Route path={'/leaderboard'}>
              <PrivateRoute userTypes={[UserType.USER]}>
                <LeaderBoardPage />
              </PrivateRoute>
            </Route>

            <Route path={'/idea/draft'}>
              <PrivateRoute>
                <IdeaDraft />
              </PrivateRoute>
            </Route>

            <Route path={'/idea/generate/:section'}>
              <PrivateRoute userTypes={[UserType.USER]}>
                <MyAlphaSwapPage hideTab raducedTopMargin>
                  <GenerateIdeaPage />
                </MyAlphaSwapPage>
              </PrivateRoute>
            </Route>

            <Route path={'/idea/:id/edit'}>
              <PrivateRoute userTypes={[UserType.USER]}>
                <MyAlphaSwapPage hideTab raducedTopMargin>
                  <EditIdeaPage />
                </MyAlphaSwapPage>
              </PrivateRoute>
            </Route>

            <Route path={'/idea/:id/vote'}>
              <PrivateRoute userTypes={[UserType.USER]}>
                <VoteIdeaPage />
              </PrivateRoute>
            </Route>

            <Route path={'/idea/:id'}>
              <PrivateRoute>
                <IdeaPage />
              </PrivateRoute>
            </Route>

            <Route path={'/settings/:section'}>
              <PrivateRoute>
                <SettingsPage />
              </PrivateRoute>
            </Route>

            <Route path={'/stock/:symbol'}>
              <PrivateRoute>
                <Symbol />
              </PrivateRoute>
            </Route>

            <Route path={'/my-alphaswap/idea-management'}>
              <PrivateRoute userTypes={[UserType.USER]}>
                <MyAlphaSwapPage>
                  <IdeaManagementPage />
                </MyAlphaSwapPage>
              </PrivateRoute>
            </Route>

            <Route path={'/my-alphaswap/track-record'}>
              <PrivateRoute userTypes={[UserType.USER]}>
                <MyAlphaSwapPage>
                  <TrackRecordPage />
                </MyAlphaSwapPage>
              </PrivateRoute>
            </Route>

            <Route path={'/my-alphaswap'}>
              <PrivateRoute userTypes={[UserType.USER]}>
                <MyAlphaSwapPage>
                  <DashboardPage />
                </MyAlphaSwapPage>
              </PrivateRoute>
            </Route>

            <Route path={'/track-record/:link'}>
              <MyAlphaSwapPage>
                <UserPageDeprecated page='trackRecordPage' />
              </MyAlphaSwapPage>
            </Route>

            <Route path={'/user/:id'}>
              <PrivateRoute>
                <MyAlphaSwapPage hideTab>
                  <UserPage />
                </MyAlphaSwapPage>
              </PrivateRoute>
            </Route>

            <Route path={'/top'}>
              <PrivateRoute userTypes={[UserType.CUSTOMER]}>
                <TopIdeasAndUsersPage />
              </PrivateRoute>
            </Route>

            <Route path={'/watchlist'}>
              <PrivateRoute userTypes={[UserType.CUSTOMER]}>
                <WatchListPage />
              </PrivateRoute>
            </Route>

            <Route path={'/report'}>
              <PrivateRoute>
                <ReportPage />
              </PrivateRoute>
            </Route>

            <Route path={'/terms-and-conditions'}>
              <TermsAndConditionsPage />
            </Route>

            <Route path={'/:404'}>
              <Page404 />
            </Route>

            <Route path={'/'}>
              <PrivateRoute>
                <AlphaFeedPage />
              </PrivateRoute>
            </Route>
          </Switch>
        </ScrollToTop>
      </Router>
    </div>
  );
};

export default hot(App);
