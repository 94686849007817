import { useEffect, useState } from 'react';
import { getRegionNameByCode } from 'utils/countryHelpers';
import { watchListApi } from 'api/watchList';
import { IGetWatchListInterface } from 'api/types';
import { FavoriteIdeasType } from 'utils/enums';
import { Table } from 'components';
import { closedIdeaRowData, closedIdeasColumns } from './data';
import { IFavorite } from 'interfaces';
import { CellType, TablePaginationActionInterface, TablePaginationActionTypeEnum } from 'components/Table/types';
import { CellFunction, ClosedIdeaCellDataType } from './types';

const ROWS_PER_PAGE = 10;

const ClosedIdeasTable: React.FC = () => {
  const [tableData, setTableData] = useState<IGetWatchListInterface>({
    count: 0,
    offset: 0,
    rows: [],
  });

  const loadTableData = async (offset: number, limit: number) => {
    try {
      const data = await watchListApi.get({
        offset,
        limit,
        status: [FavoriteIdeasType.CLOSED],
      });

      setTableData(data);
    } catch (err) {
      console.error(new Error(err));
    }
  };

  const handleTableChange = ({ type }: TablePaginationActionInterface) => {
    if (type === TablePaginationActionTypeEnum.next)
      loadTableData(tableData.offset + ROWS_PER_PAGE, ROWS_PER_PAGE).catch(err => console.error(new Error(err)));

    if (type === TablePaginationActionTypeEnum.previous)
      loadTableData(tableData.offset - ROWS_PER_PAGE, ROWS_PER_PAGE).catch(err => console.error(new Error(err)));
  };

  const transformList = (closedIdeas: IFavorite[]): CellType[][] => {
    const cellsData: ClosedIdeaCellDataType[][] = closedIdeas.map(({ ideaId, idea, createdAt }) => [
      {
        cellName: 'date',
        cellValue: createdAt,
      },
      {
        cellName: 'symbol',
        cellValue: idea.security.symbol,
      },
      {
        cellName: 'geo',
        cellValue: getRegionNameByCode(idea.security.region).label,
      },
      {
        cellName: 'sector',
        cellValue: idea.security.sector,
      },
      {
        cellName: 'entryPrice',
        cellValue: idea.entryPrice,
      },
      {
        cellName: 'exitPrice',
        cellValue: idea.closedPrice,
      },
      {
        cellName: 'lastPrice',
        cellValue: idea.closedPrice,
      },
      {
        cellName: 'performance',
        cellValue: idea.performance,
      },
      {
        cellName: 'detailsLink',
        cellValue: ideaId,
      },
    ]);

    return cellsData.map((row: ClosedIdeaCellDataType[]) =>
      row.map(({ cellName, cellValue }): CellType => {
        const cellFunc: CellFunction = closedIdeaRowData[cellName];

        return cellFunc(cellValue);
      })
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      await loadTableData(0, ROWS_PER_PAGE);
    };

    fetchData();
  }, []);

  return (
    <div className={'mb-12'}>
      <Table
        title={'Closed Ideas'}
        columns={closedIdeasColumns}
        date={new Date().toDateString()}
        data={transformList(tableData.rows)}
        total={tableData.count}
        offset={tableData.offset}
        onPageChange={handleTableChange}
      />
    </div>
  );
};

export default ClosedIdeasTable;
