import { Checkbox as CheckboxMui, FormControlLabel } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import { ICheckboxProps } from './types';

export const Checkbox: React.FC<ICheckboxProps> = ({ label, name, rules, defaultValue, onChange, labelClasses }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ onChange: onCheckChange, value, ...other }: any) => {
        return (
          <FormControlLabel
            className={labelClasses}
            onChange={(e, isChecked) => {
              onChange?.(isChecked);
              return onCheckChange(isChecked);
            }}
            checked={value}
            control={<CheckboxMui />}
            key={name}
            label={label}
            {...other}
          />
        );
      }}
    />
  );
};
