import { useFormContext } from 'react-hook-form';
import { CreditsCard } from 'containers/CreditsCard';
import { useStoreState } from 'store/hooks';
import { Banner, CardWithTitle } from 'components/common';
import { Checkbox } from 'components/common/Inputs/Checkbox';
import { CountInput } from 'components/fields';

type IVoteCreditsProps = {
  stake: number;
  isTncAccepted: boolean;
};

export const VoteCredits: React.FC<IVoteCreditsProps> = ({ stake, isTncAccepted }) => {
  const { errors } = useFormContext();
  const { wallet } = useStoreState(state => state.myAlphaswapStore);
  const totalCredits = wallet.credits + wallet.stakedCredits;

  return (
    <div className={'pt-8'}>
      <CardWithTitle title={'Virtual dollars'}>
        <div className={'grid gap-5'}>
          <Banner
            className={'w-full p-2 text-xs text-gray-800 bg-blue-100 border border-blue-600 rounded lg:text-sm'}
            textContent={
              'How many virtual dollars out of your virtual portfolio you commit gives us an idea of your conviction in the investment idea you want to co-invest in.'
            }
          />

          <p className={'py-3'}>Please allocate virtual dollars to this co-investment.</p>

          <div className={'items-center sm:justify-between sm:flex'}>
            <CreditsCard title={'VIRTUAL DOLLARS BALANCE'} color={'text-gray-700'} value={totalCredits} />
            <CreditsCard title={'VIRTUAL DOLLARS STAKED'} color={'text-yellow-500'} value={wallet.stakedCredits} />
            <CreditsCard title={'VIRTUAL DOLLARS AVAILABLE'} color={'text-green-500'} value={wallet.credits} />
          </div>

          <div className={'mt-6'}>
            <p className={'mb-4 text-sm text-blue-700'}>How many virtual dollars do you want to commit?</p>
            <CountInput
              name={'stake'}
              defaultValue={stake}
              rules={{
                required: {
                  value: true,
                  message: 'This field is required',
                },
                min: {
                  value: 1,
                  message: 'Min value is 1',
                },
                max: {
                  value: wallet.credits,
                  message: "You don't have sufficient balance",
                },
              }}
            />
          </div>
        </div>
      </CardWithTitle>
      <div className='flex flex-col items-end'>
        <Checkbox
          labelClasses={'mr-0'}
          label={"I confirm that this submission conforms with AlphaSwap's terms and conditions"}
          name={'isTncAccepted'}
          rules={{
            required: {
              value: true,
              message: 'You must confirm T&C',
            },
          }}
          defaultValue={isTncAccepted}
        />
        {errors.isTncAccepted && <p className={'text-red-500'}>{errors.isTncAccepted.message}</p>}
      </div>
    </div>
  );
};
