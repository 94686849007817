import React from 'react';
import { CloseIcon, LinkIcon } from '../../../components/icons/track-record';

interface TrackRecordModalProps {
  onClose: () => void;
  onShare: () => void;
  onDelete: () => void;
  shareLink: string;
}

export const TrackRecordModal = ({ onClose, shareLink, onShare, onDelete }: TrackRecordModalProps) => (
  <div className='fixed inset-0 flex justify-center items-center'>
    <div className='bg-white z-10 rounded-lg max-w-2xl w-full p-4 m-4 grid grid-cols-1fr gap-4'>
      <div className='border-b flex justify-between items-center'>
        <p className='capitalize text-xl'>Track record sharing</p>
        <button type='button' onClick={onClose}>
          <CloseIcon />
        </button>
      </div>
      <div className='flex justify-center'>
        <div className='text-center'>
          <button
            type='button'
            className='w-12 h-12 rounded-full bg-gray-300 flex justify-center items-center'
            disabled={shareLink.length > 0}
            onClick={onShare}
          >
            <LinkIcon />
          </button>
          <p className='text-sm'>Link</p>
        </div>
      </div>
      {shareLink.length > 0 && (
        <div className='grid grid-cols-auto-second justify-self-center'>
          <input
            readOnly
            value={`${window.location.origin}/track-record/${shareLink}`}
            className='text-center border border-gray-600 rounded-lg px-2'
          />
          <button type='button' onClick={onDelete}>
            <CloseIcon />
          </button>
        </div>
      )}
    </div>
    <button type='button' onClick={onClose} className='fixed bg-gray-600 opacity-50 inset-0 w-full h-full' />
  </div>
);
