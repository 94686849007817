import React, { useEffect, useState } from 'react';
import { useStoreActions } from 'store/hooks';
import { Container } from '../../components/common';
import { CheverondownIcon, CheveronupIcon } from '../../components/icons/generate';
import { SortIcon } from '../../components/icons/leaderBoard/SortIcon';
import { Layout } from '../../components';
import { LeaderboardTable } from '../../components/leaderboard';
import { LeaderboardObject } from '../../interfaces';
import { orderASC, orderDESC } from './helper';

type Order = 'ASC' | 'DESC' | null;

export const LeaderBoardPage = () => {
  const { loadLeaderBoardThunk } = useStoreActions(actions => actions.leaderboardStore);

  const [leaderBody, setLeaderBody] = useState<LeaderboardObject[]>([]);
  const [order, setOrder] = useState<Order>(null);

  useEffect(() => {
    loadLeaderBoardThunk().then((res: any) => {
      setLeaderBody(res);
    });
    // eslint-disable-next-line
  }, []);

  const handleChangeOrder = () => {
    switch (order) {
      case 'ASC':
        setOrder('DESC');
        break;
      case 'DESC':
        setOrder(null);
        break;
      case null:
        setOrder('ASC');
        break;
      default:
        setOrder(null);
        break;
    }
  };

  const userCell = (item: any) => (
    <div className='flex items-center'>
      <img src={item.avatar} alt='avatar' className='object-center w-6 h-6 mr-3 rounded-full' />
      {item.name}
    </div>
  );

  function buildLeaderData() {
    const data = leaderBody?.map((item: any) => ({
      data: [
        userCell({ avatar: item.profileUrl, name: item.name }),
        `${item.ideas}`,
        `${item.votes}`,
        `${item.esgRate}`,
        item.AVERF ? `${item.AVERF}%` : '_',
        item.ITDPERF ? `${item.ITDPERF}%` : '_',
        item.credits,
        item.id,
      ],
    }));
    if (order === 'ASC') {
      return orderASC(data);
    }
    if (order === 'DESC') {
      return orderDESC(data);
    }
    return data;
  }

  return (
    <Layout>
      <div className='flex items-center justify-center flex-1'>
        <Container>
          <div className='items-center justify-between sm:flex'>
            <h2 className='text-indigo-800 lg:text-xl'>Community Leaderboard</h2>
            <div className='my-2'>
              <button type='button' className='flex items-center' onClick={handleChangeOrder}>
                <div className='w-6 h-6 p-1 mr-2 overflow-hidden bg-indigo-100 border border-indigo-200 rounded-full sm:w-8 sm:h-8 sm:p-2 sm:mx-4 '>
                  <SortIcon className='w-full h-full text-indigo-600 fill-current' />
                </div>
                <h2 className='text-sm text-indigo-800 sm:text-base lg:text-xl'>Sort by virtual dollars</h2>
                {order ? (
                  <>
                    {order === 'ASC' && (
                      <CheverondownIcon
                        className='w-8 h-8 text-center text-indigo-800 fill-current sm:w-10 sm:h-10'
                        viewbox='-4 -4 30 30'
                      />
                    )}
                    {order === 'DESC' && (
                      <CheveronupIcon className='w-8 h-8 text-center text-indigo-800 fill-current sm:w-10 sm:h-10' viewbox='-3 3 30 16' />
                    )}
                  </>
                ) : (
                  <div className='w-8 h-8 text-center fill-current sm:w-10 sm:h-10' />
                )}
              </button>
            </div>
          </div>

          <div className='overflow-x-scroll p-4 mt-10 bg-white border border-indigo-100 rounded-md'>
            <LeaderboardTable data={buildLeaderData()} />
          </div>
        </Container>
      </div>
    </Layout>
  );
};
