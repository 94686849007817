import { useParams } from 'react-router-dom';
import { useStoreActions } from 'store/hooks';
import { watchListApi } from 'api/watchList';
import { IdeaHeaderProps } from './types';

const IdeaHeader = ({ name, exchangeSymbol, region, sector, isFavorite }: IdeaHeaderProps) => {
  const { id } = useParams<{ id: string }>();
  const { loadIdeaThunk } = useStoreActions(actions => actions.ideaStore);

  const watchListHandler = async () => {
    const parsedId = parseInt(id);
    try {
      isFavorite ? await watchListApi.removeIdea(parsedId) : await watchListApi.addIdea(parsedId);
      await loadIdeaThunk(parsedId);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <header className='flex justify-between items-center'>
      <div className='title'>
        <h2 className='stock-name text-lg font-semibold'>{name}</h2>
        <div className='breadcrumbs font-light'>{`${exchangeSymbol} > ${region} > ${sector}`}</div>
      </div>
      <div className='cta'>
        <button
          onClick={watchListHandler}
          className='px-4 py-2 text-xs font-light text-center text-white bg-blue-500 rounded-md focus:outline-none whitespace-nowrap hover:bg-blue-700 duration-300'
        >
          {isFavorite ? 'Remove from watchlist' : '+Add to watchlist'}
        </button>
      </div>
    </header>
  );
};
export default IdeaHeader;
