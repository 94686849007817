import { useLayoutEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Banner, CardWithTitle } from 'components/common';
import { RadioButtonsOptions, CountInput } from 'components/fields';
import { stockAttributesAspectOptions } from './data';

type Typee = {
  aspect: boolean;
  upside: number;
  downside: number;
};

export const StocksAttributes: React.FC<Typee> = ({ aspect, upside, downside }) => {
  const { watch, setValue } = useFormContext();

  useLayoutEffect(() => {
    setValue('aspect', aspect);
  }, []);

  const aspectWatch = watch('aspect');

  return (
    <div className={'pt-8'}>
      <CardWithTitle title={'Stock attributes'}>
        <div className={'grid gap-5'}>
          <Banner
            className={'w-full p-2 text-xs text-gray-800 bg-blue-100 border border-blue-600 rounded lg:text-sm'}
            textContent={
              'Please let us know whether you agree with the idea generator’s assessment of the upside and downside of the stock underlying this investment idea. If you disagree, please tell us your estimates.'
            }
          />

          <div className={'grid gap-2'}>
            <p className={'text-sm font-semibold'}>
              What is the upside and downside of the underlying stock in terms of price level?
            </p>

            <RadioButtonsOptions
              name={'aspect'}
              options={stockAttributesAspectOptions}
              defaultValue={aspect}
              rules={{
                validate: {
                  required: value => typeof value === 'boolean' || 'You must select one point',
                },
              }}
            />

            {!aspectWatch && (
              <div className={'grid gap-4 ml-9'}>
                <div>
                  <div className={'mb-2 text-sm'}>
                    <span className={'font-normal text-indigo-500'}>1{' -->'}</span>
                    <span className={'text-gray-900'}>Upside</span>
                  </div>

                  <CountInput
                    name={'upside'}
                    defaultValue={upside}
                    rules={{
                      required: {
                        value: true,
                        message: 'This field is required',
                      },
                      validate: {
                        positive: value => value >= 0 || 'Value must be positive',
                      },
                    }}
                  />
                </div>

                <div>
                  <div className={'mb-2 text-sm'}>
                    <span className={'font-normal text-indigo-500'}>2{' -->'}</span>
                    <span className={'text-gray-900'}>Downside</span>
                  </div>

                  <CountInput
                    name={'downside'}
                    defaultValue={downside}
                    rules={{
                      required: {
                        value: true,
                        message: 'This field is required',
                      },
                      validate: {
                        positive: value => value >= 0 || 'Value must be positive',
                      },
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </CardWithTitle>
    </div>
  );
};
