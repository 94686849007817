import React from 'react';
import { CheckmarkIcon } from 'components/icons/generate';
import { CloseoutlineIcon } from 'components/icons/header';
import { useStoreActions } from 'store/hooks';
import { ResponseStatusCode } from 'utils/enums';
import '../index.scss';

interface RequestNoteProps {
  notificationId: number;
}
export const RequestNote = (props: RequestNoteProps) => {
  const { notificationId } = props;
  const { readAllNotificationsThunk, readNotificationThunk, deleteNotificationThunk } = useStoreActions(
    actions => actions.notificationStore
  );

  async function handleReadNotification(id: any) {
    // eslint-disable-next-line
    const responseStatus = await readNotificationThunk(id);
    // if (responseStatus === ResponseStatusCode.OK) {
    //   await readAllNotificationsThunk();
    // }
  }

  async function handleDeleteNotification(id: any) {
    const responseStatus = await deleteNotificationThunk(id);
    if (responseStatus === ResponseStatusCode.OK) {
      await readAllNotificationsThunk();
    }
  }
  return (
    <div className='absolute z-10 w-56 mt-10 text-sm bg-white border rounded shadow-2xl ml-14 NoteMenu'>
      <button
        type='button'
        onClick={() => {
          handleReadNotification(notificationId);
        }}
        className='flex items-center w-full p-2 border-b hover:bg-gray-100 focus:outline-none'
      >
        <CheckmarkIcon className='w-5 h-5 ml-2 text-gray-500 fill-current' />
        <p className='ml-4 text-gray-800 '>Mark as read</p>
      </button>
      <button
        type='button'
        onClick={() => {
          handleDeleteNotification(notificationId);
        }}
        className='flex items-center w-full p-2 border-b hover:bg-gray-100 focus:outline-none'
      >
        <CloseoutlineIcon className='w-5 h-5 ml-2 text-gray-500 fill-current' />
        <p className='ml-4 text-gray-800 '>Delete this notification</p>
      </button>
    </div>
  );
};
