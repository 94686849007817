import { useEffect } from 'react';
import { Banner, CardWithTitle } from 'components/common';
import mixpanel from 'utils/mixpanelIntegration';
import CheckboxList from 'components/common/Inputs/CheckboxList';
import { thesisQuestionsContent } from '../content';

interface IThesisQuestionOption {
  name: string;
  label: string;
  options?: string[];
}

interface IThesisQuestion {
  name: string;
  title: string;
  query: string;
  options: IThesisQuestionOption[];
}

interface IThesisSectionProps {
  defaultValues: any;
  flowType?: string;
}

const Thesis: React.FC<IThesisSectionProps> = ({ defaultValues, flowType }) => {
  useEffect(() => {
    mixpanel.dispatchEvent(`${flowType} idea: Thesis`);
  }, []);

  const thesisQuestions = thesisQuestionsContent as IThesisQuestion[];

  return (
    <div id={'thesis'} className={'pt-8'}>
      <CardWithTitle title={'Thesis'}>
        <Banner
          className='w-full p-2 mt-3 text-xs text-gray-800 bg-blue-100 border border-blue-600 rounded lg:text-sm'
          textContent={
            'When a stock is mispriced at its current price level it is either cheap or rich compared to its true value. This opens up a window of opportunity to go long or short. The reasons for such mispricing could be fundamental, technical or stem from a special situation or corporate action. It is important to be aware of catalysts that could trigger a re-pricing of the stock and of risks to the underlying investment thesis.'
          }
        />

        {thesisQuestions.map((question: any) => {
          const { title, name } = question;

          return (
            <div key={name}>
              <h4 className={'mt-2'}>{title}</h4>
              <CheckboxList content={question} defaultValues={defaultValues} sectionType={'thesis'} />
            </div>
          );
        })}
      </CardWithTitle>
    </div>
  );
};

export default Thesis;
