import { useStoreState } from 'store/hooks';
import { getRegionNameByCode } from 'utils/countryHelpers';
import { Pill } from 'components/common';

export const GeneralInfo: React.FC = () => {
  const { name, sector, region, currency, openPrice, closePrice } = useStoreState(
    state => state.generationStore.security
  );
  const regionValue = getRegionNameByCode(region).label;

  return (
    <div>
      <div className={'px-4'}>
        <h2 className={'text-3xl text-blue-600'}>{name}</h2>

        <Pill color={'indigo'} size={'medium'} classes={'w-full my-3 sm:w-2/3'} textContent={sector} />
        <Pill color={'gray'} size={'medium'} classes={'w-full mt-3 sm:w-2/3'} textContent={regionValue} />

        <div className={'grid grid-cols-2 gap-10 mt-6'}>
          <div className={'relative inline-block'}>
            <h4 className={'my-2 mt-8 text-xs font-light text-gray-500'}>ACTUAL PRICE</h4>
            <div className={'text-2xl leading-none text-blue-600'}>{openPrice}</div>

            <div className={'absolute bottom-0 right-0 text-xs font-light text-blue-600'} style={{ right: -30 }}>
              {currency}
            </div>
          </div>
        </div>

        <div className={'grid grid-cols-2 gap-10 mt-6'}>
          <div className={'relative inline-block'}>
            <h4 className={'my-2 text-xs font-light text-gray-500'}>OPEN</h4>
            <div className={'text-2xl leading-none text-blue-600'}>{openPrice}</div>

            <div className={'absolute bottom-0 right-0 text-xs font-light text-blue-600'} style={{ right: -30 }}>
              {currency}
            </div>
          </div>

          <div className={'relative inline-block'}>
            <h4 className={'my-2 text-xs font-light text-gray-500'}>CLOSE</h4>
            <div className={'text-2xl leading-none text-blue-600'}>{closePrice}</div>

            <div className={'absolute bottom-0 right-0 text-xs font-light text-blue-600'} style={{ right: -30 }}>
              {currency}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
