import classNames from 'classnames';
import { format } from 'date-fns';
import { ICommentBlockProps } from './types';

export const CommentBlock: React.FC<ICommentBlockProps> = ({ comment, user, last }) => (
  <div className={classNames('py-4 sm:grid grid-cols-auto-first gap-4', { 'mb-10': last })}>
    <div className={'justify-center py-2 text-center sm:py-0 w-1/7 sm:flex'}>
      <img src={user.profilePicture ?? ''} alt={'avatar'} className={'object-center w-16 h-16'} />
    </div>

    <div className={'pt-2 text-md sm:w-5/7'}>
      <p className={'text-gray-800'}>
        <p>{`${comment.author.nickname ? comment.author.nickname : comment.author.firstName}`}</p>
        <span className={'text-gray-500'}>{comment.content}</span>
      </p>

      <p className={'mt-5 text-sm sm:text-base'}>created at {format(new Date(comment.createdAt), 'Pp')}</p>
    </div>
  </div>
);
