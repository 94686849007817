import React from 'react';
import { StandardReactProps } from '../../../interfaces';

export const TargetIcon = ({ className }: StandardReactProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='19.364' height='19.36' className={className}>
    <g data-name='组 1608'>
      <g data-name='组 1607'>
        <path
          d='M14.18 8.512a.457.457 0 00-.85.336 5.016 5.016 0 11-2.819-2.82.457.457 0 00.338-.849 5.923 5.923 0 103.331 3.333z'
          data-name='路径 767'
        />
      </g>
    </g>
    <g data-name='组 1610'>
      <g data-name='组 1609'>
        <path
          d='M17 8.303a.457.457 0 10-.878.251 7.746 7.746 0 11-5.313-5.316.457.457 0 10.252-.878A8.659 8.659 0 1017 8.303z'
          data-name='路径 768'
        />
      </g>
    </g>
    <g data-name='组 1612'>
      <g data-name='组 1611'>
        <path
          d='M11.849 10.414a.457.457 0 10-.91.081c.006.065.009.132.009.2a2.28 2.28 0 11-2.28-2.28c.07 0 .139 0 .2.009a.457.457 0 00.084-.91 3.007 3.007 0 00-.288-.013 3.194 3.194 0 103.193 3.194 2.615 2.615 0 00-.008-.281z'
          data-name='路径 769'
        />
      </g>
    </g>
    <g data-name='组 1614'>
      <g data-name='组 1613'>
        <path
          d='M19.294 3.071a.911.911 0 00-.844-.564h-1.593V.914a.914.914 0 00-1.56-.646L12.635 2.93a1.361 1.361 0 00-.4.969v2.585l-3.716 3.712a.457.457 0 10.646.646l3.713-3.713h2.585a1.361 1.361 0 00.969-.4l2.662-2.662a.911.911 0 00.2-.996zm-6.146.827a.454.454 0 01.134-.323L15.943.914v1.86l-2.8 2.8V3.898zm2.64 2.184a.46.46 0 01-.323.134h-1.671l2.8-2.8h1.86z'
          data-name='路径 770'
        />
      </g>
    </g>
  </svg>
);
