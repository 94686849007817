import { useFormContext, Controller, RegisterOptions } from 'react-hook-form';
import { Checkbox, FormControlLabel } from '@material-ui/core';

interface IThesisDetailsSelectorProps {
  name: string;
  nameCustom?: string;
  nameDescription: string;
  options: string[];
  rules?: RegisterOptions;
  defaultValue?: Record<string, unknown>; // Any object
  title?: string;
  nameCurrent?: string;
}

export const CheckboxWithComment: React.FC<IThesisDetailsSelectorProps> = ({
  name,
  nameCustom,
  nameDescription,
  options,
  rules,
  defaultValue,
  title,
  nameCurrent,
}) => {
  const { control, errors } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={defaultValue ?? {}}
      render={({ value, onChange }) => (
        <div className='grid w-full gap-4'>
          <div className='grid gap-4 md:grid-cols-2'>
            <div className='grid gap-4'>
              {options?.map((option, index) => (
                <FormControlLabel
                  key={option}
                  label={option}
                  checked={value[name]?.includes(index)}
                  onChange={(e, isChecked) => {
                    const checkboxesArray: number[] = value[name];
                    const result = {
                      ...value,
                      [name]: isChecked ? [...checkboxesArray, index] : checkboxesArray.filter(val => val !== index),
                    };

                    console.info(result);
                    onChange(result);
                  }}
                  control={<Checkbox />}
                />
              ))}
            </div>

            {/* TEXT */}
            {value[name]?.length > 0 && (
              <div className='w-full'>
                <p className='pb-2 text-sm text-gray-700 font-semiboldtext-sm'>{title}</p>
                <textarea
                  defaultValue={value[nameDescription]}
                  onChange={({ target }) =>
                    onChange({
                      ...value,
                      [nameDescription]: target.value,
                    })
                  }
                  className='w-full h-48 p-4 text-xs text-gray-800 border border-indigo-500 rounded-md'
                  placeholder='Fill your research here'
                />
              </div>
            )}
          </div>
          {errors[nameDescription] && <p className='text-xs text-red-600'>{errors[nameDescription].message}</p>}
          {/* CUSTOM INPUT */}
          {nameCustom && (
            <div>
              <input
                defaultValue={value[nameCustom]}
                onChange={({ target }) =>
                  onChange({
                    ...value,
                    [nameCustom]: target.value,
                  })
                }
                className='w-full px-4 py-2 text-xs text-gray-800 border border-gray-600 rounded-md'
                placeholder='Other? Please input here...'
              />
            </div>
          )}

          {/* NEW CUSTOM INPUT */}
          {nameCurrent && (
            <div>
              <p className='py-2 text-sm font-semibold'>
                Which multiple does the company currently trade at (if applicable)?
              </p>
              <input
                defaultValue={value[nameCurrent]}
                onChange={({ target }) =>
                  onChange({
                    ...value,
                    [nameCurrent]: target.value,
                  })
                }
                className='w-full px-4 py-2 text-xs text-gray-800 border border-gray-600 rounded-md'
                placeholder=''
              />
            </div>
          )}
          {errors[name] && <p className='text-xs text-red-600'>{errors[name].message}</p>}
        </div>
      )}
    />
  );
};

CheckboxWithComment.defaultProps = {
  defaultValue: undefined,
  nameCurrent: '',
  nameCustom: undefined,
  rules: undefined,
  title: '',
};
