import React, { useState, useEffect } from 'react';
import { NoteSettings } from './NoteSettings';
import { useStoreActions, useStoreState } from '../../store/hooks';
import { Notification } from './Notification';

export const NoteDropdown = () => {
  const { loadNotificationsThunk } = useStoreActions(actions => actions.notificationStore);
  const { notifications } = useStoreState(state => state.notificationStore);
  const [notesetting, setNotesetting] = useState(false);

  function showNotesetting() {
    setNotesetting(!notesetting);
  }

  useEffect(() => {
    loadNotificationsThunk();
  }, [loadNotificationsThunk]);

  return (
    <div className='absolute right-0 z-50 mt-6 text-sm bg-white border rounded shadow-2xl ' style={{ width: '260px' }}>
      <div className='flex items-center justify-between hover:bg-indigo-100 relative h-8'>
        <div className='cursor-pointer flex-1 text-center'>
          <p className='text-gray-800 '>Notifications</p>
        </div>
        <button
          type='button'
          className='w-8 h-8 rounded-full cursor-pointer hover:bg-gray-300 absolute right-0 top-0 bottom-0'
          onClick={showNotesetting}
        >
          ...
        </button>

        {notesetting ? <NoteSettings /> : null}
      </div>

      {notifications?.map((notification, index) => (
        <div className='flex justify-between border-b hover:bg-indigo-100' key={index}>
          <Notification key={index} notification={notification} />
        </div>
      ))}
      {notesetting ? (
        <button
          type='button'
          onClick={() => setNotesetting(false)}
          className='fixed inset-0 w-full h-full cursor-default focus:outline-none'
        />
      ) : null}
    </div>
  );
};
