/* eslint-disable */
import React, { useState, useRef } from 'react';
import ReactCrop from 'react-image-crop';
import Swal from 'sweetalert2';
import { getCroppedImg, resetInput } from './helper';
import { useStoreActions, useStoreState } from '../../store/hooks';

import 'react-image-crop/dist/ReactCrop.css';

interface UploadAvatarProps {
  innerRef: React.MutableRefObject<HTMLInputElement | null>;
}

export const UploadAvatar = ({innerRef}: UploadAvatarProps) => {
  const { user } = useStoreState((state) => state.userStore);

  const { updateUserThunk, setUser } = useStoreActions((actions) => actions.userStore);

  const inputRef = useRef<HTMLInputElement | null>(null);
  const [image, setImage] = useState('');
  const [showCropModal, setShowCropModal] = useState(false);
  const [imageRef, setImageRef] = useState({} as HTMLImageElement);
  const [crop, setCrop] = useState({
    aspect: 1,
    width: 50,
    height: 50,
    unit: 'px',
  } as ReactCrop.Crop);

  async function handleUploadImage() {
    if (imageRef && crop.width && crop.height) {
      const croppedImageUrl = (await getCroppedImg(imageRef, crop)) as string;
      const response = await updateUserThunk({
        profilePicture: croppedImageUrl,
      });
      setUser({
        ...user,
        profilePicture: response.data.profilePicture,
      });
      resetImageModal();
    }
  }

  function resetImageModal() {
    setImage('');
    setCrop({});
    setShowCropModal(false);
    if (inputRef.current !== null) {
      resetInput(inputRef.current, '');
    }
  }

  function handleImageLoaded(e: HTMLImageElement) {
    setImageRef(e);
  }

  async function handleImageSelect(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files[0].size > 2097152) {
      Swal.fire({
        icon: 'warning',
        title: 'File too big',
        text:
          'Image uploads are limited to 2mb, compress your image and try again',
      });
      resetImageModal();
      return false;
    }

    if (e.target.files && e.target.files.length > 0) {
      const reader: FileReader = new FileReader();
      reader.addEventListener('load', () => {
        setImage(reader.result as string);
      });
      reader.addEventListener('loadend', () => {
        setShowCropModal(true);
      });
      reader.readAsDataURL(e.target.files[0]);
      
      return true;
    }

    return false;
  }

  const attachRef = (element: HTMLInputElement) => {
    inputRef.current = element;
    innerRef.current = element;
  };

  return (
    <>
      {showCropModal && (
        <div className="fixed inset-0 flex justify-center items-center p-2 sm:p-40">
          <div className="bg-white shadow rounded-lg p-4">
            <div className="text-center text-gray-800">
              <h4 className="text-lg font-medium">Crop your picture</h4>
            </div>
            <div className="flex justify-center m-10 croppper">
              <ReactCrop
                src={image}
                crop={crop}
                onImageLoaded={(e) => handleImageLoaded(e)}
                imageStyle={{
                  maxWidth: 300,
                  height: 'auto',
                  width: '100%',
                }}
                onChange={(e) => {
                  setCrop({ ...e, aspect: 1 });
                }}
              />
            </div>
            <div className="flex justify-center">
              <button
                onClick={() => resetImageModal()} // prefer reset function
                className="px-4 py-2 mt-4 text-xs text-gray-700 border rounded-md cursor-pointer"
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 mt-4 ml-5 text-xs text-white rounded-md cursor-pointer"
                style={{
                  background: 'linear-gradient(to right, #4A80FA, #0A49D9)',
                }}
                onClick={() => handleUploadImage()}
              >
                Save picture
              </button>
            </div>
          </div>
        </div>
      )}
      <input
        id="change-profile-picture"
        className="hidden"
        type="file"
        accept="image/*"
        onChange={(e) => handleImageSelect(e)}
        ref={attachRef}
      />
    </>
  );
};
