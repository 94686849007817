import { FC } from 'react';
import { toFixedCustom } from 'utils/numberHelpers';

const progressBarColors = ['yellow-600', 'yellow-400', 'yellow-300', 'yellow-300', 'green-400', 'green-500'];

interface IProgressProps {
  rate: number;
  limit: number;
}

export const ProgressBar: FC<IProgressProps> = ({ rate, limit }) => {
  const percentage = toFixedCustom((100 / limit) * rate, 1, 0);
  const colorIndex = Math.round(parseFloat(percentage) / 20);

  return (
    <div className='flex-grow overflow-hidden h-2 text-xs flex rounded bg-gray-200'>
      <div
        style={{ width: `${percentage}%` }}
        className={`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-${progressBarColors[colorIndex]}`}
      ></div>
    </div>
  );
};

export default ProgressBar;
