import React from 'react';
import { useStoreActions } from '../../store/hooks';

export const WelcomeBoard = () => {
  const { setStep } = useStoreActions(actions => actions.onboardingStore);
  return (
    <div className='w-full h-full mt-12 text-center '>
      <div className='my-2 md:my-4'>
        <p className='text-2xl font-semibold text-indigo-800 md:text-3xl'>Welcome to AlphaSwap</p>
        <div className='my-12'>
          <p className='py-1 text-gray-800'>We would like to ask you a couple of questions so you'll get the best possible experience.</p>
          <p className='py-1 text-gray-800'>It will only take a minute!</p>
        </div>
        <button type='button' className='focus:outline-none' onClick={() => setStep(1)}>
          <p className='p-2 px-6 text-gray-700 border border-gray-400 rounded-sm'>Start onboarding</p>
        </button>
      </div>
    </div>
  );
};
