import { FormProvider, useForm } from 'react-hook-form';
import { useStoreActions, useStore } from 'store/hooks';
import { TextField, SelectInput, DatePicker } from 'components/fields';
import { jobList, countryList, hearAboutUsList } from 'utils/data';
import { ResponseStatusCode } from 'utils/enums';

export const BasicInfoBoard = () => {
  const form = useForm();

  const store = useStore();
  const { firstName, lastName, birthdate, nickname, job, country, hearAboutUs } =
    store.getState().onboardingStore.onboardingData;

  const { setStep, setOnboardingData, submitOnboardingThunk } = useStoreActions(actions => actions.onboardingStore);

  const lastNameWatch = form.watch('lastName');

  const onSubmit = async (formData: any) => {
    setOnboardingData(formData);
    const response = await submitOnboardingThunk();

    if (response === ResponseStatusCode.CREATED) {
      setStep(2);
    } else {
      form.setError('nickname', {
        message: 'This nickname already in use',
        type: 'unique',
      });
    }
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className='p-6'>
        <div className='grid gap-5'>
          <div className='grid sm:grid-cols-2 gap-5'>
            <div className='w-full text-gray-800'>
              <p className='text-gray-800 sm:text-base'>First Name</p>
              <TextField
                name='firstName'
                defaultValue={firstName}
                placeholder='Enter your first name'
                className='w-full px-3 py-2 border focus:border-indigo-500 focus:outline-none'
                rules={{
                  required: {
                    value: true,
                    message: 'This field is required',
                  },
                  minLength: {
                    value: 2,
                    message: 'Min length 2 chars',
                  },
                }}
              />
            </div>
            <div className='text-gray-800'>
              <p className='text-gray-800 sm:text-base'>Last Name</p>
              <TextField
                name='lastName'
                defaultValue={lastName}
                placeholder='Enter your last name'
                className='w-full px-3 py-2 border focus:border-indigo-500 focus:outline-none'
                rules={{
                  required: {
                    value: true,
                    message: 'This field is required',
                  },
                  minLength: {
                    value: 2,
                    message: 'Min length 2 chars',
                  },
                }}
              />
            </div>
          </div>
          <div>
            <p className='text-gray-800 font-inter'>Birthdate</p>
            <div>
              <DatePicker
                name='birthdate'
                defaultValue={birthdate}
                rules={{
                  required: {
                    value: true,
                    message: 'This field is required',
                  },
                }}
              />
            </div>
          </div>
          <div className='items-center justify-between'>
            <p className='text-gray-800'>Country</p>
            <div>
              <SelectInput
                name='country'
                defaultValue={country}
                options={countryList}
                rules={{
                  required: {
                    value: true,
                    message: 'Fields is required',
                  },
                }}
              />
            </div>
          </div>
          <div className='items-center justify-between'>
            <p className='text-gray-800'>Investment Experience</p>
            <div>
              <SelectInput
                name='job'
                defaultValue={job}
                options={jobList}
                rules={{
                  required: {
                    value: true,
                    message: 'Fields is required',
                  },
                }}
              />
            </div>
          </div>
          <div className='items-center justify-between'>
            <p className='text-gray-800'>How did you hear about AlphaSwap?</p>
            <div>
              <SelectInput
                name='hearAboutUs'
                defaultValue={hearAboutUs}
                options={hearAboutUsList}
                rules={{
                  required: {
                    value: true,
                    message: 'Fields is required',
                  },
                }}
              />
            </div>
          </div>
          <div className='text-gray-800'>
            <p className='text-gray-800 sm:text-base'>Choose your private nickname</p>
            <TextField
              defaultValue={nickname}
              placeholder='Enter your nickname here'
              className='w-full px-3 py-2 border focus:border-indigo-500 focus:outline-none'
              name='nickname'
              rules={{
                required: {
                  value: true,
                  message: 'This field is required',
                },
                minLength: {
                  value: 2,
                  message: 'Minimum 2 chars',
                },
                validate: {
                  isNotEqualToLastName: value => value !== lastNameWatch || "Nickname must doesn't match to Last name",
                },
              }}
            />
          </div>
          <div className='flex justify-center mx-auto mt-8'>
            <button className='focus:outline-none' type='submit'>
              <p className='p-2 px-6 text-gray-700 border border-gray-400 rounded-sm'>NEXT STEP</p>
            </button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
