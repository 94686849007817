import { useState } from 'react';
import { ChartTickIcon, ChartCloseIcon } from 'components/icons/generate';
import { IStockTreeContent, StockTreeDataSetKey } from './types';
import 'components/index.scss';

const content: IStockTreeContent = {
  exchangeListed: {
    isAvailable: true,
    isChildrenVisible: false,
    parentName: 'Exchange listed',
    childItems: [
      'AEX - Euronext Amsterdam',
      'AMEX - NYSE American Exchange',
      'ASX - Australian Securities Exchange',
      'BSE - Brussels Stock Exchange',
      'FRA - Frankfurt Stock Exchange',
      'GER - Deutsche Börse XETRA',
      'HKEX - Hong Kong Stock Exchange',
      'LSE - London Stock Exchange',
      'MIL - Milan Stock Exchange',
      'NASDAQ - NASDAQ Stock Exchange',
    ],
  },
  global: { isAvailable: true, isChildrenVisible: false, parentName: 'Global', childItems: [] },
  marketCap: {
    isAvailable: true,
    isChildrenVisible: false,
    parentName: '$100MM+ Market Cap',
    childItems: [],
  },
  commodities: {
    isAvailable: false,
    isChildrenVisible: false,
    parentName: 'Commodities',
    childItems: [
      'AEX - Euronext Amsterdam',
      'AMEX - NYSE American Exchange',
      'ASX - Australian Securities Exchange',
      'BSE - Brussels Stock Exchange',
      'FRA - Frankfurt Stock Exchange',
      'GER - Deutsche Börse XETRA',
      'HKEX - Hong Kong Stock Exchange',
      'LSE - London Stock Exchange',
      'MIL - Milan Stock Exchange',
      'NASDAQ - NASDAQ Stock Exchange',
    ],
  },
  etfs: {
    isAvailable: false,
    isChildrenVisible: false,
    parentName: 'ETFs',
    childItems: [
      'AEX - Euronext Amsterdam',
      'AMEX - NYSE American Exchange',
      'ASX - Australian Securities Exchange',
      'BSE - Brussels Stock Exchange',
      'FRA - Frankfurt Stock Exchange',
      'GER - Deutsche Börse XETRA',
      'HKEX - Hong Kong Stock Exchange',
      'LSE - London Stock Exchange',
      'MIL - Milan Stock Exchange',
      'NASDAQ - NASDAQ Stock Exchange',
    ],
  },
  funds: {
    isAvailable: false,
    isChildrenVisible: false,
    parentName: 'Funds',
    childItems: [
      'AEX - Euronext Amsterdam',
      'AMEX - NYSE American Exchange',
      'ASX - Australian Securities Exchange',
      'BSE - Brussels Stock Exchange',
      'FRA - Frankfurt Stock Exchange',
      'GER - Deutsche Börse XETRA',
      'HKEX - Hong Kong Stock Exchange',
      'LSE - London Stock Exchange',
      'MIL - Milan Stock Exchange',
      'NASDAQ - NASDAQ Stock Exchange',
    ],
  },
  forex: {
    isAvailable: false,
    isChildrenVisible: false,
    parentName: 'Forex',
    childItems: [
      'AEX - Euronext Amsterdam',
      'AMEX - NYSE American Exchange',
      'ASX - Australian Securities Exchange',
      'BSE - Brussels Stock Exchange',
      'FRA - Frankfurt Stock Exchange',
      'GER - Deutsche Börse XETRA',
      'HKEX - Hong Kong Stock Exchange',
      'LSE - London Stock Exchange',
      'MIL - Milan Stock Exchange',
      'NASDAQ - NASDAQ Stock Exchange',
    ],
  },
};

export const StockTree: React.FC = () => {
  const [dataSet, setDataSet] = useState(content);

  const handleListItem = (dataSetKey: StockTreeDataSetKey) => {
    setDataSet({
      ...dataSet,
      [dataSetKey]: {
        ...dataSet[dataSetKey],
        isChildrenVisible: !dataSet[dataSetKey].isChildrenVisible,
      },
    });
  };

  return (
    <div className={'px-2 py-4 m-auto bg-blue-100 border-2 border-blue-200 border-solid rounded-lg'}>
      <p className={'px-3 py-4 text-gray-800 lg:text-base'}>OUR STOCK UNIVERSE</p>

      <div className={'w-full p-1 m-auto text-gray-700'}>
        <div className={'overflow-auto stock'} style={{ height: 450 }} id={'journal-scroll'}>
          <ul className={'items-center text-start'}>
            {Object.keys(dataSet).map(dataSetKey => {
              const { isAvailable, parentName, childItems, isChildrenVisible } = dataSet[dataSetKey];

              return (
                <li className={'py-1'} key={dataSetKey}>
                  <div className={`flex my-1 md:justify-between ${childItems.length ? '' : 'pointer-events-none'}`}>
                    {isAvailable ? <ChartTickIcon className={'w-6 h-6'} /> : <ChartCloseIcon className={'w-6 h-6'} />}

                    <span
                      className={'mx-1 leading-relaxed text-sm xl:w-full lg:w-full md:w-56 box'}
                      onClick={() => handleListItem(dataSetKey)}
                    >
                      {parentName}

                      {childItems.length ? (
                        <div
                          className={`border-r-2 border-b-2 border-blue-200 border-solid w-2.5 h-2.5 ml-1.5 mt-1 transform rotate-45 ${
                            isChildrenVisible ? '-rotate-135' : ''
                          }`}
                        ></div>
                      ) : null}
                    </span>
                  </div>

                  {childItems.length ? (
                    <ul className={`px-2 py-1 text-xs nested ${isChildrenVisible ? 'active' : ''}`}>
                      {childItems.map((child, index) => (
                        <li className={'flex items-baseline'} key={index}>
                          <div className={'w-1 h-1 mx-2 bg-indigo-300 rounded-full'} />

                          {child}
                        </li>
                      ))}
                    </ul>
                  ) : null}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};
