import React from 'react';
import { StandardReactProps } from '../../../interfaces';

interface CheveronupIconProps extends StandardReactProps {
  viewbox?: string;
}

export const CheveronupIcon = ({ className, viewbox }: CheveronupIconProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox={viewbox} className={className}>
    <path d='M10.707 7.05L10 6.343 4.343 12l1.414 1.414L10 9.172l4.243 4.242L15.657 12z' />
  </svg>
);

CheveronupIcon.defaultProps = {
  viewbox: '',
};
