import { thunk, Thunk } from 'easy-peasy';
import { AxiosResponse } from 'axios';
import { ISecurity } from '@alphaswap/types';
import { IdeaObject } from 'interfaces';
import { client } from 'store/api';

interface IStockThunk {
  loadSymbolData: Thunk<this, string, any, {}, Promise<AxiosResponse<ISecurity>>>;
  loadIdeasWithSymbol: Thunk<this, string, any, {}, Promise<AxiosResponse<IdeaObject[]>>>;
}

export interface IStockModel extends IStockThunk {}

const stockStore: IStockModel = {
  /**
   * THUNKS
   */
  loadSymbolData: thunk(async (actions, symbolName) =>
    client.get<any, AxiosResponse<ISecurity>>(`/security/name/${symbolName}`)
  ),
  loadIdeasWithSymbol: thunk(async (actions, symbolName) =>
    client.get<any, AxiosResponse<IdeaObject[]>>(`/security/idea/${symbolName}`)
  ),
};

export default stockStore;
