import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { SecurityTimeseries } from './interfaces';
import { IdeaStatus } from './utils/enums';

export function showErrorModal(response: XMLHttpRequest) {
  if (response?.status) {
    const data = JSON.parse(response?.response);

    if (Array.isArray(data?.message)) {
      Swal.fire(data.error, data.message.join('\n'), 'warning');
    }

    Swal.fire(data.error, data.message, 'warning');
  }

  Swal.fire('Error', "Can't connect to server", 'warning');
}

export function findSelectOptionByValue(value: any, selectOptions: any[]) {
  return selectOptions.find(option => option.value === value);
}

/**
 *
 * @param content textarea unformatted text
 */
export function formatText(content?: string) {
  const text: any[] = [];
  if (content) {
    const parsedContent = content.split('\n');

    // eslint-disable-next-line
    for (const i in parsedContent) {
      text.push(parsedContent[i]);
      text.push(React.createElement('br', { key: i }));
    }

    return text;
  }
  return '';
}

export function useForceRender() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [value, setValue] = useState(0); // integer state
  // eslint-disable-next-line
  return () => setValue(value => ++value);
}

export function renderExpectedReturn(
  ideaStatus: IdeaStatus,
  tradeType: string,
  entryPrice: number,
  closedPrice: number,
  currentPrice: number
) {
  if (ideaStatus !== IdeaStatus.CLOSED) {
    if (!entryPrice) return 0;

    switch (tradeType) {
      case 'LONG':
        return Math.round((currentPrice / entryPrice - 1) * 100) / 100;
      case 'SHORT':
        return Math.round((1 - currentPrice / entryPrice) * 100) / 100;
      default:
        return Math.round((currentPrice / entryPrice - 1) * 100) / 100;
    }
  }

  switch (tradeType) {
    case 'LONG':
      return Math.round((closedPrice / entryPrice - 1) * 100) / 100;
    case 'SHORT':
      return Math.round((1 - closedPrice / entryPrice) * 100) / 100;
    default:
      return Math.round((closedPrice / entryPrice - 1) * 100) / 100;
  }
}

export function normalizePrice(price: number) {
  return Math.round(price * 100) / 100;
}

export function compileChartDataByTimeframe(timeseries?: SecurityTimeseries) {
  if (timeseries) {
    return timeseries['1y'].data?.map(ts => ({
      x: new Date(ts.date * 1000),
      y: [normalizePrice(ts.open), normalizePrice(ts.high), normalizePrice(ts.low), normalizePrice(ts.close)],
    }));
  }
  return undefined;
}
