import React from 'react';
import { StandardReactProps } from '../../../interfaces';

export const ChartCloseIcon = ({ className }: StandardReactProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 60 60' className={className}>
    <path fill='none' d='M-1-1h102v102H-1z' />
    <circle cx='31.922' cy='31.922' r='22.318' fill='#f2aeb6' />
    <path
      fill='#f79ba6'
      d='M32 9C19.318 9 9 19.317 9 32s10.318 23 23 23 23-10.317 23-23S44.682 9 32 9zm0 44c-11.58 0-21-9.421-21-21s9.42-21 21-21 21 9.421 21 21-9.42 21-21 21z'
    />
    <path
      fill='#f75b5b'
      d='M32 15c-9.374 0-17 7.626-17 17s7.626 17 17 17 17-7.626 17-17-7.626-17-17-17zm0 32c-8.271 0-15-6.729-15-15s6.729-15 15-15 15 6.729 15 15-6.729 15-15 15z'
    />
    <path
      fill='#f24b4b'
      d='M40.82 23.485a.85.85 0 00-1.21 0l-7.102 7.165-7.103-7.165c-.335-.338-.876-.338-1.21 0s-.336.884 0 1.222l7.102 7.165-7.103 7.165a.868.868 0 000 1.222.85.85 0 001.211 0l7.103-7.165 7.102 7.165a.85.85 0 001.21 0 .868.868 0 000-1.222l-7.102-7.165 7.103-7.165a.868.868 0 000-1.222z'
    />
  </svg>
);
