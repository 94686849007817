import React from 'react';
import { StandardReactProps } from '../../../interfaces';

export const CardBackground = ({ className }: StandardReactProps) => (
  <svg
    preserveAspectRatio='none'
    xmlns='http://www.w3.org/2000/svg'
    width='271.298'
    height='243.151'
    viewBox='0 0 271.298 243.151'
    className={className}
  >
    <g transform='translate(-1036.906 -131.36)'>
      <path
        d='M0,241.9s13.414-17.751,20.2-49.837c4.028-19.051-3.669-46.7,8.231-69.435,11.932-22.8,45.5-38.6,61.969-58.527C117.46,31.361,126.6,0,126.6,0'
        transform='translate(1037.305 131.5)'
        fill='none'
        stroke='#d1d1f4'
        strokeWidth='1'
      />
      <path
        d='M0,241.9s13.414-17.751,20.2-49.837c4.028-19.051-3.669-46.7,8.231-69.435,11.932-22.8,45.5-38.6,61.969-58.527C117.46,31.361,126.6,0,126.6,0'
        transform='translate(1078.428 132.306)'
        fill='none'
        stroke='#d1d1f4'
        strokeWidth='1'
      />
      <path
        d='M0,241.9s13.414-17.751,20.2-49.837c4.028-19.051-3.669-46.7,8.231-69.435,11.932-22.8,45.5-38.6,61.969-58.527C117.46,31.361,126.6,0,126.6,0'
        transform='translate(1121.971 132.306)'
        fill='none'
        stroke='#d1d1f4'
        strokeWidth='1'
      />
      <path
        d='M0,241.9s13.414-17.751,20.2-49.837c4.028-19.051-3.669-46.7,8.231-69.435,11.932-22.8,45.5-38.6,61.969-58.527C117.46,31.361,126.6,0,126.6,0'
        transform='translate(1170.352 132.306)'
        fill='none'
        stroke='#d1d1f4'
        strokeWidth='1'
      />
      <path
        d='M0,254.947S13.414,237.2,20.2,205.11c4.028-19.052-3.669-46.7,8.231-69.435,11.932-22.8,45.5-38.6,61.969-58.527,2.95-3.569,5.392-6.759,7.535-9.8'
        transform='translate(1209.863 119.263)'
        fill='none'
        stroke='#d1d1f4'
        strokeWidth='1'
      />
      <path
        d='M0,265.865s13.414-17.751,20.2-49.837c4.028-19.052-3.669-46.7,8.231-69.435,4.355-8.322,11.593-15.711,20.009-22.861'
        transform='translate(1259.05 108.345)'
        fill='none'
        stroke='#d1d1f4'
        strokeWidth='1'
      />
      <path
        d='M.462,286.759S14.525,266.5,21.309,234.414'
        transform='translate(1286.376 86.911)'
        fill='none'
        stroke='#d1d1f4'
        strokeWidth='1'
      />
    </g>
  </svg>
);
