import { useState } from 'react';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { MoreButtonProps } from './types';

export const MoreButton: React.FC<MoreButtonProps> = ({ options }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <div>
      <IconButton
        aria-label={'more'}
        aria-haspopup={'true'}
        onClick={handleClick}
        className={'w-9 h-9 font-bold leading-none'}
      >
        ···
      </IconButton>

      <Menu anchorEl={anchorEl} keepMounted open={open} onClose={handleClose} onClick={handleClose}>
        {options.map(({ title, onClickAction, customElement: CustomElement }) =>
          CustomElement ? (
            <CustomElement key={title} />
          ) : (
            <MenuItem key={title} onClick={event => onClickAction && onClickAction(event)}>
              {title}
            </MenuItem>
          )
        )}
      </Menu>
    </div>
  );
};
