import React from 'react';
import { StandardReactProps } from '../../../interfaces';

export const CalculatorIcon = ({ className }: StandardReactProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='17.167' height='21.864' className={className}>
    <path
      d='M15.843 0H1.324A1.325 1.325 0 000 1.324V20.54a1.325 1.325 0 001.324 1.324h14.519a1.325 1.325 0 001.324-1.324V1.323A1.325 1.325 0 0015.843 0zm.726 20.54a.727.727 0 01-.726.726H1.324a.727.727 0 01-.726-.726V1.323A.727.727 0 011.324.6h14.519a.727.727 0 01.726.726z'
      data-name='路径 831'
    />
    <path
      d='M15.158 1.708H2.007a.3.3 0 00-.3.3v3.758a.3.3 0 00.3.3h13.151a.3.3 0 00.3-.3V2.008a.3.3 0 00-.3-.3zm-.3 3.758H2.306V2.308h12.552z'
      data-name='路径 832'
    />
    <path
      d='M10.206 16.658H6.961a.3.3 0 00-.3.3v2.9a.3.3 0 00.3.3h3.245a.3.3 0 00.3-.3v-2.9a.3.3 0 00-.3-.3zm-.3 2.9H7.26v-2.3h2.652z'
      data-name='路径 833'
    />
    <path
      d='M5.258 16.658h-3.25a.3.3 0 00-.3.3v2.9a.3.3 0 00.3.3h3.25a.3.3 0 00.3-.3v-2.9a.3.3 0 00-.3-.3zm-.3 2.9H2.306v-2.3h2.652z'
      data-name='路径 834'
    />
    <path
      d='M10.206 11.917H6.961a.3.3 0 00-.3.3v2.9a.3.3 0 00.3.3h3.245a.3.3 0 00.3-.3v-2.9a.3.3 0 00-.3-.3zm-.3 2.9H7.26v-2.3h2.652z'
      data-name='路径 835'
    />
    <path
      d='M5.258 11.917h-3.25a.3.3 0 00-.3.3v2.9a.3.3 0 00.3.3h3.25a.3.3 0 00.3-.3v-2.9a.3.3 0 00-.3-.3zm-.3 2.9H2.306v-2.3h2.652z'
      data-name='路径 836'
    />
    <path
      d='M15.159 11.917h-3.25a.3.3 0 00-.3.3v7.64a.3.3 0 00.3.3h3.25a.3.3 0 00.3-.3v-7.64a.3.3 0 00-.3-.3zm-.3 7.64h-2.651v-7.042h2.653z'
      data-name='路径 837'
    />
    <path
      d='M10.206 7.175H6.961a.3.3 0 00-.3.3v2.9a.3.3 0 00.3.3h3.245a.3.3 0 00.3-.3v-2.9a.3.3 0 00-.3-.3zm-.3 2.9H7.26v-2.3h2.652z'
      data-name='路径 838'
    />
    <path
      d='M5.258 7.175h-3.25a.3.3 0 00-.3.3v2.9a.3.3 0 00.3.3h3.25a.3.3 0 00.3-.3v-2.9a.3.3 0 00-.3-.3zm-.3 2.9H2.306v-2.3h2.652z'
      data-name='路径 839'
    />
    <path
      d='M15.159 7.175h-3.25a.3.3 0 00-.3.3v2.9a.3.3 0 00.3.3h3.25a.3.3 0 00.3-.3v-2.9a.3.3 0 00-.3-.3zm-.3 2.9h-2.651v-2.3h2.653z'
      data-name='路径 840'
    />
  </svg>
);
