import React, { useState } from 'react';
import classNames from 'classnames';
import { ArrowUpIcon } from '../icons/contribution';
import { ICommentReplyFieldProps } from './types';

export const CommentReplyField: React.FC<ICommentReplyFieldProps> = ({
  onSubmit,
  placeholder,
  isChild,
  userAvatar,
}) => {
  const [value, setValue] = useState('');

  const handlePressEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && value) {
      onSubmit(value);
      setValue('');
    }
  };

  const submitHandler = () => {
    onSubmit(value);
    setValue('');
  };

  return (
    <div
      className={classNames('grid gap-4', {
        'grid-cols-auto-first': !isChild,
        'grid-cols-1 sm:grid-cols-auto-first': isChild,
      })}
    >
      <img src={userAvatar} alt='avatar' className='object-center w-16 h-16' />
      <div className='flex items-center justify-center flex-1'>
        <div className='grid grid-cols-auto-second items-center w-full h-10 p-0.5 border-2 border-indigo-600 rounded-full'>
          <input
            value={value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value)}
            onKeyPress={handlePressEnter}
            className='w-9/12 h-full mx-3 text-sm text-gray-700 sm:w-10/12 focus:outline-none md:text-base'
            placeholder={placeholder}
          />
          <button type='button' className='w-8 h-8 p-2 rounded-full bg-input-round-bg' onClick={submitHandler}>
            <ArrowUpIcon className='text-white fill-current ' />
          </button>
        </div>
      </div>
    </div>
  );
};

CommentReplyField.defaultProps = {
  isChild: false,
};
