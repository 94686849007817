import { UserCellIdeaType, IdeaCellLivePriceType } from './data';

export type UserCellDataType = {
  cellName: 'userId' | 'geo' | 'sector' | 'avgPerformance' | 'idea' | 'totalIdeas' | 'detailsLink';
  cellValue: string | number | null | UserCellIdeaType;
};

export type IdeaCellDataType = {
  cellName: 'symbol' | 'geo' | 'sector' | 'entryPrice' | 'livePrice' | 'targetPrice' | 'performance' | 'detailsLink';
  cellValue?: string | number | null | IdeaCellLivePriceType;
};

export type CellFunction = (arg: any) => any;

export enum TableName {
  users = 'topUsersTable',
  ideas = 'topIdeasTable',
}
