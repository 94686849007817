import { IdeaThesisBodyProps, TabNames } from './types';
import { ThesisTab, CompanyTab, FinancialsTab, ValuationTab, EsgTab } from './IdeaThesisTabs';

const IdeaThesisBody = ({ selectedTabName, thesis }: IdeaThesisBodyProps) => {
  const contentToRender = () => {
    switch (selectedTabName) {
      case TabNames.Thesis:
        return <ThesisTab contentValues={thesis.thesis} />;
      case TabNames.Company:
        return <CompanyTab contentValues={thesis.company} />;
      case TabNames.Financials:
        return <FinancialsTab contentValues={thesis.financials} />;
      case TabNames.Valuation:
        return <ValuationTab contentValues={thesis.valuation} />;
      case TabNames.Esg:
        return <EsgTab contentValues={thesis.esg} />;
      default:
        return null;
    }
  };

  return <div>{contentToRender()}</div>;
};

export default IdeaThesisBody;
