export const tc = {
  title: 'Alphaswap Ltd',
  subTitle: 'User Terms and Conditions – May 2021',
  note: 'PLEASE READ THESE TERMS AND CONDITIONS (“Terms”) CAREFULLY BEFORE USING THIS PLATFORM',
  versionStamp: '[26 January 2021] V 2.0',
  sections: [
    {
      title: 'INTRODUCTION',
      subSections: [
        {
          title: 'Company and Terms',
          paragraphs: {
            hasOrder: false,
            list: [
              'We are Alphaswap Ltd (CRN: 11027546). Our registered office is c/o BCS, Windsor House Station Court, Station Road, Great Shelford, Cambridge, Cambridgeshire, United Kingdom CB22 5NE.',
              'If you have any questions about these Terms, please contact us at support@alphaswap.io.',
              'By using our Platform you accept these Terms, if you do not agree to these Terms you must not use our Platform.',
              'By using our Platform we may process your personal data, we do this in accordance with our privacy policy, more information about this can be found here.',
            ],
          },
        },
        {
          title: 'Eligibility',
          paragraphs: {
            hasOrder: true,
            list: [
              'You must be 18 years of age or older to use our Platform. By using our Platform you confirm that you are 18 years of age or older and have legal capacity to enter into contracts in the country you live.',
              "AlphaSwap reserves the right to disable any user's account that is in breach of these Terms.",
            ],
          },
        },
        {
          title: 'Non-Consumers',
          paragraphs: {
            hasOrder: true,
            list: [
              'Where you are not a consumer, you confirm that you have authority to bind the business  on  behalf  of  which  you  are  accepting  these  Terms.  In  that  context, references to “you” or “your” will be to that business. Otherwise it will refer to you, the individual entering into these Terms.',
            ],
          },
        },
        {
          title: 'Changes to these Terms',
          paragraphs: {
            hasOrder: true,
            list: [
              'AlphaSwap  reserves  the  exclusive  right  to  modify  or  replace  these  Terms  at any time without prior notice. Your continued use of our Platform after any such changes constitutes your acceptance of the new Terms.',
            ],
          },
        },
      ],
    },
    {
      title: 'ACCOUNT CREATION AND SECURITY',
      subSections: [
        {
          title: 'Account Creation',
          paragraphs: {
            hasOrder: true,
            list: [
              'You may only create and maintain a single account to your person and in your name on our Platform (“User Account”).',
              'You must provide us with your full legal name when creating your User Account and any other information provided by you during the account creation process must be accurate, complete and current (“Registration Information”). Thereafter, you must update and maintain the Registration Information to keep it accurate, complete and current.',
              'Upon registration we grant to you the personal, non-transferable right and licence to use our Platform for your own internal business purposes, until terminated as set out in these Terms.',
            ],
          },
        },
        {
          title: 'Security',
          paragraphs: {
            hasOrder: true,
            list: [
              'You must treat any piece of information as part of our security procedures, including your username and password (together “Security Information”), as confidential and keep it secure and must never share your Security Information with anyone.',
              'You must not provide access to your User Account to another person, you cannot authorize another person’s access to your or any other User Account on our Platform and must not tolerate access of another person to your User Account.',
              'You must never use another User Account, even if the respective user has asked you to do so.',
              'You are fully and solely responsible for all use of your User Account and any activity that occurs on it.',
              'If you know or suspect that anyone other than you knows your Security Information or has logged in to or has used your User Account you must promptly notify us at support@alphaswap.io.',
              'You will be liable for any use of your User Account contravening these Terms, whether by you or anyone else, and for the losses of AlphaSwap or others due to such use.',
              'We reserve the right to disable any username or password at any time without giving prior notice.',
            ],
          },
        },
        {
          title: 'No Service Guarantees',
          paragraphs: {
            hasOrder: true,
            list: [
              'Our Platform is provided “as is”. We make no guarantee as to the quality of our Platform and its suitability for your individual purposes and will not be liable in the event you do not undertake your own prior due diligence.',
            ],
          },
        },
        {
          title: 'Withdrawal',
          paragraphs: {
            hasOrder: true,
            list: [
              'Access to our Platform is given on a temporary basis, and AlphaSwap reserves the right to withdraw or amend our Platform partially or fully without notice and at its sole discretion.',
            ],
          },
        },
        {
          title: 'Merchant of Record',
          paragraphs: {
            hasOrder: true,
            list: [
              'Where we pay out on your successful ideas we will use a merchant of record: [MERCHANT OF RECORD], to recover these fees and any applicable taxes. Any invoices or payments from your account will be under the name [MERCHANT OF RECORD].',
            ],
          },
        },
      ],
    },
    {
      title: 'OUR NEXT GENERATION ASSET MANAGEMENT PLATFORM',
      subSections: [
        {
          title: 'Platform',
          paragraphs: {
            hasOrder: true,
            list: [
              'Our community-driven asset management platform acts as a marketplace for investment ideas, connecting investment talent – our Idea Generators and Idea Voters – with experienced investors – our Idea Experts – as well as providing a tool for researching assets and building an investment track record (“Platform”).',
            ],
          },
        },
        {
          title: 'Ideas',
          paragraphs: {
            hasOrder: true,
            list: [
              'When you sign up to our Platform, you can use it to generate investment ideas with supporting documentation (including investment write-ups/theses, company information, financial models, financial projections, market trading information, profile information, and text/design/content of any kind) to justify your prediction (together the “Ideas”). These Ideas will be voted on by our Idea Voters with the possibility of being selected to be part of the Alpha Portfolio and ultimately, monetised. In addition to the Ideas, additional comments, messages or information may be provided in the course of voting (together the “Content”).',
              'Ideas you upload to our Platform must be the following: (i) accurate (where it states facts); (ii) be genuinely held (where it states opinions); (iii) comply with the law applicable in England and Wales; and (iv) comply with applicable laws and regulations regarding market abuse by any regulator.',
              'Any content you upload as an Idea to our Platform must not be the following: (i) defamatory of anyone or bullying, insulting, intimidating, discriminating or humiliating towards anyone; (ii) unlawful; (iii) promote sexually explicit material; (iv) promote violence; (v) infringe any copyright, database right or trade mark; (vi) deceptive or likely to deceive; (vii) give the impression that the Ideas originate from us or another person or entity without their authorisation; (viii) contain any advertising or promotion for another company and/or site; and (ix) be knowingly or technologically harmful.',
            ],
          },
        },
        {
          title: 'Idea Generators',
          paragraphs: {
            hasOrder: true,
            list: ['When you originate Ideas and upload them to our Platform, you act as an “Idea Generator”.'],
          },
        },
        {
          title: 'Idea Voters',
          paragraphs: {
            hasOrder: true,
            list: [
              'Whilst we would encourage you to originate Ideas, you can also use your account on our Platform to “vote” on whether or not you think other users’ Ideas on the Platform are accurate and subsequently will be a success. You can do this by co-investing using your available budget of Virtual Dollars. Your support may not only lead to rewards for the Idea Generator, but also for yourself as an “Idea Voter”.',
              'Any Content you upload to our Platform in the course of voting or co-investing must be the following: (i) accurate (where it states facts); (ii) be genuinely held (where it states opinions); and (iii) comply with the law applicable in England and Wales; (iv) comply with applicable laws and regulations regarding market abuse by any regulator.',
              'Any Content you upload to our Platform in the course of voting or co-investing must not be the following: (i) defamatory of anyone or bullying, insulting, intimidating, discriminating or humiliating towards anyone; (ii) unlawful; (iii) promote sexually explicit material; (iv) promote violence; (v) infringe any copyright, database right or trade mark; (vi) deceptive or likely to deceive; (vii) give the impression that the Ideas originate from us or another person or entity without their authorisation; (viii) contain any advertising or promotion for another company and/or site; and (ix) be knowingly or technologically harmful.',
            ],
          },
        },
        {
          title: 'Idea Experts',
          paragraphs: {
            hasOrder: true,
            list: ['You can register as an “Idea Expert” if you are an experienced financial services practitioner.'],
          },
        },
        {
          title: 'Commenting',
          paragraphs: {
            hasOrder: true,
            list: [
              'Users can comment on other users’ Content.',
              'Any comment you upload to our Platform must be the following: (i) accurate (where it states facts); (ii) be genuinely held (where it states opinions); and (iii) comply with the law applicable in England and Wales; (iv) comply with applicable laws and regulations regarding market abuse by any regulator.',
              'Any comment you upload to our Platform must not be the following: (i) defamatory of anyone or bullying, insulting, intimidating, discriminating or humiliating towards anyone; (ii) unlawful; (iii) promote sexually explicit material; (iv) promote violence; (v) infringe any copyright, database right or trade mark; (vi) deceptive or likely to deceive; (vii) give the impression that the Ideas originate from us or another person or entity without their authorisation; (viii) contain any advertising or promotion for another company and/or site; and (ix) be knowingly or technologically harmful.',
            ],
          },
        },
        {
          title: 'Virtual Dollars',
          paragraphs: {
            hasOrder: true,
            list: [
              'AlphaSwap applies the skin-in-the-game principle through the fact that both Idea Generators and Idea Voters have to commit virtual dollars in order to submit or support an Idea (“Virtual Dollars”).  If an Idea doesn’t work out, the Idea Generator and Idea Voters who contributed to the Idea will lose Virtual Dollars, ensuring full alignment of interest between Idea Generators and Idea Voters. Please note, when you commit Virtual Dollars to an Idea, you cannot withdraw those Virtual Dollars.',
            ],
          },
        },
        {
          title: 'Ideas in the Alpha Portfolio',
          paragraphs: {
            hasOrder: true,
            list: [
              'Core to the AlphaSwap ecosystem is a meritocratic pay out system where only successful users get paid at the exclusive discretion of AlphaSwap. Only Ideas with a high-quality investment thesis with enough votes from the other users of the Platform will be selected into our portfolio of best ideas (“Alpha Portfolio”).  Inclusion of an Idea in the Alpha Portfolio is at the exclusive discretion of AlphaSwap and can be based on a proprietary algorithm, which can take into account the Community’s voting on an Idea.  ',
            ],
          },
        },
      ],
    },
    {
      title:
        'COMPLIANCE OF BEHAVIOR AND CONTENT WITH APPLICABLE LAWS, RULES AND FINANCIAL MARKETS REGULATION AND CONTRACTUAL RESTRICTIONS',
      subSections: [
        {
          title: 'Compliance',
          header: 'Any and all Content must comply with all applicable laws, rules and financial markets regulations:',
          paragraphs: {
            hasOrder: true,
            list: [
              'You must not include or disclose any material non-public information (“MNPI”) in any Content you contribute to the Platform or convey such MNPI through any of your activities on the Platform.',
              'You must not make direct or indirect recommendations or inducements based on MNPI through any of your activities or any of your Content on the Platform.',
              'You must not make any such disclosures, recommendations or inducements based on MNPI onwards to others, on or through the Platform.',
              'You must not give false or misleading signals about the supply of, demand for or price of a financial instrument through your activities or Content on the Platform.',
              'You must not give false or misleading signals that secure or are likely to secure, the price of one or several financial instruments, at an abnormal or artificial level through your activities or Content on the Platform.',
              'You must not use fictitious devices or other deception or contrivance that is likely to affect, the price of one or several financial instruments through your activities or Content on the Platform.',
              'You must not disseminate, through your activities or Content on the Platform, information which gives, or is likely to give, false or misleading signals as to supply, demand or price of financial instruments; or secures, or is likely to secure, their price at an abnormal or artificial level, including circulating rumours when you know or ought to know the information is false or misleading.',
              'You must not submit information that would be a violation of your employer’s policies, nor will you submit information that violates any agreements to which you are subject, including, without limitation, any confidentiality and non-disclosure agreements.',
            ],
          },
        },
        {
          title: 'Consequences of Breaching',
          paragraphs: {
            hasOrder: true,
            list: [
              'Any activities or Content that appears to be or is illegal will be escalated to the appropriate authorities once it comes to AlphaSwap’s attention.',
            ],
          },
        },
        {
          title: 'Duty to Notify',
          paragraphs: {
            hasOrder: true,
            list: [
              'You must notify AlphaSwap at support@alphaswap.io immediately if you have knowledge of or suspect any Content on the Platform is breaching compliance with applicable laws, rules or financial markets regulation.',
            ],
          },
        },
      ],
    },
    {
      title: 'DISCLOSURE RULES',
      header: 'When submitting Content to our Platform you undertake to abide by the following disclosure rules:',
      subSections: [
        {
          title: 'Positioning',
          paragraphs: {
            hasOrder: true,
            list: [
              'You must disclose whether you have a long or short position (including stocks, options or other instruments) in any investments mentioned in Content you upload to the Platform at the time of uploading.',
              'If you intend, at the time of uploading Content, to sell or buy a stock mentioned in such Content within three days of such uploading, you must disclose this intention.',
              'Disclosures can be made to AlphaSwap at support@alphaswap.io.',
            ],
          },
        },
        {
          title: 'Material Relationships',
          header:
            'You must disclose any material relationships with companies whose stocks you write about in your Content or with parties that stand to gain in any way from the viewpoint you are outlining in your Content. Examples (non-exhaustive list):',
          paragraphs: {
            hasOrder: true,
            list: [
              'Board membership of a company whose stock you are writing about',
              'Directorship of a company whose stock you are writing about',
              'Employment by a company whose stock you are writing about',
              'Consulting for a company you write about',
              'Receiving of paid advertising revenue or any other form of sponsorship fee from a company you write about.',
              'This applies to narrow asset classes as well. For example, if you are paid to promote a gold dealer, that must be disclosed in any User Submission about gold.',
            ],
          },
          afterWords: ['Disclosures can be made to AlphaSwap at support@alphaswap.io.'],
        },
      ],
    },
    {
      title: 'PERFORMANCE OF OUR PLATFORM',
      subSections: [
        {
          title: 'Performance',
          paragraphs: {
            hasOrder: true,
            list: [
              'During your use of the Platform, we will take steps to ensure that the Platform functions as described at the point you signed up. Although we will introduce technology to prevent malware and viruses we do not guarantee that our Platform will be secure or free from bugs or viruses. You are responsible for configuring your information technology, computer programmes and platform to access our Platform.',
            ],
          },
        },
        {
          title: 'What Happens when the Platform Stops Working as Described',
          paragraphs: {
            hasOrder: true,
            list: [
              'In the event you report to us a substantial error with the Platform, we will seek to correct this within a reasonable amount of time – where legally permitted, this will be your sole remedy.',
            ],
          },
        },
        {
          title: 'We May Suspend or Withdraw our Platform',
          paragraphs: {
            hasOrder: true,
            list: [
              'We do not guarantee that our Platform, or any of the Ideas, will always be available or be uninterrupted. We may suspend or withdraw or restrict the availability of all or any part of our Platform for business and operational reasons.',
            ],
          },
        },
        {
          title: 'Responsibility for Internet Connection',
          paragraphs: {
            hasOrder: true,
            list: [
              'You are also responsible for ensuring that all persons who access our Platform through your internet connection are aware of and comply with these Terms.',
            ],
          },
        },
        {
          title: 'What Action We May Take in the Event of a Breach',
          header:
            'When we consider that a breach of these Terms has occurred, we may take such action as we deem appropriate including:',
          paragraphs: {
            hasOrder: true,
            list: [
              'immediate, temporary or permanent withdrawal of your right to use our Platform;',
              'immediate, temporary or permanent removal of any Ideas uploaded by you to our Platform;',
              'legal action against you; and/or',
              'disclosure of such information to law enforcement authorities as we reasonably feel is necessary or as required by law.',
            ],
          },
        },
        {
          title: 'You Must Maintain a Secure Internet Connection',
          paragraphs: {
            hasOrder: true,
            list: [
              'Where you lose access to the Platform and any Ideas due to a disruption in your telecommunications or internet services, we will in no way be liable for any losses suffered.',
            ],
          },
        },
        {
          title: 'Links to Other Sites',
          paragraphs: {
            hasOrder: true,
            list: [
              'Where our Platform contains links to other sites, Ideas and resources provided by third parties. These are provided for your information only. They should not be interpreted as approval by us of those linked websites or information you may obtain from them.',
            ],
          },
        },
        {
          title: 'Changes to the Platform',
          header: 'AlphaSwap reserves the exclusive right at any time to:',
          paragraphs: {
            hasOrder: true,
            list: [
              'Change the Platform, including, eliminating or discontinuing any content or feature of the Platform; or',
              'Impose fees, charges or other conditions for use of the Platform or parts thereof (with reasonable notice).',
            ],
          },
          afterWords: [
            'AlphaSwap may modify the Platform at any time without prior notice, and you accept those modifications if you continue to use the Platform. You should check the Platform frequently to see recent changes.',
          ],
        },
      ],
    },
    {
      title: 'INTERACTION WITH THE PLATFORM',
      subSections: [
        {
          title: 'Reverse Engineering',
          paragraphs: {
            hasOrder: true,
            list: [
              'Do not reverse engineer any of the Platform. You will not reverse engineer or otherwise attempt to derive or obtain information about the functioning, manufacture or operation of the Platform. Nor will you attempt to modify, translate, or create derivative works based on the Platform; or copy (save for archival purposes), rent, lease, distribute, pledge, assign or otherwise transfer or encumber rights to the Platform.',
            ],
          },
        },
        {
          title: 'Internal Business Use Only',
          paragraphs: {
            hasOrder: true,
            list: [
              'You acknowledge and agree that you can only use the Platform for internal business purposes only and may not transfer, sell, distribute, lease, sublease, assign or licence to any third parties.',
            ],
          },
        },
        {
          title: 'Unauthorised Access',
          paragraphs: {
            hasOrder: true,
            list: [
              'You must not attempt to gain unauthorised access. Whether this is to our Platform, the server on which our Platform is stored or any server, computer or database connected to our Platform.',
              'You must not attack our Platform via a denial-of-service attack or a distributed denial-of service attack. ',
              'By breaching these provisions, you would commit a criminal offence under the Computer Misuse Act 1990. We will report any such breach to the relevant law enforcement authorities and we will co-operate with those authorities by disclosing your identity to them. In the event of such a breach, your right to use our Platform will cease immediately.',
            ],
          },
        },
        {
          title: 'Technologically Harmful Behaviour',
          paragraphs: {
            hasOrder: true,
            list: [
              'You must not behave in a technologically harmful way on the Platform or introduce viruses, trojans, worms, logic bombs or other material that is malicious to the Platform.',
              'You must not interact with our Platform in any manner that could damage, disable, overburden or impair it.',
              'You must not collect information from or otherwise interact with the our Platform using automated scripts.',
            ],
          },
        },
      ],
    },
    {
      title: 'LIMITATIONS AND RISKS OF OUR PLATFORM',
      subSections: [
        {
          title: 'Limitations',
          paragraphs: {
            hasOrder: true,
            list: [
              'We do not provide investment, tax legal or insurance advice on our Platform. Nothing on our Platform should be construed as an offer to sell or buy, a solicitation of such offers, or a recommendation for any security by AlphaSwap or any third party. You alone are solely responsible for determining whether any investment, security or strategy, or any other product or service, is appropriate or suitable for you. Any Ideas or other content on our Platform should not be considered and is not intended to be advice.',
            ],
          },
        },
        {
          title: 'Risks',
          paragraphs: {
            hasOrder: true,
            list: [
              'You acknowledge that you could lose all of the money you have deposited into the Platform and you should not credit your account with money you cannot afford to lose.',
              'You understand that the returns via the Platform will vary and that you should seek professional advice if you do not understand the risks associated with using the Platform.',
            ],
          },
        },
      ],
    },
    {
      title: 'INTELLECTUAL PROPERTY',
      subSections: [
        {
          title: 'Our Platform',
          paragraphs: {
            hasOrder: true,
            list: [
              'We are the owner or the licensee of all intellectual property rights in our Platform, and in the materials we publish on it.  Those works are protected by copyright laws and treaties around the world. All such rights are reserved.',
              'We are the owner or the licensee of all intellectual property rights in the Platform including any modifications and improvements, whether made by us or suggested by you.  Those works are protected by copyright laws and treaties around the world. All such rights are reserved. We provide you with a worldwide, non-exclusive, non-transferable, non-sublicensable, revocable limited term licence for internal use only to use the Platform in accordance with these Terms. Such a right to use the Platform and any Ideas on the Platform will expire at the point your account is disabled or when we terminate these Terms, whichever is sooner.',
            ],
          },
        },
        {
          title: 'Content Transmitted through the Platform by Users',
          paragraphs: {
            hasOrder: true,
            list: [
              'You are the owner of all of the Content and other information you transmit through our Platform. You grant to AlphaSwap a worldwide, royalty-free, non-exclusive, transferable and sublicensable right to use your Ideas without limitation. We may also anonymise and/ or aggregate any Ideas or other content you add to our Platform.',
              'You acknowledge that where we use your Ideas, or create Ideas materially similar to or the same as any Ideas you have made public on our Platform, you will have no claims against us including for infringement or misappropriation.',
              'AlphaSwap does not guarantee long-term storage or back-up of Content submitted to the Platform.',
            ],
          },
        },
        {
          title: 'Trademarks',
          paragraphs: {
            hasOrder: true,
            list: [
              'The name AlphaSwap and other AlphaSwap trademarks, service marks, and logos of the Company (“Company Trademarks”) used and displayed on this Platform are registered and unregistered trademarks or service marks of the Company.',
              'Other company, product, and service names located on the Platform may be trademarks or service marks owned by third-parties (the “Third-Party Trademarks”, and, collectively with the Company Trademarks, the “Trademarks”). Nothing on this Platform or in these Terms should be construed as granting, by implication, estoppel, or otherwise, any license or right to use any Trademark displayed on this Platform without the prior written consent of the Company specific for each such use. The Trademarks may not be used to disparage the Company or the applicable third-party, the Company’s or third-party’s products or services, or in any manner (using commercially reasonable judgment) that may damage any goodwill in the Trademarks. Use of any Trademarks as part of a link to or from any web site is prohibited without the Company’s prior written consent',
            ],
          },
        },
      ],
    },
    {
      title: 'CANCELLATION, TERMINATION AND SUSPENSION',
      subSections: [
        {
          title: 'Termination of Your Subscription Term',
          paragraphs: {
            hasOrder: true,
            list: [
              'Your subscription will start on the date you sign up as a user of the Platform and agree to these Terms. Your access to the Platform will continue until the earlier of you disabling your account or us terminating your right to access the Platform. AlphaSwap reserves the exclusive right to terminate user accounts at its sole discretion without prior notice.',
            ],
          },
        },
        {
          title: 'Deletion of Data',
          paragraphs: {
            hasOrder: true,
            list: [
              'We will only retain your data for as long as we need it. Your data is usually deleted 12 months after the deactivation of your account with us, unless we are required to keep it for longer to comply with our legal, accounting or regulatory requirements. We will contact you by email 30 days before deactivating and deleting your account.',
            ],
          },
        },
        {
          title: 'Survival of Important Terms',
          paragraphs: {
            hasOrder: true,
            list: [
              'Please note that all rights under these Terms, which by nature should survive termination, will, including Indemnity, Liability, Governing Law, Notices and Intellectual Property.',
            ],
          },
        },
      ],
    },
    {
      title: 'INDEMNITY',
      subSections: [
        {
          title: 'Your Indemnity to Us',
          paragraphs: {
            hasOrder: true,
            list: [
              'You agree to indemnify us, our affiliates, directors, officers and employees against all loss, costs, damages liabilities and expenses that arise out of your breach of these Terms and/ or use of the Platform.',
            ],
          },
        },
      ],
    },
    {
      title: 'LIABILITY',
      subSections: [
        {
          title: 'We Do not Limit any Losses that We Are not Allowed to Limit',
          paragraphs: {
            hasOrder: true,
            list: [
              'We do not exclude or limit in any way our liability to you where it would be unlawful to do so including death or personal injury caused by our negligence.',
            ],
          },
        },
        {
          title: 'What We Do Limit',
          paragraphs: {
            hasOrder: true,
            list: [
              'We exclude all implied conditions, warranties, representations or other terms that may apply to our Platform or any Services on it. We will not be liable to you for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with the use of, or inability to use, our Platform; or use of or reliance on any Services displayed on our Platform.',
              'In particular, we will not be liable for loss of profits, sales, business, or revenue; business interruption; loss of anticipated savings; loss of business opportunity, goodwill or reputation; or any indirect or consequential loss or damage.',
            ],
          },
        },
        {
          title: 'No Service Guarantees',
          paragraphs: {
            hasOrder: true,
            list: [
              'The Platform is provided “as is”. We make no guarantee as to the quality of the Platform and its suitability for your individual purposes, and will not be liable in the event you do not undertake your own prior due diligence.',
            ],
          },
        },
        {
          title: 'Severability',
          paragraphs: {
            hasOrder: true,
            list: [
              'Each of the paragraphs of these Terms operates separately. If any court or relevant authority decides that any of them are unlawful or unenforceable, the remaining paragraphs will remain in full force and effect.',
            ],
          },
        },
      ],
    },
    {
      title: 'NOTICES',
      subSections: [
        {
          title: 'Where Do We Issue Notices to You',
          paragraphs: {
            hasOrder: true,
            list: [
              'We will issue all notices to you via the Platform save for any that will materially impact your rights or your use of the Platform which we will email to you, via the email you use to subscribe to the Platform.',
            ],
          },
        },
        {
          title: 'Complaints and Legal Disputes',
          paragraphs: {
            hasOrder: true,
            list: [
              'Where you have any complaints, are subject to insolvency (or similar) proceedings or wish to issue legal proceedings against us, you should send notice of these to support@alphaswap.io.',
            ],
          },
        },
      ],
    },
    {
      title: 'GOVERNING LAW',
      subSections: [
        {
          title: 'What Laws Apply to these Terms',
          paragraphs: {
            hasOrder: true,
            list: [
              'These Terms are governed by English law and you can bring legal proceedings in the English courts.',
            ],
          },
        },
        {
          title: 'As a Consumer',
          paragraphs: {
            hasOrder: true,
            list: [
              'However if you are a consumer you may also benefit from any mandatory provisions of the law of the country in which you are resident. Nothing in these Terms affects your rights as a consumer to rely on such mandatory provisions of local law.',
            ],
          },
        },
      ],
    },
  ],
};
