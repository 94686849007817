import React from 'react';
import { Controller, useFormContext, RegisterOptions } from 'react-hook-form';
import classNames from 'classnames';

type Option = {
  label: string;
  value: string | number;
};

interface LabelPickerProps {
  name: string;
  options: Option[];
  labelClass: string;
  inputClass?: string;
  activeClass: string;
  rules?: RegisterOptions;
  defaultValue?: string;
}

export const LabelPicker = ({ name, options, rules, labelClass, inputClass, activeClass, defaultValue }: LabelPickerProps) => {
  const { control } = useFormContext();
  return (
    <>
      <Controller
        rules={rules}
        defaultValue={defaultValue}
        name={name}
        control={control}
        render={({ onChange, ref, value }) => (
          <>
            {options.map(option => (
              <label
                key={option.value}
                className={classNames({
                  [labelClass]: Boolean(labelClass),
                  [activeClass]: value === option.value,
                })}
              >
                <span>{option.label}</span>
                <input className={inputClass} type='radio' onChange={onChange} ref={ref} name={name} value={option.value} />
              </label>
            ))}
          </>
        )}
      />
    </>
  );
};

LabelPicker.defaultProps = {
  inputClass: '',
  rules: undefined,
  defaultValue: undefined,
};
