import { v4 as uuid } from 'uuid';
import { TabProps, EsgTextTypes, TextWithoutOptions, PositivityOptions } from './types';
import { esgQuestionsContent } from 'pages/GenerationPage/InvestmentThesisSection/content';
import { stringNewlinesToJsxWithBr } from 'utils/stringHelpers';
import { NoDataPlaceholder } from './NoDataPlaceholder';

const POSITIVITY_OPTIONS = Object.values(PositivityOptions);

const text: EsgTextTypes = {
  controversy: {
    title: 'Controversies or other issues',
  },
  radial: [
    { title: 'Company\'s environmental efforts("E" of ESG)' },
    { title: 'Company\'s inclusion efforts("S" of ESG)' },
    { title: 'Company\'s shareholder value creation("G" of ESG)' },
  ],
};

const reasonWithoutOptionsContainer = (reasonValues: any, reasonQuestionContent: any, sectionKey: 'controversy') => {
  const { title }: TextWithoutOptions = text[sectionKey];
  return (
    <div className={`${sectionKey} mb-12`}>
      <h3 className='text-xl text-blue-500 font-medium mb-4'>{title}</h3>
      <ul className='flex flex-wrap'>
        {reasonValues.values.map((reasonIndex: number) => {
          return (
            <li
              key={uuid()}
              className='mt-2 py-1 px-6 rounded-lg items-center bg-blue-50 text-blue-400 border border-blue-400 mr-4'
            >
              {reasonQuestionContent.options[reasonIndex].label}
            </li>
          );
        })}
      </ul>
      {reasonValues.custom && <p>{reasonValues.custom}</p>}
      {reasonValues.description && (
        <div className='mt-8'>
          <h4 className='font-medium mb-2'>Comment:</h4>
          <p>{stringNewlinesToJsxWithBr(reasonValues.description)}</p>
        </div>
      )}
    </div>
  );
};

const EsgTab = ({ contentValues }: TabProps) => {
  if (!Object.keys(contentValues).length) return <NoDataPlaceholder />;

  const { controversy, ...radial } = contentValues;
  const { radial: radialContent, controversy: controversyContent } = esgQuestionsContent;

  return (
    <div>
      {radialContent.map((radialOption, index) => {
        const { name, content } = radialOption;

        return (
          <div key={uuid()} className='my-8'>
            <h3 className='text-xl text-blue-500 font-medium mb-4'>{text.radial[index].title}</h3>
            <ul>
              {content.map(item => {
                const { field, label } = item;
                const positivityOptionIndex = radial[name][field] - 1;
                return (
                  <li key={uuid()} className='flex justify-between mb-2'>
                    <p>{label}</p>
                    <p>{POSITIVITY_OPTIONS[positivityOptionIndex]}</p>
                  </li>
                );
              })}
            </ul>
            {radial[name].description && (
              <div>
                <h4 className='font-medium mb-2'>Comment:</h4>
                <p>{stringNewlinesToJsxWithBr(radial[name].description)}</p>
              </div>
            )}
          </div>
        );
      })}
      {reasonWithoutOptionsContainer(controversy, controversyContent, 'controversy')}
    </div>
  );
};
export default EsgTab;
