import { useState, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import Swal from 'sweetalert2';
import { FloatingMenu } from 'components/common/FloatingMenu';
import mixpanel from 'utils/mixpanelIntegration';
import { useStoreActions, useStoreState } from 'store/hooks';
import { ResponseStatusCode } from 'utils/enums';
import InvestmentThesisSections from 'pages/GenerationPage/InvestmentThesisSection';
import { menuOptions } from './menuOptions';

const EditIdeaPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { idea } = useStoreState(state => state.ideaStore);
  const { updateThesisThunk } = useStoreActions(actions => actions.editThesisStore);

  const form = useForm({
    defaultValues: useMemo(() => {
      return {
        executiveSummary: idea.executiveSummary,
        ...idea.thesis,
      };
    }, [idea]),
  });

  const history = useHistory();
  const [selectedSubCategory, setSelectedSubCategory] = useState('');

  const menuClickHandler = async (categoryName: string) => {
    setSelectedSubCategory(categoryName);
  };

  const submitFormHandler = async (data: any) => {
    const isFormValid = await form.trigger();

    if (isFormValid) {
      const response = await updateThesisThunk({
        id: idea.id,
        ...data,
      });

      if (response?.status === ResponseStatusCode.OK) {
        mixpanel.dispatchEvent('Edit idea: submitted');
        history.push(`/idea/${id}`);
      } else {
        Swal.fire('Error', response.data.message, 'error');
      }
    }
  };

  return (
    <div className={'flex mb-8'}>
      <div className={'flex-shrink-0 pt-8'}>
        <FloatingMenu
          classes={'sticky top-8'}
          menuOptions={menuOptions}
          selectedCategory={'investmentThesis'}
          selectedSubCategory={selectedSubCategory}
          onClick={menuClickHandler}
        />
      </div>
      <FormProvider {...form}>
        <form className={'flex-grow flex flex-col'} onSubmit={form.handleSubmit(submitFormHandler)}>
          <InvestmentThesisSections setSelectedSubCategory={setSelectedSubCategory} generateData={idea.thesis} />;
          <button
            type={'submit'}
            className={
              'mt-4 px-8 py-2 text-sm font-light text-white uppercase rounded-lg self-end whitespace-nowrap bg-blue-600 rounded-t-md'
            }
          >
            Submit
          </button>
        </form>
      </FormProvider>
    </div>
  );
};

export default EditIdeaPage;
