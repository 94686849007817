import { useEffect } from 'react';
import { Card, CardWithTitle } from 'components/common';
import { GeneralInfo } from 'components/generation';
import { TradeStrategy } from 'components/trade';
import mixpanel from 'utils/mixpanelIntegration';

const TradeDetailsSection: React.FC = () => {
  useEffect(() => {
    mixpanel.dispatchEvent('Create idea: Trade details');
  }, []);

  return (
    <div className={'pt-8'}>
      <CardWithTitle title={'Trade Details'}>
        <div className={'sm:flex'}>
          <Card className={'w-full mx-auto border border-blue-100 rounded-md sm:w-80 sm:mx-0'}>
            <p className={'p-4 text-sm font-light text-gray-500'}>GENERAL INFORMATION</p>
            <GeneralInfo />
          </Card>

          <div className={'grid gap-4 px-2 ml-4'}>
            <p className={'text-sm font-medium text-gray-700'}>
              Please insert here the details of your trading strategy
            </p>

            <div>
              <TradeStrategy />
            </div>
          </div>
        </div>
      </CardWithTitle>
    </div>
  );
};

export default TradeDetailsSection;
